import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HyperlinkService, ILinkRequest } from '../hyperlink.service';
import { IContentElementText, TextParagraphStyle, QuestionState, IContentElementCanvas, ImageStates, IContentElementCanvasPage, IContentElementCanvasDisplayElement, ElementType, IContentElementTextLink, IContentElement, FontFamily, IEntryStateCanvas } from '../models';
import { indexOf } from '../services/util';
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { CanvasService } from '../canvas.service';
import { TextToSpeechService } from '../text-to-speech.service';
import { getElementChildren } from '../../ui-item-maker/item-set-editor/models';

@Component({
  selector: 'element-render-canvas',
  templateUrl: './element-render-canvas.component.html',
  styleUrls: ['./element-render-canvas.component.scss']
})
export class ElementRenderCanvasComponent implements OnInit, OnChanges {

  @Input() element:IContentElementCanvas;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter?:number;
  @Input() allowTranscripts?:boolean;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() allowAudioPlaybackSpeed: boolean = false;
  @Input() allowVideoPlaybackSpeed: boolean = false;

  numPages: number
  currentPageIndex:number = 0

  currentPage = {displayList: []};
  activeBookMarks:IContentElement[];
  activeBookMarkId:string;
  clickedBookMark: IContentElement;
  viewedPages = new Map<number, boolean>();

  constructor(
    private canvasService: CanvasService,
    private hyperLinkService: HyperlinkService,
    private sanitizer: DomSanitizer,
    private whitelabel: WhitelabelService,
    public text2Speech: TextToSpeechService
  ) { }

  ngOnInit(): void {
    // {'left.em':contentElement.x, 'top.em':contentElement:element.y, 'width.em':contentElement.width, 'height.em':contentElement:element.height }
    // this.ensureState();
    this.element.pages.forEach((page,index) => {
      this.viewedPages.set(index, false)
    })

    if (!this.element.pages){
      this.element.pages = [ {displayList: []} ];
    }
    this.numPages = this.element.pages.length;
    this.gotoPage(0);

    this.hyperLinkService.linkRequestSecond.subscribe(this.onLinkRequest);
    this.hyperLinkService.requestForLinkUpdate.next({
      readerId: this.element.readerId,
      readerElementId: this.activeBookMarkId,
    })
  }


  ngOnChanges(changes: SimpleChanges) {
    // this.ensureState();
    if (changes.changeCounter) {
      this.ensureCurrentPageInBounds();    
    }
  }

  ensureCurrentPageInBounds(){
    if (this.element.pages){
      let i = indexOf(this.element.pages, this.currentPage);
      if (i==-1) {
        this.gotoPage(0);
      }
    }
  }

  isActiveBookmark(el){
    return (this.activeBookMarks.indexOf(el) !== -1)
  }

  onLinkRequest = (data:ILinkRequest) => {
    let elementIds = [];
    this.activeBookMarks = [];
    let targetPageIndex
    let debugIdRef = new Map();
    let elementIdRef = new Map();
    if (data.readerElementId){
      elementIds = data.readerElementId.split(',');
      elementIds.forEach(id => elementIdRef.set(id, true) )
    }
    if (data.bookmarkId) return this.onBookmarkRequest(data);
    if (data.readerId===this.element.readerId) {
      this.element.pages.forEach((page:IContentElementCanvasPage, pageIndex:number)=>{
        page.displayList.forEach((el:IContentElementCanvasDisplayElement)=>{
          if (el.elementType==ElementType.CANVAS_BOOKMARK ) {
            const config = <IContentElementTextLink> el;
            const bookmarkId = (''+config.readerElementId).trim();
            debugIdRef.set(bookmarkId, true);
            if (elementIdRef.has(bookmarkId)) {
              // console.log('match found', config.readerElementId)
              this.activeBookMarks.push(el);
              this.activeBookMarkId = data.readerElementId
              this.clickedBookMark = undefined;
              targetPageIndex = pageIndex
              this.currentPage = page;
            } 
          }
        })
      })
      // console.log(elementIdRef, debugIdRef)
      if (this.activeBookMarks.length){
        this.unsetBookmarks();
        this.gotoPage(targetPageIndex);
        setTimeout(()=> {
          const els = document.getElementsByClassName('bookmark-element is-active')
          if (els && els[0]){
            els[0].scrollIntoView({behavior: 'smooth'});
          }
        }, 300)
      }
    }
  }

  onBookmarkRequest = (data:ILinkRequest) => {
    this.activeBookMarkId = data.bookmarkId;
    if (this.activeBookMarkId) {
      if (this.element.bookmark2Page) {
        this.element.bookmark2Page.forEach((map)=>{
          if (map.bookmarkId==this.activeBookMarkId) {
            this.gotoPage(map.page-1)
          }
        })
      }
      setTimeout(()=> {
        const searchForClass = `bookmark id-${this.activeBookMarkId}`;
        const els = document.getElementsByClassName(searchForClass);
        if (els && els[0]){
          els[0].classList.add('is-active');
          els[0].scrollIntoView({behavior: 'smooth'});
        }
      }, 300);
    }
  }

  unsetBookmarks = () => {
    setTimeout(()=> {
      const els = Array.from(document.getElementsByClassName('bookmark is-active'));
      if (els){
        els.forEach(e => e.classList.remove('is-active'));
      }
    }, 300);
  }

  isContentElementBookmark = (contentElement) => (contentElement.elementType === 'canvas_bookmark_element');
  isContentElementText = (contentElement) => (contentElement.elementType === 'text' && contentElement.paragraphStyle=='regular');
  isContentElementDefault = (contentElement) => (!this.isContentElementBookmark(contentElement) && !this.isContentElementText(contentElement) );

  getTextStyle(contentElement) {
    return { 
      lineHeight: `${contentElement.lineHeight ? contentElement.lineHeight : 1.6}em`,
      fontFamily: this.getFontFamily(contentElement),
      whiteSpace: "pre-wrap",
      textAlign: contentElement.alignment,
      fontSize: `${contentElement.fontSize ? contentElement.fontSize : 1}em`,
      transform: `rotate(${contentElement.rotation}deg)`,
    }
  }

  getFontFamily(contentElement){
    if (this.whitelabel.getSiteFlag('IS_EQAO')){
      return contentElement.font ? contentElement.font : 'inherit'
    }
    else{
      return contentElement.font;
    }
  }

  bookMarkClicked(contentElement:IContentElement) {
    if (this.clickedBookMark != contentElement) {
      this.clickedBookMark = contentElement;
    } else this.clickedBookMark = undefined;
    this.unSetActiveBookMark();
  }

  changedPage() {
    this.clickedBookMark = undefined;
    this.unSetActiveBookMark();
  }

  unSetActiveBookMark() {
    this.activeBookMarks = [];
    this.activeBookMarkId = undefined;
    console.log("Canvas Unset Bookmark")
    this.hyperLinkService.canvasBookmarkChanged.next({
      readerId: this.element.readerId,
      readerElementId: undefined
    })
  }

  triggerCanvasPageChange() {
    this.canvasService.canvasPageNumChanged.next({
      canvasId: this.element.readerId,
      pageNum: this.currentPageIndex
    })
  }

  gotoPage(pageIndex:number){
    this.currentPageIndex = pageIndex;
    this.currentPage = this.element.pages[this.currentPageIndex]
    this.triggerCanvasPageChange()
    if (this.element.pages.length > 1){
      this.ensureState()
    }
    this.viewedPages.set(pageIndex, true)
    this.updateState();
  }
  gotoNextPage() {
    if (!this.isOnLastPage()) {
      this.gotoPage(this.currentPageIndex+1);
    }
  }
  gotoPrevPage() {
    if (!this.isOnFirstPage()) {
      this.gotoPage(this.currentPageIndex-1);
    }
  }
  isOnFirstPage = () => (this.currentPageIndex < 1);
  isOnLastPage = () => (this.currentPageIndex >= this.element.pages.length -1);
  isCurrentPage = (i:number) => (this.currentPageIndex === i);

  isPaginationActive(){
    if(this.element.pages.length <= 1) return false;
    return !this.element.isPaginationHidden;
  }

  getFilter() {
    // console.log(this.element.frame)
    //console.log(this.element.frame.dropShadowX)
    if (this.element.frame) return this.sanitizer.bypassSecurityTrustStyle("drop-shadow( "+this.element.frame.dropShadowX+"px "+this.element.frame.dropShadowY+"px "+this.element.frame.blurRadius+"px "+this.element.frame.shadowColor+" )");
    else '';
  }

  getPadding() {
    if (this.element.frame) return this.element.frame.padding;
    return '0'
  }

  getBackImg() {
    //console.log(this.element.bgImg);
    if (this.element.bgImg && this.element.bgImg.url) {
      //console.log("url('"+this.element.bgImg.url+"')")
      return this.sanitizer.bypassSecurityTrustStyle("url('"+this.element.bgImg.url+"')");
    }
    return ''
  }

  getBackImgUrlOnly() {
    if (this.element.bgImg && this.element.bgImg.url) {
      return this.element.bgImg.url;
    }
    return ''
  }

  getAltText() {
    if (this.element.bgImg && 'altText' in this.element.bgImg) {
      return this.element.bgImg.altText
    }
    return ''
  }

  getRotation(element:IContentElementText) {
    if (element.rotation) return "rotate("+element.rotation+"deg)";
    return '';
  }

  getCanvasDims(contentElement:IContentElementCanvasDisplayElement) {
    contentElement.canvasScaleWidth = contentElement.width
    contentElement.canvasScaleHeight = contentElement.height
    return contentElement;
  }

  getStyle() {
    const style = {}
    if (this.element.isNoInvertOnHiContrast && this.text2Speech.isHiContrast) {
      style["filter"] = 'invert(1)'
    }
    return style
  }

  ensureState() {
    if (this.questionState) {
      if (!this.questionState[this.element.entryId]) {
        console.log("initializing state");
        this.element.pages.forEach((page,index) => {
          this.viewedPages.set(index, false)
        })
        let entryState: IEntryStateCanvas = {
          type: ElementType.CANVAS,
          isCorrect: false,
          isStarted: false,
          isFilled: false,
          isResponded: false,
          pagesViewed: []
        };
        //console.log("initializing state")
        this.questionState[this.element.entryId] = entryState;
      }
    }
  }

  updateState(){
    if (this.questionState && this.element.pages.length > 1) {
      const isFilled = !Array.from(this.viewedPages.values()).includes(false);
      const isStarted = Array.from(this.viewedPages.values()).includes(true);
      const prevState = this.questionState[this.element.entryId]
      const pagesViewed = (prevState && prevState.pagesViewed && prevState.pagesViewed.size > 0) ?  prevState.pagesViewed : this.viewedPages
      let entryState: IEntryStateCanvas = {
        type: ElementType.CANVAS,
        isCorrect: true,
        isStarted: isStarted || prevState.isStarted,
        isFilled: isFilled || prevState.isFilled,
        isResponded: false,
        pagesViewed: pagesViewed
      };
      this.questionState[this.element.entryId] = entryState;
    }
  }


}
