import { Injectable } from '@angular/core';
import { initMappedList, IMappedList } from './data/util';
import { STUDENT_G9_COURSES, STUDENT_G9_COURSES_SIMPLE, G9_COURSES } from './data/constants';
import { IIdentifiedEntry, IDataDefinition } from './data/types';
import { IStudentAccount, IStudentTestSession } from './data/types';
import { IClassroomArchivedTeachers, IUserInfo, IClassroomSlot } from '../../app/ui-teacher/data/types';
import { IClassroom, ISemester, ISession} from "./data/types"
import { generateEntries, randArrEntry, randInt, randId, coinFlip, randDate, IAvailableSession } from "../ui-testadmin/demo-data.service";
import { randomIdentity } from "../constants/fakenames";
import { LangService } from '../core/lang.service';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { DATA_MAPPING_EQAO_G9_STUDENT, ICheckMarkMapping, IDataMappingG9Student } from './data/mappings/eqao-g9';
import { BC_SAMPLE_SCHOOL_FSA } from './data/sample-data/school';
import { WhitelabelService } from "../../app/domain/whitelabel.service";


export const generateAccessCode = (len: number) => {
  let result = '';
  const characters = 'ABCDEFGHJKLMNPQRTUVWXYZ12346789';
  const charactersLength = characters.length;
  for (var i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export const hyphenateAccessCode = (accessCode: string) => {
  if (!accessCode) { return accessCode; }
  let result = '';
  const hyphenInterval = 4;
  for (var i = 0; i < accessCode.length; i++) {
    if (i > 0 && (i % hyphenInterval == 0)) {
      result += '-'
    }
    result += accessCode.charAt(i);
  }
  return result;
}

const G9_SLUG_TO_CAPTION = {
  G9_SAMPLE: 'g9_sample_test',
  G9_OPERATIONAL: 'g9_assess_math',
  OSSLT_SAMPLE: 'lbl_osslt_practice_test',
  OSSLT_OPERATIONAL: 'lbl_osslt_test',
}
 
@Injectable({
  providedIn: 'root'
})

export class G9DemoDataService {

  teachers: IMappedList<IIdentifiedEntry>;
  schoolAdminStudents: IMappedList<IStudentAccount>;
  classOptions: IMappedList<IDataDefinition>;
  classrooms: IClassroom[];
  semesters: IMappedList<ISemester>;
  teacherClassrooms: IMappedList<any>;
  schoolData: IMappedList<any>;
  assessments:IMappedList<ISession>;
  private studentMappings = DATA_MAPPING_EQAO_G9_STUDENT;
  public checkMarkMapping: ICheckMarkMapping = {};
  private classroomMappings = [
    { source: 'id', target: 'id' },
    { source: 'name', target: 'class_code' },
    { source: 'teacher', target: 'educator' },
    { source: 'teacher_uid', target: 'teacher_uid' },
    { source: 'semester_id', target: 'semester' },
    { source: 'semester_foreign_id', target: 'semester_foreign_id' },
    { source: 'num_students', target: 'students' },
    { source: 'group_type', target: 'course_type' },
    { source: 'is_grouping', target: 'is_grouping' },
  ];
  private schoolDistrictGroupId: number
  activeSessionsCount:number;
  scheduledSessionsCount:number;

  constructor(
    private lang: LangService,
  ) {
    // const res = require("./data/sample-response.json");
    // this.init(BC_SAMPLE_SCHOOL_FSA)
  }

  initCheckmarkMapping() {
    this.studentMappings.forEach((studentMapping: IDataMappingG9Student) => {
      this.checkMarkMapping[studentMapping.target] = studentMapping.checkMarkMap;
    })
  }

  init(res: any) {

    this.initCheckmarkMapping();
    
    const studentData = res.students || [];
    const teacherData = res.teachers || [];
    const semesterData = res.school_semesters || [];
    const sessionData = res.sessions || [];
    const classesData: any[] = res.classes || [];
    const mapBy = (arr, prop) => {
      const m = new Map();
      arr.forEach(entry => {
        m.set(entry[prop], entry)
      })
      return m;
    }

    const metaKeys = [];


    // access_code: "ANTW"
    // caption: null
    // date_time_start: "2020-11-05T01:45:18.000Z"
    // school_class_id: 9
    // slug: "G9_SAMPLE"
    // test_session_id: 584


    //creates map from student id value to student data
    const studentByUid = mapBy(studentData, 'id');
    const teachersByUid = mapBy(teacherData, 'id');
    const classesByGroupId = mapBy(classesData, 'group_id');
    const classesByClassId = mapBy(classesData, 'id');
    const teacherByClassGroupId = new Map();

    res.classes_sessions = res.classes_sessions || [];
    res.classes_closed_sessions = res.classes_closed_sessions || [];
    res.classes_teachers = res.classes_teachers || [];
    res.student_meta = res.student_meta || [];
    res.classes_students = res.classes_students || [];

    classesData.forEach(classroom => {
      classroom.when = (classroom.notes || '').split('When:').join('')
      classroom.students = []
      classroom.openAssessments = []
      classroom.recentAssessments = []
      classroom.scheduledAssessments = []
    })

    res.classes_sessions.forEach(session => {
      const classroom = classesByClassId.get(session.school_class_id);
      let currDate = new Date();
      let sessionDate = new Date(session.date_time_start)
      if (currDate.getTime() < sessionDate.getTime()) {
        //scheduled for the future
        classroom.scheduledAssessments.push(session)
      }
      else{
        classroom.openAssessments.push(session);
      }
    });

    res.classes_closed_sessions.forEach(session => {
      const classroom = classesByClassId.get(session.school_class_id);
      const closeTime = new Date(session.closed_on);
      const openTime = new Date(session.date_time_start);
      if(closeTime.getTime() > openTime.getTime()){
        classroom.recentAssessments.push(session);
      }
    })

    res.student_meta.forEach(meta => {
      const student = studentByUid.get(meta.uid);
      student[meta.key] = meta.value;
      metaKeys.push(meta.key)
    })

    res.classes_teachers.forEach(entry => {
      const teacher = teachersByUid.get(entry.uid)
      const classroom = classesByGroupId.get(entry.group_id);
      classroom.teacher_uid = entry.uid;
      if (teacher) {
        teacher.classes = teacher.classes || [];
        teacher.semesters = teacher.semesters || [];
        classroom.teacher = teacher.first_name + ' ' + teacher.last_name;
        teacher.classes.push(classroom.name)
        teacher.semesters.push(classroom.semester_id)
        teacherByClassGroupId.set(entry.group_id, teacher)
      }
    })


    res.classes_students.forEach(entry => {
      const student = studentByUid.get(entry.uid)
      const teacher = teacherByClassGroupId.get(entry.group_id)
      const classroom = classesByGroupId.get(entry.group_id);
      if (student) {
        student.class_code = classroom.name;
        student.teacher = classroom.teacher;
        student.classroomIds = student.classroomIds || []
        student.classroomIds.push(entry.group_id);
        student.semester_id = classroom.semester_id
        // classroom.semesters = classroom.semesters || []
        // classroom.semesters.push(student.Program); //This doesn't seem right
        // classroom.course_id = classroom.course_ids || []
        classroom.courses = classroom.courses || []
        const relevantCourse = STUDENT_G9_COURSES.map[student.Program];
        if (relevantCourse) {
          classroom.courses.push(this.lang.tra(relevantCourse.course));
        }
        classroom.course_id = student.Program;
        classroom.num_students = classroom.num_students || 0;
        classroom.num_students++;
        if (teacher) {
          teacher.num_students = teacher.num_students || 0;
          teacher.num_students++;
        }
      }
    });


    classesData.forEach(classroom => {
      classroom.courses = _.uniq(classroom.courses).join(', ')
    })

    teacherData.forEach(teacher => {
      teacher.classes = teacher.classes || []
      teacher.classes = teacher.classes.join(', ')
      //teacher.semester = 'test'
    })

    const studentsList = [];

    for (let student of studentByUid.values()) {
      const studentNormalized = this.apiToClientPayloadStudent(student)
      studentsList.push(studentNormalized);
      if (student.classroomIds) {
        student.classroomIds.forEach(classGroupId => {
          const classroom = classesByGroupId.get(classGroupId);
          classroom.students.push(studentNormalized)
        })
      }
    }
    this.schoolAdminStudents = initMappedList(studentsList);
    const sampleStudents = this.schoolAdminStudents.list;
    const nameToId = new Map();
    const classOptionsList = classesData.map((classroom, index) => {
      const id = classroom.id;
      nameToId.set(classroom.name, id);
      return { id, label: classroom.name };
    });
    for (let student of sampleStudents) {
      student.eqao_g9_class_code_label = student.eqao_g9_class_code;
      student.eqao_g9_class_code = nameToId.get(student.eqao_g9_class_code);
    }
    this.classOptions = initMappedList(classOptionsList);
    const teachersList = [];
    for (let teacher of teacherData) {
      let time = moment.tz('2020-10-28 17:00', moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      let time2 = moment.tz('2020-11-07 17:00', moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      let secret_key;
      let invit_id
      if (teacher.created_on) {
        time = moment.tz(teacher.created_on, moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      }
      if (teacher.expire_on) {
        time2 = moment.tz(teacher.expire_on, moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      }

      const name = teacher.first_name + " " + teacher.last_name;
      teachersList.push({
        id: teacher.id,
        label: name,
        invigilator: name,
        firstName: name.trim().split(" ")[0],
        lastName: name.trim().split(" ")[1],
        email: teacher.contact_email,
        invit_id: teacher.invit_id || null,
        secret_key: teacher.secret_key || null,
        invit_email: teacher.invit_email || null,
        isConfirmed: !!teacher.contact_email,
        classCode: teacher.classes,
        semester:teacher.semesters,
        students: teacher.num_students || 0,
        startTime: time, //This and below are still sample data
        endTime: time2
      });
    }

    this.teachers = initMappedList(teachersList);


    // console.log('classesData', classesData)
    this.semesters = initMappedList(semesterData.map(semester => {
      return {
        id: semester.id,
        foreign_scope_id: semester.foreign_scope_id,
        foreign_id: semester.foreign_id,
        label: semester.name
      }
    }))

    if(!Array.isArray(sessionData)){
      this.activeSessionsCount = 0;
      this.scheduledSessionsCount = 0;
      this.assessments = initMappedList(sessionData.school_sessions.map(session => {
        return {
          id: session.test_session_id,
          school_name:null,
          invigilator: `${session.first_name} ${session.last_name}`,
          classroom_id: session.school_class_id,
          firstName: session.first_name,
          lastName: session.last_name,
          description: '',
          slug:session.slug,
          classCode: session.name,
          students: session.num_students,
          startTime: this.getDate(session.date_time_start),
          endTime: '',
          status: this.returnSessionStatus(session.date_time_start) ? 'active' : 'pending',
          submissions: this.getSubmissionStatus(sessionData.session_states, session).length,
          //submissions: 0,
          isConfirmed: coinFlip()
        }
      })) 
    }
    
    this.teacherClassrooms = initMappedList(classesData.map(classroom => {
      classroom.scheduledAssessments.forEach(session => this.processSessionEntry(session));
      classroom.openAssessments.forEach(session => this.processSessionEntry(session));
      classroom.recentAssessments.forEach(session => this.processSessionEntry(session));
      
      return {
        id: classroom.id,
        name: classroom.name,
        classCode: hyphenateAccessCode(classroom.access_code),
        owner: '',
        isAssigned: !(classroom.is_grouping == 1),
        group_id: classroom.group_id,
        curricShort: classroom.group_type || 'EQAO_G9',
        // curricShort: `${this.lang.tra('txt_g9_math')} (${classroom.courses})`,
        enableClassListingByCC: true,
        currentStudents: initMappedList(classroom.students.map(student => {
          return {
            ...student,
            displayName: [student.first_name, student.last_name].join(' ')
          }
        })),
        currentTeachers: [],
        openAssessments: classroom.openAssessments,
        recentAssessments: classroom.recentAssessments,
        scheduledAssessments: classroom.scheduledAssessments,
        timeCreated: '--',
        timeLastTouched: '--',
      }
    }));

    this.schoolData = res.school[0];
    this.classrooms = [];
    for (let classroom of classesData) {
      this.classrooms.push(this.apiToClientPayloadClass(classroom));
    }
  }

  public processSessionEntry(session: any) {
    const mStart = moment.tz(session.date_time_start, moment.tz.guess());
    session.timeDirectStart = mStart.format(this.lang.tra('datefmt_day_month'));
    session.dateTimeStartLong = mStart.format(this.lang.tra('datefmt_sentence'));
    session.hourDirectStart = mStart.format(this.lang.tra('timefmt_hour_time'));
    if(session.closed_on) {
      const mClosed = moment.tz(session.closed_on, moment.tz.guess());
      session.timeDirectClose = mClosed.format(this.lang.tra('datefmt_day_month'));
    }

    session.name = this.lang.tra(G9_SLUG_TO_CAPTION[session.slug] || 'New Assessment');
    return session;
  }

  getAPITargetMapping(sourceMapping: string): string {
    let targetMapping = '';
    let mapping = this.studentMappings.find(mapping => mapping.source === sourceMapping);
    if (mapping) {
      targetMapping = mapping.target;
    }
    return targetMapping;
  }

  generateStudentInvigilationRecord(studentAccount) {
    // console.log(JSON.stringify(studentAccount))
    // studentAccount.status = {};
    return {
      studentAccount,
      session_a: { // this should be an array
        is_session_active: true,
      },
    }
  }
  getSubmissionStatus(session_states, session) {
    const submissions = []
    const states = session_states.find(state => { return state.test_session_id === session.test_session_id }).states.studentStates;

    for (const [key, value] of Object.entries(states)) {
      for (const [innerKey, innerValue] of Object.entries(value)) {
        if (innerKey === 'is_submitted' && innerValue === 1) {
          submissions.push(key)
        }
      }
    }
    return submissions;
  }
  getDate(dateTime) {
    const mStart = moment.tz(dateTime, moment.tz.guess());
    let formatted = mStart.format(this.lang.tra('datefmt_sentence'));
    formatted = (formatted === 'Invalid date') ? this.lang.tra('sa_invalid_date') : formatted;
    return formatted;
  }
  returnSessionStatus(dateTime) {
    let currDate = new Date();
    let sessionDate = new Date(dateTime)
    if (currDate.getTime() > sessionDate.getTime()) {
      this.activeSessionsCount++;
      return true;
    }
    this.scheduledSessionsCount++;
    return false;
  }
  public timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours > 0) {
      return rhours + "hr " + rminutes + "min";
    }
    return rminutes + "min";
  }

  getStudentsByClassroomId(classroomId: string): IMappedList<IStudentAccount> {
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom) {
      return classroom.currentStudents;
    }
  }

  getSessionByClassroomId(classroomId: string, sessionId: string) {
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom && classroom.openAssessments) {
      for (let i = 0; i < classroom.openAssessments.length; i++) {
        const session = classroom.openAssessments[i];
        if (('' + session.test_session_id) === ('' + sessionId)) {
          return session;
        }
      }
      for (let i = 0; i < classroom.scheduledAssessments.length; i++) {
        const session = classroom.scheduledAssessments[i];
        if (('' + session.test_session_id) === ('' + sessionId)) {
          return session;
        }
      }
    }

    return {};
  }

  getClassroomNameById(classroomId: string) {
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom) {
      return classroom.name;
    }
    return '';
  }
  getClassroomById(classroomId: string){
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom) {
      return classroom;
    }
    return '';

  }

  apiToClientPayloadClass(fromAPI: any): IClassroom {
    let newPayload: any = {};

    this.classroomMappings.forEach((mapping: { source: string, target: string }) => {
      let val = _.get(fromAPI, mapping.source);
      newPayload[mapping.target] = val;
    });

    newPayload.course_type = newPayload.course_type || 'EQAO_G9';
    newPayload.is_grouping = +(newPayload.is_grouping || 0);
    
    const courseId = fromAPI.course_id;
    const course = STUDENT_G9_COURSES.map[courseId];
    if (course) {
      const isInPerson = course.inPerson;
      const isRemote = course.remote;
      const courseType = course.course;
      const simpleCourseId = STUDENT_G9_COURSES_SIMPLE.list.filter(item => item.course === courseType)[0].id;
      newPayload.course = simpleCourseId;
      newPayload.remote_courses_sdc_student_rem_inp_1 = isInPerson;
      newPayload.remote_courses_sdc_student_rem_inp_2 = isRemote;
    }
    newPayload.openAssessments = []; //Here and below is still unchanged from the sample data
    newPayload.recentAssessments = [];
    newPayload.onboarding = 0;
    newPayload.assessment = 0;
    return newPayload;
  }

  //Use this when saving the data back to the API
  clientToApiPayloadClass(fromClient: IClassroom): any {
    let newPayload: any = {};

    this.classroomMappings.forEach((mapping: { source: string, target: string }) => {
      _.set(newPayload, mapping.source, fromClient[mapping.target]);
    });

    const simpleCourse = STUDENT_G9_COURSES_SIMPLE.map[fromClient.course];
    const courseType: G9_COURSES = simpleCourse.course;
    const complexCourse = STUDENT_G9_COURSES.list.filter((course) => {
      return course.course === courseType &&
        course.inPerson === fromClient.remote_courses_sdc_student_rem_inp_1 &&
        course.remote === fromClient.remote_courses_sdc_student_rem_inp_2;
    })[0];
    newPayload.course_id = complexCourse.id;

    return newPayload;
  }

  apiToClientPayloadStudent(fromAPI: any): IStudentAccount {
    let newPayload: IStudentAccount = { id: fromAPI.id, first_name: "", last_name: "" }; //id is only here because it is required for IStudentAccount (not sure why), but we only use uid
    this.studentMappings.forEach((mapping: { source: string, target: string }) => {
      let val = _.get(fromAPI, mapping.source);
      newPayload[mapping.target] = val;
    });
    if (!newPayload['eqao_is_g9'] && !newPayload['eqao_is_g10']){
      newPayload['eqao_is_g9'] = '1'
    }
    newPayload['test_sessions'] = [];
    newPayload.status = { isOnline: false, assessments: {} };
    return newPayload;
  }

  clientToApiPayloadStudent(fromClient: Partial<IStudentAccount>): any {
    let newPayload: any = { id: 0, first_name: "", last_name: "", roles: [] };

    this.studentMappings.forEach((mapping: { source: string, target: string }) => {
      _.set(newPayload, mapping.source, fromClient[mapping.target]);
    });

    return newPayload;
  }

  // generateStudents(len=30){
  //   const mappedList = initMappedList(generateEntries(len, (i) => {
  //     const lang = this.lang.getCurrentLanguage();
  //     const random = genericIdentity(this.lang.tra('sample_student_last_name'), lang);
  //     const courses = STUDENT_G9_COURSES_SIMPLE.list
  //     const classroom = randArrEntry([
  //       {classCode:'MFM1P - AP - 3', course:courses[0].id},
  //       {classCode:'MPM1D - AC - 1', course:courses[1].id},
  //       {classCode:'MPM1D - AC - 2', course:courses[1].id},
  //     ])
  //     return {
  //       id: randInt(1000,9999),
  //       uid: randId(),
  //       email: random.email,
  //       group_id: 0, //randInt(1000,9999),
  //       first_name: random.firstName,
  //       last_name: random.lastName,
  //       displayName: random.name,
  //       eqao_student_gov_id: String(randInt(Math.pow(10, 8), Math.pow(10, 9) - 1)),
  //       test_sessions: [],
  //       lang: randArrEntry(['EN', 'FR']),
  //       eqao_g9_course: <string> classroom.course,
  //       classCode: coinFlip(0.9) ? classroom.classCode : undefined,
  //     }
  //   })) ;
  //  mappedList.list.sort((a, b) => {
  //     const _a = a;
  //     const _b = b;
  //     if (_a.last_name > _b.last_name){ return 1 }
  //     if (_a.last_name < _b.last_name){ return -1 }
  //     if (_a.first_name > _b.first_name){ return 1 }
  //     if (_a.first_name < _b.first_name){ return -1 }
  //     else{ return 0 }
  //   })
  //   return mappedList;
  // }


}
