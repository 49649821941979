import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { DistrictDetail, SchoolDetail } from "../../bc-accounts/bc-accounts.service";
import { GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'fsa-student-result',
  templateUrl: './fsa-student-result.component.html',
  styleUrls: ['./fsa-student-result.component.scss']
})
export class FsaStudentResultComponent implements OnInit {

  @Input() testWindow: TestWindow;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;

  loading: boolean;

  analysisCodes = ['1','2','3','4','5','8','9'];
  suspenseErrors: any[];

  penToSearch: string = '';
  selectedAnalysisCode: string = '';
  selectedSuspenseError: string = '';

  gridOptions: GridOptions = {
    rowData: [],
    columnDefs: [
      { field: 'pen', headerName: "PEN", width: 120, },
      { field: 'first_name', headerName: "First Name", width: 150, },
      { field: 'last_name', headerName: "Last Name", width: 150, },
      { field: 'grade', headerName: "Grade", width: 120, },
      { field: 'district_code', headerName: "District Code", width: 120, },
      { field: 'school_code', headerName: "School Code", width: 120, },
      { field: 'assessment', headerName: "Assessment", width: 120, },
      { field: 'analysis_code', headerName: "Analysis Code", width: 150, },
      { field: 'suspense_errors', headerName: "Suspense Errors", width: 150, },
    ],
    defaultColDef: {
      suppressMenu: true,
      floatingFilter: true,
      sortable: true,
      resizable: true,
      filter: true,
    },
    enableCellTextSelection: true,
    animateRows: true,
    pagination: true,
    paginationPageSize: 10,
    onGridReady: (event: GridReadyEvent) => {
    },
  };

  constructor(
    private auth: AuthService,
    private routes: RoutesService,
  ) { 
    this.loading = false;
  }

  gridExport = () => this.downloadAsCsv(this.gridOptions);


  async ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    // if (changes.schoolDetail || changes.testWindow || changes.districtDetail) {
    //   await this.getData()
    // }
  }

  ngOnInit(): void {
    this.getSuspenseErrors();
  }

  async init() {
    await this.getData();
  }

  async getData(all: boolean = false): Promise<void> {
    if (!this.gridOptions) return;

    const districtGroupId = this.districtDetail?.groupId;
    const schoolGroupId = this.schoolDetail?.groupId;

    this.loading = true;
    this.gridOptions.api.showLoadingOverlay();
    let response = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_FSA_ANALYSIS_REPORT, this.testWindow.id, {
      query: all ? {} : {
        districtGroupId,
        schoolGroupId, 
        analysisCode: this.selectedAnalysisCode,
        suspenseError: this.selectedSuspenseError,
        pen: this.penToSearch
      }
    })

    response = response.map(res => ({
      ...res,
      assessment: res.assessment == 'lt' ? 'Literacy' : 'Numeracy',
      analysis_code: res.analysis_code || '-',
      suspense_errors: res.suspense_errors || '-'
    }))

    this.gridOptions.api.setRowData(response);
    this.loading = false;
    this.gridOptions.api.hideOverlay();
  }

  async downloadAll() {
    if (this.loading) return;

    try {
      await this.getData(true);

      this.gridOptions.api.exportDataAsCsv();
    } catch(err) {

    }
  }

  public downloadAsCsv(gridOptions:any){
    let params = {
      processCellCallback: (params: any) => {
        return params.value;
      }
    };

    gridOptions.api.exportDataAsCsv(params);  
  }
    
  async getSuspenseErrors() {
    try {
      this.suspenseErrors = await this.auth.apiFind('public/bc-fsa/suspense-errors', {});
    } catch(err) {
      console.log(err);
    }
  }
}
