import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { AccountType } from '../constants/account-types';
import { LoginGuardService } from '../api/login-guard.service';
import { BreadcrumbsService } from '../core/breadcrumbs.service';
import { LangService } from '../core/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ui-sidebar',
  templateUrl: './ui-sidebar.component.html',
  styleUrls: ['./ui-sidebar.component.scss']
})
export class UiSidebarComponent implements OnInit {


  constructor(
      public lang:LangService,
      private auth: AuthService,
      private route: ActivatedRoute,
      private routes: RoutesService,
      private loginGuard: LoginGuardService,
      private breadcrumbsService: BreadcrumbsService,
      private router: Router,
  ) { }

  ngOnInit() {
   
  }


}
