import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MyInstitutionService, IInstitutionInfo } from '../my-institution.service';
import { IInstitution } from '../../api/models/db/institutions.schema';
import { LangService } from '../../core/lang.service';
import { AccountType } from '../../constants/account-types';
import { DemoDataService } from '../demo-data.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { Subscription } from 'rxjs';

export enum InstMngView {
  INST_INFO = 'mng-inst-info',
  ACCOUNTS = 'mng-inst-accounts',
  ALL_SESSIONS = 'all-test-sessions',
  SEB_VALIDATION = 'seb-validation',
  STUDENTS = 'mng-students-accounts',
  REPORTING = 'mng-reporting',
}



@Component({
  selector: 'pnel-my-institution',
  templateUrl: './pnel-my-institution.component.html',
  styleUrls: ['./pnel-my-institution.component.scss']
})
export class PnelMyInstitutionComponent implements OnInit, OnDestroy {

  @Input() currentView: InstMngView;
  subscription = new Subscription();
  public instInfo: IInstitutionInfo;
  public InstMngView = InstMngView;

  constructor(
    public lang: LangService,
    private myInst: MyInstitutionService,
    private whitelabel: WhitelabelService,
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.myInst.sub().subscribe(this.updateInstInfo)
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateInstInfo = (instInfo: IInstitutionInfo) => {
    this.instInfo = instInfo;
  }

  isMeInstMngr() {
    return this.myInst.isInstMngr();
  }

  isShowBookingStats() {
    return this.isMeInstMngr() && this.whitelabel.getSiteFlag('BOOKING_POLICY_STATS');
  }

  isMeAccommCoord() {
    return this.myInst.isAccommCoord();
  }

  isMngStudents() {
    return this.whitelabel.getSiteFlag('INST_MNG_TT');
  }

  getRouterMngInstInfo ()        { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/mng-inst-info`; }
  getRouterMngInstAccounts ()    { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/mng-inst-accounts`; }
  getRouterMngStudentAccounts () { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/mng-students-accounts`; }
  getRouterReporting()           { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/mng-reporting`; }
  getRouterAllTestSessions ()    { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/all-test-sessions`; }
  getRouterPendingAccomm ()      { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/pending-accomm/${this.instInfo.id}`; }
  getRouterPendingAppeals ()      { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/pending-appeals/${this.instInfo.id}`; }

}
