import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RenderModeService {

  private inAuthoringMode:boolean = false;

  turnAuthoringOff() {
      this.inAuthoringMode = false
  }

  turnAuthoringOn() {
      this.inAuthoringMode = true
  }

  isAuthoring() {
      return this.inAuthoringMode
  }

}