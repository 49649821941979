import { Component, OnInit, Input } from '@angular/core';
import { IContentElementAnnotatedText, IContentElementImage, QuestionState } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';


@Component({
  selector: 'element-render-annotation',
  templateUrl: './element-render-annotation.component.html',
  styleUrls: ['./element-render-annotation.component.scss']
})
export class ElementRenderAnnotationComponent implements OnInit {

  @Input() element:IContentElementAnnotatedText;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState; // not used
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
  ) { }

  ngOnInit() {
    // console.log('element', this.element)
  }

  isRightAlign(){
    return !this.isLeftAlign();
  }
  isLeftAlign(){
    return this.element.isLeftAligned;
  }

  getTextStyle() {
    const style:any = {}
    style["font-family"]=this.getFontFamily();
    // style["text-align"]=this.element.alignment;
    style["font-size"]=this.getFontSize();
    style["transform"]=this.getRotation();
    style["color"]= this.element.colour;
    // style["line-height"]= this.getLineHeight()

    return style;
  }

  getFontFamily() {
    if (this.element.font){
      return this.element.font;
    }
    return 'inherit';
  }

  getFontSize(){
    if (this.element.fontSize){
      return `${this.element.fontSize}em`;
    }
    return 'inherit';
  }

  getRotation() {
    if (this.element.rotation) return "rotate("+this.element.rotation+"deg)";
    return '';
  }

  // getLineHeight() {
  //   if (this.element.lineHeight){
  //     return `${this.element.lineHeight}em`;
  //   }
  //   return '1.3em';
  // }

  getAnnotationContainerTopHeight(){
    if (this.element.annotationContainerHeight){
      return this.element.annotationContainerHeight;
    }
    return -0.75;
  }
}