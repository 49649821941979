import { Component, Input, OnInit } from '@angular/core';
import { BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';


const TWTAR_FSA_SLUG_COMPONENT_LENGTH = 3;

@Component({
  selector: 'fsa-assessment-keys',
  templateUrl: './fsa-assessment-keys.component.html',
  styleUrls: ['./fsa-assessment-keys.component.scss']
})
export class FsaAssessmentKeysComponent implements OnInit 
{
  @Input() testWindow: TestWindow;

  isLoading: boolean = false;
  assessments: TestWindow["allAssessments"] = [];
  selectedAssessment: string;
  // allTwtdar type is test_window_td_alloc_rules table's columns
  // todo: make interface for twtdar and use as type
  allTwtdar: any[] = []; 
  uniqueAssessments: string[] = [];
  currentComponents: string[] = [];
  selectedComponent: string;
  // selectedTwtdarRow type is test_window_td_alloc_rules table's columns
  // todo: make interface for twtdar and use as type
  selectedTwtdarRow: any = null;

  // data to be displayed; certain columns from the TQR table; type depends on
  // which columns are currently pulled from the TQR, depending on the query.
  currentTQRParams: any[] = []; 
  constructor
  (
    private bcAssessments: BcAssessmentsService, 
  ) 
  { 
    
  }

  async ngOnInit(): Promise<void>
  {
    if (this.testWindow == null)
    {
      return;
    }

    this.allTwtdar = await this.bcAssessments.getAllTwtdars(this.testWindow.id); 
    this.uniqueAssessments = this.getAssessmentsToDisplay();
    // this.uniqueAssessments = this.cleanAssessmentNames(this.uniqueAssessments);
    this.assessments = ["Select an Assessment"].concat(this.uniqueAssessments);
    // console.log(this.allTwtdar, this.uniqueAssessments);
    this.selectedAssessment = this.assessments[0];

  }

  getKeys(arrOfObj: Object[]): any[]
  {
    // this function returns the keys for any array of objects
    // this function assumes all the keys of each object in the array are the exact same,
    // as is often the case when getting data from the API using SQL.
    // console.log(Object.keys(arrOfObj[0]));
    return Object.keys(arrOfObj[0]);
  }

  getValues(obj: Object): any[]
  {
    // this function returns all the values for a specific object /
    // if all objects in an array have the same keys, the order of the keys (above function)
    // and values from this function will always match.
    // console.log(Object.values(obj));
    return Object.values(obj);
  }

  public getAssessmentsToDisplay(): string[]
  {
    // returns unique assessment list from twtdars for the test window
    return Array.from(new Set(this.allTwtdar.map((twtdarRow) => 
    {
      // console.log(twtdarRow);
      return this.getTwtdarSlugWithoutComponent(twtdarRow.slug);
    })));
  }

  public getTwtdarSlugWithoutComponent(slug: string): string
  {
    return slug.slice(0, slug.length - TWTAR_FSA_SLUG_COMPONENT_LENGTH);
  }

  public getComponentFromTwtdarSlug(slug: string): string
  {
    return slug.slice(slug.length - TWTAR_FSA_SLUG_COMPONENT_LENGTH +1);
  }

  public cleanAssessmentNames(assessments: string[]): string[]
  {
    return assessments.map(assessment => assessment.replace("_", " "));
  }

  public undoCleanAssessmentName(assessment: string)
  {
    return assessment.replace(" ", "_");
  }

  public findAllComponentsForAssessment(assessment: string): string[]
  {
    return this.allTwtdar.filter((twtdarRow) => 
    {
      return this.getTwtdarSlugWithoutComponent(twtdarRow.slug) === assessment;
    }).map((twtdarRow) => 
    {
      return this.getComponentFromTwtdarSlug(twtdarRow.slug);
    });
    
  }

  public onSelectedAssessmentChange()
  {
    if (this.selectedAssessment === this.assessments[0])
    {
      this.currentTQRParams = [];
      return;
    }

    this.currentComponents = this.findAllComponentsForAssessment(this.selectedAssessment);
    this.selectedComponent = this.currentComponents[0];
    // make API call to get data
    this.getTQRExtract();
  }

  public async getTQRExtract()
  {
    this.isLoading = true;
    this.selectedTwtdarRow = this.findRequestedTwtdarRow();
    this.currentTQRParams = await this.bcAssessments.getTqrParamsByTwtar(this.selectedTwtdarRow.id, false);
    this.isLoading = false;
  }

  public findRequestedTwtdarRow(): any
  // return type is test_window_td_alloc_rules table's columns
  // todo: make interface for twtdar and use as type
  {
    const foundRows = this.allTwtdar.filter((twtdarRow) =>
    {
      return this.getComponentFromTwtdarSlug(twtdarRow.slug) === this.selectedComponent 
      && this.getTwtdarSlugWithoutComponent(twtdarRow.slug) === this.selectedAssessment
    });

    if (foundRows.length > 1)
    {
      console.error("Found more than one twtdar row, error. Picking first one.");
    }

    else if (foundRows.length === 0)
    {
      console.error("Found no matching twtdar rows, error.");
      throw new Error("Unknown error.");
    }

    return foundRows[0];
  }

  public export()
  {
    // todo: change exportTQRParams to be FSA specific where needed
    this.bcAssessments.exportTQRParams
    (
      this.getTestWindowName(this.testWindow),
      this.selectedAssessment, 
      this.selectedComponent, 
      this.selectedTwtdarRow.test_design_id,
      false
    );
  }

  public getTestWindowName(testWindow: TestWindow)
  {
    const title = testWindow.title;
    if (title["en"] != null)
    {
      return title["en"];
    }

    else if (title["fr"] != null)
    {
      return title["fr"];
    }
  }

  public onSelectedComponentChange()
  {
    // make API call to get data
    this.getTQRExtract();
  }

}
