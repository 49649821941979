
import { Component, OnInit, Input, SimpleChanges, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit {
  constructor() {}

  @Input() id: string;
  @Input() labels: string[];
  @Input() data: number[];

  public chart: any;

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.createChart(this.labels, this.data);
  }

  createChart(labels: string[], data: number[]) {
    if (this.chart) {
      this.chart.destroy?.();
    }

    const canvas = document.getElementById(this.id) as HTMLCanvasElement;

    console.log(canvas);

    this.chart = new Chart(canvas, {
      type: 'pie',
      data: {
        labels,
        datasets: [{
          label: 'My First Dataset',
          data,
          backgroundColor: [
            'rgb(203, 108, 229)',
            'rgb(92, 225, 230)',
            'rgb(126, 217, 86)',			
          ],
        }],
      },
      options: {
        legend: {
          display: true,
          position: 'right'
        },
        // plugins: {
        //   datalabels: {
        //     color: 'white'
        //   }
        // }
      },
      // plugins: [ChartDataLabels], // can't use this plugin since it conflicts with legacy packages
    });
  }

}

