import * as moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { RoutesService } from '../api/routes.service';
import { AuthService, IUserInfo } from '../api/auth.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ITestSessionSetup } from '../api/models/db/test-session-setups.schema';
import { ITestSessionSetupTimeSlots } from '../api/models/db/test-session-setup-time-slots.schema';
import { MyInstitutionService, IInstitutionInfo } from './my-institution.service';
import { ChecklistStepRoute } from './pnel-cts-checklist/model/checklist-steps';
import { ITestWindow } from '../api/models/db/test_windows.schema';
import { BreadcrumbsService } from '../core/breadcrumbs.service';
import { Router } from '@angular/router';
import { LangService } from '../core/lang.service';
import { AccountType } from '../constants/account-types';
import { LoginGuardService } from '../api/login-guard.service';
import * as DBT from '../api/models/db-types';


export interface ITestSessionSetupInfo {
  __reqNew?          : boolean,
  id?                : number,
  institId?          : number,
  createdByUid?      : number,
  createdOn?         : string,    // date
  isActive?          : boolean,
  isCancelled?       : boolean,
  isAvailable?       : boolean,
  checkTestWindow?   : boolean,
  checkLocation?     : boolean,
  checkTechReady?    : boolean,
  checkDateTime?     : boolean,
  checkAccom?        : boolean,
  checkRestricted?   : boolean,
  checkSessAvail?    : boolean,
  checkSendInvites?  : boolean,
  testWindowId?      : number,
  techReadyFormStore?: string,
  accommCoordUid?    : number,
  deliveryFormat?    : string,
  is_mobile_tether?  : number;
  is_video_conference?: number;
  timezone?          : string;
}

export interface ILocation {
  __reqRemove?:boolean,
  __number?: number, 
  id?: number,
  setupId?: string | number,
  room: string,
  campusBuilding: string,
  capacity: number,
  address: string,
  phoneNumber: string,
  city: string,
  province: string,
  postalCode: string,
  isRemoved?: boolean,
  isRemote?: boolean,
  videoStreamLink?: string,
  videoStreamPassword?: string,
  videostream_link?: string,
  videostream_password?: string,
}

export interface ITimeSlot {
  __reqRemove?:boolean,
  id?: number,
  setupId?: number ,
  locationId?: number
  dateTimeStart?: number,
  isHidden?: boolean,
  isAccessCodeProtected?: boolean,
  accessCode?:string,
  isRemoved?: boolean,
  videostream_link?: string;
  videostream_password?: string;
  capacity?: number;
  is_overlapping?: number;
}

export interface ITestWindowInfo {
  id: number,
  title: string,
  dateStart: number,
  dateEnd: number,
  testDesignId: number,
  notes: string,

  is_allow_test_centre: number;
  is_allow_classroom: number;
  is_allow_remote: number;
  is_allow_mobile_tether: number;
  is_allow_video_conference: number;
}

export const SORT_BY_DATETIMESTART = (a,b)=>{
  if (a.dateTimeStart === b.dateTimeStart){
    return 0
  }
  if (a.dateTimeStart > b.dateTimeStart){
    return 1
  }
  return -1;
}



@Injectable({
  providedIn: 'root'
})
export class MyTestSessionsSetupService {

  public updateChecklistState:BehaviorSubject<ITestSessionSetupInfo> = new BehaviorSubject(null);

  private info: BehaviorSubject<ITestSessionSetupInfo> = new BehaviorSubject(null);
  private _info: ITestSessionSetupInfo;
  private instInfo: IInstitutionInfo;
  private isCheckingForSetup:boolean = true;
  private infoUpdateSub:BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isCreating:boolean;
  private isCancelling:boolean;
  private isUserInitialized:boolean;

  constructor(
    private auth: AuthService,
    private myInst: MyInstitutionService,
    private routes: RoutesService,
    private breadcrumbsService: BreadcrumbsService,
    private router:Router,
    private loginGuard: LoginGuardService,
    private lang:LangService,
  ) { 
    this.auth.user().subscribe(this.updateUserInfo);
    this.infoUpdateSub.subscribe(this.getInfo);
    this.info.subscribe(v => {
      this._info = v;
      this.updateChecklistState.next(this._info);
    });
  }

  generateBreadcrumbs(setupId:string, currentPageNameTrans:string){
    return [
      this.breadcrumbsService.TESTADMIN_LANDING(),
      this.breadcrumbsService.TESTADMIN_DASHBOARD(),
      this.breadcrumbsService.TESTADMIN_CREATE_TEST_SESSION(setupId),
      this.breadcrumbsService._CURRENT(this.lang.tra(currentPageNameTrans), this.router.url),
    ]
  }

  private updateInstInfo = (instInfo:IInstitutionInfo) => {
    this.instInfo = instInfo;
  }

  private updateUserInfo = (userInfo:IUserInfo) => {
    if (userInfo){
      this.refresh();
    }
    else{
      this.info.next(null);
    }
  }

  private getInfo = (isReady:boolean) => {
    if (!isReady){ return; }
    let hasRunOnce = false;
    this.myInst
    .sub()
    .subscribe(instInfo => {
      this.updateInstInfo(instInfo);
      if (!instInfo){
        this.info.next(null);
      }
      else if(!hasRunOnce){
        if (!this.myInst.isSessionReleaser()){
          this.info.next(null);
        }
        else{
          hasRunOnce = true;
          this.isCheckingForSetup = true;
          const instit_group_id = instInfo.groupId;
          this.auth
            .apiFind(
              this.routes.TEST_ADMIN_TEST_SESSION_SETUP_ACTIVE, 
              this.constructPermissionsParams()
            )
            .then( recs => {
              // console.log('refreshing setup info', recs)
              this.updateInfo(recs[0]);
              this.isCheckingForSetup = false
            })
            .catch( e => {
              console.error('Failed to pull records', e);
              this.isCheckingForSetup = false;
            })
        }
      }

    })
  }

  private updateInfo = (rec: ITestSessionSetup | null) => {
    // console.log('my session setups :::: update info')
    // console.log(rec);
    if (!rec) {
      this.info.next({
        __reqNew: true,
      });
    } else {
      this.info.next({
        id                : rec.id,
        institId          : rec.instit_id,
        createdByUid      : rec.created_by_uid,
        createdOn         : <string>rec.created_on,
        isActive          : !!rec.is_active,
        isCancelled       : !!rec.is_cancelled,
        isAvailable       : !!rec.is_available,
        checkTestWindow   : !!rec.check_test_window,
        checkLocation     : !!rec.check_location,
        checkTechReady    : !!rec.check_tech_ready,
        checkDateTime     : !!rec.check_date_time,
        checkAccom        : !!rec.check_accom,
        checkRestricted   : !!rec.check_restricted,
        checkSessAvail    : !!rec.check_sess_avail,
        checkSendInvites  : !!rec.check_send_invites,
        testWindowId      : rec.test_window_id,
        techReadyFormStore: rec.tech_ready_form_store,
        accommCoordUid    : rec.accomm_coord_uid,
        deliveryFormat    : rec.delivery_format,
        is_mobile_tether  : rec.is_mobile_tether,
        is_video_conference: rec.is_video_conference,
        timezone : rec.timezone
      });
    }
  }

  sub() {
    return this.info;
  }

  refresh() {
    this.infoUpdateSub.next(true);
  }

  create(){
    if (this.isCreating){ console.warn('Creation request is in progress'); return; }
    this.isCreating = true;
    const instit_group_id  = this.instInfo.groupId;
    return this.auth
      .apiCreate(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_ACTIVE, 
        {instit_group_id},
        this.constructPermissionsParams()
      )
      .then((rec: ITestSessionSetup) => {
        this.isCreating = false;
        this.updateInfo(rec);
        return rec.id
      })
      .catch (e => {
        this.isCreating = false;
        throw e;
      })
  }

  validateSetupInfo(setupInfo:ITestSessionSetupInfo){
    if (this.myInst.isRolesDefined() && !this.myInst.isSessionReleaser()){
      this.loginGuard.confirmationReqActivate({
        caption: this.lang.tra('txt_err_not_suff_roles_create_ts')
      })
      this.gotoDashboard();
      return
    }
    if (setupInfo){
      if (setupInfo.__reqNew){
        if (!this.isCancelling){
          console.log('The current test session setup has already been released. Please go back to the dashboard to create a new set of test sessions.')
          this.gotoDashboard();
        }
        return false;
      }
      return true;
    }
    return false;
  }

  gotoDashboard(){
    let breadcrumb = this.breadcrumbsService.TESTADMIN_DASHBOARD();
    this.router.navigate([ breadcrumb.route ]);
  }
  

  constructPermissionsParams(query?:any){
    if (!this.instInfo){ console.warn('constructPermissionsParams early exit'); return; }
    const instit_group_id  = this.instInfo.groupId;
    const setupId  = this._info ? this._info.id : null;
    return {
      query:{ 
        setupId,
        ... query,
        instit_group_id, 
      }
    }
  }

  cancel(){
    if (this.isCancelling){ console.warn('Cancellation request is in progress');  return; }
    this.isCancelling = true;
    const currentSetup = this.info.getValue();
    return this.auth
      .apiRemove(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_ACTIVE, 
        ''+currentSetup.id, 
        this.constructPermissionsParams()
      )
      .then((rec:ITestSessionSetup) => {
        this.updateInfo(null);
        this.isCancelling = false;
      })
      .catch (e => {
        this.isCancelling = false;
        throw e;
      })
  }

  createNewTestSessionSetup(){
    this.create()
      .then( setupId => {
        this.navToFirstCS(setupId);
      })
  }

  navToFirstCS(setupId:number){
    this.navToChecklistScreen(setupId, ChecklistStepRoute.TestWindow);
  }
  navToLastVisitedCS(setupInfo:ITestSessionSetupInfo){
    const setupId = setupInfo.id;
    // not reall the last screen, but good enough for most cases
    if (setupInfo.testWindowId || setupInfo.testWindowId === 0){
      this.navToChecklistScreen(setupId, ChecklistStepRoute.Location);
    }
    else{
      this.navToChecklistScreen(setupId, ChecklistStepRoute.TestWindow);
    }
  }
  navToChecklistScreen(setupId:number, step: ChecklistStepRoute){
    this.router.navigate([
      this.lang.getCurrentLanguage(),
      AccountType.TEST_ADMIN,
      'create-test-sessions',
      setupId,
      step
    ]);
  }

  _isTouched:boolean;
  markCurrentStepAsTouched(){
    this._isTouched = true;
  }
  markCurrentStepAsUnTouched(){
    this._isTouched = false;
  }
  isTouched(){
    return this._isTouched;
  }

  getChecklistStepState(step:ChecklistStepRoute):boolean {
    if (this._info){
      switch(step){
        case ChecklistStepRoute.TestWindow:            return !!this._info.checkTestWindow;
        case ChecklistStepRoute.Delivery:              return !!this._info.deliveryFormat;
        case ChecklistStepRoute.TechnicalReadiness:    return !!this._info.checkTechReady;
        case ChecklistStepRoute.Location:              return !!this._info.checkLocation;
        case ChecklistStepRoute.DateAndTime:           return !!this._info.checkDateTime;
        case ChecklistStepRoute.Accommodations:        return !!this._info.checkAccom;
        case ChecklistStepRoute.RestrictedSessions:    return !!this._info.checkRestricted;
        case ChecklistStepRoute.MakeSessionsAvailable: return !!this._info.isAvailable;
        case ChecklistStepRoute.SendInvitations:       return !!this._info.checkSendInvites;
      }
    }
  }

  getCheckListRoute(step:ChecklistStepRoute, setupId:string){
    return ['',
      this.lang.c(),
      AccountType.TEST_ADMIN,
      'create-test-sessions',
      setupId,
      step
    ].join('/')
  }

  isReadyToMakeAvail(){
    if (!this._info){
      return false;
    }
    return this._info.checkTestWindow && 
           this._info.checkLocation && 
           this._info.checkTechReady && 
           this._info.checkDateTime && 
           this._info.checkAccom && 
           this._info.checkRestricted
  }

  patchCheckListSteps(step:ChecklistStepRoute, value:boolean){
    // this.auth
    //   .apiPatch(this.routes.TEST_ADMIN_TEST_SESSION_SETUP_CHECKLIST, {
    //     [step]: value,
    //   })
    //   .then( res => {

    //   })
  }

  // test windows
  getTestWindows(){
    return this.auth
      .apiFind( 
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_TEST_WINDOWS,
        this.constructPermissionsParams()
      )
      .then( (windowsPaginated: {data:ITestWindow[]}) => {
        return windowsPaginated.data;
      })
  }
  getTestWindow() : Promise<ITestWindowInfo>{
    const testWindowId = this._info.testWindowId;
    if (testWindowId === null){
      return new Promise( (resolve, reject) => reject() );
    }
    const setupId = this._info.id;
    return this.auth
      .apiGet( 
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_TEST_WINDOWS, 
        testWindowId, 
        this.constructPermissionsParams({
          setupId
        })
      )
      .then( (testWindow: ITestWindow) => {
        // console.log('testWindow', testWindow);
        const testWindowInfo:ITestWindowInfo = {
          id: testWindow.id, 
          title : testWindow.title, 
          dateStart : moment(<string>testWindow.date_start).valueOf(),
          dateEnd :   moment(<string>testWindow.date_end).valueOf(),
          testDesignId : testWindow.test_design_id,
          notes : testWindow.notes,
          is_allow_test_centre: testWindow.is_allow_test_centre,
          is_allow_classroom: testWindow.is_allow_classroom,
          is_allow_remote: testWindow.is_allow_remote,
          is_allow_mobile_tether: testWindow.is_allow_mobile_tether,
          is_allow_video_conference: testWindow.is_allow_video_conference,
        }
        return testWindowInfo;
      })
  }

  // locations
  getLocations(setupId?:number) : Promise<ILocation[]> {
    return this.auth
      .apiFind(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_LOCATIONS, 
        this.constructPermissionsParams({
          setupId: setupId || this._info.id,
        })
      )
      .then(res => {
        return res.data.map(locationEntry =>{
          const location:ILocation =  {
            id            : locationEntry.id,
            setupId       : locationEntry.test_session_setup_id,
            room          : locationEntry.room,
            campusBuilding: locationEntry.campus_building,
            capacity      : locationEntry.capacity,
            address       : locationEntry.address,
            phoneNumber   : locationEntry.phone_number,
            city          : locationEntry.city,
            province      : locationEntry.province,
            postalCode    : locationEntry.postal_code,
            videoStreamLink        : locationEntry.videostream_link,
            videoStreamPassword    : locationEntry.videostream_password,
            isRemoved     : !!locationEntry.is_removed,
            isRemote      : !!locationEntry.is_remote,
          }
          return location;
        });
      })
  }
  createLocation(formData:Partial<ILocation>){
    return this.auth
      .apiCreate(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_LOCATIONS, 
        {
          setupId: this._info.id,
          ... formData,
        },
        this.constructPermissionsParams()
      )
      .then(res => {
        this.refresh();
      })
  }
  patchLocation(locationId:number, formData:Partial<ILocation>){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_LOCATIONS, 
        locationId, 
        {
          setupId: this._info.id,
          ... formData,
        },
        this.constructPermissionsParams()
      )
  }
  removeLocation(locationId:number){
    const patchPayload:Partial<ILocation> = {
      __reqRemove: true,
      setupId: this._info.id,
    }
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_LOCATIONS, 
        locationId, 
        patchPayload,
        this.constructPermissionsParams()
      )
      .then(res => {
        this.refresh();
      })
  }

  // teach readiness form url
  patchSetupTechReadyFormUrl(url:string){
    // this.auth
    //   .apiPatch(this.routes.TEST_ADMIN_TEST_SESSION_SETUP_TECH_READY, { url, })
    //   .then( res => {
    //     // this.info.
    //   })
  }

  // time slots
  getTimeSlots(setupId?:number) : Promise<ITimeSlot[]> {
    return this.auth
      .apiFind(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_TIME_SLOTS, 
        this.constructPermissionsParams({
          setupId: setupId || this._info.id,
        })
      )
      .then(res => {
        return res.data.map( (timeSlotEntry:ITestSessionSetupTimeSlots) =>{
          const timeSlot:ITimeSlot = {
            id            : timeSlotEntry.id,
            setupId       : timeSlotEntry.test_session_setup_id,
            locationId    : timeSlotEntry.test_session_setup_locations_id,
            dateTimeStart : moment(timeSlotEntry.date_time_start).valueOf(),
            isHidden      : !!timeSlotEntry.is_hidden,
            isAccessCodeProtected: !!timeSlotEntry.is_access_code_protected,
            accessCode:   timeSlotEntry.access_code,
            // patchTimeSlotRestriction  
            isRemoved     : !!timeSlotEntry.is_removed,
            videostream_link: timeSlotEntry.videostream_link,
            videostream_password: timeSlotEntry.videostream_password,
            capacity: timeSlotEntry.capacity,
            is_overlapping: timeSlotEntry.is_overlapping
          }
          return timeSlot;
        });
      })
  }
  createTimeSlot(formData:Partial<ITimeSlot>) : Promise<number>{
    return this.auth
      .apiCreate(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_TIME_SLOTS, 
        {
          setupId: this._info.id,
          ... formData,
        },
        this.constructPermissionsParams()
      )
      .then(newEntry => {
        this.refresh();
        return newEntry.id;
      })
  }
  removeTimeSlot(timeSlotId:number){
    const patchPayload:Partial<ITimeSlot> = {
      __reqRemove: true,
      setupId: this._info.id,
    }
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_TIME_SLOTS, 
        timeSlotId, 
        patchPayload,
        this.constructPermissionsParams()
      )
      .then(res => {
        this.refresh();
        return res
      })
  }

  // teach readiness form url
  checkoffAccommCoord(setupId:number){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_ACCOMM_PING, 
        null,  
        {setupId},
        this.constructPermissionsParams()
      ) // it is determined entirely on the back end... this is just refreshing the property
      .then( res => {
        this.refresh();
        return res;
      })
    // if (!this.getChecklistStepState(ChecklistStepRoute.Accommodations)){
    // }
  }

  // restrictions
  checkoffTimeSlotRestriction(setupId:number){
    if (!this.getChecklistStepState(ChecklistStepRoute.RestrictedSessions)){
      return this.auth
        .apiCreate(
          this.routes.TEST_ADMIN_TEST_SESSION_SETUP_TIME_SLOT_RESTRICTION,  
          {setupId},
          this.constructPermissionsParams()
        ) // it is determined entirely on the back end... this is just refreshing the property
        .then( res => {
          this.refresh();
        })
    }
  }
  patchTimeSlotRestriction(timeSlotId:number, formData){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_TIME_SLOT_RESTRICTION, 
        timeSlotId, 
        {
          setupId: this._info.id,
          ... formData,
        },
        this.constructPermissionsParams()
      )
  }
  patchTimezone(formData) {
    return this.auth
        .apiPatch(
            this.routes.TEST_ADMIN_TEST_SESSION_SETUP_TIMEZONE,
            this._info.id,
            {
              setupId: this._info.id,
              ... formData,
            },
            this.constructPermissionsParams()
        )
  }

  // institution
  getInstInfo(){
    return this.instInfo;
  }
  getInstName(){
    if (!this.instInfo){
      return '[NO INSTITUTION]'
    }
    return this.instInfo.name;
  }

  getRoomCaption(){
    return this.lang.tra('cts_location_room_lbl');
  }

  // make sessions available
  releaseSetupSessions(){
    // return this.markSessionsAsReleased_sim();
    return this.auth
      .apiCreate(
        this.routes.TEST_ADMIN_TEST_SESSION_SETUP_SESSIONS, 
        { setupId: this._info.id, },
        this.constructPermissionsParams()
      )
      .then(res => {
        this._info.checkSessAvail = true;
        this.updateChecklistState.next(this._info);
        return res;
      });
  }

  markSessionsAsReleased_sim(){
    this._info.checkSessAvail = true;
    this.updateChecklistState.next(this._info);
    return Promise.resolve();
  }

  isSessionsReleased(){
    if (!this._info){
      return false;
    }
    return this._info.isAvailable;
  }


}
