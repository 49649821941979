import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SampleQuestionsComponent } from './sample-questions/sample-questions.component';
import { QuestionRunnerComponent } from './question-runner/question-runner.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ElementRenderIframeComponent } from './element-render-iframe/element-render-iframe.component';
import { ElementRenderImageComponent } from './element-render-image/element-render-image.component';
import { ElementRenderInputComponent } from './element-render-input/element-render-input.component';
import { ElementRenderMathComponent } from './element-render-math/element-render-math.component';
import { ElementRenderMcqComponent } from './element-render-mcq/element-render-mcq.component';
import { ElementRenderOrderComponent } from './element-render-order/element-render-order.component';
import { ElementRenderTextComponent } from './element-render-text/element-render-text.component';
import { ElementRenderVideoComponent } from './element-render-video/element-render-video.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RenderImageComponent } from './render-image/render-image.component';
import { RenderMathComponent } from './render-math/render-math.component';
import { McqOption} from './mcq-option/mcq-option.component'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MarkdownModule } from 'ngx-markdown';
import { TooltipModule } from 'ng2-tooltip-directive';
import { UiTransModule } from '../ui-trans/ui-trans.module';
import { UiPartialModule } from '../ui-partial/ui-partial.module';
import { FormulaSheetComponent } from './formula-sheet/formula-sheet.component';
import { MarkdownInlineComponent } from './markdown-inline/markdown-inline.component';
import { TestRunnerComponent } from './test-runner/test-runner.component';
import { WidgetCalculatorComponent } from './widget-calculator/widget-calculator.component';
import { ElementRenderTableComponent } from './element-render-table/element-render-table.component';
import { ElementRenderDndComponent } from './element-render-dnd/element-render-dnd.component';
import { ElementRenderComponent } from './element-render/element-render.component';
import { ElementRenderMicComponent } from './element-render-mic/element-render-mic.component';
import { ElementRenderMoveableDndComponent } from './element-render-moveable-dnd/element-render-moveable-dnd.component';
import { ElementRenderSelectTextComponent } from './element-render-select-text/element-render-select-text.component';
import { ElementRenderFrameComponent } from './element-render-frame/element-render-frame.component';
import { ElementRenderCameraComponent } from './element-render-camera/element-render-camera.component';
import { ElementRenderAnnotationComponent } from './element-render-annotation/element-render-annotation.component';
import { ElementRenderUploadComponent } from './element-render-upload/element-render-upload.component';
import { ElementRenderHotspotComponent } from './element-render-hotspot/element-render-hotspot.component';
import { ElementRenderHottextComponent } from './element-render-hottext/element-render-hottext.component';
import { WidgetMathModule } from '../widget-math/widget-math.module';
import { RenderAudioComponent } from './render-audio/render-audio.component';
import { ElementRenderAudioComponent } from './element-render-audio/element-render-audio.component';
import { ElementRenderReaderComponent } from './element-render-reader/element-render-reader.component';
import { ElementRenderGraphingComponent } from './element-render-graphing/element-render-graphing.component';
import { IoAudioModule } from '../io-audio/io-audio.module';
import { ElementRenderGraphicsComponent } from './element-render-graphics/element-render-graphics.component';
import { ElementRenderSbsComponent } from './element-render-sbs/element-render-sbs.component';
import { ElementRenderSelectionTableComponent } from './element-render-selection-table/element-render-selection-table.component';
import { ElementRenderCustomMCQComponent } from './element-render-custom-mcq/element-render-custom-mcq.component';
import { ElementRenderMatchingComponent } from './element-render-matching/element-render-matching.component';
import {TestTakerComponent} from '../ui-chat/test-taker/test-taker.component';
import { ElementRenderGroupingComponent } from './element-render-grouping/element-render-grouping.component';
import { ViewConstrRespComponent } from './view-constr-resp/view-constr-resp.component';
import { AngularSplitModule } from 'angular-split';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ElementRenderDrawingComponent } from './element-render-drawing/element-render-drawing.component';
import { ElementRenderTextConstrComponent } from './element-render-text-constr/element-render-text-constr.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ElementRenderCanvasComponent } from './element-render-canvas/element-render-canvas.component';
import { ElementRenderInsertionComponent } from './element-render-insertion/element-render-insertion.component';
import { ElementRenderTextLinkComponent } from './element-render-text-link/element-render-text-link.component';
import { ElementRenderBookmarkLinkComponent } from './element-render-bookmark-link/element-render-bookmark-link.component';
import { ElementRenderSolutionComponent } from './element-render-solution/element-render-solution.component';
import { ElementRenderDocLinkComponent } from './element-render-doc-link/element-render-doc-link.component';
import { ResultsSummarySrComponent } from './results-summary-sr/results-summary-sr.component';
import { ResultsSummaryCrComponent } from './results-summary-cr/results-summary-cr.component';
import { ResultsLegendComponent } from './results-legend/results-legend.component';
import { ElementRenderDivComponent } from './element-render-div/element-render-div.component';
import { ElementRenderResultsPrintComponent } from './element-render-results-print/element-render-results-print.component';
import { MictestComponent } from './mictest/mictest.component';
import { ElementRenderValidatorComponent } from './element-render-validator/element-render-validator.component';
import { ElementRenderCustomInteractionComponent } from './element-render-custom-interaction/element-render-custom-interaction.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        MarkdownModule,
        DragDropModule,
        TooltipModule,
        // CdkTreeModule,
        UiPartialModule,
        WidgetMathModule,
        TextFieldModule,
        IoAudioModule,
        FormsModule,
        AngularSplitModule,
        CKEditorModule,
        MatMenuModule,
    ],
  declarations: [
    SampleQuestionsComponent, 
    QuestionRunnerComponent,
    CheckboxComponent, 
    ElementRenderIframeComponent,
    ElementRenderImageComponent,
    ElementRenderInputComponent,
    ElementRenderMathComponent,
    ElementRenderMcqComponent,
    ElementRenderOrderComponent,
    ElementRenderFrameComponent,
    ElementRenderTextComponent,
    ElementRenderVideoComponent,
    ElementRenderAnnotationComponent,
    RenderImageComponent,
    RenderMathComponent,
    FormulaSheetComponent,
    MarkdownInlineComponent,
    ElementRenderMoveableDndComponent,
    TestRunnerComponent,
    WidgetCalculatorComponent,
    ElementRenderTableComponent,
    ElementRenderDndComponent,
    ElementRenderComponent,
    ElementRenderMicComponent,
    ElementRenderCameraComponent,
    ElementRenderUploadComponent,
    ElementRenderHotspotComponent,
    ElementRenderHottextComponent,
    RenderAudioComponent,
    ElementRenderAudioComponent,
    ElementRenderReaderComponent,
    ElementRenderGraphingComponent,
    ElementRenderGraphicsComponent,
    ElementRenderSbsComponent,
    ElementRenderSelectionTableComponent,
    ElementRenderSelectTextComponent,
    ElementRenderCustomMCQComponent,
    ElementRenderMatchingComponent,
    McqOption,
    TestTakerComponent,
    ElementRenderGroupingComponent,
    ViewConstrRespComponent,
    ElementRenderDrawingComponent,
    ElementRenderTextConstrComponent,
    ElementRenderCanvasComponent,
    ElementRenderInsertionComponent,
    ElementRenderTextLinkComponent,
    ElementRenderBookmarkLinkComponent,
    ElementRenderSolutionComponent,
    ElementRenderDocLinkComponent,
    ResultsSummarySrComponent,
    ResultsSummaryCrComponent,
    ResultsLegendComponent,
    ElementRenderDivComponent,
    ElementRenderResultsPrintComponent,
    MictestComponent,
    ElementRenderValidatorComponent,
    ElementRenderCustomInteractionComponent,
  ],
    exports: [
        QuestionRunnerComponent,
        TestRunnerComponent,
        RenderImageComponent,
        RenderMathComponent,
        RenderAudioComponent,
        ElementRenderComponent,
        ElementRenderReaderComponent,
        ElementRenderAudioComponent
    ]
})
export class UiTestrunnerModule { }
