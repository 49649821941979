<mat-accordion>
  <mat-expansion-panel [ngStyle]="style || {}">
    <mat-expansion-panel-header>
      <mat-panel-title>
          <div  style="font-weight: bold;">{{title}}</div>
          <div style="margin-left: 0.5em;">({{subTitle}})</div>
      </mat-panel-title>
      <mat-panel-description>
        {{description}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ng-container [ngSwitch]="type">
        <div *ngSwitchCase="'TextArea'">
          <div class="flex-gap">
            <span class="text-area-middle" *ngFor="let c of content">
              <label>{{c.label}} </label>
              <textarea [(ngModel)]="c.data"></textarea>
            </span>
          </div>
        </div>
      </ng-container>
      
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>