<div *ngIf="isMinistryAdmin() && district || !isMinistryAdmin() && school" class="score-entry-students scroll-screen">
    <button class="button view-sld-button" *ngIf="isBulkView && (!isMinistryAdmin() && school && viewSldReportSlugProps)"
        (click)="viewSldReportForSchool()">
        <tra-md slug="sa_se_view_sld_report" [props]="viewSldReportSlugProps">
        </tra-md>
    </button>

    <div class="section-header school-selector">
        <div>
            <score-entry-students-table 
            [school]="school" [testWindow]="testWindow" [district]="district"
            [themeProfile]="themeProfile" [scoreProfile]="scoreProfile"
            (isBulkView)="changeBulkStatus($event)">
            </score-entry-students-table>
        </div>
    </div>
</div>
<div *ngIf="!school">
    <div *ngIf="isSchoolAdmin()" class="score-entry-students no-school">
        <tra slug="sa_sa_select_school"></tra>
    </div>

    <div *ngIf="isDistrictAdmin()" class="score-entry-students">

        <score-entry-aggregation-table [district]="district" [school]="school" [testWindow]="testWindow"
            totalSlug="da_score_entry_district_total">
        </score-entry-aggregation-table>

    </div>

    <ng-container *ngIf="isMinistryAdmin()">
        <div *ngIf="!district" class="no-district">
            <tra slug="bced_select_dist_schl"></tra>
        </div>
    </ng-container>
</div>

<!-- 
<div class="custom-modal saving-modal" *ngIf="isSaving">
    <div class="modal-contents" style="width:400px">
        <div class="modal-header">
            <h1>
                <tra slug="da_score_entry_saving"></tra>
            </h1>
        </div>

        <div class="loader-container">
            <div class="bcg-loader">
                <div></div>
            </div>
        </div>

    </div>
</div> -->