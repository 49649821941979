import {Component, Input, OnInit} from '@angular/core';
import {IView} from "../view-ministryadmin-dashboard/data/views";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from '@angular/router';
import {LangService} from "../../core/lang.service";
import {SidepanelService} from "../../core/sidepanel.service";
import {BreadcrumbsService} from "../../core/breadcrumbs.service";
import {AccountType} from "../../constants/account-types";
import { DataGuardService } from '../../core/data-guard.service';

export enum BCFSAScoreEntrySessionView {
  SUMMARY = 'summary',
  PAST = 'past',
  EDIT = 'edit',
  STUDENTS = 'bulk',
  INDIVIDUAL = 'indiv',
  REPORTS = 'reports',
}

export const BC_FSA_SCORE_ENTRY_TABS = [{
  id: BCFSAScoreEntrySessionView.SUMMARY,
  caption: ('Summary'), //'Summary'
}, {
  // id: BCFSAScoreEntrySessionView.PAST,
  // caption: ('Past Score Entry Session'), //'Past',
  // disabled: true
// }, {
  id: BCFSAScoreEntrySessionView.REPORTS,
  caption: ('View School Level Data Reports'),
  disabled: true
}]

@Component({
  selector: 'ma-score-entry-session',
  templateUrl: './ma-score-entry-session.component.html',
  styleUrls: ['./ma-score-entry-session.component.scss']
})
export class MaScoreEntrySessionComponent implements OnInit {
  views:IView<BCFSAScoreEntrySessionView>[] = [];
  selectedTab: BCFSAScoreEntrySessionView;
  displayedView: BCFSAScoreEntrySessionView;
  schoolType = this.route.snapshot.data['schoolType'];
  BCFSAScoreEntrySessionView = BCFSAScoreEntrySessionView;
  @Input() breadcrumb;
  routeSub:Subscription;
  
  constructor(
      private router:Router,
      private route: ActivatedRoute,
      public lang:LangService,
      private sidePanel: SidepanelService,
      private breadcrumbsService: BreadcrumbsService,
      private dataGuard: DataGuardService
) { }

  ngOnInit(): void {
    this.sidePanel.activate();
    // if (this.router.url.indexOf('/score-entry') === -1) {
    //   this.dataGuard.activate();
    // }
    let subView = this.route.snapshot.paramMap.get('subView');
    if (!subView) {
      this.selectedTab = BCFSAScoreEntrySessionView.SUMMARY;
      this.displayedView = this.selectedTab;
    } else {
      this.selectedTab = subView as (BCFSAScoreEntrySessionView);
      this.displayedView = this.selectedTab;
    }
    if (this.schoolType === 'BC_FSA') {
      this.views = BC_FSA_SCORE_ENTRY_TABS.map(view => Object({
        ... view,
        caption: this.lang.tra(view.caption)
      }))
    }
    this.updateBreadcrumb();
    this.initRouteSub();
  }

  selectView(id: BCFSAScoreEntrySessionView){
    this.router.navigate([this.getViewRoute(id)]);
  }

  initRouteSub(){
    if (this.routeSub){
      this.routeSub.unsubscribe();
    }
    // this.routeSub = this.route.params.subscribe(e => this.onRouteChange(e));
    // this.router.events.subscribe((val) => {
    //   if (val instanceof NavigationEnd){
    //     debugger
    //     if (this.router.url.indexOf('/score-entry') === -1) {
    //           confirm("test");
    //         }
    //   }
    // })
  }

  onRouteChange(routeParams: any) {
    const subView = routeParams['subView'];
    console.log(subView);
    console.log(routeParams);
    if (subView) {
      window.scrollTo(0,0);
      if (subView === BCFSAScoreEntrySessionView.EDIT || subView === BCFSAScoreEntrySessionView.STUDENTS || subView === BCFSAScoreEntrySessionView.INDIVIDUAL) {
        this.selectedTab = BCFSAScoreEntrySessionView.SUMMARY;
        this.displayedView = subView;
      } else {
        this.selectedTab = subView;
        this.displayedView = this.selectedTab;
      }
      this.updateBreadcrumb();
    }
  }

  getBaseRoute(){
    return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}`;
  }

  getDashboardRoute(){
    if (this.schoolType === 'BC_FSA') {
      return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}/bc-fsa/dashboard`;
    }
  }

  getBaseViewRoute(){
    if (this.schoolType === 'BC_FSA') {
      return `${this.getBaseRoute()}/bc-fsa/score-entry`;
    }
  }

  getViewRoute(viewSlug:BCFSAScoreEntrySessionView){
    if (viewSlug === BCFSAScoreEntrySessionView.SUMMARY) {
      return this.getBaseViewRoute();
    } else {
      return `${this.getBaseViewRoute()}/${viewSlug}`;
    }
  }
  
  updateBreadcrumb(){
    this.breadcrumb.splice(0, this.breadcrumb.length);
    this.breadcrumb.push(this.breadcrumbsService._CURRENT( this.lang.tra('da_dashboard_district_admin_bc'), this.getDashboardRoute()));
    this.breadcrumb.push(this.breadcrumbsService._CURRENT( this.lang.tra('Score Entry'), this.getBaseViewRoute()));
    if (this.displayedView === BCFSAScoreEntrySessionView.SUMMARY || this.displayedView === BCFSAScoreEntrySessionView.STUDENTS || this.displayedView === BCFSAScoreEntrySessionView.EDIT){
      this.breadcrumb.push(this.breadcrumbsService._CURRENT( this.lang.tra('Summary'), this.getBaseViewRoute()));
    } else if (this.displayedView === BCFSAScoreEntrySessionView.PAST) {
      this.breadcrumb.push(this.breadcrumbsService._CURRENT(this.lang.tra('Past'), this.getViewRoute(BCFSAScoreEntrySessionView.PAST)));
    }
  }
  //
  // ngOnDestroy() {
  //   this.dataGuard.deactivate();
  // }
}
