import { ISectionDef } from "../../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component";
import { IQuestionRun } from "../../../ui-item-maker/item-set-editor/models";
import { IQuestionConfig } from "../../models";
import { LangService } from "../../../core/lang.service";

type QuestionId = number;
export type QuestionTitleMap = Map<QuestionId, string>;

const checkIfGradedQuestion = (questionConfig:Partial<IQuestionConfig>) => {
  if (questionConfig && questionConfig.isReadingSelectionPage){
    return false;
  }
  return true;
}

export const getQuestionTitles = (sections:ISectionDef[], questionSrc:Map<number, IQuestionRun>, useQuestionLabel:boolean, questionWord:string, lang:LangService):QuestionTitleMap => {
  const map:QuestionTitleMap = new Map();
  sections.forEach((section, sectionIndex) => { 
    let questionCounter = 0;
    section.questions.forEach((questionId, questionIndex) =>{
      const questionConfig:Partial<IQuestionConfig> = questionSrc.get(questionId);
     
      let title;
      if (!questionConfig){
        console.warn('Missing item config', questionId);
        return;
      }

      if (questionConfig.caption){
        title = questionConfig.caption
      }
      else{
        const isCountedQuestion = checkIfGradedQuestion(questionConfig);
        if (isCountedQuestion){
          questionCounter ++;
        }
        if (useQuestionLabel){
          title = questionConfig.label;
        }
        else {
          title = questionWord + " " + questionCounter;
        }
        if (questionConfig.isReadingSelectionPage){
          title = lang.tra('lbl_reading_selection');
        }
      }
      map.set(questionId, title)
    })
  })
  // console.log('getQuestionTitles', sections, questionSrc, map)
  return map;
}
export const getQuestionTitleFromMap = (map: QuestionTitleMap, qId: number) => {
  return map.get(qId);
}