import {ElementRef, Injectable} from '@angular/core';
import {AuthService} from '../api/auth.service';
import Sockette from "sockette";
import {DATETIME} from '../api/models/db-types';
import { BehaviorSubject, fromEventPattern } from 'rxjs';

// Lambda/Dynamo specifics.
enum ChatEventType {
  CHAT_DATA = 'CHAT_DATA',
  ACTIVE_UPDATE = 'ACTIVE_UPDATE',
  NEW_MESSAGE = 'NEW_MESSAGE',
  MESSAGE_SENT = 'MESSAGE_SENT',
  READ_MESSAGE = 'READ_MESSAGE',
  PING = 'PING',
  REFRESH = 'REFRESH',
}
enum ChatEndpoint {
  USER_CONNECT = 'userConnect',
  USER_DISCONNECT = 'userDisconnect',
  SEND_MESSAGE = 'sendMessage',
  SEND_GROUP_MESSAGE = 'sendGroupMessage',
  SEND_BROADCAST = 'sendBroadcast',
  CLEAR_UNREAD = 'clearUnread',
  PING = 'ping',
  REFRESH = 'refresh',
}

interface ILambdaQuery {
  Count: number;
  ScannedCount: number;
  Items: any[];
}
interface ILambdaChatData {
  eventType: ChatEventType;
  userChats: ILambdaQuery;
  users?:any;
}

// public/UI pieces
export enum ChatRowType {
  BROADCAST = 'BROADCAST',
  PERSON = 'PRIVATE',
  GROUP = 'GROUP',
}

export enum PersonType {
  MARKER = 'Marker',
  LEADER = 'Leader',
  COORDINATOR = 'Coordinator',
}

export enum GroupType {
  LEADERS = 'LEADERS',
  MARKERS = 'Markers',
  ALL = 'BROADCAST'
}


// UI Interfaces.
// DEPRECATED
export interface IMessage {
  senderUid: number;
  senderName: string;
  timestamp: DATETIME;

  message: string;
  isBroadcast: boolean;

  // seenBy: number[]; // array of UIDs.
}

// could be a group or person.
// DEPRECATED
interface IChat {
  id: number;
  title: string;
  chat: IMessage[];

  type: ChatRowType;
  personType?: PersonType;
  groupType?: GroupType;
}

// DEPRECATED
export interface IChatRow {
  unreadMessagesCount: number;
  activeCount: number;
  chat: IChat;
}

export interface IChatSection {
  rows: IChatRow[];
}

export interface IChatData {
  recent: IChatSection;
  groups: IChatSection;
  people: IChatSection;
  supportChat?: IChatRow;
}

// Updated interfaces
export interface INewMessage {
  senderUid: number;
  senderName: string;
  timestamp: DATETIME;

  message: string;
  isBroadcast: boolean;
  isUnread: boolean;
}

export interface INewChat {
  id: number;
  unreadCount: number;
  activeCount: number;
  theirUUID:number;
  title?: string;
  chat: INewMessage[];
  chatType: ChatRowType;
  uid?: number;
  chatId?: number;
  active?:boolean;
  imgURL?:string;
  name?:string;
  leaders?:any[];
  markers?:any[];
  coordinators?:any[];
  participants?:any[];
  // only when chatType === ChatRowType.BROADCAST.
  // this can be true for multiple users per broadcast chat. Indicates that it will show up in their list.
  isBroadcastOwner?: boolean;
}

export interface INewChatSection {
  rows: INewChat[];
}

export interface INewChatData {
  recent: INewChatSection;
  groups: INewChatSection;
  people: INewChatSection;
  // supportChat?: IChatRow;
}

export interface IMessageQueueItem {
  chat: IChat,
  msg: any,
  receiverUid: number,
  itemId?: number
}

@Injectable({
  providedIn: 'root'
})
export class MarkingChatService {
  MAX_RECENT = 5;

  gotNewChatData = new BehaviorSubject<boolean>(false);

  public isLoadingContacts = true;
  public isLoadingRecent = true;
  public errorLoading = false;
  public hasUnread = false;
  public allChatData: INewChatData = {groups: {rows: []}, people: {rows: []}, recent: {rows: []}};
  public openChatId: number;
  public elem: ElementRef;

  private socket: any;
  private readonly uri: string = 'wss://mwgmbuiprh.execute-api.ca-central-1.amazonaws.com/production';
  private messageQueue: any[] = [];


  isUserInited = false;
  isConnected = false;

  communicationTimeout;
  reconnectTimeout;
  notificationSound;

  constructor(public auth: AuthService) {
    this.notificationSound = new Audio();
    this.notificationSound.src = "assets/pop.wav";
    this.notificationSound.load();

    // need this because sometimes it fails to get uid at this point in loading.
    console.log('chat init');
    
    auth.user().subscribe(user => {
      if(user && !this.isUserInited) {
        this.isUserInited = true;
        this.initChat();
      }
    });

    setInterval(() => {
      this.tryResolvingQueue();
    }, 2000);

    // window.addEventListener('online', async () => {
    //   if (!this.messageQueue.length) return;

    //   for (const message of this.messageQueue) {
    //     message.msg.status = null; // need to reset message status or receiving user(s) will see 'sent' on the message that they receive
    //     if (message.itemId) {
    //       await this.sendMessage(message.chat, message.msg, message.receiverUid, message.itemId);
    //     } else {
    //       await this.sendMessage(message.chat, message.msg, message.receiverUid)
    //     }
    //   }

    //   this.messageQueue = [];
    // });  

    auth.subscribeToLogout(() => 
    { 
      if (this.socket != null)
      {
        this.socket.close();
        this.isConnected = false;
        this.isUserInited = false;
      }
      
      this.allChatData = {groups: {rows: []}, people: {rows: []}, recent: {rows: []}};
      this.hasUnread = false;
    })
    

  }
  

  async tryResolvingQueue() {
    if (this.messageQueue.length == 0) {
      return;
    }
  
    for (let i = 0; i < this.messageQueue.length; i++) {
      const message = this.messageQueue[i];
      message.msg.status = null;
  
      let sentStatus;
      if (message.itemId) {
        sentStatus = await this.sendMessage(message.chat, message.msg, message.receiverUid, message.itemId, true);
      } else {
        sentStatus = await this.sendMessage(message.chat, message.msg, message.receiverUid, undefined, true);
      }
  
      if (sentStatus == 1) {
        this.messageQueue.splice(i, 1); // remove the element from the original array
        i--; // decrement i to adjust for the removed element
      }
    }
  }

  async initChat() {
    await this.initChatData();
    this.initSocket();
    
    this.resetCommunicationTimeout();
    this.refetchChatDataForNameChanges();
  }

  refetchChatDataForNameChanges() {
    setTimeout(this.didLoseCommunication, 60000);
  }

  resetCommunicationTimeout() {
    if(this.communicationTimeout) {
      clearTimeout(this.communicationTimeout);
    }
    this.communicationTimeout = setTimeout(this.didLoseCommunication, 5000); //comminication is considered lost if no message in 10 seconds.
  }
  
  didLoseCommunication = () => {
    //If we have not heard from the WS server in 65 seconds, reconnect.
    this.isConnected = false;
    this.emit(ChatEndpoint.USER_CONNECT, { uid: this.auth.getUid() });
    this.initChatData();
    this.resetCommunicationTimeout();

    //If we have not recieved a message from the websocket in 10 seconds, re-init the websocket.
    this.reconnectTimeout = setTimeout(() => {
      if (!this.isConnected) {
        this.initSocket();
      }

      clearTimeout(this.reconnectTimeout);
    }, 10000);    
  }

  contacts = [];
  recent;
  broadcasts;
  userType;


  async initChatData() {
    this.userType = this.auth.myActiveMarkingRole();
    let chatInfo = await this.auth.apiGet('public/mrkg-lead/marking-chat', null);

    this.broadcasts = chatInfo.usersByItem;


    let contacts = [];

    for(let broadcast of this.broadcasts) {
      broadcast.chatType = 'BROADCAST';
      for(let marker of broadcast.markers) {
        marker.chatType = 'PRIVATE';
        if(!contacts.find(usr => usr.uid == marker.uid)) {
          contacts.unshift(marker);
        }
      }
      for(let leader of broadcast.leaders) {
        leader.chatType = 'PRIVATE';
        if(!contacts.find(usr => usr.uid == leader.uid)) {
          contacts.push(leader);
        }
      }
      for(let coordinator of broadcast.coordinators) {
        coordinator.chatType = 'PRIVATE';
        if(!contacts.find(usr => usr.uid == coordinator.uid)) {
          contacts.push(coordinator);
        }
      }
    }

    this.contacts = [...contacts];
    this.isLoadingContacts = false;

    this.parseChatData(chatInfo).then(() => {
      this.gotNewChatData.next(true);
      this.isLoadingRecent = false;
    })

    
    return;
  }

  initSocket() {
    this.errorLoading = false;

    this.socket = new Sockette(this.uri,{
      timeout: 10e3,
      maxAttempts: 10,
      onopen: e => {
        const data = {
          uid: this.auth.getUid()
        };
        this.emit(ChatEndpoint.USER_CONNECT, { uid: this.auth.getUid() });
      },
      onmessage: e => {
        let data: ILambdaChatData;

        try{
          data = JSON.parse(e.data);
        }catch(e) {
          return;
        }

        const eventType: ChatEventType = data.eventType;
  
        switch (eventType) {
          case ChatEventType.ACTIVE_UPDATE:
            this.resetCommunicationTimeout();
            this.activeUpdate(data);
            break;
          case ChatEventType.NEW_MESSAGE:
            this.resetCommunicationTimeout();
            this.receiveMessage(data).then(() => {
              this.gotNewChatData.next(true);
            })
            break;
          case ChatEventType.PING:
            this.resetCommunicationTimeout();
            this.newActiveUpdate(data.users);
            break;
          case ChatEventType.READ_MESSAGE:
            this.receiveReadReceipt(data);
            break;
          case ChatEventType.REFRESH:
            this.initChatData()
            break;
          default:
            console.log("Unexpected chat event", data);
            break;
        }
  
      },
      onreconnect: e => console.log('Reconnecting...', e),
      onmaximum: e => console.log('Stop Attempting!', e),
      onclose: e => {
        console.log('Closed!', e);
        this.socket.json({action:ChatEndpoint.USER_DISCONNECT});
      },
      onerror: e => console.log('Error:', e)
    });

  }

  private emit(action, data) {
    this.socket.json({action, data});
  }

  receiveReadReceipt(data) {
    let chatId = data.chatId;
    let messageId = data.messageId;

    let foundIt = false;
    for(let row of this.contacts) {
      if(row.chatId == chatId) {
        for(let message of row.chat) {
          if(message.id == messageId) {
            message.didTheyRead = true;
            foundIt = true;
          }
        }
      }
    }

    //look in recent
    if(!foundIt) {
      for(let row of this.recent) {
        if(row.chatId == chatId) {
          for(let message of row.chat) {
            if(message.id == messageId) {
              message.didTheyRead = true;
              foundIt = true;
            }
          }
        }
      }
    }
  }

  private async  receiveMessage(data) {
    const chatId: number = data.chatId;
    const msg: INewMessage = data.msg;
    console.log(data);
    console.log('received message');
    if (+msg.senderUid === +this.auth.getUid()) {
      msg.isUnread = false;
    }

    let newChat: INewChat;
    // check people then groups for the chatId

    
    for(let row of this.contacts) {
      if((row.uid == msg.senderUid && !data.participants) || (msg.isBroadcast && +msg.senderUid == +this.auth.getUid() && row.uid == data.theirUUID)) {
        if(!row.chat) {
          row.chat = [];
          row.chatId = chatId;
          row.unreadCount = 0;
          row.chatType = data.chatType;
        }
        row.chat.push(msg);
        if(msg.isUnread) {
          this.hasUnread = true;
          row.unreadCount++;
        }

        newChat = row;
      }
    }
    
    // for (let i = 0; i < this.allChatData.people.rows.length; i++) {
    //   if (+this.allChatData.people.rows[i].id === +chatId) {
    //     this.allChatData.people.rows[i].chat.push(msg);
    //     /*
    //     if (msg.isUnread) {
    //       this.hasUnread = true;
    //       this.allChatData.people.rows[i].unreadCount += 1;
    //     }*/

    //     newChat = this.allChatData.people.rows[i];
    //     break;
    //   }
    // }
    if (!newChat) {
      if(data.participants) {
        for(let row of this.recent) {
          if(row.chatId == data.chatId) {
            row.chat.push(msg);
            this.hasUnread = true;
            row.unreadCount++;
            newChat = row;

          }
        }

        if(!newChat) {
          this.hasUnread = true;
          for(let p of data.participants) {
            p = this.contacts.find(c => c.uid == p);
          }
          newChat = {
            id: chatId,
            unreadCount:1,
            activeCount:0,
            theirUUID:0,
            chatType:ChatRowType.GROUP,
            chat:[msg],
            chatId:data.chatId,
            participants:await this.getUsersFromParticipants(data.participants)
          }
         
        }
      }
    }

    if (!newChat) {
      return;
    }

    if (this.openChatId === +chatId) {
      this.clearUnread(newChat);
    } else if (msg.isUnread) {
      this.hasUnread = true;
      console.log("DING");
      this.notificationSound.play();
    }

    // then update recent with the new chat we find.
    this.scrollToBottom();
    this.updateRecent(newChat);
  }

  newActiveUpdate(data) {
    if (data.includes(this.auth.getUid())) {
      this.isConnected = true;
    }

    for(let contact of this.contacts) {
      contact.active = data.includes(contact.uid);
    }
  }

  private activeUpdate(data) {
    const isActive = data.isActive;
    if (data.uid === this.auth.getUid()) {
      this.isConnected = true;
    }

    for(let row of this.contacts) {
      if(row.uid == data.uid) {
        if(isActive) {
          row.active = true;
        }
        else {
          row.active = false;
        }
      }
    }
  }

  public isActive(uid) {

    if(!this.contacts) {
      return false;
    }
    for(let c of this.contacts) {
      if(c.uid == uid) return c.active;
    }

    return false;
  }
  
  initialChatData;
  private async parseChatData(data) {
    console.log("PARSING CHAT DATA");
    this.initialChatData = data;

    let groups = [];
    for( let chat of data.userChats) {
      if(chat.chat_type == "BROADCAST") {
        let broadcast = this.broadcasts.find(broadcast => broadcast.id == chat.item_id);
        if(broadcast) {
          broadcast.chat = chat.messages;
          broadcast.chatId = chat.id;
          broadcast.unreadCount = 0;
          broadcast.chatType = chat.chat_type;
          for(let msg of broadcast.chat) {
            if(msg.isUnread  && msg.senderUid != this.auth.getUid()) {
              broadcast.unreadCount++;
            }
          }
        }
      }
      else if(chat.chat_type == 'PRIVATE') {
        let theirUID = chat.participants.find(p => p != this.auth.getUid());
        let contact = this.contacts.find(contact => contact.uid == theirUID);
        if(contact) {
          if(!contact.chat) {
            contact.chat = [];
          }
          contact.chat = chat.messages;
          contact.chatId = chat.id;
          contact.unreadCount = 0;
          contact.chatType = chat.chat_type;
          contact.participants = await this.getUsersFromParticipants(chat.participants);
          for(let msg of contact.chat) {
            if(msg.isUnread && msg.senderUid != this.auth.getUid()) {
              contact.unreadCount++;
              chat.unreadCount++;
            }
          }
        }
      }
      else if(chat.chat_type == "GROUP") {
        let newGroup = {
          chat:chat.messages,
          chatId: chat.id,
          unreadCount: 0,
          chatType:chat.chat_type,
          participants: await this.getUsersFromParticipants(chat.participants)
        };

        for(let msg of newGroup.chat) {
          if(msg.isUnread  && msg.senderUid != this.auth.getUid()) {
            newGroup.unreadCount++;
          }
        }
        groups.push(newGroup);
      }
      

    }
    
    this.recent = [];
    for(let user of this.contacts) {
      if(user.chat) {
        this.recent.push(user);
      }
    }
    for(let g of groups) {
      // if(this.areAllParticipantsContacts(g)) {
        this.recent.push(g);
      // }
    }
    this.recent.sort((a,b) => {
      if (a.chat.length == 0 && b.chat.length == 0) {
        return 0;
      }
      if (a.chat.length == 0) {
        return 1;
      }
      if (b.chat.length == 0) {
        return -1;
      }

      if(new Date(a.chat[a.chat.length - 1].created_on) < new Date(b.chat[b.chat.length - 1].created_on)) {
        return 1;
      }
      else if(new Date(a.chat[a.chat.length - 1].created_on) > new Date(b.chat[b.chat.length - 1].created_on)) {
        return -1;
      }

      return 0;
    });

  }



  scrollToBottom() {
    let self = this;
    setTimeout(function() {
      if (self.elem) {
        self.elem.nativeElement.scrollTop = self.elem.nativeElement.scrollHeight;
      } else {
        console.error('chatDiv is undefined (service)');
      }
    }, 50);
  }

  getGroups() {
    return this.allChatData.groups;
  }
  getPeople() {
    return this.allChatData.people;
  }

  areAllParticipantsContacts(group) {
    for(let user of group.participants) {
      if(user.uid != this.auth.getUid() && !this.contacts.find(c => c.uid == user.uid)) {
        return false;
      }
    }

    return true;
  }

  getTotalUnread() {
    let totalUnread = 0;
    if(!this.recent) return 0;
    for(let chat of this.recent) {
      totalUnread+= chat.unreadCount;
    }
    if (!totalUnread) { this.hasUnread = false; }
    return totalUnread;
  }

  updateRecent(chat: INewChat) {
    console.log(chat);
    if(chat.chatType == 'BROADCAST') return;

    //let newRows: INewChat[] = [chat];
    for(let i = 0; i < this.recent.length; i++) {
      let row = this.recent[i];
      if(row.chatId == chat.chatId) {
        this.recent.splice(i, 1);
      }
    }
    this.recent.unshift(chat);

    // if (this.allChatData.recent.rows.length > 0) {
    //   for (let i = 0; i < Math.min(this.MAX_RECENT, this.allChatData.recent.rows.length); i++) {
    //     if (this.allChatData.recent.rows[i].id !== chat.id) {
    //       newRows.push(this.allChatData.recent.rows[i]);
    //     }
    //   }
    // }
    // this.allChatData.recent.rows = newRows;
  }

  clearUnread(row: INewChat) {
    if (row.unreadCount) { // to reduce API calls.
      this.allChatData.recent.rows.forEach((tempRow: INewChat) => {
        if (tempRow.id === row.id) {
          tempRow.unreadCount = 0;
        }
      });

      const type = row.chatType;
      if (type === ChatRowType.PERSON) {
        this.allChatData.people.rows.forEach((tempRow: INewChat) => {
          if (tempRow.id === row.id) {
            tempRow.unreadCount = 0;
          }
        });
      } else { // type === ChatRowType.GROUP
        this.allChatData.groups.rows.forEach((tempRow: INewChat) => {
          if (tempRow.id === row.id) {
            tempRow.unreadCount = 0;
          }
        });
      }
      row.unreadCount = 0;

      const data = {
        action:'CLEAR_UNREAD',
        uid: this.auth.getUid(),
        chatId: row.chatId,
      };

      this.auth.apiUpdate('public/mrkg-lead/marking-chat', null, data);

      //this.emit(ChatEndpoint.CLEAR_UNREAD, data);
    }
  }

  async sendMessage(chat: any, msg, receiverUid, itemId?:number, isRetry?:boolean) {
    msg.created_on = new Date();
    if(msg.message == null || msg.message == "") {
      return 0;
    }
    msg.isUnread = true;
    const message: IMessageQueueItem = {
      chat: chat,
      msg: msg,
      receiverUid: receiverUid,
      itemId: itemId
    }

    if (!window.navigator.onLine && !isRetry) {
      this.messageQueue.push(message);
      msg.status = 'failed'; // need this or the message will say 'sent' for user who is sending
      return 0;
    }

    if (msg.isBroadcast) {
      const data = {
        action:'SEND_BROADCAST',
        uid: this.auth.getUid(),
        receiverUid,
        chatId:chat.chatId,
        msg:{...msg},
        itemId
      };
      // this.emit(ChatEndpoint.SEND_BROADCAST, data);
      msg.status = 'sending';
      let resp = await this.auth.apiUpdate('public/mrkg-lead/marking-chat', null, data);
      if(resp?.message == msg.message) {
        msg.status = 'sent';
        if(!chat.chatId) chat.chatId = resp.chat_id;
      }
      else if(!isRetry){
        msg.status = 'failed';
        this.messageQueue.push(message);
      }
    }
    else if(Array.isArray(receiverUid)) {
      //its a group
      const data = {
        action:'SEND_GROUP_MESSAGE',
        uid: this.auth.getUid(),
        receiverUid,
        chatId:chat.chatId,
        msg:{...msg},
      };

      // this.emit(ChatEndpoint.SEND_GROUP_MESSAGE, data);
      msg.status = 'sending';
      let resp = await this.auth.apiUpdate('public/mrkg-lead/marking-chat', null, data);
      if(resp?.message == msg.message) {
        msg.status = 'sent';
        if(!chat.chatId) chat.chatId = resp.chat_id;
      }
      else if(!isRetry){
        msg.status = 'failed';
        this.messageQueue.push(message);
        return 0;
      }
    } else {
      const data = {
        action:'SEND_MESSAGE',
        uid: this.auth.getUid(),
        receiverUid,
        chatId:chat.chatId,
        msg:{...msg},
      };
      msg.status = 'sending';
      let resp = await this.auth.apiUpdate('public/mrkg-lead/marking-chat', null, data);
      if(resp?.message == msg.message) {
        msg.status = 'sent';
        if(!chat.chatId) chat.chatId = resp.chat_id;
      }
      else if(!isRetry){
        msg.status = 'failed';
        this.messageQueue.push(message);
        return 0;
      }
      // this.emit(ChatEndpoint.SEND_MESSAGE, data);
    }

    return 1;
  }

  async getUsersFromParticipants(participants) {
    let users = [];
    for(let uid of participants) {
      let contact = this.contacts.find(c => c.uid == uid);
      if(!users.find(u => u.uid == uid)) {
        if(contact) {
          users.push({
            firstName:contact.firstName,
            lastName: contact.lastName,
            uid
          })
        }
        else {
          if(this.auth.getUid() != uid) {
            this.auth.apiFind('public/mrkg-lead/user', {
              query: {
                uid
              }
            }).then(userRecord => {
              if(userRecord.data.length == 1) {
                users.push({
                  firstName:userRecord.data[0].firstName,
                  lastName: userRecord.data[0].lastName,
                  uid
                })
              }
            });
            
          }
          else {
            users.push({
              uid:this.auth.getUid()
            });
          }
         
        }
      }
    }

    return users;
  }

}
