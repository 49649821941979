import { Type } from "@angular/core";
import { SCALE_MODES } from "pixi.js-legacy";
import { TwiddleState } from "src/app/ui-partial/twiddle/twiddle.component";
import { ItemType } from "../../ui-item-maker/models";
import { ISquiggleData, IsquiggleLabelStartPos } from "../element-render-custom-interaction/controllers/util/pixi-graph";
import { PubSubTypes } from "../element-render-frame/pubsub/types";
import { IQPubSubPayload } from "../question-runner/pubsub/question-pubsub";

export interface ICustomTaskSet {
  __id?: string;
  __backup__id?: string;
  __backup__userDisplayName?: string;
  name?: string;
  order?: number;
  taskId?: string; // cross link to collection `tasks`
  project?: string;
  isReady?: boolean;
  isProtected?: boolean;
  labelCounter?: number;
  isArchived?: boolean;
  questions?: IQuestionConfig[] | string;
  creatorUid?: string;
  timeCreated?: any;
  timeLastSaved?: any;
  schemaVersion?: number;
  lastTouchedBy?: string;
  assessmentFrameworkId?: string;
}

export interface ICustomTaskTag {
  __id?: string;
  caption: string;
  customTaskSetId: string;
  localTaskId: number;
  createdBy: string;
  timestamp: any;
}

export interface ICustomTaskComment {
  __id?: string;
  caption: string;
  customTaskSetId: string;
  localTaskId: number;
  createdByName: string;
  createdByUid: string;
  timestamp: any;
}

export interface IReadSelProps {
  [key:string] : {
    isVisible: boolean
  }
}

export interface IQuestionConfig {
  id?: number;
  assetId?: number;
  content: IContentElement[];
  label: string;
  testLabel: string;
  isReady?: boolean;
  readSel?: string;
  caption?: string;
  points?: string;
  readSelections?: string[];
  readSelProps?: IReadSelProps;
  isInfoSlide?: boolean;
  isNotScored?: boolean;
  notes?: string;
  taskId?: string;
  isReadingSelectionPage?: boolean; // intended to be temporary
  readingSelectionCaption?: string;
  isReqFill?: boolean;
  ishidePoints?: boolean;
  reqFillEntries?: string;
  reqFillMsg?: string;
  isReadingSelectionsAlwaysShown?: boolean;
  isStartHalf?: boolean;
  localTaskId: number;
  entryIdCounter?: number;
  entryOrder?: number[];
  quadrantFreq?: number;
  estimatedExposure?: number;
  isLocked?: boolean;
  langLink: IQuestionConfig;
  voiceover: {
    url?: string;
    script?: string;
    fileType?: string;
    lastModifiedOn?: number
  };
  __changeCounter?: number; // only used for meta changes at first
  meta: any;
  comments?: any[];
  type?: ItemType;
  reviewQuestionText?: string;
  isReviewQuestionExculded?: boolean;
  enableManualSaveButton?: boolean;
  manualSaveButtonTopMargin?: number;
  isConstructedResponse?: boolean;
  isRegistered? : boolean; //flag to determine if it should be included in tqr or not
  children?: IQuestionConfig[];
  disableQuestionResponseShrinkageDetection?: boolean;
}

export interface IDBQuestion {
  id: number,
  question_label: string,
  config: string,
  order: number,
  [key: string]: any,
}

export interface IQuestionImpressionConfig {
  question_id: number;
  element_type: string;
  total_unique_user_impressions: number;
}

export interface IAssetImpressionConfig {
  asset_id: number;
  asset_version_id: number;
  element_type: string;
  item_set_id: number;
  question_id: number;
}

export interface ISequenceConfig extends IQuestionConfig {
  children: IQuestionConfig[];
  isCollapsed: boolean;
}

export interface IContentElement {
  elementType: string | ElementType;
  isShowAdvancedOptions?: boolean;
  entryId?: number;
  _changeCounter?: number;
  isStateEnabled?: boolean;
  assetId?: number;
  assetVersionId?: number;
  colourScheme?: IContentElementColorPanel;
  voiceover?: any;
  canvasScaleWidth?: number;
  canvasScaleHeight?: number;
  _isCollapsed?: boolean;
  isNoInvertOnHiContrast?: boolean;
  url?: string;
  transcriptUrl?: string;
  format?: string;
  displayStyle?: string;
  paragraphStyle?: string;
  images?: any;
  content?: any;
}

export interface ReviewPageFilters {
  viewAll : boolean;
  unAnswered: boolean;
  flagged : boolean;
}

export enum ReviewPageFilterMode {
  VIEWALL = 'viewAll',
  UNANSWERED= 'unAnswered',
  FLAGGED = 'flagged'
}

export enum ValidatorMode {
  NORMAL = "NORMAL",
  COMBINATION = "COMBINATIONS"
}

export interface IContentElementValidator extends IValidatorCombinationProp, IContentElement, IScoredResponse {
  // validateId: number;
  // correctValue: string;
  validateProp: string;
  combinations?: IValidatorCombinationProp[][]
  mode: ValidatorMode;
}
export interface IValidatorCombinationProp{
  validateId: number;
  elementType: string;
  correctValue?: string;
  dndElements?: Array<{
    targetId: string;
    correctValue: string;
    isOrderImp: boolean;
  }>
  
  score?: number
} 

export enum CustomInteractionType {
  SCALE_RADIUS = "SCALE_RADIUS",
  SLIDER = "SLIDER",
  ROTATE_SPRITE = "ROTATE_SPRITE",
  GRID_DND = "GRID_DND",
  PIE_CHART = "PIE_CHART",
  GRID_FILL = "GRID_FILL",
  GRID_SCATTER_PLOT = "GRID_SCATTER_PLOT",
  DRAG_DROP = "DRAG_DROP",
  GRID_PAINT = "GRID_PAINT"
}
export interface IContentElementCustomInteraction extends IContentElement, IScoredResponse {
  interactionType: CustomInteractionType;
  canvasWidth: number;
  canvasHeight: number;
}

export interface IContentElementUpload extends IContentElement {
  allowedFileTypesStr:string
}

export interface IContentElementScaleRadius extends IContentElementCustomInteraction {
  scale: number;
  img: IContentElementImage;
  decimalRound: number;
  answer: number;
  // initialRadius: number,
  // minRadius: number,
  // maxRadius: number,
  // scale: number,
}
export interface IContentElementPixiDraggable {
  id: number;
  width: number;
  height: number;
  manualX: number;
  manualY: number;
  img: IContentElementImage;
  text: string;
}

export interface IContentElementPixiTarget {
  id: number;
  startHeight: number;
  startWidth: number;
  manualX: number;
  manualY: number;
  titleText: string;
  titleImg: IContentElementImage;
  titleHeight: number;
  backgroundImg: IContentElementImage;
}

export interface IContentElementPixiDragDrop extends IContentElementCustomInteraction {
  targets: IContentElementPixiTarget[]
  draggables: IContentElementPixiDraggable[]
}
export interface IContentElementRotateSprite extends IContentElementCustomInteraction {
  scale: number;
  spriteX: number;
  spriteY: number;
  spriteHeight: number;
  spriteWidth: number;
  spriteAnchorPoint: string;
  rotateSpriteImage?: IContentElementImage;
  rotateSpriteBgImage?: IContentElementImage;
  scaleBg: number;
  spriteBgX: number;
  spriteBgY: number;
  spriteBgHeight: number;
  spriteBgWidth: number;
  spriteBgAnchorPoint: string;
  rotateSpriteanswer;
}

export interface IContentElementSlider extends IContentElementCustomInteraction {
  sliderImage?: IContentElementImage;
  isVertical?: boolean;
  minValue: number;
  maxValue: number;
  startingValue: number;
  unit?: string;
  numNotches: number;
  showBigNotches: boolean;
  bigNotchMod?: number;
  bigNotchScale?: number;
  padding: number;
  notchLength: number;
  notchThickness: number;
  sliderOffset: number; //x offset for vertcal, y offset for horizontal
  sliderScale: number;
  snapAmount: number;
  minCorrectValueRange?: number[]
  maxCorrectValueRange?: number[]
  textMod: number;
  fontSize: number;
  skipFirstText: boolean;
  switchTextSide: boolean;
  textPadding: number;
  isAllowTargetFrame?:boolean;
  targetFrameId?:number;
  frameType?: PubSubTypes
  frameRotate?: number;
  frameScale?: number;
  isAllowMcqInteraction: boolean;
  mcqId?: number;
  mcqType?: McqDisplay;
  mapRangetoMcqOptionArray?: number[];
  sliderTextBackgroundColor?: string;
  sliderTextSize?: number;
  sliderTextColor?: string;
  sliderTextPosition_x?: number;
  sliderTextPosition_y?: number;
  displayTextValue: boolean;
  sliderTextStartingValue: number;
  sliderTextEndingValue: number;
  sliderTextDecimalPlaces: number;
  sliderTextValueGranularity: number;
  disableFrenchValueDisplayFormat: boolean;
  disableSliderText: boolean;
}

export interface IContentElementCartesianPlane extends IContentElementCustomInteraction {
  xLength: number;
  yLength: number;
  yGap: number;
  xGap: number;
  xInc: number;
  yInc: number;
  xAxisLabel: string;
  yAxisLabel: string;
  axisLabelFontSize: number;
  minX?: number;
  maxX?: number;
  minY?: number;
  maxY?: number;
  thicknessOfAxis?: number;
  thicknessOfLines?: number
  options?: IContentElementCartesianPlaneOption[];
  enableDrags?: boolean;
  draggableSide?: Direction;
  displayCoordsForDrags?: boolean;
  snapDragsOnGrid?: boolean;
  incDistanceBetweenLabelsX?: number;
  incDistanceBetweenLabelsY?: number;
}

export interface PieSlice {
  id?: string;
  colour: number;
  correctAns?: number;
  caption?: string;
  initialValue?: number;
  targetEntryId?: number
  weight?: number;
}
export interface IContentElementPieChart extends IContentElementCustomInteraction {
  granularity: number;
  totalPieValue: number;
  slices: PieSlice[];
  roundingAllowance: number;
  pieRadius: number;
  mode?: PieChartMode;
  answer?: number;
}

export enum PieChartMode {
  MOVE = "move",
  SELECT = "select"
}

export interface IContentElementCartesianPlaneOption {
  img: IContentElementImage;
  label?: string;
  fontSize?: number;
  correctX?: number;
  correctY?: number;
  length?: number;
  width?: number;
  gap?: number;
  id: string;
}

export interface IContentElementPixiGraph extends IContentElementCustomInteraction {
  // Please update pixi-graph.ts ; IGraphConfig & PIXI_GRAPH_DEFAULTS
  xAxisLabel?: string;
  yAxisLabel?: string;
  xLength: number;
  yLength: number;
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  xOrigin?: number;
  yOrigin?: number;
  yGap?: number;
  xGap?: number;
  // scaleUnit?: number;
  isGraphArrowsDisabled?: boolean;
  axisThickness?: number;
  lineThickness?: number;
  axisUnitFontSize?: number;
  axisLabelFontSize?: number;
  // xInc: number;
  // yInc: number;
  distanceBetweenLabelX?: number;
  distanceBetweenLabelY?: number;
  textRes?: number;
  isHideAxisUnitLabels?: boolean;
  isSnapping?: boolean;
  snapScale?: number;
  isSquiggled?: boolean;
  squiggleData?: ISquiggleData; 
  squiggleLabelStartPos?: IsquiggleLabelStartPos;
  isSmallGridEnabled?: boolean;
  xSmallGrid?: number;
  ySmallGrid?: number;
  backgroundColor?: string;
  backgroundColorAlpha?: number;
  scaleUnitX?: number;
  scaleUnitY?:number;
  isSquiggleHidden?: boolean;
  isXDecimal?: boolean;
  isYDecimal?: boolean;
}

export interface IContentElementGridFill extends IContentElementPixiGraph {
  defaultX: number;
  defaultY: number;
  defaultWidth: number;
  defaultHeight: number;
  isDefaultBorder: boolean;
  defaultBorderThick: number; 
  defaultBorderColor: string;
  defaultFillColor: string;
  defaultFillColorAlpha?: number;
  value: number;
  tolerance: number;  
}

export interface IContentElementGridScatterPlot extends IContentElementPixiGraph {
  pointRadius: number;
  pointColor: string;
  pointColorAlpha: number;
  isPointCircled: boolean;
  circleRadius: number;
  circleBorder: number;
  circleColor: string;
  correctPoints: {[name:string]: number[]}
}

export interface IContentElementGridPaint extends IContentElementPixiGraph {
  xStartDefault: number;
  yStartDefault: number;
  xEndDefault: number;
  yEndDefault: number;
  defaultFillColor: string;
  defaultFillColorHC?: string;
  defaultFillColorAlpha?: number;
  isAdjacentSelection?: boolean;
  value: number
}

export interface IContentElementMic extends IContentElement {
  setTimer?: boolean;
  isPractice?: boolean;
  time?: number;
  minDuration?: number;
  maxDuration?: number;
  onCountdownEnd?: IQPubSubPayload[];
  absoluteCountdownProgressBarPosition?: boolean;
  countdownProgressBarTopMargin?: number;
  mustListenBeforeUpload?: boolean;
  askTolistenBeforeUpload?: boolean;
  maxNumberOfRecording?: number;
}

export interface IScoredResponse {
  entryId?: number;
  scoreWeight?: number;
  enableProportionalScoring?: boolean;
  isOptional?: boolean;
}
export interface IContentElementSbs extends IContentElement {
  left: IContentElement[];
  right: IContentElement[];
}

export interface IContentElementSelectionTable extends IContentElement, IScoredResponse {
  topRow: IContentElementSelectionTableCell[];
  leftCol: IContentElementSelectionTableCell[];
  fontSize: number;
  checkBoxRows: IContentElementSelectionTableBool[][];
  isMustHaveOnePerRow: boolean;
  isMustHaveOnePerCol: boolean;
  allowMultipleAnswersPerRow?: boolean;
  allowMultipleAnswersPerColumn?: boolean;
  maxCheckedPerRow?: number;
  maxCheckedPerCol?: number;
  checkBoxSize: number;
  isQuestionWidthSet?: boolean;
  isAnswerWidthSet?: boolean;
  isAnswerHeightSet?: boolean;
  questionColumnWidth?: number;
  answerColumnWidth?: number;
  answerColumnHeight?: number;
  isFirstRowHeader?: boolean;
  isFirstColHeader?: boolean;
  configColWidth?: number;
  configRowWidth?: number;
  showAudio?: boolean;
  isRadio?: boolean;
  topLeftText?: IContentElementText;
  isLeftColumnHidden?: boolean;
  isTopRowHidden?: boolean;
  isElementInSelectableCell?: boolean;
  isCellPaddingSet?:boolean;
  leftCellPadding?: number;
  rightCellPadding?: number;
  topCellPadding?: number;
  bottomCellPadding?: number;
  leftColX?: number;
  leftColY?: number;
  isClickabilityConditional?: boolean;
  overflow?: string;
}

/*export interface IContentElementSelectionTableHeader {
  elementType: string;
  content: any;
}*/
export interface IContentElementSelectionTableCell {
  content: any;
  alignText?: string;
  alignItems?: string;
  audio?: IContentElementImage;
  altText?: string;
}

export interface IContentElementSelectionTableBool {
  value: boolean;

  cellType?: string;
  mathCellElement?: IContentElementMath;
  textCellElement?: IContentElementText;
  imgCellElement?: IContentElementImage;
  selectedCellType?: string;
  mathCellSelectedElement?: IContentElementMath;
  textCellSelectedElement?: IContentElementText;
  imgCellSelectedElement?: IContentElementImage;
  isSelectionDisabled?: boolean;
  defaultColor?: string;
  selectedColor?: string;
}

export interface IContentElementGraphing extends IContentElement {
  availableTools: { [key: string]: boolean };
}
export interface IContentElementGraphics extends IContentElement {
  url?: string;
}
export interface IContentElementText extends IContentElement {
  caption: string;
  paragraphStyle: TextParagraphStyle;
  simpleList?: string[];
  advancedList: IContentElement[];
  paragraphList?: TextParagraph[];
  paragraphNumberingPos?: NumberingPosition;
  paragraphNumberingMargin?: number;
  paragraphNumberingScale?: number;
  paragraphNumberingStart?: number;
  paragraphSpaceAfter?: number; // em
  link?: IContentElementTextLink;
  isAdvancedOptionsDisabled?: boolean;
  font?: FontFamily;
  alignment?: string;
  rotation?: number;
  paragraphWidth?: number; // used for paragraphs
  fontSize?: number;
  lineHeight?: number;
  isInvisible?: boolean;
  advancedInlineWidth?: number;
  colour?: string;
  unsetColor?: boolean;
  isShowDynamicText?: boolean;
  fromEntryId?: number;
  isTranscript?: boolean;
  isFixedHeight?: boolean;
  fixedHeight?: number;
  isShowNumberInputOrMCQResponse?: boolean;
  showhowNumberInputOrMCQResponseFormat?: string;
}

export interface TextParagraph {
  caption?: string,
  paddingRight?: number,
  paddingLeft?: number,
  paragraphSpaceAfter?: number,
  numberingOverride?: string,
}
export interface IContentElementSolution extends IContentElement {
  content: IContentElement[];
  hideFromTestTakers: boolean;
  hideFromMarkers: boolean;
  isRubric: boolean;
  isHeaderHidden: boolean;
}

export interface IContentElementResultsPrint extends IContentElement {
  content: IContentElement[];
  inputEntryId?: number;
}

export interface IContentElementTextSelection extends IContentElement {
  texts: IContentElementSelectableText[];
  hasOutline?: boolean;
  isSelectionsLimited?: boolean;
  maxSelections?: number;
  width?: number;
  isParagraphMode?: boolean;
  paragraphs?: IContentElementSelectableText[][]
  spaceAfterParagrapgh?: number;
  enableProportionalScoring?: boolean;
  scoreWeight?: number
}

export interface IContentElementSelectableText extends IContentElementMcqOption {}

export interface IContentElementColorPanel {
  backgroundColor?: string;
  textColor?: string;
}

export interface IContentElementFrameStateStyle {
  backGroundImg: IContentElementDynamicImage;
  dropShadowX: number;
  dropShadowY: number;
  blurRadius: number;
  shadowColor: string;
  backgroundColor: string;
  padding: number;
}

export interface IContentElementCanvasPage {
  displayList: IContentElementCanvasDisplayElement[];
}

export interface Bookmark2Page {
  page:number;
  bookmarkId:string;
}

export interface IContentElementCanvas extends IContentElement {
  width: number;
  height: number;
  scale?: number;
  pages: IContentElementCanvasPage[];
  readerId?: string;
  caption?: string;
  showBookmarks?: boolean;
  frame?: IContentElementFrameStateStyle;
  bgImg?: IContentElementDynamicImage;
  isPaginationHidden?: boolean;
  isFrameHidden?: boolean;
  bookmark2Page?: Bookmark2Page[];
}
export interface IContentElementFrame extends IContentElement {
  id?: number;
  content: IContentElement[];
  activatedStyles?: string[];
  styleRaw?: { [key: string]: string | number };
  width?: number;
  height?: number;
  maxHeight?: number;
  isClickable?: boolean;
  isAnimatable?: boolean;
  isOff?: boolean;
  toggleTargetId?: number;
  toggleTargetGroupId?: string;
  eventType?: PubSubTypes;
  eventData?: any;
  isFilledNotRequired?: boolean;
  linkedContent?: {
    // not yet implemented
    itemId: number;
    frameId: number;
  };
}
export interface IContentElementCanvasDisplayElement extends IContentElement {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  _isCollapsed?: boolean;
  element?: IContentElement;
  maintainAspectRatio?: number;
  isDisabled?: boolean;
}
export interface IContentElementTable extends IContentElement {
  grid: Array<Array<IContentElementTableCell>>;
  isHeaderRow: boolean;
  isHeaderCol: boolean;
  isColWidthConst: boolean;
  isTableOfValues?: boolean;
  colWidthConst: number;
}
export interface IContentElementTableCell {
  val: string;
  elementType?: string;
  align?: string;
  lineHeight?: string;
  centreVertically?: boolean;
}

export enum TextParagraphStyle {
  HEADLINE = 'headline',
  HEADLINE_SMALL = 'headline_small',
  REGULAR = 'regular',
  SMALL = 'small',
  BULLET = 'bullet',
  NUMBERED = 'numbered',
  PARAGRAPHS = 'paragraphs',
  ADVANCED_INLINE = 'advanced-inline',
  LINK = 'link',
  ANNOTATION = 'annotation',
}

export enum InputFormat {
  NUMBER = "number",
  FRACTION = "fraction",
  ALGEBRA = "algebra",
  RATIO = "ratio",
  TEXT = "text",
  FORM = "form",
  NUMBER_LIST = "number_list",
}

export type QuestionState = Map<number, any>;

// <option>Headline</option>
// <option>Regular</option>
// <option>Small</option>
// <option>Bullet List</option>
// <option>Numbered List</option>
// <option>Advanced Inline</option>

export enum BookmarkType {
  NORMAL = "normal",
  IMAGE = "image"
}

export enum NumberListModes {
  NUMBER_LIST = "numberList",
  NUMBER_GROUPS = "numberGroups",
}

export enum AlgebraInputModes {
  ALGEBRA_INPUT = "algebraInput",
  ALGEBRA_LIST = "algebraList",
  ALGEBRA_GROUPS = "algebraGroups"
}

export interface IContentElementTextLink extends IContentElement {
  caption: string;
  readerId?: string;
  readerElementId?: string;
  itemLabel?: string;
  thumbNail?: IContentElementImage;
  isIconDisabled?: boolean;
  bookmarkId?: string;
  readingSelectionCaption?: string;
  mode?: BookmarkType;
  bookmarkImg?: IContentElementImage;
  bottomPadding?: number;
  isSupressed?: boolean;
}

export enum AccessibilityVersion {
  NORMAL = 'normal',
  ACCESSIBLE = 'access'
}

export interface IContentElementMath extends IContentElement {
  latex: string;
  paragraphStyle: string;
  accessibilityImg: IContentElementImage;
  imgWidth?: number;
  imgHeight?: number
  showAccessibility?: AccessibilityVersion
}

export enum ImageStates {
  SELECTED = "selected",
  HOVERING = "hovering",
  DEFAULT = "default"
}

export enum NumberingPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  NONE = 'NONE',
}

export enum FontFamily {
  TIMES_NEW_ROMAN = "Times New Roman",
  BIG_CASLON = "Big Caslon",
  BODONI_MT = "Bodoni MT",
  BOOK_ANTIQUA = "Book Antiqua",
  BOOKMAN = "Bookman",
  ARIAL = "Arial",
  HELVETICA = "Helvetica",
  VERDANA = "Verdana",
  CALIBRI = "Calibiri",
  NOTO = "Noto",
  LUCIDA_SANS = "Lucida Sans",
  GILL_SANS = "Gill Sans",
  OSWALD = "Oswald"
}

export interface IContentElementDynamicImage extends IContentElementImage {
  images?: { [key: string]: IContentElementConditionalImage };
  subtexts?: IContentElementImageSubText[];
}

export interface IContentElementConditionalImage {
  condition: string;
  image?: IContentElementImage;
}

export interface IContentElementDocLink extends IContentElement {
  url?: string;
  fileType?: string;
  caption?: string;
}

export interface IContentElementLocAndDims extends IContentElement {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IContentElementAnnotatedText extends IContentElementText {
  text: IContentElementText;
  annotation?: string;
  isLeftAligned?: boolean;
  isConstrainedWidth?: boolean;
  annoteLineHeight?: number; 
  font?: FontFamily;
  fontSize?: number;
  lineHeight?: number;
  isInvisible?: boolean;
  advancedInlineWidth?: number;
  annotationContainerHeight?: number;
}

export enum ToolTipPositions {
  TOP = "TOP",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  BOTTOM = "BOTTOM"
}

export interface IContentElementAudio extends IContentElement, IScoredResponse {
  url?: string;
  fileType?: string;
  altText?: string;
  numAudioPlays?: number;
  isLimitAudioPlays?: boolean;
  isAutoPlay?: boolean;
  doNotAutoResume?: boolean;
  isHidden?: boolean;
  isPermanentTooltip?: boolean;
  toolTipPosition?: ToolTipPositions;
  autoPlayDelay?: number;
  onAudioEnd?: IQPubSubPayload[];
  labelX?: number;
  labelY?: number;
  buttonScale?: number;
  displayMode?: DisplayMode;
  loopAutomatically?: boolean;
  transcriptReadTime?: number;
  transcriptBoxID?: number;
  frenchTimer?: boolean;
  infoText?: string;
  customPlaybakSelectionMenuPosition?: boolean;
  playbackMenuTopMargin?: number;
  playbackMenuLeftMargin?: number;
}

export const defaultImageScaleFactor = 20;
export interface IContentElementImage extends IContentElement {
  url?: string;
  urls?: string[];
  fileType?: string;
  altText?: string;
  scale?: number;
  outline?: boolean;
  scaleFactor?: number;
  XPosition?: number;
  YPosition?: number;
  setRelative?: boolean;
  frameState?: IContentElementFrameStateStyle;
  hiContrastImg?: IContentElementImage
  isHiContrastSensitive?: boolean
  mode?: ContrastMode,
  isNoInvertOnHiContrast?: boolean
  isShowDynamicImage?: boolean
  fromEntryId?: number,
  textInImage?: string
}

export enum ContrastMode {
  NORMAL = 'normal',
  HIGH_CONTRAST = 'hicontrast'
}

export interface IContentElementImageSubText extends IContentElement {
  text: string;
  x: number;
  y: number;
  size: number;
  lineHeight?: number;
  rotation?: number;
  width: number;
  invisible: any;
  colourScheme?: IContentElementColorPanel;
  fontFamily?: string;
  alignment?: string;
  invertTextOnHightContrast?: boolean;
}

export enum DisplayMode {
  NORMAL = "normal",
  CUSTOM = "custom"
}

export interface IContentElementVideo extends IContentElement {
  url: string;
  urls?: string[];
  fileType?: string;
  altText?: string;
  subtitlesUrl?: string;
  maxViews?: number;
  isUnPausable?: boolean;
  isRestricted?: boolean;
  isAlwaysShowingTime?: boolean;
  allowFullScreen?: boolean;
  isAutoPlay?: boolean;
  loopAutomatically?: boolean;
  autoPlayDelay?: number;
  onVideoEnd?: IQPubSubPayload[];
  enableVideoControls?: boolean;
  mustWatch?: boolean;
  markFilledWhenEnd?: boolean;
  allowPlaybackSpeedAdjustment?: boolean;
  doNotResumePlayingWhenReturn?: boolean;
  showMouseOverToolTip?: boolean;
  toolTipOffset_x?: number;
  toolTipOffset_y?: number;
}
export interface IContentElementIframe extends IContentElement {
  url: string;
}
export interface IContentElementDnd extends IContentElement, IScoredResponse {
  targetType: DndTargetType;
  width?: number;
  height?: number;
  customTargetDim?: boolean;
  defaultTargetStyle: IContentElementDndSub;
  backgroundElements: Array<IContentElementDndBackground>;
  draggables: Array<IContentElementDndDraggable>;
  targets: Array<IContentElementDndTarget>;
  draggableCounter: number;
  targetCounter: number;
  groups: IDndGroup[];
}

export enum GroupingArrangementType {
  MANUAL = "manual",
  SIDE_BY_SIDE = "side",
  NORMAL = "normal",
}

export enum GroupingTargetContentAlignmentType {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export interface IContentElementGroup extends IContentElement, IScoredResponse {
  targetType: DndTargetType;
  width?: number;
  height?: number;
  customTargetDim?: boolean;
  wrapInTargets?: boolean;
  wrapInTargets_noShrink?: boolean;
  targetWidth?: number;
  targetHeight?: number;
  defaultTargetStyle: IContentElementDndSub;
  // backgroundElements: Array<IContentElementDndBackground>;
  draggables: Array<IContentElementDndDraggable>;
  targets: Array<IContentElementGroupTarget>;
  isNoInvertDragPreview: boolean;
  draggableCounter: number;
  targetCounter: number;
  groups: IDndGroup[];
  isInstructionsDisabled: boolean;
  isMatchingMode: boolean;
  isVerticalLayout: boolean;
  draggableColourScheme?: IContentElementColorPanel;
  targetColourScheme?: IContentElementColorPanel;
  arrangementType?: GroupingArrangementType;
  alignmentType?: GroupingTargetContentAlignmentType;
  groupWidth?: number;
  isTargetsAbove?: boolean;
  isTargetsContentsCentred?: boolean;
  draggableWidth?: number;
  draggableHeight?: number;
  isOptionsReusable?: boolean;
  separatorType?: string;
  separatorText?: IContentElementText;
  separatorImage?: IContentElementImage;
  separatorCanvas?: IContentElementCanvas;
  isShowSeparator?: boolean;
  isShowSeparatorInRenderHidden?: boolean;
  delimiterType?: string;
  delimiterImg?: IContentElementImage;
  delimiterIcon?: string;
  delimiter?: string;
  isShowDelimiter?: boolean;
  isRenderDelimiter?: boolean;
  isGroupSizeLimited?: boolean;
  groupSize?: number;
  isTargetListHorizontal?: boolean;
  removeDraggableBgBorder?: boolean;
  removeContainerBgBorder?: boolean;
  isDragContainerWidthSet?: boolean;
  dragContainerWidth?: number;
  isGroupContainerNoWrap?: boolean;
  isManualDraggableOrientationHorizontal?: boolean;
  isDraggableContainerGone?: boolean;
  sortDraggablesInGroups?: boolean;
  isTargetsSame?: boolean;
  isTargetsOrdered?: boolean;
  correctCombinations?: GroupingCombination[];
  correctOrders?: OrderCombination[];
  topAlignTargets?: boolean;
  reqMinimumPlacedTarget?: number;
  isPartialWeightsEnabled?: boolean;
  perGroupCheck?: boolean;
  isNotPadded?: boolean;
  bottomMarginEM?: number;
  targetPadding?: number;
  showCount?: boolean;
  showCountBackgroundColor?: string;
  showCountTextColor?: string;
  showCountText?: string;
  showCountTextMarginTop?: number;
  removeDropTransition?: boolean;
}

export interface OrderCombination {
  orderTarget?: number
  order: string
}
export interface GroupingCombination {
  id2amount: {}
}

export interface IDndGroup {
  id: number;
  caption: string;
}
export enum DndTargetType {
  DRAGGABLE = "DRAGGABLE",
  TARGET = "TARGET",
  GROUP = "GROUP"
}
export interface IContentElementDndSub {
  id?: number;
  x: number;
  y: number;
  width?: number;
  height?: number;
  backgroundColor?: string;
  borderColor?: string;
  borderThickness?: string;
  borderRadius?: number;
  padding?: number;
  verticalAlignment?: string;
  horizontalAlignment?: string;
  voiceover?: any;
  isReusable?: boolean;
  key_id?: string;
}
export interface IContentElementDndBackground extends IContentElementDndSub {
  element: IContentElement;
}
export interface IContentElementDndDraggable extends IContentElementDndSub {
  element: IContentElement;
  targetId?: number;
  key_id?: string;
  groupId?: number;
  voiceover?: any;
  containerBackgroundColor?: string;
  exactDraggableRequired?: number;
  minRequired?: number;
  maxRequired?: number;
  targetID2Amount?: any;
  label?: string;
  sortOrder?: number;
  partialWeight?: number;
  category?: string;
}
export interface IContentElementDndTarget extends IContentElementDndSub {
  draggableId?: number;
  groupId?: number;
  altText?: string;
  category?: string;
}

export enum GradingType {
  PLUS_MINUS = "plus_minus",
  REDUCTION = "reduction",
  NORMAL = "normal"
}
export interface IContentElementMoveableDragDrop extends IContentElement, IScoredResponse {
  width?: number;
  height?: number;
  altText?: string;
  draggables: IContentElementDndDraggable[];
  targets: IContentElementDndTarget[];
  isTargetsInvisible?: boolean;
  isDraggablesInvisible?: boolean;
  draggableCounter?: number;
  isDragsTransparent?: number;
  isTargetColourSame?: boolean;
  isAllTargetsToPlace?: boolean;
  isDraggableColourSame?: boolean;
  hasFrame?: boolean;
  moveableImages?: any[];
  targetColour?: string;
  draggableColour?: string;
  backgroundImg?: IContentElementImage;
  isOptionsReusable?: boolean;
  isCustomValidataion?: boolean;
  isMultipleOptionsRight?: boolean;
  pairMapping?: IDPairing[];
  isDraggableNotResizable?: boolean;
  isHideDraggableDropShadow?: boolean;
  isAcceptMultipleCombinations?: boolean;
  multipleCombinations?: any[];
  howManyToFill?: number;
  gradingMode: GradingType;  
  isNoInvertDragPreview: boolean;
  isReusable?: boolean;
  countNumberOfDraggables?: boolean;
  inputboxCounterEntryID?: number;
  enableResetButton?: boolean;
  resetBtnX?:number;
  resetBtnY?:number;
  resetBtnBorderRadius?:number;
  resetBtnBorderColour?:string;
  resetBtnTextColour?:string;
  resetBtnBackGroundColour?:string;
  resetBtnWidth?:number;
  isAllFilledNotRequired?:boolean;
  isCountOnlyRequired?: boolean;
  countOnly?: string;
}

export interface IDPairing {
  optionID?: string;
  targetID?: string;
}
export interface IContentElementGroupTarget extends IContentElementDndSub {
  element?: IContentElement;
  draggableId?: number;
  groupId?: number;
  groups?: any[];
  bgColor?: string;
  key_id?: string;
  backgroundImg?: IContentElementImage;
  voiceover?: any;
  headerTextColor?: string;
  headerMinHeight?: number;
  headerBGColour?: string;
  width?: number
  height?: number
  limit?: number
}

export enum OrderMode {
  TARGET = "target",
  REORDER = "reorder"
}

export interface IContentElementOrder extends IContentElement, IScoredResponse {
  displayStyle: McqDisplay;
  options: Array<IContentElementOrderOption>;
  scrambledOptions?: Array<IContentElementOrderOption>;
  delimeter: string;
  isScrambled: boolean;
  showDefaultText?: boolean;
  delimiter?: any;
  orderMode?: OrderMode;
  delimiterType?: string;
  delimiterImg?: IContentElementImage;
  delimiterIcon?: string;
  separatorType?: string;
  separatorText?: IContentElementText;
  separatorImage?: IContentElementImage;
  separatorCanvas?: IContentElementCanvas;
  targetWidth?: number;
  targetHeight?: number;
  isShowDelimiter?: boolean;
  isShowLabels?: boolean;
  isShowSeparator?: boolean;
  isShowSeparatorInRenderHidden?: boolean;
  isCenterDraggableContent?: boolean;
  isCenterLabelContent?: boolean;
  idCount?: number;
  isTargetOrientationReversed?: boolean;
  isDragWidthSet?: boolean;
  widthOfDrags?: number;
}

export interface IContentElementOption {
  voiceover?: any;
}

export interface IContentElementOrderOption extends IContentElementOption {
  optionId: number;
  elementType: string;
  content: any;
  [key: string]: any;
  isReadOnly?: boolean; //this is only for target mode.
  key_id?: string;
  label?: string;
  labelImg?: IContentElementImage;
  labelType?: string;
  x?: number;
  y?: number;
  labelx?: number;
  labely?: number;
}

export interface IContentElementMcq extends IContentElement, IScoredResponse {
  displayStyle: McqDisplay;
  options: Array<IContentElementMcqOption>;
  gridCellWidth?: number;
  isMultiSelect?: boolean;
  isSelectToggle?: boolean;
  isBgClear?: boolean;
  isBgClearAlways?: boolean;
  isPolaroidStyle?: boolean;
  gridNumColumns?: number;
  isContentsVertPaddDisabled?: boolean;
  staticOptionContainerPosition?: boolean;
  isContentsCentered?: boolean;
  isContentsCenteredVertically?: boolean;
  isScrambled?: boolean;
  isLimittedWidth?: boolean;
  isCanLink?: boolean;
  isColoredLikert?: boolean;
  avoidTextColorChangeLikert?: boolean;
  isOptionLabelsDisabled?: boolean;
  isRadioDisabled?: boolean;
  selectionBorderColor?: string;
  HCSelectionBorderColor?: string;
  isRadioBackgroundHidden?: boolean;
  isRadioBackgroundHiddenAlways?: boolean;
  currentSettings?: string;
  optionHeightSet?: boolean;
  isOffsetSelectionBorder?: boolean;
  isBorderColor?: boolean;
  isHCBorderColor?: boolean
  hasFrame?: boolean;
  minHeight?: number;
  optionHeight?: number;
  defaultDropdownText?: string;
  mcqAreaHeight?: number;
  isNoThickBorder?: number;
  marginRight?: number;
  marginBottom?: number;
  maxOptions?: number;
  width?: number;
  height?: number;
  isVerticallyCenterOptionIndicator?: boolean;
  isOnRightSideOptionIndicator?: boolean;
  isDynamicallyCorrect?: boolean;
  isHideOptionActivated?: boolean
  isCustomHoverEffect?: boolean
  fontSizeDropdownOptions?: boolean
  isOptionsBgColor?: boolean;
  optionsBgColor?: string;
  isOptionsFontColor?: boolean;
  optionsFontColor?: string;
  isOptionsBoxShadow?: boolean;
  optionDropshadowX?: number;
  optionDropshadowY?: number;
  optionDropshadowBlur?: number;
  optionDropshadowColor?: string;
  isSelectionScale?: Boolean;
  selectionScale?: number;
  isSelectionBorder?: Boolean;
  selectionBorder?: string;
  isEnablePartialweights?: boolean;
  isTextLeftAligned?: boolean;
  maxCustomDDWidth?: number;
  framePopIds?: string;
  isDropdownOptionCentered?: boolean;
  countNumberOfMCQSelected?: boolean;
  inputboxCounterEntryID?: number;
  blockTextInputBeforeSelection?:boolean;
  inputboxTextrEntryID?:number;
  enableResetButton?:boolean;
  resetBtnX?:number;
  resetBtnY?:number;
  resetBtnBorderRadius?:number;
  resetBtnBorderColour?:string;
  resetBtnTextColour?:string;
  resetBtnBackGroundColour?:string;
  resetBtnWidth?:number;
  resetBtnText?:string;
  isAllOrNothing?:boolean;
  selectTargetNumRequire?:string;
  // randomizeChoices?:boolean;
  numberOfSelectionsToMarkFilled?: number;
  isOptionalButRecordResponse?: boolean;
}

export interface IContentElementCustomMCQ extends IContentElementMcq {
  size?: number;
}

export interface IContentElementMcqOption extends IContentElementOption {
  optionId: number;
  isCorrect?: boolean;
  elementType: string;
  content: string;
  url?: string;
  link?: IContentElementTextLink;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  partialWeight?: number;
  frameInteractionType?: string;
  frameInteractionId?: number;
  frameData?: any;
  optionTargetsAndEvents?: IContentElementMcqOptionTargetEvent[];
  advancedList?: IContentElementTextLink[];
}

export interface IContentElementMcqOptionTargetEvent {
  frameInteractionIdList?: string;
  frameInteractionType?: string; 
}

export interface IContentElementCustomMcqOption extends IContentElementMcqOption {
  dynamicImage: IContentElementDynamicImage;
  xBubble?: number;
  yBubble?: number;
  subtext?: Array<IContentElementCustomMcqOptionSubText>;
  currentState?: ImageStates;
}

export interface IContentElementCustomMcqOptionSubText {
  text: string;
  x: number;
  y: number;
  size: number;
  colourScheme?: IContentElementColorPanel;
}

export enum McqDisplay {
  VERTICAL = "vertical",
  GRID = "grid",
  HORIZONTAL = "horizontal",
  WRAP = "wraparound",
  FREEFORM = "freeform",
  DROPDOWN = "dropdown",
  CUSTOM_DROPDOWN = "custom_dropdown",
  LIKERT = "likert",
  BUBBLE = "bubble"
}
export interface IRatioTermConfig {
  value: string;
}
export enum NumberTolerance {
  ALTVALUE = "altvalue",
  DECIMALS = "decimals",
  MINMAX = "minmax",
  PROXPERC = "proxperc" // proximity by percentage
}
export interface INumberTolerance {
  toleranceTypeId: NumberTolerance;
  numDecimals?: number;
  min?: number;
  max?: number;
  proxPerc?: number;
}
export interface IContentElementInput extends IContentElement, IScoredResponse {
  format: InputFormat;
  isDisabled?: boolean;
  latex?: string;
  startingLatex?: string;
  tolerances?: INumberTolerance[];
  value?: string;
  prefix?: string;
  suffix?: string;
  selectedLimitType?: string;
  minChars?: number;
  maxChars?: number;
  minWords?: number;
  maxWords?: number;
  alternativeValues?: IContentElementInput[];
  ratioTerms?: string[];
  isStrictLowestTerms?: boolean;
  isMixedNumber?: boolean;
  roundingTolerance?: string;
  roundingTolerancePercentageBased?: boolean;
  fracWholeNumber?: string;
  fracNumerator?: string;
  fracDenominator?: string;
  isStrictSimplified?: boolean;
  isAllowEquivalent?: boolean;
  formElements?: IContentElement[];
  defaultText?: string;
  defaultTextColour?: string;
  isToolbarAutoHide?: boolean;
  isFixedHeight?: boolean;
  fixedHeight?: number;
  isAnswerNotNecessary?: boolean;
  isSetHardLimitToZero?: boolean;
  isShowingFrenchKeys?: boolean;
  isShowingNumericKeyboard?: boolean;
  isShowingNumericKeyboardWithDecimal?: boolean;
  isDecimalsNotAllowed?: boolean;
  mathCharLimit?: number;
  maxCharFraction?: number;
  isReductionForced?: boolean;
  defaultTextAlignment?: string;
  inputNumFormat?: string;
  numberList?: NumberList[];
  numberGroups?: NumberList[][];
  acceptEnFrNumericFormats?: boolean;
  numberListMode?: string;
  algebraInputMode?: string;
  algebraList?: AlgebraList[];
  algebraGroups?: AlgebraList[][];
  isShowingWordCount?: boolean;
  isAlgebraSimple?: boolean;
  isTranscript?: boolean;
  useSpaceAsNumberSeparator?: boolean;
  lockBeforeAnswearingMCQ?:boolean;
  inputBoxBorderColor?: string;
  inputBoxBorderThickness?: number;
  inputBoxShadow?:boolean;
  inputFontWeight?: number;
  frenchKeyboardSize?: number;
}

export interface NumberList {
  value?: string;
  suffix?: string;
  values?: string[];
}

export interface AlgebraList {
  value?: string;
  suffix?: string;
}

export interface IContentElementInsertion extends IContentElement, IScoredResponse {
  textBlocks: IContentElementDndDraggable[];
  draggables: IContentElementDndDraggable[];
  draggableCounter: number;
  textBlocksCounter: number;
  isDragBetweenWords: boolean;
  isShowInstructions?: boolean;
  isTargetsAbove?: boolean;
  isRepeatableOptions?: boolean;
  blankTargetSize?: boolean;
  isDragContainerFixedWidth?: boolean;
  widthOfDraggables?: number;
}

export interface IContentTextBlock {
  value: IContentElementText;
}

export interface IElementTypeDef {
  id: ElementType | string;
  caption: string;
  icon: string;
  isInteractive?: boolean;
  isKeyboardAccessible?: boolean;
  isDisabled?: boolean;
  feedProps?: { [key: string]: any }; // used for the dropdown creators where the block type is overloaded and the config is what differentiates it
}

export enum ElementType {
  ANNOTATION = "annotation",
  AUDIO = "audio",
  BOOKMARK_LINK = "bookmark_link",
  CAMERA = "camera",
  CANVAS = "canvas",
  CANVAS_BOOKMARK = "canvas_bookmark_element",
  CUSTOM_INTERACTION = "custom_interaction",
  CUSTOM_MCQ = "custom_mcq",
  DND = "dnd",
  DOC_LINK = "doc_link",
  DYNAMIC_IMAGE = "dynamic_image",
  FRAME = "frame",
  GRAPHICS = "Graphics",
  GRAPHING = "graphing",
  GROUPING = "Grouping",
  HOTSPOT = "hotspot",
  HOTTEXT = "hottext",
  IFRAME = "iframe",
  IMAGE = "image",
  IMAGE_SUBTEXT ="image_subtext",
  INPUT = "input",
  INSERTION = "insertion",
  MATCHING = "matching",
  MATH = "math",
  MCQ = "mcq",
  MIC = "mic",
  MOVEABLE_DND = "moveable_dnd",
  ORDER = "order",
  READER = "reader",
  RESULTS_PRINT = "results_print",
  SBS = "sbs",
  SELECT_TABLE = "select_table",
  SELECT_TEXT = "select_text",
  SOLUTION = "solution",
  TABLE = "table",
  TEXT = "text",
  TEXT_LINK = "text_link",
  UPLOAD = "upload",
  VALIDATOR = "validator",
  VIDEO = "video",
  INPUT_NUMBER = "input-number",
  BLANK = 'BLANK',
}

export const ElementTypeDefs = {
  CUSTOM_INTERACTION: { id: ElementType.CUSTOM_INTERACTION, caption: "auth_custom_interaction", icon: "fa-american-sign-language-interpreting", isKeyboardAccessible: false, isPrintFriendly: true },
  VALIDATOR: { id: ElementType.VALIDATOR, caption: "auth_validator\n", icon: "fa-check", isKeyboardAccessible: true, isPrintFriendly: true },
  RESULTS_PRINT: { id: ElementType.RESULTS_PRINT, caption: "auth_results_print", icon: "fa-print", isKeyboardAccessible: true, isPrintFriendly: true },
  SOLUTION: { id: ElementType.SOLUTION, caption: "Solution", icon: "fa-columns", isKeyboardAccessible: true, isPrintFriendly: true },
  ANNOTATION: { id: ElementType.ANNOTATION, caption: "Annotation", icon: "fa-link", isKeyboardAccessible: false, isPrintFriendly: true },
  SELECT_TABLE: { id: ElementType.SELECT_TABLE, caption: "auth_selection_table", icon: "fa-table", isKeyboardAccessible: false, isPrintFriendly: true },
  CUSTOM_MCQ: { id: ElementType.CUSTOM_MCQ, caption: "auth_custom_mcq", icon: "fa-bars", isKeyboardAccessible: false, isPrintFriendly: false },
  MOVEABLE_DND: { id: ElementType.MOVEABLE_DND, caption: "auth_drag_drop", icon: "fa-hand-pointer", isPrintFriendly: false },
  INSERTION: { id: ElementType.INSERTION, caption: "Insertion", isDisabled: false, icon: "fas fa-sign-in-alt fa-rotate-90", isKeyboardAccessible: true, isPrintFriendly: false },
  TEXT: { id: ElementType.TEXT, caption: "auth_text", icon: "fa-font", isKeyboardAccessible: true },
  TEXT_LINK: { id: ElementType.TEXT_LINK, caption: "auth_reader_link", icon: "fa-link", isKeyboardAccessible: false, isPrintFriendly: true },
  BOOKMARK_LINK: { id: ElementType.BOOKMARK_LINK, caption: "auth_bookmark_link", icon: "fa-link", isKeyboardAccessible: true, isPrintFriendly: true  },
  FRAME: { id: ElementType.FRAME, caption: "auth_frame", icon: "fa-crop-alt", isKeyboardAccessible: true, isPrintFriendly: true },
  CANVAS: { id: ElementType.CANVAS, caption: "Canvas", isDisabled: false, icon: "fa-sticky-note", isKeyboardAccessible: true, isPrintFriendly: true },
  CANVAS_BOOKMARK: { id: ElementType.CANVAS_BOOKMARK, caption: "Bookmark Target", isDisabled: true, icon: "fa-sticky-note", isKeyboardAccessible: true, isPrintFriendly: true  },
  SELECT_TEXT: { id: ElementType.SELECT_TEXT, caption: "auth_text_selection", icon: "fa-font", isKeyboardAccessible: false, isPrintFriendly: true },
  TABLE: { id: ElementType.TABLE, caption: "auth_table", icon: "fa-table", isKeyboardAccessible: true, isPrintFriendly: true },
  MATH: { id: ElementType.MATH, caption: "auth_math", icon: "fa-pencil-alt", isKeyboardAccessible: true, isPrintFriendly: true },
  IMAGE: { id: ElementType.IMAGE, caption: "Image", icon: "fa-image", isKeyboardAccessible: true, isPrintFriendly: true },
  VIDEO: { id: ElementType.VIDEO, caption: "auth_video", icon: "fa-file-video", isKeyboardAccessible: true, isPrintFriendly: false },
  AUDIO: { id: ElementType.AUDIO, caption: "Audio", isDisabled: false, icon: "fa-file-audio", isKeyboardAccessible: false, isPrintFriendly: false },
  DOC_LINK: { id: ElementType.DOC_LINK, caption: "Document", isDisabled: false, icon: "fa-file-pdf", isKeyboardAccessible: true, isPrintFriendly: true },
  INPUT: { id: ElementType.INPUT, caption: "auth_keyboard_input", isInteractive: true, isDisabled: false, icon: "fa-keyboard", isKeyboardAccessible: true },
  MCQ: { id: ElementType.MCQ, caption: "auth_multiple_choice", isInteractive: true, icon: "fa-bars", isKeyboardAccessible: true },
  ORDER: { id: ElementType.ORDER, caption: "auth_ordering", isInteractive: true, icon: "fa-sort", isKeyboardAccessible: true, isPrintFriendly: false },
  GRAPHING: { id: ElementType.GRAPHING, caption: "auth_geometry", isInteractive: true, isDisabled: false, icon: "fa-paint-brush", isKeyboardAccessible: false, isPrintFriendly: true },
  DND: { id: ElementType.DND, caption: "auth_drag_drop", isInteractive: true, isDisabled: true, icon: "fa-hand-pointer-o", isKeyboardAccessible: true, isPrintFriendly: false },
  HOTSPOT: { id: ElementType.HOTSPOT, caption: "Hot Spot", isInteractive: true, isDisabled: true, icon: "fa-asterisk", isKeyboardAccessible: false, isPrintFriendly: true },
  HOTTEXT: { id: ElementType.HOTTEXT, caption: "Hot Text", isInteractive: true, isDisabled: true, icon: "fa-commenting", isKeyboardAccessible: false, isPrintFriendly: true }, // include features such as: click any word to highlight, include instructions above (which use the highlighting styling), click any word to capitalize it, drag in a comma (into any open space), semi-colon or other punctuation, drag out a punctuation to throw it away.
  MATCHING: { id: ElementType.MATCHING, caption: "Matching", isInteractive: true, isDisabled: false, icon: "fa-compress", isKeyboardAccessible: false, isPrintFriendly: true }, // like equation attack ... https://h5p.org/image-pairing
  READER: { id: ElementType.READER, caption: "Reader", isDisabled: true, icon: "fa-bars", isKeyboardAccessible: false, isPrintFriendly: true }, // like the liseuse in French
  MIC: { id: ElementType.MIC, caption: "Microphone", isInteractive: true, isDisabled: false, icon: "fa-microphone", isKeyboardAccessible: true, isPrintFriendly: false },
  CAMERA: { id: ElementType.CAMERA, caption: "Camera", isInteractive: true, isDisabled: false, icon: "fa-camera", isKeyboardAccessible: true, isPrintFriendly: false },
  UPLOAD: { id: ElementType.UPLOAD, caption: "auth_file_upload", isInteractive: true, isDisabled: false, icon: "fa-upload", isKeyboardAccessible: true, isPrintFriendly: false },
  GRAPHICS: { id: ElementType.GRAPHICS, caption: "auth_graphics", isInteractive: true, isDisabled: true, icon: "fa-image", isKeyboardAccessible: true, isPrintFriendly: true },
  GROUPING: { id: ElementType.GROUPING, caption: "auth_grouping", isInteractive: true, isDisabled: false, icon: "fa-object-group", isKeyboardAccessible: true, isPrintFriendly: false },
  PAINTING: { id: ElementType.TEXT, caption: "Painting", icon: "fa-font", isKeyboardAccessible: false, isPrintFriendly: true },
  SCULPTING: { id: ElementType.TEXT, caption: "Sculpting", icon: "fa-font", isKeyboardAccessible: false, isPrintFriendly: true }
};

export const elementTypes: IElementTypeDef[] = [
  ElementTypeDefs.TEXT,
  ElementTypeDefs.CANVAS,
  ElementTypeDefs.FRAME,
  ElementTypeDefs.TABLE,
  ElementTypeDefs.MATH,
  ElementTypeDefs.INSERTION,
  ElementTypeDefs.IMAGE,
  ElementTypeDefs.GRAPHICS,
  ElementTypeDefs.MOVEABLE_DND,
  ElementTypeDefs.VIDEO,
  ElementTypeDefs.AUDIO,
  ElementTypeDefs.DOC_LINK,
  ElementTypeDefs.INPUT,
  ElementTypeDefs.MCQ,
  ElementTypeDefs.ORDER,
  ElementTypeDefs.GRAPHING,
  ElementTypeDefs.SELECT_TEXT,
  // ElementTypeDefs.DND,
  // ElementTypeDefs.MATCHING,
  ElementTypeDefs.GROUPING,
  // ElementTypeDefs.HOTSPOT,
  // ElementTypeDefs.HOTTEXT,
  ElementTypeDefs.SELECT_TABLE,
  ElementTypeDefs.CUSTOM_MCQ,
  ElementTypeDefs.MIC,
  ElementTypeDefs.CAMERA,
  ElementTypeDefs.UPLOAD,
  // ElementTypeDefs.READER,
  ElementTypeDefs.SOLUTION,
  ElementTypeDefs.RESULTS_PRINT,
  ElementTypeDefs.VALIDATOR,
  ElementTypeDefs.CUSTOM_INTERACTION
];

export enum Direction {
  UP = "up",
  DOWN = "down",
  LEFT = "left",
  RIGHT = "right"
}

export interface IEntryState {
  type: string;
  isCustomGrading?: boolean;
  isCorrect?: boolean;
  isStarted: boolean;
  isFilled: boolean;
  isResponded?: boolean;
  isOptionalResponded?: boolean;
}

export const getElementWeight = (element: IScoredResponse) => {
  const weight = element.scoreWeight;
  if (weight != null && +weight === 0) {
    return weight; // should probably return +weight to make sure it's a number, otherwise "0" can be returned
  }
  if (!weight) {
    return 1;
  }
  return +weight;
};

export const roundNumeric =(num: number) => { 
  return Math.round(100*num)/100; // fixed to 2 decimal places for now
}

export const shuffle = (arr: any[]) => {
  for (let i = 0; i < arr.length; i++) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
};

export const countDecimals = (number) => {
  if(Math.floor(number) == number) return 0;
  return number.toString().split('.')[1].length || 0;
}

export const intializeDefault = (defaults, object) => {
  for (let def of Object.keys(defaults)) {
    if (object[def] == null) {
      object[def] = defaults[def];
    }
  }
}

export const mapToObject = ( map:Map<any, any>) => {
  const obj:any = {};
  map.forEach((value, key) => {
    obj[key] = value;
  })
  return obj;
}

export const objectToMap = ( obj:any ) => {
  const map:Map<any, any> = new Map();
  for (const [key, value] of Object.entries(obj)) {
    map.set(key, value);
  }
  return map;
}


export enum ScoringTypes {
  AUTO = "AUTO",
  REVIEW = "REVIEW",
  MANUAL = "MANUAL"
}
export interface IEntryStateScored extends IEntryState {
  score: number;
  weight: number;
  scoring_type: ScoringTypes;
  isScoringDisabled?: boolean
}

export interface IEntryStateMcq extends IEntryStateScored {
  selections: Array<{
    i: number;
    id: number;
    elementType: string;
    content: any;
  }>;
  selectionsMap?: Map<number,boolean>
  isPathFollowed?: boolean;
  alreadyScrambled?: boolean;
  isOptional?: boolean;
  isOptionalButRecordResponse?: boolean;
}

export interface IEntryStateOrder extends IEntryStateScored {
  answers: IContentElementOrderOption[][];
  options: IContentElementOrderOption[];
}

export interface IEntryStateSelectionTable extends IEntryStateScored {
  checkMarks: { value: boolean }[][];
}

export interface IEntryStateGroup extends IEntryStateScored {
  draggables: any[];
  targets: any[];
  isTargetsOrdered?: boolean
}

export interface IEntryStateCustomInteraction extends IEntryState, IEntryStateScored {
  subtype: CustomInteractionType;
}
export interface IEntryStateScaleRadius extends IEntryStateCustomInteraction {
  radius: number;
  xCursor: number;
  yCursor: number;
  userInteracted: boolean;
  userScaledRadius: boolean;
}

export interface IEntryStateDragDrop extends IEntryStateCustomInteraction {
  targetsID: number[][]
}
export interface IEntryStatePieChart extends IEntryStateCustomInteraction {
  values: {[id:string] : number}
  answers: number[]
  ansIndex: number
  startAngle: number
}

export interface IEntryStateSlider extends IEntryStateCustomInteraction {
  value: number;
  userInteracted?:boolean;
  userMovedSlider?: boolean;
}

export interface IEntryStateRotateSprite extends IEntryStateCustomInteraction {
  value: number;
  rotation: number;
}
export interface IEntryStateCartesianPlane extends IEntryStateCustomInteraction {
  optionStates: any[]
}

export interface IEntryStateGridFill extends IEntryStateCustomInteraction {
  value: number;
  shapeBoundries: any;
}

export interface IEntryStateGridScatterPlot extends IEntryStateCustomInteraction {
  value: Map<string, number[]>;
}
export interface IEntryStateGridPaint extends IEntryStateCustomInteraction {
  paintContainerData: Map<string, {
    xStart: number;
    yStart: number;
    xEnd: number;
    yEnd: number;
  }>;
}

export interface IEntryStateVideo extends IEntryState {
  numViews?: number;
  timeStamp?: number;
  transcriptAudioTime?: number;
  seekTimes?: number[];
  loadTimes?: number[];
  pauseTimes?: number[];
  endedTimes?: number[];
}


export interface IElementPos {
  ref: IContentElementDndSub;
  originalX: number;
  originalY: number;
  isTarget?: boolean;
  isDraggable?: boolean;
  style: {
    [key: string]: string | number
  };
}
export interface KeyboardDrop{
  lastSrcIndex: number | null,
  sourceElement: IElementPos | {},
  source: IElementPos[],
  srcFromHome?: boolean | null
}
export interface IEntryStateValidator extends IEntryStateScored {
  value?: number;
}

export interface IEntryStateFrame extends IEntryState {
  rotation: number;
  currHeight: number;
}

export interface IEntryStateInsertion extends IEntryStateScored {
  draggables: any[];
  targets: any[];
}

export interface IEntryStateMoveableDragAndDrop extends IEntryStateScored {
  targets: any[];
}

export interface IEntryStateCamera extends IEntryStateScored {
  url?: string;
}
export interface IEntryStateMic extends IEntryStateScored {
  fileType?: string;
  url?: string;
  time?: number;
  numberOfRecordingsRemain?: number;
}

export interface IEntryStateInputNumber extends IEntryStateScored {
  value: string;
}
export interface IEntryStateInputMath extends IEntryStateScored {
  latex: string;
}
export interface IEntryStateInputText extends IEntryStateScored {
  str: string;
  lockingMcqEntryId: number;
}
export interface IEntryStateInputFraction extends IEntryStateScored {
  wholenumber: number;
  numerator: number;
  denominator: number;
  reducedValue?: number;
}
export interface IEntryStateInputRatio extends IEntryStateScored {
  terms: number[];
}

export interface IEntryStateGraphing extends IEntryStateScored {
  state: any;
}

export interface IEntryStateGraphics extends IEntryState {
  state: any;
}

export interface IEntryStateInputNumberList extends IEntryStateScored {
  values: string[];
}

export interface IEntryStateCanvas extends IEntryState {
  pagesViewed: any;
}
