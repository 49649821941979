<div *ngIf="isVisible()" class="solution-area" (mouseenter)="audioTrigger.next(true)">
  <div style="border:1px solid #ccc; margin-top:2em;"></div>
  <div  class="solution-container">
    <div class="solution-header" [ngStyle]="getTextStyle()" *ngIf="isSolutionHeaderVisible()">
      <!-- <tra slug="lbl_solution"></tra><tra slug="txt_colon"></tra> -->
      <tra slug="lbl_correct_answer"></tra><tra slug="txt_colon"></tra>
    </div>
    <div *ngFor="let contentElement of element.content" [class.invert]="invert()">
      <element-render
        [contentElement]="contentElement" 
        [questionState]="questionState" 
        [isLocked]="isLocked"
        [questionPubSub]="questionPubSub"
      ></element-render>
    </div>

  </div>
</div>

<render-audio
[url]="element.voiceover?.url || ''"
[trigger]="audioTrigger"
[isTriggerDisabled]="!isVoiceoverEnabled()"
></render-audio>
