import { IPanelLink } from "./type";
import { BC_GRAD_SCHOOL_ADMIN_VIEWS } from '../../../ui-schooladmin/view-schooladmin-dashboard/data/views'

const items: IPanelLink[] = BC_GRAD_SCHOOL_ADMIN_VIEWS.map(view => {
  // if(view.caption == 'Marking') {
  //   return {
  //     caption: view.caption,
  //     routerLink: `/en/mrkg-coord/dashboard`,
  //     iconUrl: view.imgUrl,
  //     disabled: !!view.disabled
  //   }
  // }
  return {
    id: view.id,
    caption: view.caption,
    routerLink: `/en/school-admin/bc-grad/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled,
    isHidden: !!view.isHidden,
  }
})

export default items