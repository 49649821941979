import { SimpleChange } from '@angular/core';


export const ngHasInputChanged = (value: SimpleChange, field: string): boolean => {
    if (!value) return false;
    // if both values are undefined, no change
    if (value.currentValue === undefined && value.previousValue === undefined) return false;
    // if both values are null, no change
    if (value.currentValue === null && value.previousValue === null) return false;
    // if one value is null or undefined, there is a change
    if (value.currentValue == null || value.previousValue == null) return true;
    // if values are equal, no change
    if (value.currentValue[field] === value.previousValue[field]) return false;
    // there is a change
    return true;
}