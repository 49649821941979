import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {
  public zoomSub:BehaviorSubject<number> = new BehaviorSubject(null);
  public screenShrinkSub:BehaviorSubject<number> = new BehaviorSubject(null);

  // to do: this should be in its own service: CkEditorFocusService
  currentCkEditorId = -1;
  private ckEditorIdAutoIncr = 0;
  setCurrentCkEditor = (id:number) => (this.currentCkEditorId = id);
  isCurrentCkEditor = (id:number) => (id === this.currentCkEditorId);
  getNewCkEditorId = () => (this.ckEditorIdAutoIncr ++);
  //////

  constructor() { 
  }

  update(zoomVal: number, ignoreScreenShrink: boolean = false) {
    this.zoomSub.next(zoomVal);
    if(ignoreScreenShrink) {
      this.screenShrinkSub.next(1);
    }
  }

  updateScreenShrink(screenShrink: number) {
    this.screenShrinkSub.next(screenShrink);
  }

  getZoom() {
    return this.zoomSub.value;
  }

  getScreenShrink() {
    return this.screenShrinkSub.value;
  }

  getScreenShrinkZoom() {
    return this.zoomSub.value * this.screenShrinkSub.value;
  }
}
