<div *ngIf="testState && !testState.isSubmitted" class="screen-warning">
  <tra slug="error_small_screen"></tra>
</div>

<div *ngIf="testState && testState.isSubmitted" class="sample-question-review">
  <h2>
    <tra slug="title_sample_q_r"></tra>
  </h2>
  <div>
    <markdown class="markdown" [data]="lang.tra('txt_sample_q_r')" ngPreserveWhitespaces> </markdown>  
  </div>
  <hr/>
  <div class="question-review-viewport">
    <div class="section-question-preview" #questionReviewRunner>
      <div style="margin-bottom:0.5em;"> <strong>
          <tra slug="title_section"></tra>
        {{testState.currentSectionIndex+1}}, 
        <tra slug="title_question"></tra>
        {{testState.currentQuestionIndex+1}}
      </strong> </div>
      <question-runner
        [currentQuestion]="getActiveQuestionContent()"
        [currentQuestionIndex]="0"
        [totalQuestions]="4"
        [isSubmitted]="true"
        [questionState]="getActiveQuestionState()"
      ></question-runner>
    </div>
    <div class="section-question-list">
      <div *ngFor="let section of currentTestDesign.sections; let sectionIndex = index" [class.is-hidden]="!section.isShuffleDisabled">
        <div class="question-summary-section" >
          <tra slug="title_section"></tra> 
          {{sectionIndex+1}} 
          <tra slug="of_enum"></tra> 
          {{currentTestDesign.sections.length}} 
          : <tra [slug]="section.caption"></tra>

        </div>
        <div class="question-row-container">
          <div *ngFor="let questionId of section.questions; let qIndex = index;" class="question-row">
            <button class="button" 
              [class.is-text]="questionId != getActiveQuestionId()" 
              [class.is-info]="questionId == getActiveQuestionId()" 
              (click)="selectSectionAndQuestion(sectionIndex, qIndex)"
            >
              <span class="validation-icon">
                <i *ngIf="getQuestionScore(questionId)" class="is-correct fa fa-check-circle-o" title="Correct" aria-hidden="true"></i>
                <i *ngIf="!getQuestionScore(questionId)" class="is-incorrect fa fa-times-circle-o" title="Incorrect" aria-hidden="true"></i>
              </span>
              <tra slug="title_question"></tra>
              <span style="margin-left: 0.2em;">{{qIndex+1}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
  
  <hr/>
  <div>
    <button class="button footer-btn is-large is-info" (click)="leaveTest()">
      <tra slug="btn_leave_sample_q_r"></tra>
    </button>
  </div>
</div>

<div *ngIf="testState && !testState.isSubmitted" class="question-content-frame" [class.is-hi-contrast]="isHighContrast" [ngStyle]="{'font-size.em': zoomLevel}">
  <div *ngIf="!isHelpOverlay"  class="question-content">
    <div class="question-content-constrainer is-fixedwidth" style="margin-bottom:2em;">
      <div class="question-loc-sm">
          <tra slug="title_section"></tra> {{testState.currentSectionIndex+1}}, 
          <tra slug="title_question"></tra> {{testState.currentQuestionIndex+1}}
      </div>
      <div style="position:relative" [class.is-hidden]="isLineReaderActive">
          <progress 
            class="progress is-info" 
            style="margin-bottom:0px;" 
            [value]="getCurrentProgressLoc(true)" 
            max="100"
          >{{getCurrentProgressLoc()}}</progress>
          <div 
            *ngFor="let section of currentTestDesign.sections; let isLast = last" 
            class="section-progress-marker"
            [ngStyle]="{left: section.__meta.markLoc}"
            [class.is-hidden]="isLast"
          > </div>
      </div>
    </div>
    <div *ngIf="isFormulasToggledOn && isFormulasAvailable()" style="    max-width: 100%; overflow: auto;">
      <formula-sheet></formula-sheet>
      <hr/>
    </div>
    <div class="helper-tools">
      <div  *ngIf="isLineReaderActive" >
        <div class="line-reader-container">
            <div cdkDrag class="line-reader" >
              <div class="line-reader-top"></div>
              <div class="line-reader-left"></div>
              <div class="line-reader-right"></div>
              <div class="line-reader-bottom"></div>
            </div>
        </div>
      </div>
      <div  *ngIf="isCalcAvailable()" style="z-index: 999;">
        <widget-calculator *ngIf="isShowingCalc()"></widget-calculator>
      </div>
    </div>
    <div class="question-content-constrainer">
        <div *ngIf="isShowingSectionInfo" style="position: relative;">
          <div style="font-size: 2em; color: #3298dc; position:absolute; left:-1em; top:0.1em;">
            <i class="fa fa-info"></i>
          </div>
          <question-runner
            [currentQuestion]="getSectionInfoContent()"
            [currentQuestionIndex]="0"
            [totalQuestions]="4"
          ></question-runner>
        </div>
        <div class="next-button-container" *ngIf="isShowingSectionInfo">
          <button (click)="hideSectionInfo()" class="button is-large is-info">
            <tra slug="btn_next_question"></tra>
          </button>
        </div>
        <!--  -->
        <div [class.is-hidden]="isShowingSectionInfo">
            <question-runner
              [currentQuestion]="getActiveQuestionContent()"
              [currentQuestionIndex]="0"
              [totalQuestions]="4"
              [questionState]="getActiveQuestionState()"
            ></question-runner>
        </div>
        <div class="next-button-container"  [class.is-hidden]="isShowingSectionInfo">
            
          <button [disabled]="!isCurrentQuestionFilled()" *ngIf="!isOnLastQuestion()" (click)="gotoNextQuestion()" class="button is-large is-info">
            <tra slug="btn_next_question"></tra>
          </button>
          <button [disabled]="!isCurrentQuestionFilled()" *ngIf="isOnLastQuestion()" (click)="reviewAndSubmit()" class="button is-large is-info">
            <tra slug="btn_review_submit"></tra>
          </button>
        </div>
    </div>
  </div>
  <div *ngIf="isHelpOverlay" class="question-content">
    <div class="question-content-constrainer is-fixedwidth" style="margin-bottom:2em;">
      <question-runner
        [currentQuestion]="getHelpScreenLayout()"
        [currentQuestionIndex]="0"
        [totalQuestions]="4"
      ></question-runner>
    </div>
  </div>
  <div class="test-nav" *ngIf="!isLineReaderActive">
    <div>
      <div class="support" *ngIf="false">
        <button (click)="reportIssue()">
          <img src="https://mathproficiencytest.ca/img/red-flag.png">
          <tra slug="btn_report_issue"></tra>
        </button>
      </div>
      <div class="identity">
        <div class="identity-name">[<tra slug="txt_dummy_tt_name"></tra>]</div>
        <div class="identity-key">[<tra slug="txt_dummy_attempt_key"></tra>]</div>
      </div>
      <div class="test-title">
          <tra slug="brand_long"></tra>
      </div>
      <div class="test-section">
        <tra slug="title_section"></tra>
        {{(getCurrentSectionIndex() + 1)}} 
        <tra slug="of_enum"></tra>
        {{currentTestDesign.sections.length}}
        <button (click)="showSectionInfo()" class="button is-small" [class.is-info]="isShowingSectionInfo" style="margin-left: 2em;"><i class="fa fa-info"></i></button>
      </div>
    </div>
    <div class="test-questions">
      <button 
        *ngFor="let question of getCurrentQuestions(); let qIndex = index;"
        class="question" 
        tabindex="0"
        (click)="selectQuestion(qIndex)" 
        [class.is-active]="!isShowingSectionInfo && getCurrentQuestionIndex() === qIndex" 
      >
        <span class="icon">
          <i *ngIf="!isQuestionFilled(qIndex)" class="far fa-circle" title="Not Filled" aria-hidden="true"></i>
          <i *ngIf="isQuestionFilled(qIndex)" class="fas fa-circle" title="Filled" aria-hidden="true"></i>
        </span>
        <span>
          <tra slug="title_question"></tra> {{qIndex+1}}
        </span>
      </button>
      <button class="question" (click)="reviewAndSubmit()">
        <tra slug="btn_review_submit"></tra>
      </button>
    </div>
    <div class="test-timing">
      <button (click)="checkTimeLeft()">
        <img src="https://mathproficiencytest.ca/img/clock.png">
      </button>
    </div>
  </div>

  <div class="toolbar">
    <div class="tools-top">
      <button 
        class="toolbar-icon tool-help" [class.is-activated]="isHelpOverlay"
        (click)="toggleHelpScreen()" 
        title="{{lang.tra('btn_help')}}"
      >
        <!-- <img src="https://mathproficiencytest.ca/img/text-to-speech.png"> -->
        <i class="fa fa-question-circle" style="font-size: 2em;" aria-hidden="true"></i>
        <!-- Text to speech by Trevor Dsouza from the Noun Project -->
      </button>
      <button 
        class="toolbar-icon" 
        (click)="toggleTextToSpeech()" 
        title="{{lang.tra('btn_listen')}}"
      >
        <img src="https://mathproficiencytest.ca/img/text-to-speech.png">
        <!-- Text to speech by Trevor Dsouza from the Noun Project -->
      </button>
      <button 
        class="toolbar-icon" 
        (click)="zoomIn()" 
        title="{{lang.tra('btn_zoom_in')}}"
      >
        <img src="https://mathproficiencytest.ca/img/zoom-in.png">
      </button>
      <button 
        class="toolbar-icon"  
        (click)="zoomOut()"  
        title="{{lang.tra('btn_zoom_out')}}"
      >
        <img src="https://mathproficiencytest.ca/img/zoom-out.png">
      </button>
      <button 
        class="toolbar-icon" 
        (click)="toggleLineReader()"  
        [class.is-active]="isLineReaderActive"
        title="{{lang.tra('btn_line_reader')}}"
      >
        <img src="https://www.mathproficiencytest.ca/img/line_reader.png">
      </button>
      <button 
        class="toolbar-icon" 
        (click)="toggleHiContrast()"  
        title="{{lang.tra('btn_hi_contrast')}}"
      >
        <!-- hi contrast -->
        <img src="https://mathproficiencytest.ca/img/hi-contrast.png">
      </button>
      <button class="toolbar-icon" *ngIf="false">
        <!-- teext aloud -->
        <img src="https://mathproficiencytest.ca/img/text-aloud.png">
      </button>
    </div>
    <div class="tools-bottom">
      <button 
        *ngIf="isFormulasAvailable()"  
        (click)="toggleFormulas()" 
        class="toolbar-icon" 
        title="{{lang.tra('btn_formulas')}}" 
      >
        <img src="https://mathproficiencytest.ca/img/reference-book.png">
      </button>
      <button 
        *ngIf="isCalcAvailable()" 
        (click)="toggleCalc($event)"  
        class="toolbar-icon" 
        title="{{  lang.tra('btn_calculator')}}" 
      >
        <img src="https://mathproficiencytest.ca/img/calculator.png">
      </button>
  </div>  
</div>

<div class="custom-modal" *ngIf="currentModal">
  <!-- <div class="modal-bg" (click)="closeModal()"></div> -->
  <div class="modal-contents">
    <div>{{currentModal.caption}}</div>
    <div class="modal-buttons">
        <button class="button" (click)="closeModal()"> <tra slug="btn_cancel"></tra> </button>
        <button class="button is-info" (click)="confirmModal()">OK</button>
    </div>
  </div>
</div>