<!-- these links have been commented out and not removed due to the chance that we might want to add them back in through the support tool -->
<!-- <div *ngIf="displayInfoForAdmins" class="assessment-level-intro guide-intro">
        <h2 style="float: left">
            <tra slug="technicalGuide_adminHeader"></tra>
        </h2>
    </div>
    <div *ngIf="displayInfoForAdmins" class="main-content">
        <ul class="techGuideLinks">
            <li>
                <h3>
                    <a target="_blank" [href]="lang.tra('admin_technical_tips_link')">
                        <tra slug="admin_technical_tips"></tra>
                    </a>
                </h3>
            </li>
            <li>
                <h3><a target="_blank" [href]="lang.tra('sa_admin_guide_link')">
                        <tra slug="admin_fsa_admin_manual"></tra>
                    </a></h3>
            </li>
            <li>
                <h3><a target="_blank" [href]="lang.tra('admin_text_reader_tips_FSA_link')">
                        <tra slug="admin_text_reader_tips"></tra>
                    </a></h3>
            </li>
        </ul>
    </div> -->
<div class="box">
  <ng-container *ngFor="let row of techGuidesLinks">
    <div class="assessment-level-intro guide-intro">
      <h2 style="float: left">
        <tra-md [isCondensed]="true" [slug]="displayTitle(row.sub_section_title)"></tra-md>
      </h2>
    </div>
    <div class="main-content">
      <tra-md *ngIf="row.sub_section_description" [isCondensed]="true" style="padding: 15px" [slug]="row.sub_section_description"></tra-md>
      <ul class="techGuideLinks">
        <li *ngFor="let link of row.content">
          <h3 style="padding-right: 2em;">
            <a *ngIf="link.enabled" target="_blank" [href]="link.document_link">
              <tra-md [isCondensed]="true" [slug]="link.document_title"></tra-md>
            </a>
            <a *ngIf="!link.enabled" style="color:darkgray; cursor: not-allowed;">
              <tra-md [isCondensed]="true" [slug]="link.document_title"></tra-md>
            </a>
          </h3>
        </li>
      </ul>
    </div>
  </ng-container>
</div>