import { CustomInteractionType, getElementWeight, ElementType, IContentElementPieChart, IEntryStatePieChart, QuestionState, PieSlice, IEntryState, PieChartMode, ElementTypeDefs } from "src/app/ui-testrunner/models";
import { QuestionPubSub } from "../../../ui-testrunner/question-runner/pubsub/question-pubsub";
import { CustomInteractionCtrl } from "./custom-interaction";
import * as PIXI from 'pixi.js-legacy';
import * as _ from 'lodash';
import { ScoringTypes } from "../../models";
import { SpriteLoader } from "./sprite-loader";
import { event } from "jquery";
import { PubSubTypes } from "../../element-render-frame/pubsub/types";
import { pixiGraph } from "./util/pixi-graph";
import { PIXITextService } from "../../pixi-text.service";
import { AccessibilityKeys, keysMap } from "./custom-interaction";

interface Handle {
    x: number,
    y: number,
    sliceIndex: number,
    angle?: number
}

interface Slot {
    x: number,
    y: number,
    angle: number
}

export class PieChartCtrl extends CustomInteractionCtrl {

    element: IContentElementPieChart;
    canvasGr : PIXI.Graphics;
    backGroundGr : PIXI.Graphics
    spriteLoader:SpriteLoader;
    slices : PIXI.Graphics[]
    handles: Handle[]
    slots: Slot[]
    centerX: number;
    centerY: number
    radius: number = 30
    answers
    answerIndex
    handleFactor
    startingAngle
    prev_x: number;
    prev_y: number;
    firstKeyPress:boolean;
    selectedHandleIndex: number;
    isLowerHalf: boolean;
    values: {[id:string] : number}; ///currently Only used to identify responses with tag on response extraction
    isResponded: boolean;
    pixiText: PIXITextService;

    constructor(element: IContentElementPieChart, questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, zoom, isLocked, textToSpeech, pixiText: PIXITextService){
        super(questionState, questionPubSub, addGraphic, render, zoom, isLocked, textToSpeech, pixiText);
        this.element = element;
        if (this.element.pieRadius) {
            this.radius = this.element.pieRadius
        } else {
            if (this.element.canvasWidth != undefined) {
                this.radius = this.element.canvasWidth/2
            }
            if (this.element.canvasHeight != undefined && this.radius*2 > this.element.canvasHeight) {
                this.radius = this.element.canvasHeight/2
            }
        }

        if (this.element.mode==PieChartMode.SELECT) {
            this.handleFactor = 1.0;
        } else {
            this.handleFactor = 1.1;
        }

        this.spriteLoader = new SpriteLoader()
        this.canvasGr = new PIXI.Graphics();
        this.addGraphic(this.canvasGr)
        this.backGroundGr = new PIXI.Graphics();
        this.addGraphic(this.backGroundGr)

        const rect = new PIXI.Rectangle(0, 0, this.radius*this.handleFactor*2, this.radius*this.handleFactor*2)
        this.backGroundGr.beginFill(0xFFFFFF);
        this.backGroundGr.drawShape(rect)
        this.backGroundGr.endFill()
        
        const state = this.questionState[this.element.entryId]
        if(!state?.values) this.values = {}; 
        if (!state || !state.answers || state.answers.length==0) {
            const total = this.getTotal()
            this.answers = []
                        
            if (this.element.slices) {
                this.element.slices.forEach((slice:PieSlice, index)=>{
                    let sliceValue: number;
                    if (slice.initialValue || slice.initialValue == 0) {
                        sliceValue = slice.initialValue                        
                    } else {
                        sliceValue = total/this.element.slices.length                        
                    }

                    this.answers.push(sliceValue)
                    // extract and put ids in to values index is for backward compatibility although id should be added/updated in the item config
                    this.values[slice.id ?? String(index)] = sliceValue
                })
            }
        } else {
            this.answers = state.answers
            this.startingAngle = state.startAngle
            this.values = state.values
        }
        this.center()
        this.calculateSlots()
        setTimeout(()=>this.updateInputs(), 100)
        this.onAnswerUpdate()
        this.registerMouseEvents(this.canvasGr)
        this.registerMouseEvents(this.backGroundGr)

        this.prev_x = this.element.canvasWidth/2;
        this.prev_y = this.element.canvasHeight/2;
        this.firstKeyPress = true;
        this.selectedHandleIndex = 0;
        this.isLowerHalf = this.isLowerHalfPie();

        document.addEventListener("keydown", this.keydown);
        document.addEventListener("keyup", this.keyup);
    }

    registerMouseEvents(graphics: PIXI.Graphics) {
        graphics.on('mousedown', ($event) => {this.activateMouseDown($event)});
        graphics.on('mouseup', ($event) => {this.deactivateMouseDown()});
        graphics.on('touchstart', ($event) => {this.activateMouseDown($event)});
        graphics.on('touchend', ($event) => {this.deactivateMouseDown()});
        const mousemove = ($event) => {
            this.mouseMove($event)
        }
        graphics.on('mousemove', mousemove);
        graphics.on('touchmove', mousemove);
        graphics.interactive = true
        graphics.cursor = 'pointer'
    }

    keydown = (event) =>{
        if(!(document.activeElement.tagName === "ELEMENT-RENDER-CUSTOM-INTERACTION"))
            return
        
        if(event.keyCode in keysMap)
        keysMap[event.keyCode] = true;

        if(this.firstKeyPress && event.keyCode in keysMap){
            this.firstKeyPress = false;
            this.selectedHandleIndex = 0;
            this.updatedCircle(this.handles[this.selectedHandleIndex], this.handles[this.selectedHandleIndex]);
            return;
        }

        //right 
        if((keysMap[AccessibilityKeys.RIGHT] || keysMap[AccessibilityKeys.D]) && !keysMap[AccessibilityKeys.ENTER]){
            this.cycleSelectedHandle(this.isLowerHalf, false);
        }
            
        //left
        if((keysMap[AccessibilityKeys.LEFT] || keysMap[AccessibilityKeys.A]) && !keysMap[AccessibilityKeys.ENTER]){
            this.cycleSelectedHandle(this.isLowerHalf, true);
        }
            
        //confirm (place/remove a point)    
        if(keysMap[AccessibilityKeys.ENTER]){
            this.activateKeyDown(this.prev_x, this.prev_y);
            this.hilightSelectedHandle()

        } 
        //escape
        if(keysMap[AccessibilityKeys.ESCAPE]){
            this.firstKeyPress = true;
            this.updatedCircle();
        }
    }

    keyup = (event) =>{
        if(event.keyCode in keysMap)    
        keysMap[event.keyCode] = false;
        this.deactivateKeyDown();
        this.isLowerHalf = this.isLowerHalfPie();
        if(event.keyCode === 13){
            this.updatedCircle(this.handles[this.selectedHandleIndex], this.handles[this.selectedHandleIndex]);
        }
    }

    removeAccessibilityKeyListeners() {
        document.removeEventListener('keydown', this.keydown)
        document.removeEventListener('keyup', this.keyup)
    }

    calculateSlots() {
        const gran = this.element.granularity
        if (!gran) {
            this.slots = undefined
            return;
        }
        this.slots = []
        const radius = this.radius
        const numSlots = this.element.totalPieValue/gran
        for (let i = 0;i<numSlots;i++) {
            const angle = i/numSlots*360
            const angleRads = angle/180*Math.PI
            const x = this.centerX+Math.cos(angleRads) * (radius);
            const y = this.centerY+Math.sin(angleRads) * (radius);
            this.slots.push({
                x,
                y,
                angle
            })
        }
    }

    onAnswerUpdate() {
        //this.roundAnswers()
        this.updatedCircle()
        if (this.element.mode==PieChartMode.SELECT && this.questionState && this.questionState[this.element.entryId]?.ansIndex >= 0) {
            this.updatedCircle()
            const qs = this.questionState[this.element.entryId]
            const index = qs.ansIndex;
            const nearestHandle = this.handles[index]
            let lastHandle
            if (index>0) {
                lastHandle = this.handles[index-1]
            } else {
                lastHandle = this.handles[this.handles.length-1]
            }
            this.updatedCircle(nearestHandle,lastHandle)
        }
    }

    center() {
        this.centerX = this.radius*this.handleFactor
        this.centerY = this.radius*this.handleFactor
    }

    calculateAnglesBasedOnAnswer() {
        this.handles.forEach((handle, i)=>{
            handle.angle = this.answers[i]/this.element.totalPieValue*360
        })
    }

    getDistSquared(x1, y1, x2, y2) {
        return Math.pow(x1-x2, 2) + Math.pow(y1-y2, 2)
    }

    currentHandle: Handle;

    activateMouseDown($event) {
        if(this.isLocked) return;
        const p = $event.data.global
        const x = p.x/this.zoom
        const y = p.y/this.zoom

        this.isResponded = true;

        if (this.isMoveMode()) {
            this.throttled_mouse_event(x, y)
        } else {
            this.throttled_mouse_event_select(x,y)
        }
    }

    deactivateMouseDown() {
        this.currentHandle = undefined
    }

    activateKeyDown(x, y) {
        if(this.isLocked) return;

        if (this.isMoveMode()) {
            if(keysMap[AccessibilityKeys.RIGHT] || keysMap[AccessibilityKeys.D])
                this.throttled_keyboard_event(false, this.isLowerHalf)
            else if(keysMap[AccessibilityKeys.LEFT] || keysMap[AccessibilityKeys.A])
                this.throttled_keyboard_event(true, this.isLowerHalf)
        } else {
            this.throttled_mouse_event_select(x,y)
        }
    }

    deactivateKeyDown() {
        this.currentHandle = undefined
    }

    isMoveMode() {
        return this.element.mode==PieChartMode.MOVE || !this.element.mode
    }

    mouseMove($event) {
        if (!this.currentHandle || this.isLocked) {
            return
        }

        const p = $event.data.global
        const x = p.x/this.zoom
        const y = p.y/this.zoom
        if (Math.sqrt(this.getDistSquared(x,y,this.centerX,this.centerY))>this.element.pieRadius*this.handleFactor*1.3) {
            this.deactivateMouseDown();
        } else {
            this.throttled_mouse_event(x, y)
        }
    }

    throttled_mouse_event_select = _.throttle((x, y)=>{
        if (this.isLocked) return;
        let nearestHandle1 = undefined
        let nh1Angle = undefined
        let index = -1
        const xRel = x-this.centerX
        const yRel = y-this.centerY
        const angle = this.calculateAngle(xRel, yRel, 1, 0);
        this.handles.forEach((handle,i)=>{
            const angleH = this.calculateAngle(handle.x-this.centerX,handle.y-this.centerY,1,0);
            if (angleH<angle) {
                if (!nearestHandle1 || angleH>nh1Angle) {
                    nearestHandle1 = handle
                    nh1Angle = angleH
                    index = handle.sliceIndex
                }
            }
        })

        if (!nearestHandle1) {
            nearestHandle1 = this.handles[this.handles.length-1]
            nh1Angle = 0;
        }
        this.answerIndex = index+1;
        let lastHandle;
        if (index<this.handles.length-1) {
            lastHandle = this.handles[index+1]
        } else {
            lastHandle = this.handles[0]
        }
        this.canvasGr.clear();
        this.updatedCircle(nearestHandle1, lastHandle);
        this.updateState()
        this.render();
    })

    throttled_mouse_event = _.throttle((xTemp, yTemp) => {
        let x = xTemp
        let y = yTemp
        if (this.questionState && this.questionState[this.element.entryId]) {
            this.questionState[this.element.entryId].isFilled = true
            this.questionState[this.element.entryId].isStarted = true
        }
        const gran = this.element.granularity
        if (gran && this.slots) {
            let nearestSlot = undefined
            this.slots.forEach((slot,i)=>{
                if (i==0 || (this.getDistSquared(xTemp, yTemp, slot.x,slot.y) < this.getDistSquared(xTemp, yTemp, nearestSlot.x, nearestSlot.y))) {
                    nearestSlot = slot
                }
            })
            x = nearestSlot.x 
            y = nearestSlot.y
        }
        let nearestHandle = undefined
        let currentDistSquared = -1
        let index = -1
        this.handles.forEach((handle,i)=>{
            if (!nearestHandle) {
                nearestHandle = handle
                currentDistSquared = this.getDistSquared(handle.x, handle.y, x, y)
                index = i
                return
            }
            const hx = handle.x
            const hy = handle.y
            const tempDist = this.getDistSquared(hx, hy, x, y)
            if (tempDist<currentDistSquared) {
                currentDistSquared = tempDist
                nearestHandle = handle
                index = i
            }
        })
        this.currentHandle = nearestHandle
        const newPoint = this.getNewPoint(x,y,this.radius)
        this.currentHandle.x = newPoint.x
        this.currentHandle.y = newPoint.y
        this.calculateAllAngles()
        this.calculateAnswers()
        this.onAnswerUpdate()
        this.updatedCircle()
        this.updateState()
        this.updateInputs()
        
    }, 20)

    /*roundAnswers() {
        const gran = this.element.granularity
        if (!gran) {
            return false
        }
        const newAnswers = []
        this.answers.forEach((ans)=>{
            const rem = ans%gran
            let newAns = Math.round(ans/gran)*gran
            newAnswers.push(newAns)
        })
        console.log(this.answers)
        console.log(newAnswers)
        this.answers = newAnswers
    }*/

    calcAnsBasedOnGran(ans) {
        let ans1 = ans
        const gran = this.element.granularity
        if (gran) {
            let newAns = Math.round(ans/gran)*gran
            ans1 = newAns
        }
        return ans1
    }

    updateInputs() {

        // for some reason this.values is getting undefined adding a check here
        this.values = {};

        this.answers.forEach((ans, index)=>{
            const entryId = this.element.slices[index].targetEntryId;
            const val = this.calcAnsBasedOnGran(ans);
            
            const id = ("id" in this.element.slices[index]) ? this.element.slices[index].id : index;
            this.values[String(id)] = val;

            if (entryId || entryId == 0) {
                this.questionPubSub.elementPub(entryId, PubSubTypes.INPUT, {
                    elementType: CustomInteractionType.PIE_CHART,
                    isResponded: this.isResponded,
                    value: val
                })
            }
        })
    }

    getNewPoint(mouseX, mouseY, radius) {
        const distX = mouseX - this.centerX
        const distY = mouseY - this.centerY
        
        const totaldist = Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2))
        const ratio = radius/totaldist

        const newDistX = distX*ratio + this.centerX
        const newDistY = distY*ratio +this.centerY
        return {x: newDistX, y: newDistY}
    }

    calculateAngle(x1, y1, x2, y2) {
        const dot = x1*x2 + y1*y2
        const det = x1*y2 - y1*x2
        const angle = Math.atan2(det, dot)/Math.PI * 180
        let a =  (angle+360) % 360
        a = (360-a)
        return Number(a.toFixed(0))
    }

    calculateAllAngles() {
        if (this.handles.length<=1) {
            return
        }
        this.calculateThisAngle(this.handles[0], this.handles[this.handles.length-1])
        for (let i = 1;i<this.handles.length;i++) {
            this.calculateThisAngle(this.handles[i], this.handles[i-1])
        }
        /*this.handles.forEach((handle)=>{
            console.log(handle.angle)
        })*/
        //convert from angle to point
    }

    calculateThisAngle(handle, prevHandle) {
        const x = handle.x
        const y = handle.y
        const prevX = prevHandle.x
        const prevY = prevHandle.y
        handle.angle = this.calculateAngle(x-this.centerX, y-this.centerY, prevX-this.centerX, prevY-this.centerY)
    }

    calculateAnswers() {
        this.handles.forEach((handle, i)=>{
            this.answers[i] = this.calcAnsBasedOnGran(handle.angle/360*this.getTotal())
        })
        this.calculateAnglesBasedOnAnswer()
    }

    getTotal() {
        let total = this.element.totalPieValue
        if (!total) {
            total = 100
        }
        return total
    }

    extendLinePoint(x, y, centerX, centerY) {
        x -= centerX
        y -= centerY
        x *= this.handleFactor
        y *= this.handleFactor
        return {x: x+centerX, y: y+centerY}
    }

    drawWedge(target, x, y, radius, arc, startAngle, hexString, index) {
        target.beginFill(parseInt(hexString, 16));
        const endAngle = startAngle+arc
        const startAngleRads = startAngle/180*Math.PI
        const endAngleRads = endAngle/180*Math.PI
        const labelRad = (startAngleRads+endAngleRads)/2
        target.moveTo(x, y);
        target.arc(x, y, radius, startAngleRads, endAngleRads)
        target.endFill();
        if (this.element.slices.length>1) {
            target.moveTo(x, y);
            const handleAx = x + Math.cos(endAngleRads) * (radius);
            const handleAy = y + Math.sin(endAngleRads) * (radius);
            const labelx = x+Math.cos(labelRad)*(radius)*0.5;
            const labely = y+Math.sin(labelRad)*(radius)*0.5;
            let val:any = this.element.slices[index].initialValue
            let valStr = undefined;
            if (val) {
                valStr = val.toString()+"%"
            }
            target.lineStyle(1, 0x000000, 1)
            if (this.element.mode == PieChartMode.SELECT) {
                const text:PIXI.Text = this.getText(valStr, {fontSize: 10}, 2, labelx,labely)
                text.zIndex = 10
                this.addGraphic(text);
            }
            const newPoint = this.extendLinePoint(handleAx, handleAy, this.centerX, this.centerY)
            target.lineTo(newPoint.x, newPoint.y);
            target.lineStyle(0, 0x000000, 1)
            this.handles.push({
                x: handleAx,
                y: handleAy,
                sliceIndex: index
            })
            //target.arc(x, y, radius+handleWidth, endAngleRads-2, endAngleRads+2)
            //target.lineStyle(0, 0x000000, 1)
            //target.lineTo(x, y);
        }
    }
    
    updatedCircle(nearestHandle?, lastHandle?) {
        this.slices = []
        this.canvasGr.clear();
        let total = this.getTotal()
        let startAngle = 0;
        if (this.handles && this.handles.length>1) {
            const handle = this.handles[this.handles.length-1]
            startAngle = this.calculateAngle(handle.x-this.centerX, handle.y-this.centerY, this.radius, 0)
            this.startingAngle = startAngle
            //console.log(startAngle)
        } else if (this.startingAngle) {
            startAngle = this.startingAngle
        }
        this.center()
        this.handles = []
        if (this.element.slices) {
            this.element.slices.forEach((slice:PieSlice, index:number)=>{
                const val = this.answers[index]
                let hexString = slice.colour.toString(16)
                hexString = hexString.substr(1)
                hexString = "0x"+hexString
                //this.canvasGr.arc(20, 20, 10, 0, Math.PI);
                const arc = val/total*360
                this.drawWedge(this.canvasGr, this.centerX, this.centerY, this.radius, arc, startAngle, hexString, index)
                //this.canvasGr.arc(radius, radius, radius, startAngle, startAngle+arc)
                startAngle += arc
            })
        }

        if (nearestHandle && lastHandle) {
            this.canvasGr.beginFill(0xFFFF00);
            this.canvasGr.lineStyle(3, 0xFFFF00)
            //this.canvasGr.arc(this.centerX, this.centerY, this.radius, 0, 5)
            //this.canvasGr.arc(this.centerX, this.centerY, nearestHandle.angle, lastHandle.angle, this.radius)
            this.canvasGr.moveTo(this.centerX, this.centerY)
            this.canvasGr.lineTo(nearestHandle.x, nearestHandle.y)
            this.canvasGr.moveTo(this.centerX, this.centerY)
            this.canvasGr.lineTo(lastHandle.x, lastHandle.y)
            this.canvasGr.endFill()
        }
        this.canvasGr.zIndex = 10
        this.render();
    }

    /*getInitialState(): Partial<IEntryStatePieChart> {
        return {
            type: ElementType.CUSTOM_INTERACTION,
            subtype: CustomInteractionType.PIE_CHART,
            answers: [],
            isStarted: false,
            isFilled: false,
            isCorrect: false,
            score: 0,
            weight: getElementWeight(this.element),
            scoring_type: ScoringTypes.AUTO
        }
    }*/

    getUpdatedState(): Partial<IEntryStatePieChart> {
        if (this.isMoveMode()) {
            let weight = getElementWeight(this.element)
            let score = weight
            let isCorrect = true
            let roundingAllowance = this.element.roundingAllowance
            if (!roundingAllowance) {
                roundingAllowance = 0
            }
            const checkAnswer = (ans, correctAns) => {
                if (!correctAns) {
                    correctAns = 0
                }
                const granAns = this.calcAnsBasedOnGran(ans)
                const diff = Math.abs(correctAns - granAns)
                if (diff > roundingAllowance) {
                    isCorrect = false
                    return false
                }
                return true
            }
            if (!this.element.enableProportionalScoring) {
                this.answers.forEach((ans, index) => {
                    let correctAns = this.element.slices[index].correctAns
                    checkAnswer(ans, correctAns)
                });
                score = isCorrect ? weight : 0;
            } else {
                weight = 0
                score = 0
                this.answers.forEach((ans, index)=>{
                    let correctAns = this.element.slices[index].correctAns
                    const thisWeight = this.element.slices[index].weight
                    weight += thisWeight
                    if (checkAnswer(ans, correctAns)) {
                        score += thisWeight
                    }
                })
            }
            const qsState = this.questionState ? this.questionState[this.element.entryId] : undefined
            return {
                type: ElementType.CUSTOM_INTERACTION,
                subtype: CustomInteractionType.PIE_CHART,
                values: this.values, //currently Only used to identify responses with tag on response extraction
                answers: this.answers,
                ansIndex: this.answerIndex,
                startAngle: this.startingAngle,
                isStarted: qsState && qsState.isStarted ? true : false,
                isFilled: qsState && qsState.isFilled ? true : false,
                isCorrect: isCorrect,
                isResponded: !!this.isResponded,
                score: score? +score.toFixed(6) : score,
                weight: this.element.scoreWeight,
                scoring_type: ScoringTypes.AUTO
            }
        } else {
            const qsState = this.questionState ? this.questionState[this.element.entryId] : undefined
            const isCorrect = this.answerIndex == this.element.answer
            return {
                type: ElementType.CUSTOM_INTERACTION,
                subtype: CustomInteractionType.PIE_CHART,
                answers: this.answers,
                ansIndex: this.answerIndex,
                startAngle: 0,
                isStarted: this.answerIndex>=0,
                isFilled: this.answerIndex>=0,
                isCorrect: isCorrect,
                isResponded: !!this.isResponded,
                score: isCorrect ? 1 : 0,
                weight: 1,
                scoring_type: ScoringTypes.AUTO
            }
        }
        
    }

    handleNewState(): void {
        let qsState = this.questionState[this.element.entryId]
        this.isResponded = !!qsState['isResponded'];
        if (qsState && qsState.answers) {            
            this.answers = qsState.answers
            this.answerIndex = qsState.ansIndex
        }
    }

    loadAssets = () => {
        /*let assets = []
        if (this.element && this.element.img && this.element.img.url) {
            assets.push({name: "circle", path: this.element.img.url})
        }
        this.spriteLoader.addSpritestoLoader(assets)*/
        return this.spriteLoader.loadSprites()
    }
   
    throttled_keyboard_event = _.throttle((left, isLowerHalf) => {

        this.currentHandle = this.handles[this.selectedHandleIndex]

        let currentSlot = undefined
        this.slots.forEach((slot,i)=>{
            if (i==0 || (this.getDistSquared(this.currentHandle.x, this.currentHandle.y, slot.x,slot.y) < this.getDistSquared(this.currentHandle.x, this.currentHandle.y, currentSlot.x, currentSlot.y))) {
                currentSlot = slot
            }
        })

        let nextHandleIndex = this.handles.indexOf(this.currentHandle)
        let nextSlotIndex = this.slots.indexOf(currentSlot)

        if((left && !isLowerHalf) || (!left && isLowerHalf)){
            nextHandleIndex = nextHandleIndex - 1;
            nextSlotIndex = nextSlotIndex - 1;

            if(nextHandleIndex < 0)
                nextHandleIndex = this.handles.length - 1;

            if(nextSlotIndex < 0)
                nextSlotIndex = this.slots.length - 1;

        } else {
            nextHandleIndex = nextHandleIndex + 1;
            nextSlotIndex = nextSlotIndex + 1;

            if(nextHandleIndex > this.handles.length - 1)
                nextHandleIndex = 0;

            if(nextSlotIndex > this.slots.length - 1)
                nextSlotIndex = 0;
        }

        let nextHandleSlot = undefined
        this.slots.forEach((slot,i)=>{
            if (i==0 || (this.getDistSquared(this.handles[nextHandleIndex].x, this.handles[nextHandleIndex].y, slot.x,slot.y) < this.getDistSquared(this.handles[nextHandleIndex].x, this.handles[nextHandleIndex].y, nextHandleSlot.x, nextHandleSlot.y))) {
                nextHandleSlot = slot
            }
        })
        
        if (nextSlotIndex === this.slots.indexOf(nextHandleSlot)){
            this.currentHandle = this.handles[nextHandleIndex];
            this.selectedHandleIndex = nextHandleIndex;
            return
        }
            
        this.currentHandle.x = this.slots[nextSlotIndex].x
        this.currentHandle.y = this.slots[nextSlotIndex].y
        
        this.calculateAllAngles()
        this.calculateAnswers()
        this.onAnswerUpdate()
        this.updatedCircle(this.currentHandle, this.currentHandle);
        this.updateState()
        this.updateInputs()

        this.prev_x = this.currentHandle.x;
        this.prev_y = this.currentHandle.y;
        
    }, 20)

    private isLowerHalfPie(){
        let maxY = Math.max.apply(Math, this.handles.map(function(o) { return o.y; }));
        let minY = Math.min.apply(Math, this.handles.map(function(o) { return o.y; }));
        let midum = (maxY - minY)/2;
        return(this.handles[this.selectedHandleIndex].y > midum)
    }

    private cycleSelectedHandle(isLowerHalf: boolean, isLeftKeyPress: boolean){
        if(isLowerHalf && isLeftKeyPress){
            this.nextHandle();
        }
        else if(!isLowerHalf && isLeftKeyPress){
            this.previousHandle();
        }
        else if(isLowerHalf && !isLeftKeyPress){
            this.previousHandle()
        } else {
            this.nextHandle();
        }
    }

    private previousHandle(){
        this.selectedHandleIndex = this.selectedHandleIndex - 1;
        if(this.selectedHandleIndex < 0 ){
            this.selectedHandleIndex = this.handles.length - 1;
        }
        this.updatedCircle(this.handles[this.selectedHandleIndex], this.handles[this.selectedHandleIndex]);
    }

    private nextHandle(){
        this.selectedHandleIndex = this.selectedHandleIndex + 1;
        if(this.selectedHandleIndex > this.handles.length - 1 ){
            this.selectedHandleIndex = 0;
        }
        this.updatedCircle(this.handles[this.selectedHandleIndex], this.handles[this.selectedHandleIndex]);
    }

    private hilightSelectedHandle(){
        this.canvasGr.beginFill(0xff6a00);
        this.canvasGr.lineStyle(5, 0xff6a00)
        //this.canvasGr.arc(this.centerX, this.centerY, this.radius, 0, 5)
        //this.canvasGr.arc(this.centerX, this.centerY, nearestHandle.angle, lastHandle.angle, this.radius)
        this.canvasGr.moveTo(this.centerX, this.centerY)
        this.canvasGr.lineTo(this.handles[this.selectedHandleIndex].x, this.handles[this.selectedHandleIndex].y)
        this.canvasGr.endFill()
        this.canvasGr.zIndex = 10
        this.render();
    }
}