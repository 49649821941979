<div class="chat-message"
     [class.from-me]="message.senderUid === MY_UID"
     [class.from-other]="message.senderUid !== MY_UID"
     [class.broadcast]="message.isBroadcast"
     [class.meeting]="message.message.includes('meetingId')"
     [class.lighten]="message.status == 'sending'"
>
    <span *ngIf="message.status == 'failed'" class="failed"><i class="fas fa-exclamation-circle"></i></span>
    <p class="sender-name"  *ngIf="message.senderUid === MY_UID"> <tra slug="lbl_you_vous"></tra> </p>
    <p class="sender-name" *ngIf="message.senderUid !== MY_UID">{{ getDisplayName(name) }}</p>

    <ng-container *ngFor="let sec of messageSection">
        <ng-container *ngIf="sec.isLink; else NotLink">
            <button class="chat-link" (click)="routeLink(sec.msg)" style="word-break:break-all;">{{renderLink(sec)}}</button>
        </ng-container>
        <ng-template #NotLink>
            {{sec.msg}}
        </ng-template>
    </ng-container>

<!-- 
    <ng-container *ngIf="message.message.includes('http'); else NotLink">
        <button class="chat-link" (click)="routeLink(message.message)" style="word-break:break-all;">{{renderLink(message.message)}}</button>
    </ng-container>
    <ng-template #NotLink>
        <markdown
            class="chat-markdown"
            [data]="message.message.includes('meetingId') ? (message.senderUid === MY_UID ? 'You have invited ' + (name ? name : 'the group') + ' to join a meeting!' : message.senderName + ' has invited you to join a meeting!') : linkify(message.message, message.senderUid === MY_UID ? 'me':'them')"
            ngPreserveWhitespaces
        ></markdown>
    </ng-template> -->

    <p class="timestamp">{{message.status == lang.tra('mrkg_failed') || message.status == lang.tra('mrkg_sending') ? message.status : formatMessageTime()}} <span *ngIf="message.status != 'sending' && message.status != 'failed' && message.senderUid === MY_UID">&middot; {{message.didTheyRead ? lang.tra('mrkg_read') : lang.tra('mrkg_sent')}}</span></p>
    <!-- {{ message.message }}-->
    <!--
    <ng-container *ngFor="let item of messageArray">
        <span *ngIf="!item.isEmoji">{{ item.message }}</span>
        <ngx-emoji *ngIf="item.isEmoji" [emoji]="item.message" size="16"></ngx-emoji>
    </ng-container>
    -->
</div>
