import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'input-list-select',
  templateUrl: './input-list-select.component.html',
  styleUrls: ['./input-list-select.component.scss']
})
export class InputListSelectComponent implements OnInit {

  constructor() { }

  @Input() object: any;
  @Input() prop: string;
  @Input() options: string[];
  @Output() newItemEvent = new EventEmitter<string>();

  checkboxMap:any = {};
  value:string[];

  ngOnInit(): void {
  }

  update(){
    this.value = [];
    this.options.forEach(option =>{
      if (this.checkboxMap[option]){
        this.value.push(option);
      }
    });

    // this.object[this.prop] = JSON.stringify(this.value);
    this.newItemEvent.emit(JSON.stringify(this.value));
  }

}
