<div>
    <div class="filters-section">
            <div class="dropdown-selectors">
                <div class="district-selector">
                    <bc-search-dropdown placeholder="District" [disabled]="isLoading" [inputValue]="selectedDistrict"
                        [itemDisplayFn]="districtDisplayFn" [filterFn]="getFilterDistrictsFn()"
                        (itemSelected)="districtSelected($event)"></bc-search-dropdown>
                </div>
                <div class="school-selector">
                    <bc-search-dropdown placeholder="School" [disabled]="isLoading" [inputValue]="selectedSchool"
                        [itemDisplayFn]="schoolDisplayFn" [filterFn]="getFilterSchoolsFn()"
                        (itemSelected)="schoolSelected($event)">
                    </bc-search-dropdown>
                </div>
            </div>
    </div>

    <div style="margin-bottom: -1.5em; margin-top: -1.5em;"> 
        <h3>Click anywhere in a row to see more information.</h3>
    </div>

    <div style="margin-bottom: -1.5em; margin-top: -1.5em;">
        <h3>The "Is Fully Resolved" column can be used to check for any remaining problematic cases.</h3>
    </div>

    <ag-grid-angular
        style="width: 100%; height: 600px; margin-top: 2rem;"
        class="ag-theme-alpine"
        [gridOptions]="gridOptions"
        (cellClicked)="onCellClicked($event)"
    >
    </ag-grid-angular>

    <button class="button has-icon" (click)="gridExport()" [disabled]="isLoading">
        <span class="icon">
            <i class="fas fa-file-export"></i>
        </span>
        <span> Export </span>
      </button>

    <div *ngIf="showMoreInfoModal" class="custom-modal">
        <div class="modal-contents"> 
            <div *ngIf="areModalContentsLoading" class="notification is-warning enlarge-mini">
                    <tra slug="loading_caption"></tra>
            </div>
            
            <div *ngIf="!areModalContentsLoading">  
                <h2>
                    Selected Student
                </h2>

                <div class="table is-bordered" style="margin-top: 1em;">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    PEN
                                </th>
                                <th>
                                    First Name
                                </th>
                                <th>
                                    Last Name
                                </th>
                                <th>
                                    Grade
                                </th>
                                <th>
                                    School of Enrollment
                                </th>
                                <th>
                                    District of Enrollment
                                </th>
                            </tr>
                        </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{currentStudent.PEN}}
                                    </td>
                                    <td>
                                        {{currentStudent.first_name}}
                                    </td>
                                    <td>
                                        {{currentStudent.last_name}}
                                    </td>
                                    <td>
                                        {{currentStudent.grade}}
                                    </td>
                                    <td>
                                        {{currentStudent.enrollment_school}}
                                    </td>
                                    <td>
                                        {{currentStudent.enrollment_district}}
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                </div> 

                <h2 style="margin-top: 1em;">List of Attempts</h2>

                <div *ngIf="currentStudentAttempts.SRAttempts.length === 0"
                style="margin-top: 1em;">
                    <h4>
                    There are no duplicate SR attempts for this student.
                    </h4>
                </div>

                <div class="table is-bordered" *ngIf="currentStudentAttempts.SRAttempts.length !== 0"
                style="margin-top: 1em;">
                    <h4>SR Attempts</h4>

                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Change Attempt Status
                                </th>

                                <th>
                                    Is Deleted
                                </th>
    
                                <th>
                                    Test Attempt ID
                                </th>
    
                                <th>
                                    Total Score
                                </th>

                                <th>
                                    # Questions Accessed
                                </th>

                                <th>
                                    Started On
                                </th>

                                <th>
                                    Is Submitted
                                </th>
    
                                <th>
                                    Is Submitted On
                                </th>
    
                                <th>
                                    Assessment
                                </th>

                                <th>
                                    School of Attempt
                                </th>
    
                                <th>
                                    District of Attempt
                                </th>
    
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let attempt of currentStudentAttempts.SRAttempts">
                                <td>
                                    <button (click)="toggleTAIsInvalid(attempt.ta_id, true)"
                                    [ngClass]="attempt.is_invalid === 1 ? 'restore-btn' : 'delete-btn'">
                                        {{getAttemptStatusBtnText(attempt.is_invalid)}}
                                    </button>
                                </td>

                                <td>
                                    {{boolToStr(attempt.is_invalid)}}
                                </td>

                                <td>
                                    {{attempt.ta_id}}
                                </td>

                                <td>
                                    {{attempt.total_score}}
                                </td>

                                <td>
                                    {{attempt.num_questions_seen}}
                                </td>

                                <td>
                                    {{attempt.created_on}}
                                </td>

                                <td>
                                    {{boolToStr(attempt.is_closed)}}
                                </td>

                                <td>
                                    {{attempt.closed_on}}
                                </td>

                                <td>
                                    {{getTwtdarSlugWithoutComponent(attempt.assessment)}}
                                </td>

                                <td>
                                    {{attempt.test_attempt_school}}
                                </td>

                                <td>
                                    {{attempt.test_attempt_district}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div *ngIf="currentStudentAttempts.CRAttempts.length === 0" 
                style="margin-top: 1em;">
                    <h4>
                    There are no duplicate CR attempts for this student.
                    </h4>
                </div>

                <div class="table is-bordered" *ngIf="currentStudentAttempts.CRAttempts.length !== 0"
                style="margin-top: 1em;">
                    <h4>CR Attempts</h4>
                    
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Change Attempt Status
                                </th>

                                <th>
                                    Is Deleted
                                </th>
    
                                <th>
                                    Test Attempt ID
                                </th>
    
                                <th>
                                    Total Score
                                </th>

                                <th>
                                    # Questions Accessed
                                </th>

                                <th>
                                    Started On
                                </th>
    
                                <th>
                                    Assessment
                                </th>
    
                                <th>
                                    School of Attempt
                                </th>
    
                                <th>
                                    District of Attempt
                                </th>
    
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let attempt of currentStudentAttempts.CRAttempts">
                                <td>
                                    <button (click)="toggleTAIsInvalid(attempt.ta_id, false)"
                                    [ngClass]="attempt.is_invalid === 1 ? 'restore-btn' : 'delete-btn'">
                                        {{getAttemptStatusBtnText(attempt.is_invalid)}}
                                    </button>
                                </td>

                                <td>
                                    {{boolToStr(attempt.is_invalid)}}
                                </td>

                                <td>
                                    {{attempt.ta_id}}
                                </td>
            
                                <td>
                                    {{attempt.total_score}}
                                </td>

                                <td>
                                    {{attempt.num_questions_seen}}
                                </td>

                                <td>
                                    {{attempt.created_on}}
                                </td>

                                <td>
                                    {{getTwtdarSlugWithoutComponent(attempt.assessment)}}
                                </td>

                                <td>
                                    {{attempt.test_attempt_school}}
                                </td>

                                <td>
                                    {{attempt.test_attempt_district}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
    
                <div class="actions">
                    <button class="cancel-button" (click)="closeShowMoreInfoModal()" [disabled]="isLoading">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>