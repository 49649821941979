export const tcDataDlApiPrefix = 'public/test-ctrl/schools/data-dl/';

export interface ICodebook {
  sections: {
    id: number,
    caption: string,
    link?: string,
    dataFrames?: {
      id: number,
      isEnabled?:boolean,
      apiPath:string,
      caption: string,
      description: string,
      sampleDescription?: string,
    }[],
  }[]
}

export interface IDataDownloadFilters {
  dateStart: string,
  dateEnd: string,
  allowed: {
    schoolDistrictForeignId?: string,
  }
}

export const CODEBOOK_G9:ICodebook = {
  sections: [
    {
      id: 1,
      caption:'Overview and Changelog Available in Codebook',
      link:'https://docs.google.com/spreadsheets/d/1JUn2wQ4ZW3EucttQLUASi2eBHwZ1WLcHKLxVjRHvn6o/edit#gid=1222699256',
    },
    {
      id: 2,
      caption: 'Assessment Framework',
      dataFrames: [
        { id: 1,  isEnabled:true, apiPath:'asmt-frmk/test-designs', caption: 'Test Designs', description: 'This view contains a listing of test designs with their associated blueprint and versions.', },
        { id: 2,  isEnabled:true, apiPath:'asmt-frmk/test-design-versions', caption: 'Test Design Versions', description: 'This view contains further information on each test design version created.', },
        { id: 3,  isEnabled:true, apiPath:'asmt-frmk/reporting-components', caption: 'Reporting Components', description: 'This view contains a list of all reporting components in the test.', },
        { id: 4,  isEnabled:true, apiPath:'asmt-frmk/reporting-component-types', caption: 'Reporting Component Types', description: 'This view contains a list of all reporting component types in the test.', },
        { id: 5,  isEnabled:true, apiPath:'asmt-frmk/reporting-component-scoring-types', caption: 'Reporting Component Scoring Types', description: 'This view contains a list of all reporting component scoring types in the test.', },
        { id: 6,  isEnabled:true, apiPath:'asmt-frmk/sessions', caption: 'Sessions', description: 'This view contains a list of all the sections in the test.', },
        { id: 7,  isEnabled:true, apiPath:'asmt-frmk/stages', caption: 'Stages', description: 'This view contains a list of all the stages in the test.', },
        { id: 8,  isEnabled:true, apiPath:'asmt-frmk/modules', caption: 'Modules', description: 'This view contains a list of all the modules in the test.', },
        { id: 9,  isEnabled:true, apiPath:'asmt-frmk/routing', caption: 'Routing', description: 'This view contains a list of all the routing rules and connections in the test.', },
      ]
    },
    {
      id: 3,
      caption: 'Question Bank',
      dataFrames: [
        { id: 1, isEnabled:true, apiPath:'item-bank/items', caption: 'Items', description: 'This view contains a listing of each question, with associated detail such as version, label, and psychometric variables.', },
        { id: 2, isEnabled:true, apiPath:'item-bank/flat-item-bank', caption: 'Flattened Question Bank Export', description: 'Customization. Combines "Questions" and "Answer Key" tables into a flattened reference for the EQAO psychometric team.', },
        { id: 3, isEnabled:true, apiPath:'item-bank/test-panels', caption: 'Test Panels', description: 'This view contains a list of all test panels created.', },
        { id: 4, isEnabled:true, apiPath:'item-bank/test-panel-items', caption: 'Test Panel Items', description: 'This view contains an association between an item and its location on one more many test panels.', },
        { id: 5, isEnabled:true, apiPath:'item-bank/questionnaire', caption: 'Questionnaire', description: 'Contains a text extract of the survey questions.', },
        { id: 6, isEnabled:true, apiPath:'item-bank/questionnaire-resp-codes', caption: 'Questionnaire Response Codes', description: 'Contains a text extract of the available survey responses.', },
      ]
    },
    {
      id: 4,
      caption: 'Users, Roles, and Bookings',
      dataFrames: [
        { id: 1, isEnabled:true, apiPath:'registrations/test-windows', caption: 'Test Windows', description: 'This view contains description of all test windows on the system, including their start time and test session constraints.', },
        { id: 2, isEnabled:true, apiPath:'registrations/test-window-design-alloc', caption: 'Test Windows - Associated Designs', description: 'This view contains the associated and activated test designs within each test window.', },
        { id: 3, isEnabled:true, apiPath:'registrations/test-sessions', caption: 'Test Sessions', description: 'This view contains a list of all test sessions on the system and their relevant information.', },
        { id: 4, isEnabled:true, apiPath:'registrations/students', caption: 'Test Taker Registration Info', description: 'Includes test taker information only provided at time of registration.', },
        { id: 5, isEnabled:true, apiPath:'registrations/schools', caption: 'School Info', description: 'Includes school and board information.', },
        { id: 6, isEnabled:true, apiPath:'registrations/class', caption: 'Class Info', description: 'Includes class information aside from teacher id.', },
        { id: 7, isEnabled:true, apiPath:'registrations/teachers', caption: 'Teachers Info', description: 'Includes teacher information.', },
        { id: 8, isEnabled:true, apiPath:'registrations/school-districts', caption: 'School Board Info', description: 'Includes teacher information.', },
      ]
    },
    {
      id: 5,
      caption: 'Responses & Results',
      dataFrames: [
        { id: 1,   isEnabled:true, apiPath:'responses/subm-test-att', caption: 'Submitted Test Attempts', description: 'This view contains a list of all submitted test attempts. ', sampleDescription:'The extracted sample contains all applicant submission data for 2 test sessions (including no-show attempts).' },
        { id: 2,   isEnabled:true, apiPath:'responses/subm-item-resp', caption: 'Submitted Questions Responses', description: 'This view contains a list of all related questions that have been submitted for the relevant test attempts.', sampleDescription:'In the extracted sample, questionnaire responses are excluded.' },
        { id: 3,   isEnabled:true, apiPath:'responses/flat-test-att-resp', caption: 'Flattened Test Attempts & Responses', description: 'Customization. Combines Test Attempts table and answer key into a flattened reference for the EQAO psychometric team.							', },
        { id: 4,   isEnabled:true, apiPath:'responses/stu-att', caption: 'Student Attempt Records', description: 'Customization. Combines the Test Attempts table and student registration info.							', },
        { id: 5,   isEnabled:true, apiPath:'responses/item-summ', caption: 'Question Summary', description: 'This view contains a summary of all questions as answered by test takers.							', },
        { id: 6,   isEnabled:true, apiPath:'responses/resp-summ', caption: 'Response Summary', description: 'This view contains a summary of all responses as answered by test takers.							', },
        { id: 7,   isEnabled:true, apiPath:'responses/reported-issues', caption: 'Reported Issues', description: 'This view provides a listing of all reported issues coming in to the system through support.							', },
        { id: 8.1, isEnabled:true, apiPath:'responses/process-data/items', caption: 'Process Data - Items', description: 'Includes granular-level process data within the item.							', },
        { id: 8.2, isEnabled:true, apiPath:'responses/process-data/screens', caption: 'Process Data - Screens', description: 'Includes granular-level process data within the item and response concerning individual screens.							', },
        { id: 8.3, isEnabled:true, apiPath:'responses/process-data/recordings', caption: 'Process Data - Recordings', description: 'Includes granular-level process data within the item and response concerning individual recordings.							', },
        { id: 8.4, isEnabled:true, apiPath:'responses/process-data/text-to-speech', caption: 'Process Data - TTS', description: 'Includes granular-level process data within the item and response concerning text to speech usage.							', },
        { id: 8.5, isEnabled:true, apiPath:'responses/process-data/teacher-responses', caption: 'Process Data - Teacher Response', description: 'Includes granular-level process data within the item and response concerning occurrences of teachers entering the responses for the student.							', },
        { id: 8.6, isEnabled:true, apiPath:'responses/process-data/drawing-tools', caption: 'Process Data - Graphics', description: 'Includes granular-level process data within the item and response concerning usage of graphical tools.							', },
      ]
    },
  ]
}
