import * as moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { AuthService, IUserInfo, getFrontendDomain } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { BehaviorSubject } from 'rxjs';
import { IInstitution } from '../api/models/db/institutions.schema';
import { ITestSession, ITestSessionBookingInfo } from '../api/models/db/test-sessions.schema';
import { AvailableSessionsService } from './available-sessions.service';
import { IUserRoleWUserInfo } from '../api/models/db/user-role-w-user-info.schema';
import { IGroupUserWUserInfoExt } from '../api/models/db/group_users_w_user_info.schema';
import { FormControl } from '@angular/forms';
import { LangService } from '../core/lang.service';
import { IBooking } from './demo-data.service';
import { DBT } from '../api/models';

export enum DB_Roles_TA {
  mpt_test_admin_inst_mngr = 'mpt_test_admin_inst_mngr',
  mpt_test_admin_accomm_coord = 'mpt_test_admin_accomm_coord',
  mpt_test_admin_invig = 'mpt_test_admin_invig',
}

interface INewAccountInfo {
  firstName?: string,
  lastName?: string,
  email?: string,
  isAutoEmail?: boolean,
  domain?:string,
  langCode?:string
}

export interface IInstitutionInfo {
  id?          : number,
  groupId?     : number,
  name?        : string,
  address?     : string,
  city?        : string,
  province?    : string,
  postalCode? : string,
  phoneNumber?: string,
  faxNumber?  : string,
  contactEmail?  : string, // this always starts blank... inst managers need to put this in place themselves.
  notes?  : string,
  isShown?    : boolean,
  roles?    : {[key:string]: boolean}, // this should be "my roles"
  hasSessions?  : boolean,
  hasSeb?     :  DBT.BOOL_INT,
  applicant_rt_policy? : string,
  resp_time_policy?  : string,
  reg_buffer_policy?: string,
  accomm_responses?: {
    total:number,
    max:number,
    min:number,
    average:number
  },
  applicant_rt_policy_unit?: string,
  resp_time_policy_unit?: string,
  reg_buffer_policy_unit?: string
  is_accomm_coord_assigned?: boolean,
  is_invigilator_assigned?: boolean
}

export interface IAccountInfo {
  uid: number,
  isSelf:boolean,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  isAccommCoord: boolean,
  accountCreatedOn: moment.Moment,
  isInviteUsed: boolean,
  invitationCode: string
  accessGrantedOn: moment.Moment,
  accessExpiresOn: moment.Moment,
  numSessions: number,
  // invitationCode: string,  // these will come later using a left join
  // invitationName: string,
  // invitationEmail: string,
}

export const sanitizeInstRecord = (institutionRecord:Partial<IInstitution>) : IInstitutionInfo => {
  return {
    id: institutionRecord.id,
    groupId: institutionRecord.group_id,
    name: institutionRecord.name,
    address: institutionRecord.address,
    city: institutionRecord.city,
    province: institutionRecord.province,
    postalCode: institutionRecord.postal_code,
    phoneNumber: institutionRecord.phone_number,
    faxNumber: institutionRecord.fax_number,
    contactEmail: institutionRecord.email,
    notes: institutionRecord.notes,
    isShown: !!institutionRecord.is_shown,
    roles: arrayToBoolHash(institutionRecord.role_types),
    hasSessions: institutionRecord.numActiveSessions > 0,
    hasSeb: institutionRecord.has_seb,      
    applicant_rt_policy : institutionRecord.applicant_rt_policy,
    resp_time_policy  : institutionRecord.resp_time_policy,
    reg_buffer_policy: institutionRecord.reg_buffer_policy,    
    accomm_responses: institutionRecord.accomm_responses,
    applicant_rt_policy_unit: institutionRecord.applicant_rt_policy_unit,
    resp_time_policy_unit: institutionRecord.resp_time_policy_unit,
    reg_buffer_policy_unit: institutionRecord.reg_buffer_policy_unit,
    is_accomm_coord_assigned: institutionRecord.is_accomm_coord_assigned,
    is_invigilator_assigned: institutionRecord.is_invigilator_assigned
  }
}

export const arrayToBoolHash = (arr:string[]) => {
  if (arr){
    const hash:{[key:string]:boolean} = {};
    arr.forEach(prop => {
      hash[prop] = true;
    });
    return hash;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MyInstitutionService {

  private _info: IInstitutionInfo;
  private _userInfo: IUserInfo;
  private info: BehaviorSubject<IInstitutionInfo> = new BehaviorSubject(null);
  private roleFailed: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _hasSessions:boolean;
  private accounts:BehaviorSubject<IAccountInfo[]> = new BehaviorSubject(null);
  // private _accounts:IAccountInfo[];

  constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private availSess: AvailableSessionsService,
    private lang: LangService,
  ) { 
    this.auth.user().subscribe(this.updateUserInfo);
    this.sub().subscribe(this.onInfoUpdate);
    this.availSess.sub().subscribe(v => {
      if (v){
        this._hasSessions = v.length > 0;
      }
    })
  }

  private onInfoUpdate = (v) => {
    this._info = v;
    this.toggleAvailSessSuperWatcher(this._info);
  }

  private updateUserInfo = (userInfo:IUserInfo) => {
    if (userInfo){
      this._userInfo = userInfo;
      this.reloadInstInfo();
    }
    else{
      this.info.next(null);
    }
  }

  public reloadInstInfo(){
    this.auth
      .apiFind(
        this.routes.TEST_ADMIN_INSTITUTION, 
        {}
      )
      .then( (institutionRecords:IInstitution[]) => {
        if (institutionRecords.length === 0){
          this.roleFailed.next(true);
          this.info.next(null);
          return;
        }
        // only accepting the first institution for now.
        return institutionRecords[0];
      })
      .then( (institutionRecord:IInstitution) => {
        const preliminaryInfo = sanitizeInstRecord(institutionRecord)
        this.toggleAvailSessSuperWatcher(preliminaryInfo);
        this.availSess.setInstitGroupId(institutionRecord.group_id);
        if (institutionRecord.numActiveSessions){
          this._hasSessions = true;
          this.availSess.refresh();
        }
        return institutionRecord;
      })
      .then( (institutionRecord:IInstitution) => {
        this.roleFailed.next(false);
        this.info.next( sanitizeInstRecord(institutionRecord) );
      })
      .catch( e =>{
        this.roleFailed.next(true);
        this.info.next(null);
      })
  }

  toggleAvailSessSuperWatcher(info:IInstitutionInfo){
    if (this.isSuperSessionWatcher(info)){
      this.availSess.toggleSuperWatcher(true);
    }
    else{
      this.availSess.toggleSuperWatcher(false);
    }
  }

  constructPermissionsParams(query?:any){
    if (!this._info){ console.warn('myInst constructPermissionsParams early exit'); return; }
    const instit_group_id  = this._info.groupId;
    return {
      query:{ 
        ... query,
        instit_group_id, 
      }
    }
  }



  sub(){
    return this.info;
  }

  fail(){
    return this.roleFailed;
  }

  hasSessions(){
    return this._hasSessions;
  }

  reflectRemovedRole(role:DB_Roles_TA){
    if (this._info){
      this._info.roles[role] = false;
    }
  }

  reflectAddedRole(role:DB_Roles_TA){
    if (this._info){
      this._info.roles[role] = true;
    }
  }

  isRolesDefined(info?:IInstitutionInfo){
    info = info || this._info;
    return !!(info && info.roles);
  }

  hasRole(role:DB_Roles_TA, institutionRecord?:IInstitutionInfo){
    const info = institutionRecord || this._info;
    if (info){
      return !! info.roles[role];
    }
    return false;
  }

  isSuperSessionWatcher(info?:IInstitutionInfo){
    const isInstMngr = this.isInstMngr(info) 
    const isAccommCoord = this.isAccommCoord(info);
    return isInstMngr || isAccommCoord;
  }

  isSessionReleaser(info?:IInstitutionInfo){
    const isInstMngr = this.isInstMngr(info) 
    const isAccommCoord = this.isAccommCoord(info);
    return isInstMngr || isAccommCoord;
  }

  isInstMngr(info?:IInstitutionInfo){
    return this.hasRole(DB_Roles_TA.mpt_test_admin_inst_mngr, info);
  }
  isAccommCoord(info?:IInstitutionInfo){
    return this.hasRole(DB_Roles_TA.mpt_test_admin_accomm_coord, info);
  }


  
  
  getInstAccounts(){
    return this.auth.apiFind(
      this.routes.TEST_ADMIN_ACCOUNTS_ACCESS,
      this.constructPermissionsParams({ })
    )
    .then( (paginatedUserRoleInfo:{data:IGroupUserWUserInfoExt[]}) => {
      const accounts:IAccountInfo[] = paginatedUserRoleInfo.data.map( userRoleInfo => {
        const isSelf = (userRoleInfo.uid === this._userInfo.uid);
        let isAccommCoord = false;
        let earliestRoleCreation;
        userRoleInfo.roles.forEach(uri => {
          if (!earliestRoleCreation || earliestRoleCreation > uri.created_on){
            earliestRoleCreation = uri.created_on;
          }
          if (uri.role_type === DB_Roles_TA.mpt_test_admin_accomm_coord){
            isAccommCoord = true;
          }
        });
        const isInviteUsed = !!userRoleInfo.is_claimed;
        const accountInfo:IAccountInfo = {
          uid: userRoleInfo.uid,
          isSelf,
          firstName: userRoleInfo.first_name,
          lastName: userRoleInfo.last_name,
          email: userRoleInfo.contact_email,
          phoneNumber: '',
          isAccommCoord,
          accountCreatedOn: moment.tz(earliestRoleCreation, moment.tz.guess()),
          numSessions: userRoleInfo.num_sessions,
          isInviteUsed,
          invitationCode: null,
          accessGrantedOn: null,
          accessExpiresOn: null,
        }
        if (!isInviteUsed){
          accountInfo.accessGrantedOn = moment.tz(userRoleInfo.created_on, moment.tz.guess());
          accountInfo.accessExpiresOn = moment.tz(userRoleInfo.expires_on, moment.tz.guess());
          accountInfo.invitationCode = userRoleInfo.invitationCode;
        }
        return accountInfo;
      })
      this.accounts.next(accounts);
      return {accounts};
    })
  }

  assignAccommCoord(uid:number){
    return this.auth.apiCreate(
      this.routes.TEST_ADMIN_ACCOUNTS_ASSIGNED_COORD,
      { 
        uid,
        instit_group_id: this._info.groupId
      },
      this.constructPermissionsParams()
    )
    .then(res =>{
      if (uid === this._userInfo.uid){
        this.reflectAddedRole(DB_Roles_TA.mpt_test_admin_accomm_coord);
      }
      return res;
    })
  }

  unassignAccommCoord(uid:number){
    return this.auth
      .apiRemove(
        this.routes.TEST_ADMIN_ACCOUNTS_ASSIGNED_COORD,
        null,
        this.constructPermissionsParams({ 
          uid,
          instit_group_id: this._info.groupId
        })
      )
      .then(res =>{
        if (uid === this._userInfo.uid){
          this.reflectRemovedRole(DB_Roles_TA.mpt_test_admin_accomm_coord);
        }
        return res;
      })
  }

  createInvigilatorAccount(newAcctInfo:INewAccountInfo){
    if (newAcctInfo.isAutoEmail){
      newAcctInfo.domain = getFrontendDomain();
      newAcctInfo.langCode = this.lang.c();
    }
    return this.auth
      .apiCreate(
        this.routes.TEST_ADMIN_ACCOUNTS_ACCESS,
        newAcctInfo,
        this.constructPermissionsParams()
      )
  }

  getPendingAccommodations(){
    return this.auth
      .apiFind(
        this.routes.TEST_ADMIN_ACCOMM_PENDING_REQUESTS,
        this.constructPermissionsParams()
      )
      .then( (accommodationRequests: ITestSessionBookingInfo[]) => {
        return accommodationRequests.map(this.sanitizePendingAccommEntry)
      })
  }

  sanitizePendingAccommEntry = (booking:ITestSessionBookingInfo) : IBooking => {
    const firstName = booking.first_name;
    const lastName = booking.last_name;
    const ts_info =  booking.testSessionInfo;
    const timezone = moment.tz.guess();
    console.log('sanitizePendingAccommEntry', booking)
    return {
      name: firstName+' '+lastName,
      firstName,
      lastName,
      email: booking.contact_email,
      nameSortable: lastName+firstName,
      timestamp: moment.tz(booking.created_on, timezone),
      uid: booking.uid,
      test_session_id: ts_info.test_session_id,
      reqTransferTimestamp: booking.reqTransferTimestamp,
      date_time_start: moment.tz(ts_info.date_time_start, timezone),
      room: ts_info.room,
      campus_building: ts_info.campus_building,
      responses: booking.responses ? {
        id:booking.responses.id,
        is_resolved: booking.responses.is_resolved,
        created_on: moment.tz(booking.responses.created_on, timezone)
      } : null
    }
  }

  revokeAccountsAccess(uids:number[]){
    return this.auth
      .apiRemove(
        this.routes.TEST_ADMIN_ACCOUNTS_ACCESS,
        null,
        this.constructPermissionsParams({
          uids
        })
      )
  }

  updateInstInfo(newInstInfo:Partial<IInstitution>){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_INSTITUTION,
        null,
        newInstInfo,
        this.constructPermissionsParams()
      )
      .then(res=> {
        this.reloadInstInfo();
        return res;
      })
  }


}
