
export interface ICheckMarkMap {
  [key: string]: boolean;
}

export interface ICheckMarkMapping {
  [key: string]: ICheckMarkMap;
}

const checkMarkMap : ICheckMarkMap = {
  '1': true,
  '#': false
};

const brailleCheckMarkMap: ICheckMarkMap = {
  '3': true,
  '4': true,
  '#': false
}

export interface IDataMappingG9Student {
  source: string,
  target: string,
  key_namespace?: string,
  checkMarkMap?: ICheckMarkMap
}

export const DATA_MAPPING_EQAO_G9_STUDENT : IDataMappingG9Student[]= [
    { source: 'first_name', target: 'first_name'},
    { source: 'last_name', target: 'last_name'},
    { source: 'id', target: 'uid'},
    { source: 'roles', target: 'roles'},
    { source: 'StudentOEN' , target: 'eqao_student_gov_id'},
    { source: 'Program', target: 'eqao_g9_course'},
    { source: 'IEP', target: 'eqao_iep', checkMarkMap},
    { source: 'Linear', target: 'eqao_dyn_linear', checkMarkMap},
    { source: 'IPRCBehaviour', target: '_identification_placement_behaviour', checkMarkMap },
    { source: 'IPRCAutism', target: '_identification_placement_autism', checkMarkMap },
    { source: 'IPRCDeaf', target: '_identification_placement_deaf', checkMarkMap },
    { source: 'IPRCBlind', target: '_identification_placement_blind', checkMarkMap },
    { source: 'IPRCGifted', target: '_identification_placement_gifted', checkMarkMap },
    { source: 'IPRCIntellectual', target: '_identification_placement_intellectual', checkMarkMap },
    { source: 'IPRCDevelopmental', target: '_identification_placement_developmental', checkMarkMap },
    { source: 'IPRCMultiple', target: '_identification_placement_multiple', checkMarkMap },
    { source: 'IPRCPhysical', target: '_identification_placement_physical', checkMarkMap },
    { source: 'IPRCSpeech', target: '_identification_placement_speech', checkMarkMap },
    { source: 'IPRCLanguage', target: '_identification_placement_language', checkMarkMap },
    { source: 'IPRCLearning', target: '_identification_placement_learning', checkMarkMap },
    // { source: 'AccAssistiveTech', target: 'eqao_acc_assistive_tech'},
    { source: 'AccAssistiveTech_Chrome', target:'eqao_acc_assistive_tech_chrome', checkMarkMap},
    { source: 'AccAssistiveTech_Kurz_dl', target:'eqao_acc_assistive_tech_kurz_dl', checkMarkMap},
    { source: 'AccAssistiveTech_Kurz_ext', target:'eqao_acc_assistive_tech_kurz_ext', checkMarkMap},
    { source: 'AccAssistiveTech_Nvda', target:'eqao_acc_assistive_tech_nvda', checkMarkMap},
    { source: 'AccAssistiveTech_Voiceover', target:'eqao_acc_assistive_tech_voiceover', checkMarkMap},
    { source: 'AccAssistiveTech_Readaloud', target:'eqao_acc_assistive_tech_readaloud', checkMarkMap},
    { source: 'AccAssistiveTech_Jaws', target:'eqao_acc_assistive_tech_jaws', checkMarkMap},
    { source: 'AccAssistiveTech_Chromevox', target:'eqao_acc_assistive_tech_chromevox', checkMarkMap},
    { source: 'AccAssistiveTech_Natread' , target:'eqao_acc_assistive_tech_read', checkMarkMap},
    { source: 'AccAssistiveTech_Custom', target:'eqao_acc_assistive_tech_custom'},
    { source: 'AccBraille', target: 'eqao_acc_braille', checkMarkMap: brailleCheckMarkMap},
    { source: 'AccAudioVersion', target: 'eqao_acc_audio_version', checkMarkMap },
    { source: 'AccScribing', target: 'eqao_acc_scribing', checkMarkMap},
    { source: 'StudentType', target: 'eqao_student_school_enrol_type'},
    { source: 'DateofBirth', target: 'date_of_birth'},
    { source: 'Gender', target: 'eqao_gender'},
    { source: 'FrenchImmersionOrExtended', target: 'eqao_g9_french', checkMarkMap},
    { source: 'IS_G9', target: 'eqao_is_g9', key_namespace:'eqao_dyn'},
    { source: 'IS_G10', target: 'eqao_is_g10', key_namespace:'eqao_dyn'},
    { source: 'StatusInCanada', target: 'eqao_status_in_canada'},
    { source: 'OutOfProvinceResidence', target: 'eqao_out_province_residence'},
    { source: 'EnrolledOntario', target: 'eqao_enrolled_ontario'},
    { source: 'FirstLanguage', target: 'eqao_first_language'/*, checkMarkMap*/},
    { source: 'IndigenousType', target: 'eqao_indigenous_type'},
    { source: 'DateEnteredSchool', target: 'eqao_date_entered_school'},
    { source: 'DateEnteredBoard', target: 'eqao_date_entered_board'},
    { source: 'BornOutsideCanada', target: 'eqao_born_outside_canada'},
    { source: 'TimeInCanada', target: 'eqao_time_in_canada'},
    { source: 'Refugee', target: 'eqao_refugee'},
    { source: 'AccLargePrint', target: 'eqao_acc_large_print'},
    { source: 'AccColoured', target: 'eqao_acc_coloured'}, //This is different from current setup. currently eqao_acc_coloured_blue, eqao_coloured_green, etc.
    { source: 'AccLPColoured', target: 'eqao_acc_lp_coloured'}, //This is different from current setup. currently eqao_acc_lp_coloured_blue etc...
    { source: 'AccSign', target: 'eqao_pres_format', checkMarkMap},
    { source: 'AccAssistiveDevices', target: '_changes_in_setting', checkMarkMap},
    { source: 'AccBreaks', target: '_extended_time', checkMarkMap},
    { source: 'AccAudioResponse', target: '_audio_recording_of_resp', checkMarkMap},
    { source: 'AccComputer', target: '_assist_tech_for_recording', checkMarkMap},
    { source: 'SpecPermTemp', target: '_no_iep_temp_inj', checkMarkMap},
    { source: 'SpecPermMoved', target: '_no_iep_recent_arriv', checkMarkMap},
    { source: 'ESLELD', target: '_ell', checkMarkMap},
    { source: 'SpecProvBreaks', target: '_periodic_breaks', checkMarkMap},
    { source: 'TeacherNotes', target: 'teacher_notes'},
    { source: 'class_code', target: 'eqao_g9_class_code'},
    { source: 'teacher', target: 'teacher'},
    { source: 'semester_id', target: 'semester' },
    { source: 'EligibilityStatus', target: 'EligibilityStatus'}, 
    { source: 'LevelofStudyLanguage', target: 'LevelofStudyLanguage'}, 
    { source: 'DateOfFTE', target: 'DateOfFTE'}, 
    { source: 'Grouping', target: 'Grouping'}, 
    { source: 'Homeroom', target: 'Homeroom'}, 
    { source: 'NonParticipationStatus', target: 'NonParticipationStatus'}, 
    { source: 'Graduating', target: 'Graduating'}, 
    { source: 'AccReadGraphic', target: 'AccReadGraphic'}, 
    { source: 'AccReading', target: 'AccReading'}, 
    { source: 'AccVideotapeResponse', target: 'AccVideotapeResponse', checkMarkMap}, 
    { source: 'AccOther', target: 'AccOther', checkMarkMap}, 
    { source: 'SpecPermIEP', target: 'SpecPermIEP', checkMarkMap}, 
  ];