import { ActivatedRoute, ParamMap } from "@angular/router";
import { BCED_LANGUAGE, QueryParam, QueryParams } from "../types/bced";
import { Moment } from "moment";

export enum OperatingSystem {
    Mac = 'Mac',
    Windows = 'Windows',
    Other = 'Other'
}

export function parseQueryParams(route: ActivatedRoute): QueryParams {
    const map: ParamMap = route.snapshot.queryParamMap;

    return {
        testWindowId:  map.get(QueryParam.TEST_WINDOW),
        schoolGroupId: map.get(QueryParam.SCHOOL),
        districtGroupId: map.get(QueryParam.DISTRICT),
    }
}

export function formatDate(d: Moment, language?: BCED_LANGUAGE) {
    if (language === BCED_LANGUAGE.FR) return d.format('Do MMM YYYY, HH:mm:ss');
      
    return d.format('MMMM Do YYYY, h:mm:ss a');
}

export function getOS() {
    var platform = window.navigator.platform;
    var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    var windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

    if (macosPlatforms.indexOf(platform) !== -1) {
        return OperatingSystem.Mac;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return OperatingSystem.Windows;
    } else {
        return OperatingSystem.Other;
    }
}
