import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { BcAccountsService, DistrictDetail, Pagination, SchoolLevelDataReportProficiencyLevel } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { LangService } from 'src/app/core/lang.service';

export enum ImageSource 
{ 
  YUKON_LOGO_ENGLISH = "/assets/BC-logos/Yukon-Logo-CMYK-Resized-For-ISR.png",

  // unused, this image is way too big for the report and doesn't fit under current logo CSS
  // YUKON_LOGO_ENGLISH = "/assets/BC-logos/Yukon-Logo-CMYK.png",

  // unused, relying on "sa_isrr_logo slug"
  // BC_LOGO_ENGLISH = "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/bed-isr-logo/1629259627047/bed-isr-logo.png",

  // unused, relying on "sa_isrr_logo slug"
  // BC_LOGO_FRENCH = "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/bed-isr-logo-fr/1629263063643/bed-isr-logo-fr.png",
}

export interface SingleModeData {
  literacyScore: number;
  numeracyScore: number;
  singlePagination: Pagination;
  singleModePens: {
    pen: string,
    lang: string,
    grade: number,
  }[];
  singleModeName: {
    first_name: string,
    last_name: string,
  };
  pen: string;
  grade: number;
  singleModeLitAssessmentCode: string;
  singleModeNumAssessmentCode: string;
  studentSchool?: string;
  studentDistrict?: number;
  studentSchoolLang?: string;
  isScaled?: boolean;
  litLevel?: string;
  numLevel?: string;
}

@Component({
  selector: 'student-individual-report',
  templateUrl: './student-individual-report.component.html',
  styleUrls: ['./student-individual-report.component.scss']
})
export class StudentIndividualReportComponent implements OnInit, OnChanges {

  @Input() singleModeData: SingleModeData;
  @Input() districtCode: number;
  @Input() schoolLang: string;
  @Input() isScaled: boolean;
  @Input() isBulk: boolean;
  @Input() reportYear: string|number;
  @Input() testWindow: TestWindow

  imageSource: ImageSource | string;
  reportLogoImg: string;


  TableHeaders = [
    'sa_sr_emerging',
    'sa_sr_ontrack',
    'sa_sr_extend'
  ]
  LiteracyTableContent = [
    'sa_fr_issr_emergingLit',
    'sa_fr_issr_ontrackLit',
    'sa_fr_issr_extendLit'
  ]
  NumeracyTableContent = [
    'sa_fr_issr_emergingNum',
    'sa_fr_issr_ontrackNum',
    'sa_fr_issr_extendNum'
  ]

  Levels = [
    SchoolLevelDataReportProficiencyLevel.EMERGING,
    SchoolLevelDataReportProficiencyLevel.ON_TRACK,
    SchoolLevelDataReportProficiencyLevel.EXTENDING
  ]

  constructor(
    private bcAccounts: BcAccountsService,
    public lang: LangService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void 
  {
    console.log("student-individual-report.component");
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    this.setReportLogoImg();

    if (changes.singleModeData) {
      this.formatNames();
    }
  }

  tra(slug: string){
    return this.lang.tra(slug);
  }

  getProficiencyLevel(type: 'literacy' | 'numeracy', score: number): string {
    if (score === undefined || score === null) {
      return SchoolLevelDataReportProficiencyLevel.PLU;
    }

    let assessmentCode = type == 'literacy' ? this.singleModeData.singleModeLitAssessmentCode : this.singleModeData.singleModeNumAssessmentCode;
    let level;

    if (this.singleModeData.isScaled && type == 'literacy') {
      level = this.parseProficiencyLevelTextToCode(this.singleModeData.litLevel);
    } else if (this.singleModeData.isScaled && type == 'numeracy') {
      level = this.parseProficiencyLevelTextToCode(this.singleModeData.numLevel);
    } else {
      level = this.bcAccounts.calculateLevelFromIrtScore(assessmentCode, score, this.isScaled);
    }
    return level;
  }

  translateLevel(level: string): string {
    if (level == 'EM') {
      return this.lang.tra('sa_sr_emerging');
    } else if (level == 'OT') {
      return this.lang.tra('sa_sr_ontrack');
    } else if (level == 'EX') {
      return this.lang.tra('sa_sr_extend');
    } else {
      return this.lang.tra('sa_srr_plu_title');
      if(this.reportYear > 2021) {
        return "Performance Level Unknown (PLU)"
      }
      else {
        return this.lang.tra('sa_srr_plu_title');
      }
      
    }
  }

  parseProficiencyLevelTextToCode(text: string): string {
    const parsedText = text.trim?.();

    if (parsedText == 'Extending') {
      return 'EX';
    } else if (parsedText == 'Emerging') {
      return 'EM';
    } else if (parsedText == 'On Track') {
      return 'OT';
    }

    return text;
  }

  private updateImageSource(): void 
  {
    console.log('updateImageSource');
    // console.log(this.districtCode, this.schoolLang);

    if (this.districtCode && this.districtCode == 98) 
    {
      this.imageSource = (ImageSource.YUKON_LOGO_ENGLISH);
      return;
    }

    else 
    {
      // console.log(this.lang.tra("sa_isrr_logo"));
      this.imageSource = this.lang.tra("sa_isrr_logo");
    }

    // if (this.schoolLang && this.schoolLang.toUpperCase() == "FR") 
    // {
    //   this.imageSource = (ImageSource.BC_LOGO_FRENCH);
    // }

    // else 
    // {
    //   this.imageSource = (ImageSource.BC_LOGO_ENGLISH);
    // }
  }

  private async setReportLogoImg(): Promise<void> {
    try {
      const { logo } = await this.auth.apiGet('public/bced/individual-student-results-report', this.testWindow.id, {
        query: {
          slug: 'isr-meta'
        }
      })

      const language = this.schoolLang?.toLowerCase() || 'en';
      const province = this.districtCode == 98 ? 'yukon' : 'bc';

      const logoImgSrc = logo.find(item => item.province === province && item.language === language)?.value;

      this.imageSource = logoImgSrc?.includes('http') ? logoImgSrc : this.lang.tra(logoImgSrc);
    } catch(err) {
      if (this.districtCode == 98) {
        this.imageSource = (ImageSource.YUKON_LOGO_ENGLISH);
      } else {
        this.imageSource = this.lang.tra("sa_isrr_logo");
      }
    }
  }

  private formatNames() {
    if(!this.singleModeData.singleModeName) return;
    this.singleModeData.singleModeName.first_name = this.singleModeData.singleModeName.first_name?.toUpperCase();
    this.singleModeData.singleModeName.last_name = this.singleModeData.singleModeName.last_name?.toUpperCase();
  }

  isFrench(){
    return this.lang.getCurrentLanguage() == 'fr';
  }
  isPreliminary() {
    return this.isScaled === false
  }

  // andChildCare() {
  //   if (this.isFrench()) {
  //     return "et des Services à la petite enfance";
  //   }
  //   else {
  //     return "and Child Care";
  //   }
  // }

}
