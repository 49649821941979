export enum BCED_LANGUAGE {
    EN = "EN",
    FR = "FR",
}

export enum Language {
    English = 'en',
    French = 'fr',
}

export enum QueryParam {
    TEST_WINDOW = "testWindow",
    DISTRICT = "district",
    SCHOOL = "school",
}

export type QueryParams = {
    testWindowId: string | null;
    districtGroupId: string | null;
    schoolGroupId: string | null;
}

export enum GradAssessmentCode {
    LTE10 = 'LTE10',
    LTE12 = 'LTE12',
    NME10 = 'NME10',
    NMF10 = 'NMF10',
    LTP10 = 'LTP10',
    LTP12 = 'LTP12',
    LTF12 = 'LTF12',
}