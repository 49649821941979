import { Injectable } from '@angular/core';
import { AuthService } from '../api/auth.service';

export enum AssignmentView {
  UPCOMING = 'upcoming',
  CURRENT = 'current'
}
export interface IAssignment {
  itemIDs: number[];
  uid?: number;
  uids?:number[];
  leader?: any;
  chairperson?: any;
  markers?:any[];
  items?: any[];
  isSelected?:boolean;
}



export interface IAssignmentInfo {
  current_assignment: IAssignment[];
  upcoming_assignment: IAssignment[];
  current_timeslot: Date | null,
  upcoming_timeslot: Date | null,
}


@Injectable({
  providedIn: 'root'
})
export class AssignmentService {

  constructor(private auth:AuthService) { }

  ngOnDestroy() {

  }

  async getMarkerAssignments(marking_session_id, allow_revoked: boolean = false) {
    return await this.auth.apiGet('/public/mrkg-lead/marking-assignment', marking_session_id, {
      query:{
        type:'mrkg-mrkr',
        allow_revoked: allow_revoked ? 1 : 0
      }
    });
  }

  async getLeaderAssignments(marking_session_id) {
    return await this.auth.apiGet('/public/mrkg-lead/marking-assignment', marking_session_id, {
      query:{
        type:'mrkg-lead'
      }
    });
  }

  async getChairpersonAssignments(marking_session_id) {
    return await this.auth.apiGet('/public/mrkg-lead/marking-assignment', marking_session_id, {
      query:{
        type:'mrkg-chair'
      }
    });
  }

  async unassignLeader(uid, item_id, marking_session_id) {
    return await this.auth.apiRemove('/public/mrkg-lead/marking-assignment', null, {
      query: {
        item_id,
        uid,
        type:'mrkg-lead',
        marking_session_id
      }
    });
  }

  async unassignChairperson(uid, item_id, marking_session_id) {
    return await this.auth.apiRemove('/public/mrkg-lead/marking-assignment', null, {
      query: {
        item_id,
        uid,
        type:'mrkg-chair',
        marking_session_id
      }
    });
  }

  //instead of passing item_id and leader uid, we pass a list of leaders and the items we want to assign them all at once.
  async bulkAssignLeader(leadersAndItems, marking_session_id) {
    return await this.auth.apiCreate('/public/mrkg-lead/marking-assignment', {
      leadersAndItems,
      type:'mrkg-lead',
      marking_session_id
    });
  }

  //instead of passing item_id and leader uid, we pass a list of leaders and the items we want to assign them all at once.
  async bulkAssignChairperson(leadersAndItems, marking_session_id) {
    return await this.auth.apiCreate('/public/mrkg-lead/marking-assignment', {
      leadersAndItems,
      type:'mrkg-chair',
      marking_session_id
    });
  }

  async assignLeader(uid, item_id, marking_session_id) {
    return await this.auth.apiCreate('/public/mrkg-lead/marking-assignment', {
      item_id,
      uid,
      type:'mrkg-lead',
      marking_session_id
    });
  }

  //instead of passing item_id and pair, we pass a list of pairs and the items we want to assign them all at once.
  async bulkAssignMarkers(pairsAndItems, marking_session_id) {
    return await this.auth.apiCreate('/public/mrkg-lead/marking-assignment', {
      pairsAndItems,
      type:'mrkg-mrkr',
      marking_session_id
    });
  }

  async assignMarkers(uids, item_id, marking_session_id) {
    return await this.auth.apiCreate('/public/mrkg-lead/marking-assignment', {
      item_id,
      uids,
      type:'mrkg-mrkr',
      marking_session_id
    });
  }

  async unassignMarkers(uids, item_id, marking_session_id) {
    return await this.auth.apiRemove('/public/mrkg-lead/marking-assignment', null, {
      query: {
        item_id,
        uids,
        type:'mrkg-mrkr',
        marking_session_id
      }
    });
  }


  async pair(marking_session_id, uids) {
    return await this.auth.apiPatch('/public/mrkg-lead/marking-assignment', null, {}, {
      query: {
        marking_session_id,
        uids,
        action:"pair",
        type:'mrkg-mrkr'
      }
    });
  }

  async unpair(marking_session_id, uids) {
    return await this.auth.apiPatch('/public/mrkg-lead/marking-assignment', null, {}, {
      query: {
        marking_session_id,
        uids,
        action:"unpair",
        type:'mrkg-mrkr'
      }
    });
  }

  async patchMarkingSessionAssignmentJSON(marking_session_id, property, value) {
    let data = {};

    data[property] = value;
    
    return await this.auth.apiPatch('/public/mrkg-lead/marking-assignment', null, data, {
      query:{
        marking_session_id,
        action:'patch'
      }
    });
  }

  async activateUpcomingNow(marking_session_id, type) {
    return await this.auth.apiPatch('/public/mrkg-lead/marking-assignment', marking_session_id, {}, {
      query: {
        action:'activate',
        type
      }
    });
  }




}
