<div
    class="math-container"
    [class.is-inline-alignment]="isInline"
    [class.is-heading]="isHeading()"
    [class.is-heading-small]="isHeadingSmall()"
    [class.is-body]="isBody()"
    [class.is-small]="isSmall()"
>
    <render-math #mathField *ngIf="!(renderAccessible() && !displayMathEl)" [obj]="element" [prop]="getProp()"></render-math>
    <!--<render-image *ngIf="renderAccessible() && !displayMathEl"  [element]="element.accessibilityImg"></render-image>-->
    <div *ngIf="renderAccessible() && !displayMathEl" >
        <img
            [style.margin.em]="0.25" 
            [style.width]='imgWidth' 
            [style.height]='imgHeight' 
            [style.object-fit]="!isMinus() ? 'fill' : 'scale-down'" 
            [src]="getImageURL()"
            [attr.alt]="element.accessibilityImg.altText" 
        />
        <div class="multiline" *ngIf="showAltText()">[[ALT_TEXT: {{element.accessibilityImg.altText}}]]</div>
    </div>

</div>
