<!-- <capture-image></capture-image> -->
<!-- <input type="file" /> -->

<input type="file" (change)="startUpload($event.target.files)">

<div *ngIf="getImgUrl() && !isRequestingReupload" style="margin-top:1em; font-size:0.8em;">
    <p>This is what you uploaded:</p>
    <div >
        <img [src]="getSanitizedImageUrl()">
    </div>
</div>

