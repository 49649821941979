import { IPanelLink } from "./type";
import { AccountType } from "../../../constants/account-types";
import {SCORING_LEADER_VIEWS} from '../../../ui-scoring-leader/view-sl-dashboard/data/views'

export const scoringLeaderPanels:IPanelLink[] = SCORING_LEADER_VIEWS.map(view => {
    return {
        caption: view.caption,
        routerLink: `/en/${AccountType.SCOR_LEAD}/${view.id}/1`, // to complete
        iconUrl: view.imgUrl
    }
})