export enum ScoringSupervisorView {
  SUPERVISORS = 'supervisors',
  SCORERS = 'scorers',
  RESPONSES = 'responses',
  TRAINING = 'training',
}
  
export const SCORING_SUPERVISOR_VIEWS = [
  {
    id:ScoringSupervisorView.SUPERVISORS, 
    caption: ('Co-Supervisors'),
    imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/online/1610324506178/online.png',
    description: ('View contact details for other supervisors assigned to this item.'), //'',
    sideStats: [
      {caption:'{{SUPR_ACCT}} assigned'},
    ]
  },
  {
    id:ScoringSupervisorView.SCORERS, 
    caption: ('Scorers'), 
    imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/project/1610324458874/project.png',
    description: ('Monitor scoring performance and unblock scorers when needed.'), 
    sideStats: [
      {caption:'{{SCOR_ACCT}} assigned'},
      {caption:'{{SCOR_TRAIN}} are trained'},
      {caption:'{{SCOR_BLOCKED}} are blocked'},
      {caption:'{{SCOR_BATCHES_AVAIL}} have batches avail.'},
      {caption:'{{SCOR_SCOR}} are scoring'},
    ]
  },
  {
    id: ScoringSupervisorView.RESPONSES, 
    caption: ('Item & Responses'),
    imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/algorithms/1610324520257/algorithms.png',
    description: ('View item information, access all responses, assign responses for scoring, and directly score responses.'), 
    sideStats: [
      {caption:'{{RESPONSES}} responses'},
      {caption:'{{RESPONSES_SCORED}} scored'},
      {caption:'{{BATCH_SIZE}} batch size'},
      {caption:'{{SCOR_BATCHES_SCORED}} batches scored'},
      {caption:'{{SCOR_BATCHES_CLAIMED}} batches claimed'},
    ]
  },
  {
    id:ScoringSupervisorView.TRAINING, 
    caption: ('Training Materials'),
    imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/authorization/1610324576927/authorization.png',
    description: ('View training materials.') // '67 students are currently enrolled. Click here to validated their information and re-assign them to different teachers.',
  },
]
