<!-- <div id="calculator-div" class="widget-display-container" style="transform: scale(0.6); height:550px; padding:20px; margin:auto; width:340px; background-color: #2A304B; cursor:grab" [ngStyle]="{'width': getTransformWidth(), 'height': getTransformHeight()}" cdkDrag> -->

<div id="calculator-div" *ngIf="!isSimple && !isFSA" class="widget-display-container" style="transform: scale(0.6); padding:20px; margin:auto; width:340px; background-color: #2A304B; cursor:grab" [ngStyle]="{'width': getTransformWidth(), 'height':getTransformHeight() }" cdkDrag>
    <element-render-iframe [zoomLevel]="zoom" [element]="element"></element-render-iframe>
</div>

<div *ngIf="isSimple || isFSA" style="position:absolute; left: 21em; top: 6em;">
    <div class="calc-container" 
         #container cdkDrag tabindex="0" 
    >
        <div class="calc-display">
        <div [ngStyle]="{'font-size.em':getFontDisplayScale()}">
            {{getScreenDisplay()}}
        </div>
    </div>
    <div class="button-container">
        <div class="button-container-main">
            <div class="button-container-main-top">
                <ng-container *ngFor="let btn of functionButtons">
                    <span
                        *ngIf="btn.isVisible"                    
                        (click)="pressFunction(btn.func)"
                        [class.is-2w]="btn.isDoubleWidth"
                        [class.is-3w]="btn.isTripleWidth"
                    >{{btn.caption}}</span>
                </ng-container>            
            </div>
            <div  class="button-container-main-bottom">
            <span
                    *ngFor="let btn of numberButtons"
                    (click)="pressNumber(btn.num)"
                    [class.is-2w]="btn.isDoubleWidth"
                    [class.is-3w]="btn.isTripleWidth"
            >{{btn.caption || btn.num}}</span>
            </div>
        </div>
        <div class="button-container-side">
            <ng-container *ngFor="let btn of operatorButtons">
                <button            
                    *ngIf="btn.isVisible"
                    (click)="pressOperator(btn.operator)"
                    [class.is-2w]="btn.isDoubleWidth"
                    [class.is-3w]="btn.isTripleWidth"
                    [class.is-no-padding]="isFSA"
                    tabindex="-1"
                >{{btn.caption}}</button>
            </ng-container>
            
        </div>
    </div>
    <input id="null-focus" style="display:none"/>
</div>