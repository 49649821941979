export const insertAtCursor = (myField, myValue) => {
    //IE support
    if (document['selection']) {
        myField.focus();
        const sel = document['selection'].createRange();
        sel.text = myValue;
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart == '0') {
        var startPos = myField.selectionStart;
        var endPos = myField.selectionEnd;
        myField.value = myField.value.substring(0, startPos)
            + myValue
            + myField.value.substring(endPos, myField.value.length);
        const newPos = startPos+myValue.length;
        myField.setSelectionRange(newPos, newPos);
        // console.log('reselect', newPos)

    } 
    else {
        myField.value += myValue;
    }
}