import { BCGradSchoolAdminView } from "src/app/ui-schooladmin/view-schooladmin-dashboard/data/views";

export const DL_VIEW_OVERRIDE_MAP = {
    [BCGradSchoolAdminView.BC_GRAD_DL_CENTER_REPORT]: {
        isHidden: false
    }
}

export const OOP_VIEW_OVERRIDE_MAP = {
    [BCGradSchoolAdminView.BC_GRAD_SCHOOL_ADMINS]: {
        isHidden: true
    },
    [BCGradSchoolAdminView.BC_GRAD_ASSESSMENT_MATERIALS]: {
        description: 'sa_grad_assessment_materials_desc_oop'
    },
    [BCGradSchoolAdminView.BC_GRAD_STUDENTS]:{
        description: 'sa_grad_student_registration_desc_oop'
    },
}