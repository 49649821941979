import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'info-center-details',
  templateUrl: './info-center-details.component.html',
  styleUrls: ['./info-center-details.component.scss']
})
export class InfoCenterDetailsComponent implements OnInit {

  @Input() isAuthActive:boolean;
  @Output() dismiss = new EventEmitter();

  emailUrl:SafeResourceUrl;

  constructor(
    private lang:LangService,
    private loginGuard:LoginGuardService,
    private whitelabel: WhitelabelService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.emailUrl = this.sanitizer.bypassSecurityTrustResourceUrl('mailto:'+this.getEmail());
  }

  getSupportReq   = () => this.loginGuard.getSupportReq()

  getEmail(){
    return this.lang.tra(this.whitelabel.getSiteText('supportEmail'));
  }

  isPhoneDisplayed(){
    if(this.whitelabel.getSiteFlag('IS_BCED') || this.whitelabel.getSiteFlag('IS_NWT')){
      return true;
    }else{
      return this.isAuthActive && this.getPhone();
    }
  }

  getPhone(){
    return this.whitelabel.getSiteText('supportPhone');
  }

}
