<div class="assessment-materials">
    <header *ngIf="isSupportAccess" [breadcrumbPath]="breadcrumb"></header>
    <div [ngClass]="{'hideFromGrad': isGrad() && !isSupportAccess }" class="header-container">
        <bc-header-layout [title]="'sa_assessment_material_bc'"
            [is_force_show_asmt_materials]="true"
            [allowQA]="true"
            [imgSrc]="'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/assessment_materials/1610134259593/assessment_materials.svg'"
            (school)="onSelectedSchoolChange($event)" (testWindow)="onSelectedTestWindowChange($event)" [allowQA]="true">
        </bc-header-layout>
    </div>

    <div [class.supportAccess]="isSupportAccess">
        <div>
            <div style="display: flex; flex-direction: row; margin-top: 2em; justify-content: space-between;" [class.preview-controls]="previewMode">
                <button *ngIf="!this.isGradSchoolAdmin()" class="hide-lists-button" (click)="toggleExpandAll()">
                    <ng-container *ngIf="expandAll">
                        <tra slug="sa_am_hide_all"></tra>
                    </ng-container>
                    <ng-container *ngIf="!expandAll">
                        <tra slug="sa_am_show_all"></tra>
                    </ng-container>
                </button>
                <div style="display: flex; flex-direction: row;" [class.preview-controls]="previewMode">
                    <div style="display: flex; align-items: center; margin-right: .5em;">
                        <label *ngIf="previewMode">
                            <toggle [(ngModel)]="previewSchoolAdminLink"  [label]="previewSchoolAdminLink ? 'S/A Links Preview' : 'M/A Links Preview'"></toggle>
                        </label>
                    </div>
                    <div *ngIf="editMode || previewMode">
                        <input id="file-input" type="file" (change)="uploadFile($event)" style="display: none;">
                        <label class="hide-lists-button button" style="font-weight: 600;" for="file-input">Upload File</label>
                    </div>

                    <button *ngIf="editMode || previewMode" (click)="handlePreview()" class="hide-lists-button button">
                        <span *ngIf="!previewMode">Preview</span>
                        <span *ngIf="previewMode">Back to Edit</span>
                    </button>

                    <button *ngIf="isSupportAccess && !editMode && !previewMode" (click)="handleEdit()" class="hide-lists-button button">
                        <span>Edit</span>
                    </button>
                    <button *ngIf="editMode || previewMode" (click)="handleSave()" class="hide-lists-button button">
                        <span >Save</span>
                    </button>
                    <button *ngIf="editMode || previewMode" (click)="handleCancel()" class="hide-lists-button button">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
            <span *ngIf="uploadName && (editMode || previewMode)">
                <span style="font-weight: bolder;">Name:</span> {{uploadName}}<br>
                <span style="font-weight: bolder;">URL:</span> {{uploadURL}}
            </span>
            <div *ngIf="loading" class="accounts-loader">
                <div></div>
            </div>
            <ng-container *ngIf="!loading">
                <div class="material-list" *ngFor="let card of cards, index as card_i">
                    <div *ngIf="!card.test_window_id" style="font-weight: bold; text-align: right; width: 100%"><tra slug="bced_support_am_nontw"></tra></div>
                    <div style="display: flex; align-items: flex-start; flex-direction: row;">
                        <button *ngIf="!this.isGradSchoolAdmin()" class="expand-button">
                            <i (click)="toggleExpanded(card)" class="fa fa-caret-down" [class.expanded]="card.expanded"></i>
                        </button>
                        <div *ngIf="!this.isGradSchoolAdmin()" class="list-title">
                            <span (click)="toggleExpanded(card)" *ngIf="!editMode">
                                <tra-md [isCondensed]="true" [slug]="displayTitle(card.card_title)"></tra-md>
                                <div class="list-subtitle" *ngIf="card.card_description && (isMinistryAdmin() || isSupportAccess)">
                                    <tra-md [isCondensed]="true" [slug]="card.card_description"></tra-md>
                                </div>
                            </span>
                            <div *ngIf="editMode" class="input-container list-title-noCursor">
                                EN: <input type="text" [(ngModel)]="card.card_title.en" />
                                FR: <input type="text" [(ngModel)]="card.card_title.fr" /><br>
                                <span *ngIf="!isGrad() && !card.disable_card_description">
                                    Subtitle: <input type="text"  [(ngModel)]="card.card_description" />
                                </span>
                            </div>
                        </div>
        
                        <button *ngIf="!this.isGradSchoolAdmin()" class="expand-button">
                        </button>
                        <div *ngIf="this.isGradSchoolAdmin()" class="list-title list-title-noCursor">
                            <span *ngIf="!editMode"><tra-md [isCondensed]="true" [slug]="displayTitle(card.card_title)"></tra-md></span>
                            <div  *ngIf="editMode">
                                EN: <input type="text" [(ngModel)]="card.card_title.en" />
                                FR: <input type="text" [(ngModel)]="card.card_title.fr" />
                            </div>
                        </div>
                    </div>
                    <ng-container>
                        <table *ngIf="card.expanded" class="list-table" [class.edit-mode-table]="editMode">
                            <tbody>
                                <tr *ngFor="let section of card.content, index as section_i">
                                    <td>
                                        <span *ngIf="!editMode"><tra-md [isCondensed]="true" [slug]="displayTitle(section.section_title)"></tra-md></span>
                                        <span *ngIf="editMode">
                                            <b>EN:</b><input type="text" [(ngModel)]="section.section_title.en" /><br>
                                            <b>FR:</b><input type="text" [(ngModel)]="section.section_title.fr" />
                                        </span>
                                    </td>
                                    <td>
                                        <div class="lists-container">
                                            <div class="list-links" [class.single-section]="section.content.length == 1" *ngFor="let sub_section of section.content, index as sub_section_i">
                                                <div class="links-header" >
                                                    <ng-container *ngIf="sub_section.sub_section_title">
                                                        <span *ngIf="!editMode">
                                                            <tra-md [isCondensed]="true" [slug]="displayTitle(sub_section.sub_section_title)"></tra-md>
                                                            <div *ngIf="techGuidesOrTechReadiness(card) && sub_section.sub_section_description"
                                                                style="font-weight: normal; font-size: 0.8em;">
                                                                <tra-md [isCondensed]="true" [slug]="sub_section.sub_section_description"></tra-md>
                                                            </div>
                                                        </span>
                                                        <div *ngIf="editMode">
                                                            EN: <input type="text" [(ngModel)]="sub_section.sub_section_title.en" /><br>
                                                            FR: <input type="text" [(ngModel)]="sub_section.sub_section_title.fr" /><br>
                                                            <div *ngIf="techGuidesOrTechReadiness(card)" class="text-area-container">
                                                                Description: <textarea rows="3" style="resize: true" [(ngModel)]="sub_section.sub_section_description"></textarea>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <ul>
                                                    <div *ngFor="let document of sub_section.content, index as link_i">
                                                        <ng-container *ngIf="editMode && !document.is_removed">
                                                            <li>
                                                                <div style="display: flex; flex-direction: row;">
                                                                    <b>Title:</b> <input type="text" [(ngModel)]="document.document_title" />
                                                                    <input type="number" style="width:3em" [(ngModel)]="document.sort_order" />
                                                                </div>
                                                                <div [style]="!document.sa_shares_ma && !document.ma_shares_sa ? 'margin: 1em 0em;' : ''">
                                                                    <b *ngIf="!techGuidesOrTechReadiness(card)" style="font-size: 0.8em;">For School Admin:</b><br>
                                                                    <ng-container *ngIf="!document.sa_is_secure_file">
                                                                        <b [class.disabled]="document.sa_shares_ma || !document.for_sa">Link:</b> <input type="text" [disabled]="document.sa_shares_ma || !document.for_sa" [(ngModel)]="document.document_link" /><br>
                                                                    </ng-container>
                                                                    <div *ngIf="document.sa_is_secure_file" class="secure-section">
                                                                        <span><b [class.disabled]="document.sa_shares_ma || !document.for_sa">Bucket:</b></span> <input type="text" [disabled]="document.sa_shares_ma || !document.for_sa" [(ngModel)]="document.sa_bucket" /><br>
                                                                        <span><b [class.disabled]="document.sa_shares_ma || !document.for_sa">Key:</b></span> <input type="text"    [disabled]="document.sa_shares_ma || !document.for_sa" [(ngModel)]="document.sa_key" /><br>
                                                                    </div>
                                                                    <div style="display: flex; justify-content: space-between; margin-bottom: .3em; font-size: 0.8em;">
                                                                        <label [class.disabled]="document.sa_shares_ma || !document.for_sa"><b>is secure:</b></label>
                                                                        <input class="input-checkbox" type="checkbox" [disabled]="document.sa_shares_ma || !document.for_sa" [(ngModel)]="document.sa_is_secure_file" />
                                                                    </div>
                                                                    <ng-container *ngIf="!techGuidesOrTechReadiness(card)">
                                                                        <b style="font-size: 0.8em;">For Ministry Admin:</b><br> 
                                                                        <ng-container *ngIf="!document.ma_is_secure_file">
                                                                            <b [class.disabled]="document.ma_shares_sa">Link:</b> <input type="text" [disabled]="document.ma_shares_sa" [(ngModel)]="document.ma_link" /><br>
                                                                        </ng-container>
                                                                        <div *ngIf="document.ma_is_secure_file" class="secure-section">
                                                                            <span><b [class.disabled]="document.ma_shares_sa">Bucket:</b></span> <input type="text" [disabled]="document.ma_shares_sa" [(ngModel)]="document.ma_bucket" /><br>
                                                                            <span><b [class.disabled]="document.ma_shares_sa">Key:</b></span> <input type="text" [disabled]="document.ma_shares_sa" [(ngModel)]="document.ma_key" /><br>
                                                                        </div>
                                                                        <div style="display: flex; justify-content: space-between; margin-bottom: 1em; font-size: 0.8em;">
                                                                            <label [class.disabled]="document.ma_shares_sa"><b>is secure:</b></label>
                                                                            <input class="input-checkbox" type="checkbox" [disabled]="document.ma_shares_sa" [(ngModel)]="document.ma_is_secure_file" />
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <div style="display: flex; flex-direction: column; margin-top: 0.5em; margin-bottom: .3em; font-size: 0.8em;">
                                                                    <div style="display: flex; justify-content: space-between;">
                                                                        <label><b>Enabled for School Admins:</b></label>
                                                                        <input class="input-checkbox" type="checkbox" [(ngModel)]="document.allow_sa" (change)="enabledForSAChange(document)" />
                                                                    </div>
                                                                    <div style="display: flex; justify-content: space-between;">
                                                                        <label><b>Available for School Admins:</b></label>
                                                                        <input class="input-checkbox" type="checkbox" [(ngModel)]="document.for_sa" (change)="availableForSAChange(document)"/>
                                                                    </div>
                                                                    <div *ngIf="!techGuidesOrTechReadiness(card)" style="display: flex; justify-content: space-between; margin-top: 0.4em">
                                                                        <label><b>Ministry Admin Shares School Admin's Link:</b></label>
                                                                        <input class="input-checkbox" type="checkbox" [disabled]="!document.for_sa" [(ngModel)]="document.ma_shares_sa" (change)="maSharingChange(document)"/>
                                                                    </div>
                                                                    <div *ngIf="!techGuidesOrTechReadiness(card)" style="display: flex; justify-content: space-between;">
                                                                        <label [class.disabled]="!document.for_sa"><b>School Admin Shares Ministry Admin's Link:</b></label>
                                                                        <input class="input-checkbox" type="checkbox" [disabled]="!document.for_sa" [(ngModel)]="document.sa_shares_ma" (change)="saSharingChange(document)" />
                                                                    </div>
                                                                </div>
                                                                <span class="add-remove remove" (click)="removeLink(card_i, section_i, sub_section_i, link_i)">
                                                                    <i class="fa fa-minus-square"></i>&nbsp;Remove Link
                                                                </span>
                                                            </li>
                                                        </ng-container>
                                                        <ng-container *ngIf="!editMode && !document.is_removed">
                                                            <li>
                                                                <ng-container *ngIf="!document.enabled">
                                                                    <a class="links unavailable"><tra-md [isCondensed]="true" [slug]="document.document_title"></tra-md></a>
                                                                </ng-container>
                                                                <ng-container *ngIf="document.enabled">
                                                                    <a [href]="previewSchoolAdminLink ? document.document_link : document.ma_link" target="_blank" rel="noreferrer noopener" class="links" 
                                                                        [class.unavailable-preview]="previewMode && !document.allow_sa"
                                                                        [class.hidden-preview]="previewMode && !document.for_sa"
                                                                        [class.secure-preview]="previewMode && document.sa_is_secure_file || document.ma_is_secure_file"
                                                                        ><tra-md
                                                                            [isCondensed]="true" [slug]="document.document_title"></tra-md></a>
                                                                </ng-container>
                                                            </li>
                                                        </ng-container>
                                                        <ng-container *ngIf="!editMode && document.is_removed">
                                                            <em><b>Removed</b> {{document.document_title}}</em><br><br>
                                                        </ng-container>
                                                    </div>
                                                </ul>
                                                <div *ngIf="editMode">
                                                    <span class="add-remove add" (click)="addNewLink(card_i, section_i, sub_section_i)">
                                                        <i class="fa fa-plus-square"></i>&nbsp;Add New Link
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>