/**
 
 */


import * as PIXI from "pixi.js-legacy";
import { IContentElementPixiGraph } from "../../../models";
import { drawLine, drawRectangle } from "./drawShapes";

export interface AxisInfo {
    text?: PIXI.Text;
    displayCoord: number;
    // axisCoord: number;
}

export enum AXIS {
    X = 'x',
    Y = 'y'
}
export interface ISquiggleData {
    xPosSquiggle?: number;
    yPosSquiggle?: number;
    xNegSquiggle?: number;
    yNegSquiggle?: number;
}

export interface IsquiggleLabelStartPos {
    lablePosSquiggle?: string
}

export enum SquiggleLabelStartPos {
    X_POS = "x",
    X_NEG = "-x",
    Y_POS = "y",
    Y_NEG = "-y"
}
export interface IGraphConfig {
    xAxisLabel?: string;
    yAxisLabel?: string;
    xLength: number;
    yLength: number;
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
    xOrigin?: number;
    yOrigin?: number;
    isSmallGridEnabled?: boolean;
    xSmallGrid?: number;
    ySmallGrid?: number
    isSquiggled?: boolean;
    isSquiggleHidden?: boolean;
    squiggleData?: ISquiggleData;
    squiggleLabelStartPos?: IsquiggleLabelStartPos;
    xGap?: number;
    yGap?: number;
    isXDecimal?: boolean;
    isYDecimal?: boolean;
    scaleUnitX?: number;
    scaleUnitY?:number;
    // scaleUnit?: number;
    isSnapping?: boolean;
    snapScale?: number;
    isGraphArrowsDisabled?: boolean;
    axisThickness?: number;
    lineThickness?: number;
    axisUnitFontSize?: number;
    axisLabelFontSize?: number;
    distanceBetweenLabelX?: number;
    distanceBetweenLabelY?: number;
    textRes?: number;
    isHideAxisUnitLabels?: boolean;
    backgroundColor?: string;
    backgroundColorAlpha?: number;
    canvasHeight: number;
    lineColor: number;
    isHiContrast: boolean;
    render: () => void;
}

export const PIXI_GRAPH_DEFAULTS: Partial<IGraphConfig> = {
    xAxisLabel: "",
    yAxisLabel: "",
    xLength: 200,
    yLength: 200,
    minX: 0,
    maxX: 16,
    minY: 0,
    maxY: 16,
    xOrigin: 0,
    yOrigin: 0,
    isSmallGridEnabled: false,
    xSmallGrid: undefined,
    ySmallGrid: undefined,
    isSquiggled: false,
    squiggleData: undefined,
    squiggleLabelStartPos: undefined,
    xGap: 1,
    yGap: 1,
    isXDecimal: false,
    isYDecimal: false,
    scaleUnitX: 1,
    scaleUnitY: 1,
    isSquiggleHidden: false,
    // scaleUnit: 1,
    isSnapping: false,
    // snapScale: 1,
    isGraphArrowsDisabled: false,
    axisThickness: 2,
    lineThickness: 0.5,
    axisLabelFontSize: 10,
    axisUnitFontSize: 10,
    distanceBetweenLabelX: 1,
    distanceBetweenLabelY: 1,
    textRes: 2,
    isHideAxisUnitLabels: false,
    backgroundColor: '#ffffff',
    backgroundColorAlpha: 0
}

/**
   * @description Utility class that draws a graph / grid on the screen.
   * @extends PIXI.Graphics
   * 
   * Graph Info:
   * 
   *                          ┬ (yEnd) Px
   *                          ┼
   *                          ┼
   *      (xStart) Px ├───────┼───────┤ (xEnd) Px
   *                          ┼
   *                          ┼
   *                          ┴ (yStart) Px
   * 
   * Intersection / Origin (0,0) => xOrigin, yOrigin (pixels on the container)
   * 
   * Exposed Properties :
   *  @access xEnd
   * 
   * 
   */
export class pixiGraph extends PIXI.Graphics {

    // Provided in config

    private _minX: number;
    private _maxX: number;
    private _minY: number;
    private _maxY: number;
    private _xGap: number;
    private _yGap: number;
    private _isXDecimal: boolean;
    private _isYDecimal: boolean;
    private _scaleUnitX: number;
    private _scaleUnitY: number;

    private _xOrigin: number;
    private _yOrigin: number;    
        
    private _actualGridW: number;
    private _actualGridH: number;
    private _actualXLength: number;
    private _actualYLength: number;

    private _xAxisLabel: string;
    private _yAxisLabel: string;
    private _axisLabelFontSize: number;
    private _axisUnitFontSize: number;
    private _isHideAxisUnitLabels: boolean;

     /**
     * Small Grid :
     * 1 => 10 // not useful as we are already doing that
     * 2 => 0.5 ...
     * 5 => 0.2 0.4 0.6 0.8 ...
     * 10 => 0.1 0.2 0.3 0.4 0.5 .....
     */
    private _isSmallGridEnabled: boolean;
    private _xSmallGrid: number;
    private _ySmallGrid: number;

    // private _scaleUnit: number;
    private _snapScale: number;
    private _isSnapping: boolean;
    private _isSquiggled: boolean;
    private _isSquiggleHidden: boolean;
    private _squiggleData: ISquiggleData;
    private _squiggleLabelStartPos: IsquiggleLabelStartPos;
    private _isGraphArrowsDisabled: boolean;
    private _axisThickness: number;
    private _lineThickness: number;
    private _distanceBetweenLabelX: number;
    private _distanceBetweenLabelY: number;
    private _lineColor: number;
    private _isHiContrast: boolean;
    private _textRes: number;
    private _canvasHeight: number;
    private _render2: () => void;

    /** Graph Coords Data  */

    private _xAxisData: Map<number, AxisInfo>
    private _yAxisData: Map<number, AxisInfo>
    /**
     * _UNIT_LABEL_SPACE : space between the Axis line and the unit label
     * _AXIS_LABEL_SPACE : space between the Axis line and the Axis label 
     *                    => For X axis it will be Vertical
     *                    => For Y axis it will be horizontal
     */

    // Calculated here
    private _unitLabelOffset: number = 15;
    private _axisLabelOffset: number = 30;
    private _xLength: number;
    private _yLength: number;
    private _xStart: number;
    private _yStart: number;
    private _xEnd: number;
    private _yEnd: number;
    private _gridW: number;
    private _gridH: number;
    private _graphHeight: number;
    private _graphWidth: number;
    private _backgroundColor: string;
    private _backgroundColorAlpha: number;


    constructor(config: IGraphConfig) {
        super();
        this._graphConfig = config;
        this._drawGraph();
    }

    /**
     * set Graph config
     */
    private set _graphConfig(config: IGraphConfig) {

        this._render2 = config.render;
        this._xAxisLabel = config.xAxisLabel || '';
        this._yAxisLabel = config.yAxisLabel || '';
        this._isHideAxisUnitLabels = config.isHideAxisUnitLabels;

        this._minX = config.minX || 0;
        this._maxX = config.maxX || 1;
        this._minY = config.minY || 0;
        this._maxY = config.maxY || 1;
        // this._scaleUnit = config.scaleUnit || 1;
        this._scaleUnitX = config.scaleUnitX || 1;
        this._scaleUnitY = config.scaleUnitY || 1;

        // let snappingScale;
        // if(config.snapScale){
        //     snappingScale = config.snapScale * this._scaleUnit
        // } else {
        //     snappingScale = config.isSnapping ? this._scaleUnit : 0.1 * this._scaleUnit
        // }
        // this._snapScale = config.snapScale || config.isSnapping ? 1 : 0.1;
        this._isSmallGridEnabled = config.isSmallGridEnabled;
        this._xSmallGrid = config.xSmallGrid;
        this._ySmallGrid = config.ySmallGrid;
        this._isSquiggled = config.isSquiggled;
        this._isSquiggleHidden = config.isSquiggleHidden;
        this._squiggleData = config.squiggleData;
        this._squiggleLabelStartPos = config.squiggleLabelStartPos;
        this._canvasHeight = config.canvasHeight;

        this._xGap = config.xGap || 1;
        this._yGap = config.yGap || 1;
        this._isXDecimal = config.isXDecimal;
        this._isYDecimal = config.isYDecimal;
        this._isGraphArrowsDisabled = config.isGraphArrowsDisabled;
        this._axisThickness = config.axisThickness || 1;
        this._lineThickness = config.lineThickness || 0.5;
        this._axisUnitFontSize = config.axisUnitFontSize;
        this._axisLabelFontSize = config.axisLabelFontSize;
        this._distanceBetweenLabelX = config.distanceBetweenLabelX;
        this._distanceBetweenLabelY = config.distanceBetweenLabelY;
        this._textRes = config.textRes;
        this._lineColor = config.lineColor;
        this._isHiContrast = config.isHiContrast;

        let totalXNotches = this._maxX - this._minX;
        let totalYNotches = this._maxY - this._minY;
        this._actualXLength = config.xLength || 100;
        this._actualYLength = config.yLength || 100;
        this._actualGridW = this._getRoundedVal(this._actualXLength / totalXNotches);
        this._actualGridH = this._getRoundedVal(this._actualYLength / totalYNotches);
        

        this._xLength = (totalXNotches + this._xGap) * this._actualGridW;
        this._yLength = (totalYNotches + this._yGap) * this._actualGridH;
        this._gridW = this._getRoundedVal(this._xLength / totalXNotches);
        this._gridH = this._getRoundedVal(this._yLength / totalYNotches);
       
        
        // Setter aren't exposed for Calculated Properties
        
        this._xOrigin = config.xOrigin ? config.xOrigin  + 40  : 40;
        this._yOrigin = config.yOrigin ? this._canvasHeight - config.yOrigin - 40 : this._canvasHeight - 40;
        this._xStart = this.xOrigin - (-this._minX * this.gridW);
        this._yStart = this.yOrigin + (-this._minY * this.gridH);
        this._xEnd = this._xOrigin + (this._maxX * this.gridW);
        this._yEnd = this._yOrigin - (this._maxY * this.gridH);
        this._graphWidth = totalXNotches * this._gridW;
        this._graphHeight = totalYNotches  * this._gridH;
        // this._debug();
        this._xAxisData = new Map();
        this._yAxisData = new Map();
        this.hitArea = new PIXI.Rectangle(this.xStart - 5, this.yEnd - 5, this._graphWidth + 10, this._graphHeight + 10);        
        this._backgroundColorAlpha = config.backgroundColorAlpha;
        this.backgroundColor = config.backgroundColor;
    }

    /**
     * Draw Graph
     */
    private _drawGraph() {
        // #TODO: Validation
        this._drawHorizontalLines();
        this._drawVerticalLines();
        if (!this._isGraphArrowsDisabled) {
            this._arrow.then(({ resources }) => {
                let size = 4 * this._axisThickness;
                this._drawArrow(resources, size, this.xOrigin - size / 2, this._yEnd, 270);
                this._drawArrow(resources, size, this._xEnd, this.yOrigin - size / 2, 0);
                if (this._minX < 0) {
                    this._drawArrow(resources, size, this.xStart, this.yOrigin + size / 2, 180)
                }
                if (this._minY < 0) {
                    this._drawArrow(resources, size, this.xOrigin + size / 2, this.yStart, 90)
                }
                this._render2();
            });
        }
        // Add 0:
        // const unitLabel: PIXI.Text = this._getText((0).toString(), {fontSize: this._axisUnitFontSize, fill: this._lineColor}, this._textRes, undefined, undefined)
        // unitLabel.x = this.xStart - 10;
        // unitLabel.y = this.yStart + 5;
        // this.addChild(unitLabel)
        this._addAxisLabels();
    }

    private _getYCoord = (pos) => this._getRoundedVal(this.yOrigin - (pos * this._gridH), 1);
    private _getXCoord = (pos) => this._getRoundedVal(this.xOrigin + (pos * this._gridW), 1);

    /** Draw horizontal lines */
    private _drawHorizontalLines() {

        for (let i = this._minY; i <= this._maxY; i++) {

            let squiggleNum = 0;
            if(this._isSquiggled){
                const lablePosSquiggle  = this._squiggleLabelStartPos?.lablePosSquiggle;
                if(this._squiggleData.yNegSquiggle && (i < 0 || lablePosSquiggle === SquiggleLabelStartPos.Y_NEG)) squiggleNum = this._squiggleData.yNegSquiggle;
                if(this._squiggleData.yPosSquiggle && (i > 0 || lablePosSquiggle === SquiggleLabelStartPos.Y_POS)) squiggleNum = this._squiggleData.yPosSquiggle;
            }

            // this._yAxisData.set(i * this._scaleUnit, { text: null, displayCoord: lineY })
            // this._yAxisData.push({text: null, displayCoord: lineY, axisCoord: i * this._scaleUnit });
            this._drawGraphLines(this.xStart, this._getYCoord(i), this.xEnd, this._getYCoord(i), i, AXIS.Y, this._distanceBetweenLabelY, squiggleNum, this._scaleUnitY, this._isYDecimal, {
                width: this._getThickness(i),
                color: this._lineColor,
                alpha: 1
            });

            // check for cell division and quadrant also validate 
            if(this._isSmallGridEnabled && this._ySmallGrid){   
                if(this._isSquiggled){
                    // what for jagged line? maybe avoid 1st section where jagged line is drawn?
                    if(this._squiggleData.yNegSquiggle && !this._isSquiggleHidden && i === -1) continue;
                    if(this._squiggleData.yPosSquiggle && !this._isSquiggleHidden && i === 0) continue;
                }               
                for (let j = this._getSmallGridPos(i, this._ySmallGrid, 1); this._validateSmallGrid(j, i+1, this._maxY); j = this._getSmallGridPos(j, this._ySmallGrid, 1)){
                    // Draw the small grid lines
                    this._drawGraphLines(this.xStart, this._getYCoord(j), this.xEnd, this._getYCoord(j), j, AXIS.Y, this._distanceBetweenLabelY, squiggleNum, this._scaleUnitY, this._isYDecimal, {
                        width: this._getThickness(j),
                        color: this._lineColor,
                        alpha: 0.3
                    });
                }
            }
        }
    }
    
    /** Draw vertical lines */
    private _drawVerticalLines() {
        for (let i = this._minX; i <= this._maxX; i++) {

            let squiggleNum = 0;
            if(this._isSquiggled){
                const lablePosSquiggle  = this._squiggleLabelStartPos?.lablePosSquiggle;
                if(this._squiggleData.xNegSquiggle && (i < 0 || lablePosSquiggle === SquiggleLabelStartPos.X_NEG)) squiggleNum = this._squiggleData.xNegSquiggle;
                if(this._squiggleData.xPosSquiggle && (i > 0 || lablePosSquiggle === SquiggleLabelStartPos.X_POS)) squiggleNum = this._squiggleData.xPosSquiggle;
            }

            this._drawGraphLines(this._getXCoord(i), this.yStart, this._getXCoord(i), this.yEnd, i, AXIS.X, this._distanceBetweenLabelX, squiggleNum, this._scaleUnitX, this._isXDecimal, {
                width: this._getThickness(i),
                color: this._lineColor,
                alpha: 1
            });

            if(this._isSmallGridEnabled && this._xSmallGrid){  
                if(this._isSquiggled){
                    if(this._squiggleData.xNegSquiggle && !this._isSquiggleHidden && i === -1) continue;
                    if(this._squiggleData.xPosSquiggle && !this._isSquiggleHidden && i === 0) continue;
                }              
                for (let j = this._getSmallGridPos(i, this._xSmallGrid, 1); this._validateSmallGrid(j, i+1, this._maxX); j = this._getSmallGridPos(j, this._xSmallGrid, 1)){
                    // Draw the small grid lines
                    this._drawGraphLines(this._getXCoord(j), this.yStart, this._getXCoord(j), this.yEnd, j, AXIS.X, this._distanceBetweenLabelX, squiggleNum, this._scaleUnitX, this._isXDecimal, {
                        width: this._getThickness(j),
                        color: this._lineColor,
                        alpha: 0.3
                    });
                }
            }
        }
    }
    
    private _drawGraphLines = (xStart, yStart, xEnd, yEnd, pos, type: AXIS, distanceBetweenLabel, squiggleNum, scale, decimal, style) => {
        if (this._isSquiggled && !this._isSquiggleHidden && pos === 0) {
            this._drawSquiggledLine(type, style);
        } else {
            let line = drawLine(xStart, yStart, xEnd, yEnd, style);
            this.addChild(line);
        }
    
        // Add Units 
        if ((pos !== 0 || squiggleNum) && pos % distanceBetweenLabel == 0 && !this._isHideAxisUnitLabels) {
            let label = ((pos + squiggleNum)* scale).toString();
            label = decimal ? Number(label).toFixed(1).toString() : label;
            const unitLabel: PIXI.Text = this._getText(label, { fontSize: this._axisUnitFontSize, fill: this._lineColor }, this._textRes, undefined, undefined);
            let labelPositon = this._getLabelPosition(type, pos, unitLabel.width, unitLabel.height);
            unitLabel.x = labelPositon.x;
            unitLabel.y = labelPositon.y;
            this.addChild(unitLabel);
        }

        // Set Data
        if (type === AXIS.X){
            this._xAxisData.set((pos + squiggleNum)* scale, { text: null, displayCoord: xStart })
        } else if (type === AXIS.Y){
            this._yAxisData.set((pos + squiggleNum)* scale, { text: null, displayCoord: yStart })
        }
    }

    private _getLabelPosition(type: AXIS, position: number, width: number, height: number){
        let x ,y;
        switch (type) {
            case AXIS.X:
                x = this._getXCoord(position) - width / 2;
                y = this.yOrigin + this._unitLabelOffset - height / 2;            
                return { x, y }
            case AXIS.Y:
                x = this.xOrigin - this._unitLabelOffset - width / 2;
                y = this._getYCoord(position) - height / 2;;        
                return { x, y }
            default:
                break;
        }
    }

    private _drawSquiggledLine(axis: AXIS, style) {
        // #TODO: Refactor
        let width = this.gridW / 10;
        let height = this.gridH / 10;

        const drawSqiggleX = (start, end) => {
            this.addChild(drawLine(start, this.yOrigin, start + (2 * width), this.yOrigin, style));
            this.addChild(drawLine(start + (2 * width), this.yOrigin, start + (4 * width), this.yOrigin - (3 * height) ,style ))
            this.addChild(drawLine(start + (4 * width), this.yOrigin - (3 * height), start + (6 * width), this.yOrigin + (3 * height), style))
            this.addChild(drawLine(start + (6 * width), this.yOrigin + (3 * height), start + (8 * width), this.yOrigin, style))
            this.addChild(drawLine(start + (8 * width), this.yOrigin, end, this.yOrigin, style))
        }

        
        const drawSqiggleY = (start, end) => {
            this.addChild(drawLine(this.xOrigin, start, this.xOrigin, start - (2 * height), style));
            this.addChild(drawLine(this.xOrigin, start - (2 * height), this.xOrigin - (3 * width), start - (4 * height) ,style ))
            this.addChild(drawLine(this.xOrigin - (3 * width), start - (4 * height), this.xOrigin + (3 * width), start - (6 * height), style))
            this.addChild(drawLine(this.xOrigin + (3 * width), start - (6 * height), this.xOrigin, start - (8 * height), style))
            this.addChild(drawLine(this.xOrigin, start - (8 * height), this.xOrigin, end, style));
        }

        if(axis === AXIS.X){

            // Draw normal line upto -1 if we have minX < 0
            if(this._squiggleData.xNegSquiggle){
                let start = this._getXCoord(-1);
                let end = this.xOrigin;
                this.addChild(drawLine(this.xStart, this.yOrigin, this._getXCoord(-1), this.yOrigin, style));
                // Draw sqiggle upto 0 
                drawSqiggleX(start,end);
            } else if(this._minX < 0) {
                // Draw normal line upto origin
                this.addChild(drawLine(this.xStart, this.yOrigin, this.xOrigin, this.yOrigin, style));
            }
            if(this._squiggleData.xPosSquiggle){
                let start = this.xOrigin;
                let end = this._getXCoord(1);
                // Draw sqiggle upto 1 
                drawSqiggleX(start,end);
                // Draw normal line upto maxX
                this.addChild(drawLine(this._getXCoord(1), this.yOrigin, this.xEnd, this.yOrigin, style));
            } else {
                // Draw normal line upto maxX
                this.addChild(drawLine(this.xOrigin, this.yOrigin, this.xEnd, this.yOrigin, style));
            }
        }

        if (axis === AXIS.Y) {

            if(axis === AXIS.Y && this._squiggleData.yNegSquiggle){
                let start = this._getYCoord(-1);
                let end = this.yOrigin;
                this.addChild(drawLine(this.xOrigin, this.yStart, this.xOrigin, this._getYCoord(-1), style));
                // Draw sqiggle upto 0 
                drawSqiggleY(start, end);
            } else if (this._minY < 0){
                // Draw normal line upto origin
                this.addChild(drawLine(this.xOrigin, this.yStart, this.xOrigin, this.yOrigin, style));
            }
            if(axis === AXIS.Y && this._squiggleData.yPosSquiggle){
                let start = this.yOrigin;
                let end = this._getYCoord(1);
                // Draw sqiggle upto 1 
                drawSqiggleY(start, end);
                this.addChild(drawLine(this.xOrigin, this._getYCoord(1), this.xOrigin, this.yEnd, style));
            } else {
                // Draw normal line upto maxY
                this.addChild(drawLine(this.xOrigin, this.yOrigin, this.xOrigin, this.yEnd, style));
            }
        }
    }

    private get _arrow(): Promise<PIXI.Loader> {
        let blackArrowhead = "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/arrow_2/1620862487602/arrow_2.png";
        let whiteArrowhead = "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/UI_Elements/gridComponent/arrow_white.png";
        let arrow = this._isHiContrast ? whiteArrowhead : blackArrowhead;
        const arrowLoader = new PIXI.Loader()
        arrowLoader.add('arrow', arrow, { crossOrigin: true });
        return new Promise(resolve => arrowLoader.load(resolve))
    }

    private set backgroundColor(color:string){
        let container = drawRectangle(this.xStart, this.yEnd, this._graphWidth, this._graphHeight, {
            width: 1,
            color: color,
            alpha: this._backgroundColorAlpha
        });
        container.zIndex = -1
        this.addChild(container);
    }

    /**
     * @param resources : PIXI.Loader resources
     * @param size : height and width
     * @param x    : x position
     * @param y    : y position
     * @param angle : angle of the arrow
     */
    private _drawArrow(resources, size, x, y, angle) {
        const arrow = PIXI.Sprite.from(resources['arrow'].texture);
        arrow.width = size;
        arrow.height = size;
        arrow.angle = angle;
        arrow.x = x;
        arrow.y = y;
        this.addChild(arrow);
    }

    /** Add Axis Labels */
    private _addAxisLabels() {
        if (this._xAxisLabel) {
            const label: PIXI.Text = this._getText((this._xAxisLabel).toString(), { fontSize: this._axisLabelFontSize, fill: this._lineColor }, this._textRes, undefined, undefined)
            label.x = this.xOrigin + (this._xEnd - this.xOrigin) / 2 - (label.width / 2);
            label.y = this.yOrigin + this._axisLabelOffset;
            this.addChild(label)
        }
        if (this._yAxisLabel) {
            const label: PIXI.Text = this._getText((this._yAxisLabel).toString(), { fontSize: this._axisLabelFontSize, fill: this._lineColor }, this._textRes, undefined, undefined)
            label.angle = 270;
            label.x = this.xOrigin - this._axisLabelOffset - label.height;
            label.y = this.yOrigin - (this.yOrigin - this._yEnd) / 2 + (label.width / 2);
            this.addChild(label)
        }
    }

    /**
     * Get Graph data
     */
     get xAxisData(): Map<number, AxisInfo> {
        return this._xAxisData;
    }

    get yAxisData(): Map<number, AxisInfo> {
        return this._yAxisData;
    }

    /**
     * Cell Width
     */
    get gridW(): number {
        return this._gridW;
    }

    /**
     * Cell Height
     */
    get gridH(): number {
        return this._gridH;
    }

    /** pixel value at (0,0) */
    get xOrigin(): number {
        return this._xOrigin;
    }

    /** pixel value at (0,0) */
    get yOrigin(): number {
        return this._yOrigin;
    }

    /** pixel value at minX */
    get xStart(): number {
        return this._xStart
    }

    /** pixel value at minY */
    get yStart(): number {
        return this._yStart
    }

    /** pixel value at maxX */
    get xEnd(): number {
        return this._xEnd
    }

    /** pixel value at maxY */
    get yEnd(): number {
        return this._yEnd;
    }

    /**
     * Utility functions
     */

    private _getRoundedVal = (value, precision?) => {
        let multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }
    
    private _getSmallGridPos = (pos, parts, roundVal?) => this._getRoundedVal(pos + (1 / parts), roundVal);
    private _validateSmallGrid = (pos, nextUnit, maxBound) => pos < nextUnit && pos < maxBound;

    private _getThickness = (axisIdx: number) => axisIdx === 0 ? this._axisThickness : this._lineThickness;

    private _getText(text: string, style, resolution: number, x, y) {
        const textObj = new PIXI.Text(text, style)
        if (resolution) {
            textObj.resolution = resolution
        }
        if (x) {
            textObj.x = x
        }
        if (y) {
            textObj.y = y
        }
        return textObj
    }

    private _getTextStyle = (options) => {
        return new PIXI.TextStyle({
            fontSize: options.fontSize || 5,
            fill: options.color || 0x000000
        });
    };

    private _getSnappedCoords() {
        throw new Error("Not Implemented");
    }

    /**
     * 
     * @param axis 
     * @param axisData 
     * @param currentPx 
     * @returns { displayCoord, coord }
     */
    getSnappedAxisData(axis, axisData, currentPx) {
        return this._getSnappedAxisData(axis, axisData, currentPx);
    }

    private _getSnappedAxisData(axis, axisData: Map<number, AxisInfo>, currentPx) {
        let minDiff = -1;
        let displayCoord = undefined;
        let coord = undefined;
        currentPx = this._getRoundedVal(currentPx);
        axisData.forEach((val, key) => {
            let displayPx = val.displayCoord;
            let diff = currentPx - displayPx;
            if (diff < 0) diff *= -1

            if (minDiff == -1 || diff < minDiff) {
                minDiff = diff
                displayCoord = displayPx
                coord = key
            }
        });

        // to snap 0.1
        // let smallestDisplayPx, smallestDisplayCoord, nextDisplayCoord;
        // switch(axis){
        //     case AXIS.X:
        //         //each grid(block) contains 10x10 grid 
        //         // Width is smallest grid width inside the grid.
        //         let width = (this.gridW / 10) * (this._snapScale * 10); 
        //         smallestDisplayCoord = this._getRoundedVal(minDiff / width) * width;
        //         nextDisplayCoord = axisData.get(coord + 1).displayCoord;
        //         console.log("snap",currentPx, displayCoord, nextDisplayCoord)
        //         if(currentPx < nextDisplayCoord && currentPx > displayCoord){     
        //             console.log("inside if")      
        //             smallestDisplayPx = displayCoord + smallestDisplayCoord
        //         }  else {
        //             smallestDisplayPx = displayCoord - smallestDisplayCoord
        //         } 
        //         break;
        //     case AXIS.Y:
        //         let height = (this.gridH / 10) * (this._snapScale * 10); 
        //         smallestDisplayCoord = this._getRoundedVal(minDiff / height) * height;
        //         nextDisplayCoord = axisData.get(coord + 1).displayCoord;
        //         // If we go upside Px value decreses as PIXI maps PX from top left corner
        //         if(currentPx > nextDisplayCoord && currentPx < displayCoord){           
        //             smallestDisplayPx = displayCoord - smallestDisplayCoord
        //         }  else {
        //             smallestDisplayPx = displayCoord + smallestDisplayCoord
        //         } 
        //         break;
        //     default:
        //         throw new Error("Invalid AXIS");
        // }
        // smallestDisplayPx = displayCoord;
        // console.log("minDiff", smallestDisplayPx, currentPx, displayCoord,minDiff);
        return { displayCoord, coord }
    }

    getGridCell(mousePos: {x: number, y: number}, {xAxisData, yAxisData}: { xAxisData:Map<number, AxisInfo>, yAxisData: Map<number, AxisInfo>}){
        return this._getGridCell(mousePos, {xAxisData, yAxisData});
    }

    private _getGridCell(mousePos: {x: number, y: number}, {xAxisData, yAxisData}: { xAxisData:Map<number, AxisInfo>, yAxisData: Map<number, AxisInfo>}){

        const IncDec = 1;

        const getPlusMinusCoords = (axisData:Map<number, AxisInfo>, coord: number) => {
            let minus = axisData.get(coord - IncDec);
            let plus =  axisData.get(coord + IncDec);
            return [minus, plus] 
        } 

        const get2ndNearestCoord = (coords: {coordMinus:AxisInfo, coordPlus:AxisInfo}, mousePos, refCoord: number) => {
            const {coordMinus, coordPlus} = coords;
            if(!coordMinus && !coordPlus) return undefined;
            if(!coordMinus) return { displayCoord: coordPlus.displayCoord, coord: refCoord + IncDec}
            if(!coordPlus) return { displayCoord: coordMinus.displayCoord, coord: refCoord - IncDec}  
            const isMinusSmallest = Math.abs(mousePos - coordMinus.displayCoord) < Math.abs(coordPlus.displayCoord - mousePos);
            return isMinusSmallest 
                    ? { displayCoord: coordMinus.displayCoord, coord: refCoord - IncDec} 
                    : { displayCoord: coordPlus.displayCoord, coord: refCoord + IncDec} 
        }
        
        //find nearest axis for X1 and Y1
        const x1 = this._getSnappedAxisData(AXIS.X, xAxisData, mousePos.x);
        const y1 = this._getSnappedAxisData(AXIS.Y, yAxisData, mousePos.y)
       
        // find the 2nd nearest axis for X2, Y2
        const [xMinus, xPlus] =  getPlusMinusCoords(xAxisData, x1.coord)
        const [yMinus, yPlus] = getPlusMinusCoords(yAxisData, y1.coord)
        const x2 = get2ndNearestCoord({coordMinus: xMinus, coordPlus: xPlus}, mousePos.x, x1.coord) 
        const y2 = get2ndNearestCoord({coordMinus: yMinus, coordPlus: yPlus}, mousePos.y, y1.coord)
        return { x1, x2, y1, y2}
    }

    private _debug(){
        console.log("Default Config and calculated graph variables");
        console.group();
        console.log("x start", this.xStart)
        console.log("y start", this.yStart)
        console.log("x origin", this.xOrigin)
        console.log("y origin", this.yOrigin)
        console.log("x End", this.xEnd)
        console.log("y End", this.yEnd)
        console.log("Graph width", this._graphWidth)
        console.log("Graph Height", this._graphHeight)
        console.groupEnd();
    }

}