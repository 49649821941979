import { Injectable } from '@angular/core';
import { AuthService } from '../api/auth.service';
import { LoginCtrl } from './login-ctrl';

@Injectable({
  providedIn: 'root'
})



export class LoginValidationService {

  constructor(
    private auth: AuthService,
  ) {

  }
  initCtrl(){
   return new LoginCtrl(this.auth);
  }
 

}
