// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class StudentG9ConnectionService {

//   constructor() { }
// }


import { Injectable, OnInit } from '@angular/core';
import {AuthService} from '../api/auth.service';
import Sockette from 'sockette';
import { AccountType } from '../constants/account-types';
import { BehaviorSubject } from 'rxjs';
import { RoutesService } from '../api/routes.service';
import { LoginGuardService } from '../api/login-guard.service';
import { LangService } from '../core/lang.service';
import { Router } from '@angular/router';
import { StudentAssistiveTechService } from './student-assistive-tech.service';
import { WhitelabelService } from '../domain/whitelabel.service';

export interface IStudentPositionUpdate {
  uid: number,
  stageIndex?: number,
  questionIndex?: number,
  submitConfig?: {
    submitted: boolean, 
    subSessionIndex: number
  }
  softLock?:number // boolean // CLOSER_LOOK_20210807 incoming was number
}

interface SocketQueueItem {
  action: string,
  data: any
}

@Injectable({
  providedIn: 'root'
})

export class StudentG9ConnectionService implements OnInit {
  
  private ws: Sockette;
  private classId: number;

  private readonly wsURL: string = 'wss://mqvurtmevc.execute-api.ca-central-1.amazonaws.com/production';

  private socketReady: boolean = false;
  private socketQueue: Array<SocketQueueItem> = [];

  public connectedStudentsSub = new BehaviorSubject<any[]>([]);
  public isSocketReady:BehaviorSubject<any> = new BehaviorSubject({});
  public updateStudentPositionSub = new BehaviorSubject<IStudentPositionUpdate>(null);

  constructor(
    private routes: RoutesService,
    private auth: AuthService,
    private loginGuard: LoginGuardService,
    private lang: LangService,
    private assisTech: StudentAssistiveTechService,
    private router: Router,
    private whiteLabel: WhitelabelService,
  ) {
  }

  ngOnInit(): void {
  }

  onUserInfoChange(info) {
    if(info && info.accountType === AccountType.STUDENT) {
      //Update the classID
     return this.auth.apiFind(this.routes.STUDENT_CLASS_ID).then((res) => {
        if(res[0]) {
          console.log("not empty")
          this.setClassId(res[0].id);
          this.connect();
          return res[0];
        }
      })
    }
  }

  setClassId(classId) {
    this.classId = classId;
  }

  connect() {
    if(this.isConnected()) {
      this.disconnect();
    }
    this.ws = new Sockette(this.wsURL, {
      timeout: 10e3,
      maxAttempts: 10,
      onopen: (ev:Event) => {
        this.onSocketReady()
        this.onUserConnect()
      },
      onmessage: (ev:MessageEvent) => { this.onUserMessage(ev) },
      onreconnect: (ev:Event | CloseEvent) => {
        this.socketReady = false
        console.debug('websocket trying to reconnect...', ev);
      },

      onmaximum: (ev: CloseEvent) => {
        console.debug('websocket max connection attempts reached, giving up', ev);
        this.socketReady = false;
      },
      onclose: (ev: CloseEvent) => {
        console.debug('websocket closed!', ev);
        this.socketReady = false
        this.onUserDisconnect()
      },
      onerror: (ev: Event) => {
        console.debug('websocket error:', ev)
        this.socketReady = false
      }
    });
  }

  private wsSend (action:string, data:any):void {
    if (this.socketReady) {
      this.ws.json({ action, data });
      return;
    }
    // console.log('socket not ready')
    this.socketQueue.push({ action, data });
  }

  private onSocketReady() {
    this.socketReady = true;
    this.isSocketReady.next({isReady:true}) // removed in CLOSER_LOOK_20210807
    while (this.socketQueue.length) {
      const { action, data } = this.socketQueue.shift();
      this.wsSend(action, data);
    }
  }

  disconnect() {
    if(this.ws) {
      this.ws.close();
      this.ws = undefined;
    }
    this.socketReady = false;
  }

  updateStudentPosition(data: Partial<IStudentPositionUpdate>) {
    console.log('updating student position')
    this.wsSend('studentUpdatePosition', data);
    // this.ws.json({action: "studentUpdatePosition", data});
  }

  updateStudentSoftLock(data:{softLock:number}){
    this.wsSend('updateStudentSoftLock',data)
  }

  // updateStudentSoftLock(data:{softLock:boolean}){
  //   this.wsSend('updateStudentSoftLock',data)
  // }

  notifyStudentsOfSubsession(uids: number[], available: boolean, caption: string) {
    this.wsSend('teacherNotifyStudents', { uids, available, caption });
  }

  private onUserConnect() {
    this.wsSend('studentConnect', {
      uid: this.auth.getUid(),
      userType: this.auth.user().value.accountType,
      classId: this.classId
    });
  }

  private onUserDisconnect() {
  }

  private onUserMessage(e) {
    let eObj;

    try {
      eObj = JSON.parse(e.data);
    } catch(e) {
      //Don't set eObj
    }
    if(!eObj) {
      return;
    }

    switch (this.auth.user().value.accountType) {
      case AccountType.STUDENT:
        this.onStudentMessage(eObj);
        break;
      case AccountType.EDUCATOR:
        this.onTeacherMessage(eObj);
        break;
    }
  }

  private onTeacherMessage(e) {
    switch(e.eventType) {
      case 'updateConnectedStudents':
        this.connectedStudentsSub.next(e.connectedStudents);
        break;
      case 'updateStudentPosition':
        console.log(e)
        this.updateStudentPositionSub.next({
          uid: e.uid,
          softLock: e.softLock,
          stageIndex: e.stageIndex,
          questionIndex: e.questionIndex,
          submitConfig: e.submitConfig
        });
        break;
    }
  }

  private onStudentMessage(e) {
    switch(e.eventType) {
      case 'notifyAssessmentAvailable':
        this.showAssessmentPopup(e.available, e.caption);
        break;
      case 'forceLogout':
        this.forceLogout();
        break;
    }
  }


  forceLogout() {
    this.auth.logout().then(() => {
      this.loginGuard.confirmationReqActivate({caption: this.lang.tra('lbl_logged_in_on_other_device'), hideCancel: true, confirm: () => {
        if (this.whiteLabel.getSiteFlag('IS_BCED') || this.whiteLabel.getSiteFlag('IS_NWT')){
          this.router.navigate(['/en/bced-landing/admin']);
        }
        else{
          this.router.navigate(['/en/login-router-st']);
        }
      } });
    });
  }

  showAssessmentPopup(available: boolean, caption: string) {
    const sessionState = available ? "opened":"closed";
    let message
    //console.log(caption)

    if (sessionState === 'opened') {
      if (caption === 'Q'){
      message = this.lang.tra('test_session_Q_opened')
      }
      else{
      message = this.lang.tra('test_session_opened', undefined, {caption: caption})
      }
    } else {
      if (caption === 'Q'){
      message = this.lang.tra('test_session_Q_closed')
        }
      else{
      message = this.lang.tra('test_session_closed', undefined, {caption: caption})
        }
    }
    if (!available) {
      if(!this.assisTech.studentAssistiveTechStatus){ // possibly removed in CLOSER_LOOK_20210807 or the change was just not here yet
        this.router.navigateByUrl(`${this.auth.getDashboardRoute(this.lang.c())}/main`)
      }
      
    }
    this.loginGuard.quickPopup(message);

    //Acknowledge that the notification was received
    this.wsSend('studentAcknowledge', { uid: this.auth.getUid() })
  }

  isConnected():boolean {
    return !!this.ws;
  }
  sub() {
    return this.isSocketReady;
  }
}
