<div class="page-body">
    <div>

      <header [breadcrumbPath]="breadcrumb" ></header>
      <div *ngIf="activeSessions.length ">
        <div class="today-sessions">
          <div class="panel-header"><tra slug="title_todays_sessions"></tra></div>
          <div>
            <markdown 
              class="markdown" 
              [data]="lang.tra('txt_ta_instr_open_invig')" 
            ></markdown>
          </div>
          <div class="session-card squishable" *ngFor="let session of activeSessions" [class.is-hidden]="session.isClosed">
            <div class="number-indic"> {{session.__num}} </div>
            <div >
              <div class="info-segment">
                <div style="font-size:1.2em;margin-bottom:0.3em;">{{session.testWindowTitle}}</div>
                <div class="info-segment-header"><tra slug="lbl_time_and_date"></tra> :</div>
                <div>{{renderLongTimestamp(session.dateTimeStart)}}</div>
              </div>
              <div class="info-segment">
                <div class="info-segment-header"><tra slug="location_title"></tra> : </div>
                <div class="info-segment-location">
                  <div><tra slug="cts_location_room_lbl"></tra> {{session.room}}, {{session.campusBuilding}}</div>
                  <div>{{session.address}}, {{session.city}} {{session.province}} {{session.postalCode}} </div>
                  <div><tra slug="ta_my_faculty_tele_lbl"></tra>: {{session.phoneNumber}}</div>
                </div>
              </div>
              <div>
                <a [routerLink]="getPrintRoute(session.id)">
                  <i style="margin-right: 0.4em;" class="fa fa-print" aria-hidden="true"></i>
                  <tra slug="btn_print_list"></tra>
                </a>
              </div>
            </div>
            <div class="vert-stretch right-container-wide">
              <div class="bookings">
                <div>{{session.bookingsCount}}/{{session.capacity}} <tra slug="txt_snipp_booked"></tra></div>
                <progress 
                  class="progress is-small" 
                  [class.is-success]="session.bookingsCount == session.capacity"
                  value="{{session.bookingsCount}}" 
                  max="{{session.capacity}}"
                ></progress>
              </div>
              <a  [routerLink]="getInvigRoute(session.id)" class="button is-main is-disabled">
                <tra slug="btn_open"></tra>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div 
        class="page-content" 
        [class.is-sessions-upcoming]="getSetupState() == SETUP_STATES.SETUP_DONE" 
      >
        <div class="view-menu">
          <pnel-my-institution></pnel-my-institution>
        </div>
        <div  class="view-body">
          <div [ngSwitch]="getSetupState()">
            <div *ngSwitchCase="SETUP_STATES.LOADING">
                <tra slug="loading_caption"></tra>
              <tra slug="loading_caption"></tra>
            </div>
            <div *ngSwitchCase="SETUP_STATES.FAILED">
              <tra-md slug="txt_err_acct_no_access"></tra-md>
            </div>
            <div *ngSwitchCase="SETUP_STATES.SETUP_PENDING">
              <tra-md slug="txt_err_no_assigned_ts"></tra-md>
            </div>
            <div *ngSwitchCase="SETUP_STATES.NEW_SETUP">
              <h2><tra slug="page_ta_dashboard_1_title"></tra></h2>
              <div>
                <tra slug="page_ta_dashboard_1_main_txt"></tra>
              </div>
              <div class="button-main-row" >
                <button 
                  (click)="createNewTestSessionSetup()"
                  class="button is-main "
                >
                  <tra slug="page_ta_dashboard_1_getstarted_btn"></tra>
                </button>
              </div>
            </div>
            <div *ngSwitchCase="SETUP_STATES.RESUME_SETUP">
                <h2><tra slug="page_ta_dashboard_2_title"></tra></h2>
                <div>
                    <tra slug="page_ta_dashboard_2_main_txt"></tra>
                </div>
                <div class="button-main-row" >
                  <button 
                    (click)="resumeCreateNewTestSessions()"
                    class="button is-main "
                  >
                    <tra slug="page_ta_dashboard_2_btn"></tra>
                  </button>
                </div>
            </div>
            <div *ngSwitchCase="SETUP_STATES.SETUP_DONE">
              <div class="upcoming-sessions"> 
                <div *ngIf="false && isShowBookingStats()">
                  <div class="panel-header">
                    <tra slug="Booking Stats"></tra>
                  </div>
                  <ul>
                    <li><tra slug="Number of Test Sessions"></tra>: <b>{{sessionCount}}</b></li>
                    <li><tra slug="Number of Registrations"></tra>: <b>{{registrationCount}}</b></li>
                    <li *ngIf="instInfo.accomm_responses">
                      <tra slug="ta_unresolved_accomm"></tra>: <b>{{instInfo.accomm_responses.total}}</b>
                      <ul *ngIf="instInfo.accomm_responses.total > 0">
                        <li><tra slug="ta_accomm_days_avg"></tra> : <b>{{getMsAsDays(instInfo.accomm_responses.average)}}</b></li>
                        <li><tra slug="ta_accomm_days_min"></tra> : <b>{{getMsAsDays(instInfo.accomm_responses.min)}}</b></li>
                        <li><tra slug="ta_accomm_days_max"></tra> : <b>{{getMsAsDays(instInfo.accomm_responses.max)}}</b></li>
                      </ul>
                    </li>
                  </ul>
                  <br>
                </div>
                <div *ngIf="unclosedTestSessions.length > 0">
                  <div class="panel-header" style="display:flex; flex-direction: row; align-items: center;">
                    <tra slug="Recently Completed Test Sessions"></tra>
                  </div>
                  <button *ngIf="!showUnclosedTestSessions" (click)="showUnclosedTestSessions = true" class="button is-small" >Show</button>
                  <button *ngIf="showUnclosedTestSessions" (click)="showUnclosedTestSessions = false" class="button is-small" >Hide</button>
                  <ul *ngIf="showUnclosedTestSessions">
                    <li *ngFor="let session of unclosedTestSessions">
                      <div class="info-segment-header">
                        <a [routerLink]="getInvigilationsRoute(session.id)"><tra slug="cts_location_room_lbl"></tra> {{session.room}}, {{session.campus_building}}</a>
                      </div>
                    </li>
                  </ul>
                  <br>
                </div>
                <div class="panel-header">
                  <tra slug="title_scheduled_sessions"></tra>
                </div>
                <p>
                  <tra slug="txt_instr_ta_scheduled_sessions"></tra>
                </p>
                <p *ngIf="upcomingSessions && (upcomingSessions.length == 0)">
                  <tra slug="txt_instr_ta_scheduled_sessions_none"></tra>
                </p>
                <p *ngIf="isSessionCreator()">
                  <tra slug="txt_instr_ta_scheduled_sessions_sub"></tra>
                </p>
                <div style="margin-top:1em;">
                  <div class="calendar-container" *ngIf="isSessionsLoaded" >
                    <!-- [class.is-hidden]="!events.length" -->
                    <full-calendar 
                      #calendar
                      defaultView="dayGridMonth" 
                      [plugins]="calendarPlugins"
                      [events]="calendarEvents"
                      [eventTimeFormat]="eventTimeFormat"
                      [locale]="getCalendarLocale()"
                    ></full-calendar>
                  </div>
                </div>
                <div>
                  <div class="session-card" *ngFor="let session of upcomingSessions" [class.is-hidden]="session.isClosed">
                    <div class="number-indic"> {{session.__num}} </div>
                    <div class="vert-stretch">
                      <div style="font-size:1.2em;margin-bottom:0.3em;">{{session.testWindowTitle}}</div>
                      <div class="info-segment">
                        <div class="info-segment-header"><tra slug="cts_location_room_lbl"></tra> {{session.room}}</div>
                        <div>{{renderShortTimestamp(session.dateTimeStart)}}</div>
                      </div>
                      <div style="font-size:0.8em;">
                        <strong><tra slug="lbl_invig"></tra> : </strong>
                        {{getInvigilDisplay(session)}}
                      </div>
                    </div>
                    <div class="session-icons">
                      <i *ngIf="session.isAccessCodeEnabled" class="fa fa-lock" aria-hidden="true"></i>
                      <i *ngIf="session.isHidden" class="fa fa-eye-slash" aria-hidden="true"></i>
                    </div>
                    <div class="vert-stretch right-container">
                      <div class="bookings">
                        <div>
                          {{session.bookingsCount}}/{{session.capacity}} <tra slug="txt_snipp_booked"></tra>
                          <span 
                            *ngIf="session.waitlistCount > 0" 
                            style="margin-left:0.5em;" 
                            class="tag"
                          >
                            <span>{{session.waitlistCount}}&nbsp;</span>
                            <tra slug="txt_snipp_on_waiting_list"></tra>
                          </span>
                        </div>
                        <progress 
                          class="progress is-small" 
                          [class.is-success]="session.bookingsCount == session.capacity"
                          value="{{session.bookingsCount}}" 
                          max="{{session.capacity}}"
                        ></progress>
                      </div>
                      <a [routerLink]="getBookingsRoute(session.id)" class="button is-info">
                        <tra slug="btn_manage_bookings"></tra>
                      </a>
                    </div>
                  </div>
                </div>
                <div *ngIf="isSessionCreator()" style="max-width: 58em; margin-top: 1em;">
                  <button (click)="createNewTestSessionSetup()" class="button is-main is-large is-fullwidth" >
                    <tra *ngIf="setupInfo.__reqNew" slug="btn_create_new_sessions"></tra>
                    <tra *ngIf="!setupInfo.__reqNew" slug="page_ta_dashboard_2_btn"></tra>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer [hasLinks]="true"></footer>
  </div>
