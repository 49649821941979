import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';

const LOADING_MSG_TIMEOUT_MS = 10000;
@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {
  @Input() scale = 1;
  @Input() color = '#4e8ff1';
  @Input() showLoadingMsg?: boolean = false;
  isLoadingSlow:boolean;
  loadingSlowMsg:string;

  constructor(
    private auth:AuthService,
  ) { }

    ngOnInit() {
      this.isLoadingSlow = false;
      setTimeout(()=> {
        this.isLoadingSlow = true;
      }, LOADING_MSG_TIMEOUT_MS);
  }
  
}
