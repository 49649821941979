<div>
    <!-- <table class="table is-bordered">
        <tr>
            <th>PEN</th>
        </tr>
        <tr *ngFor="let item of getSelfReflections()"> 
            <td>{{ item.meta.session }}</td>
            <td>{{ item.meta.assessment }}</td>
            <td>{{ item.meta.item }}</td>
            <td><a [href]="item.meta.file_url"><tra [slug]="item.meta.file_link_slug"></tra></a></td>
            <td>
                <span *ngIf="!item.meta.img_url"><tra slug="sa_na" ></tra></span>
                <img *ngIf="item.meta.img_url" class="word-cloud" [src]="item.meta.img_url">
            </td>
        </tr>
    </table> -->
    <!-- <ng-container *ngIf="isGridLoaded"> -->
        <label style="margin-right: 8px;">PEN:</label>
        <input style="margin-right: 8px;" [(ngModel)]="penToSearch">

        <label style="margin-right: 8px;">Analysis Code:</label>
        <select [disabled]="loading" [(ngModel)]="selectedAnalysisCode">
            <option value="">Any</option>
            <option 
                *ngFor="let analysisCode of analysisCodes" 
                [value]="analysisCode"
            >
                {{analysisCode}}
            </option>
        </select>

        <label style="margin-left: 1rem; margin-right: 8px;">Suspense Error:</label>
        <select [disabled]="loading" [(ngModel)]="selectedSuspenseError">
            <option value="">Any</option>
            <option 
                *ngFor="let suspenseError of suspenseErrors" 
                [ngValue]="suspenseError.code"
            >
                {{suspenseError.code}}
                ({{suspenseError.description}})
            </option>
        </select>

        <button
            style="margin-left: 1rem;"
            (click)="getData()"
            [disabled]="loading"
        >
            Search
        </button>

        <button 
            style="margin-left: 1rem;"
            (click)="downloadAll()"
            [disabled]="loading"
        >
            Download All
        </button>

        <ag-grid-angular
            style="width: 100%; height: 600px; margin-top: 2rem;"
            class="ag-theme-alpine"
            [gridOptions]="gridOptions"
        >
        </ag-grid-angular>

        <button class="button has-icon" (click)="gridExport()">
            <span class="icon">
                <i class="fas fa-file-export"></i>
            </span>
            <span> Export CSV </span>
          </button>
      
    <!-- </ng-container> -->
</div>