import { IPanelLink } from "./type";
import { BC_FSA_SCHOOL_ADMIN_VIEWS, BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY_VIEWS } from '../../../ui-schooladmin/view-schooladmin-dashboard/data/views'

export const fsaSchoolAdminPanels: IPanelLink[] = BC_FSA_SCHOOL_ADMIN_VIEWS.map(view => {
  // if(view.caption == 'Marking') {
  //   return {
  //     caption: view.caption,
  //     routerLink: `/en/mrkg-coord/dashboard`,
  //     iconUrl: view.imgUrl,
  //     disabled: !!view.disabled
  //   }
  // }
  return {
    id: view.id,
    caption: view.caption,
    routerLink: `/en/school-admin/bc-fsa/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled
  }
})

export const fsaSchoolAdminScoreEntryPanels: IPanelLink[] = BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY_VIEWS.map(view => {
  return {
    id: view.id,
    caption: view.caption,
    routerLink: `/en/school-admin/bc-fsa/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled,
  }
})