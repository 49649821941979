import { ChangeDetectorRef, Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { IContentElementMath, TextParagraphStyle, QuestionState, AccessibilityVersion } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { ActivatedRoute } from "@angular/router";
import { PrintAltTextService } from '../../ui-item-maker/print-alt-text.service';
import { RenderModeService } from '../render-mode.service';
import { TextToSpeechService } from '../text-to-speech.service';

@Component({
  selector: 'element-render-math',
  templateUrl: './element-render-math.component.html',
  styleUrls: ['./element-render-math.component.scss']
})
export class ElementRenderMathComponent implements OnInit {

  @Input() element:IContentElementMath;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() isInline:boolean;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() changeCounter?: number
  @ViewChild('mathField', { static: false }) mathFieldRef: ElementRef;

  constructor(private renderMode:RenderModeService, private route: ActivatedRoute, private printAltText: PrintAltTextService, private changeDetector: ChangeDetectorRef, private textToSpeech: TextToSpeechService) { }

  accommodation;
  isTTS;
  displayMathEl = true;
  imgWidth;
  imgHeight;
  htmlRenderMathWidth
  htmlRenderMathHeight
  ngOnInit() {
    // console.log('mathquill in angular', (window as any).MathQuill);
    // console.log('isInline', this.isInline)
    this.accommodation = this.route.snapshot.queryParamMap.get('isAccomm')
    this.isTTS = this.route.snapshot.queryParamMap.get('isTTS')
    this.displayMathEl = true
    this.renderHelper()
  }

  renderHelper() {
    setTimeout(()=>{
      if (this.renderAccessible()) {
        if (!this.htmlRenderMathWidth) {
          const element = this.mathFieldRef["container"].nativeElement
          this.htmlRenderMathWidth = element.offsetWidth
          this.htmlRenderMathHeight = element.offsetHeight
        }
        this.imgWidth = this.htmlRenderMathWidth
        this.imgHeight = this.htmlRenderMathHeight
        
        if (this.element.latex.indexOf('\\frac')==-1) {
          if (this.element.latex.indexOf('cubed')==-1 && 
              this.element.latex.indexOf('squared')==-1 && 
              this.element.latex.indexOf('power')==-1 && 
              this.element.latex.indexOf('^')==-1 &&
              this.element.latex.indexOf('(')==-1 &&
              this.element.latex.indexOf(')')==-1) {
            this.imgHeight *= 0.65
          } else {
            this.imgHeight *= 0.75
          }
        }
        if (this.element.imgWidth && this.element.imgHeight) {
          this.imgWidth = this.element.imgWidth+'em'
          this.imgHeight = this.element.imgHeight+'em'
        } else {
          this.imgWidth += 'px'
          this.imgHeight += 'px'
        }
        this.displayMathEl = false;
      }
    }, 100)
  }

  ngOnChanges(changes:SimpleChanges) {
    this.renderHelper()
  }

  isImgDimsInPixels() {
    if (this.element.imgWidth && this.element.imgHeight) {
      return false;
    }
    return true;
  }

  isHeading(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE
  }
  isHeadingSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL
  }
  isBody(){
    return this.element.paragraphStyle === TextParagraphStyle.REGULAR
  }
  isSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.SMALL
  }

  isMinus() {
    let prop;
    if (this.element['content']) {
      prop = this.element['content'];
    } else {
      prop = 'latex';
    }
    const content = this.element[prop];
    return content=='-';
  }

  isAccessible() {
    return this.element.showAccessibility == AccessibilityVersion.ACCESSIBLE
  }

  isAccommodation() {
    return this.accommodation == 1
  }

  isTTSEnabled() {
    return this.isTTS == 1
  }

  renderAccessible() {
    return ((this.isTTSEnabled() && !this.renderMode.isAuthoring()) || (this.isAccessible() && this.renderMode.isAuthoring())) && (this.element.accessibilityImg && (this.element.accessibilityImg.url || this.element.accessibilityImg.altText) )
  }

  showAltText() {
    return this.printAltText.getAltTextVisible();
  }

  getProp() {
    if (!this.element["content"]) {
      return 'latex'
    }
    return 'content';
  }

  getImageURL() {
    if (this.textToSpeech.isHiContrast && this.element.accessibilityImg.hiContrastImg && this.element.accessibilityImg.hiContrastImg.url) {
      return this.element.accessibilityImg.hiContrastImg.url;
    } 
    return this.element.accessibilityImg.url;
  }
}
