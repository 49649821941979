<ng-container [ngSwitch]="!!element.isParagraphMode"> 
    <!-- <ng-container *ngSwitchCase="false">
        <div style="display:flex; flex-wrap:wrap;" [ngStyle]="getStyle()">
            <div class="block-el" *ngFor="let block of blocks; let i = index;" (click)="!isLocked ? toggleAnswer(block.index) : ''" [ngStyle]="getWordStyle(block, i)">
                <markdown-inline [input]="getFormattedWord(block.word)"></markdown-inline>        
            </div>
        </div>        
    </ng-container>
    <br> -->
    <ng-container *ngSwitchCase="false">
        <div [ngStyle]="getStyle()">
            <div class="block-el-sentence" *ngFor="let sentence of getSentences_Block(); let i = index;" (click)="!isLocked ? toggleAnswer(i) : ''" [ngStyle]="getWordStyle_Sentence(sentence, i)">
                <markdown-inline [input]="getFormattedWord(sentence)"></markdown-inline>        
            </div>
        </div> 
    </ng-container>
    <br>

    <ng-container *ngSwitchCase="true">
        <ng-container *ngFor="let paragraph of paragraphs; let idx = index">
            <!-- <div style="display:flex; flex-wrap:wrap;" [ngStyle]="getStyle(idx)">
                <div class="block-el" *ngFor="let block of paragraph; let i = index;" (click)="!isLocked ? toggleAnswer(block.index) : ''" [ngStyle]="getWordStyle(block, block.wordIdx)">
                    <markdown-inline aria-hidden="true" [input]="getFormattedWord(block.word)"></markdown-inline>
                </div>
            </div> -->
            <div [ngStyle]="getStyle(idx)">
                <div class="block-el-sentence" *ngFor="let sentence of getSentences(paragraph), let i = index;" (click)="!isLocked ? toggleAnswer(getSentenceIndex(paragraph, sentence)) : ''" [ngStyle]="getWordStyle_Sentence_2(paragraph, sentence, i)">
                    <markdown-inline [input]="getFormattedSentence_2(sentence)"></markdown-inline>        
                </div>
            </div> 
        </ng-container>
    </ng-container>
</ng-container>
<div *ngIf="this.showMaxSelectedMsg" (click)="!isLocked ? this.showMaxSelectedMsg = false : ''" class="mcq-max-selected-msg">
    <markdown 
        class="markdown is-condensed" 
        [data]="getMaxMsgText()" 
        ngPreserveWhitespaces
     ></markdown>
    <div class="click-dismiss-msg">
        <markdown 
            class="markdown is-condensed" 
            [data]="getClickToDismissMsg()" 
            ngPreserveWhitespaces
        ></markdown>
    </div>
</div>