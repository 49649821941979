<ng-container style="width: 100%;">
  <!-- For MA and SA -->
  <div class="row">
    <div class="label">Assessment Component</div>
    <div class="value">{{ displayTitle() }}</div>
  </div>

  <div class="row">
    <div class="label">Assessment Status</div>
    <div class="value">{{ status }}</div>
  </div>
    
  <div  class="row">
    <div class="label">Login Date & Time</div>
    <div class="value">{{ displayDate(loginTime) }}</div>
  </div>

  <div  class="row">
    <div class="label">Submission<br>Date & Time</div>
    <div class="value">{{ displayDate(submissionTime) }}</div>
  </div>
</ng-container>