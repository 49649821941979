import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import moment from 'moment';
import { AuthService } from 'src/app/api/auth.service';
import { BcAccountsService, DistrictDetail, FilterCondition, PaginatedRows, Pagination, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcReportsService } from 'src/app/bc-reports/bc-reports.service';
import { ConfirmationCodeReportRow } from 'src/app/bc-reports/types';
import { AccountType } from 'src/app/constants/account-types';
import { BcPaginatedTableComponent, IColumnHeading, TextDisplay } from '../bc-paginated-table/bc-paginated-table.component';
import { LangService } from 'src/app/core/lang.service';

@Component({
  selector: 'confirmation-code-report',
  templateUrl: './confirmation-code-report.component.html',
  styleUrls: ['./confirmation-code-report.component.scss']
})
export class ConfirmationCodeReportComponent implements OnInit, OnChanges {

  @ViewChild(BcPaginatedTableComponent) table: BcPaginatedTableComponent<ConfirmationCodeReportRow>;

  @Input() testWindow: TestWindow;
  @Input() schoolDetail: SchoolDetail;
  @Input() districtDetail: DistrictDetail;
  @Input() accountType: AccountType;

  columnHeadings: IColumnHeading[];
  tableColumnWidths: number[] = [200, 200, 250, 200, 200, 200, 200, 250, 250, 250];

  pagination: Pagination;

  FilterCondition = FilterCondition;

  isLoading: boolean = false;

  constructor(
    private auth: AuthService,
    private bcAccounts: BcAccountsService,
    private bcReports: BcReportsService,
    private lang: LangService,
  ) { }

  ngOnInit(): void {
    this.pagination = this.bcAccounts.getInitialPagination();
    this.columnHeadings = [];
    if (!this.isSchoolAdmin()) {
      this.columnHeadings.push(
        { heading: this.lang.tra('sa_sr_district_code'), sortBy: 'district_foreign_id' },
        { heading: this.lang.tra('sa_sr_school_code'), sortBy: 'school_foreign_id' },
        { heading: this.lang.tra('sa_sr_school_name'), sortBy: 'school_name' },
      );
    }
    this.columnHeadings.push(
      { heading: this.lang.tra('sa_aa_lname'), sortBy: 'last_name' },
      { heading: this.lang.tra('sa_aa_fname'), sortBy: 'first_name' },
      { heading: this.lang.tra('sa_pen'), sortBy: 'pen' },
      { heading: this.lang.tra('sa_grade_fsa'), sortBy: 'grade' },
      { heading: this.lang.tra('sa_component'), sortBy: 'slug' },
      { heading: this.lang.tra('sa_sr_con_code'), sortBy: 'confirmation_code' },
      { heading: this.lang.tra('sa_sr_date_created'), sortBy: 'created_on', filterDisabled: true, },
    );

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.testWindow || changes.districtDetail || changes.schoolDetail) {
      if (this.table) {
        this.table.updateTable();
      }
    }
  }

  getRows = (pagination: Pagination): Promise<PaginatedRows<ConfirmationCodeReportRow>> => {
    if (!this.testWindow || !this.districtDetail || this.schoolDetail === undefined) return Promise.resolve({
      count: 0,
      data: [],
    });

    return this.bcReports.getConfirmationCodeReport({
      test_window_id: this.testWindow.id,
      district_group_id: this.districtDetail.groupId,
      school_group_id: this.schoolDetail ? this.schoolDetail.groupId : undefined,
    }, pagination).then((result) => {
      // result.count = 1;
      // result.data.push({
      //   district_group_id: 35502,
      //   district_foreign_id: 901,
      //   school_group_id: 35504,
      //   school_foreign_id: 90100001,
      //   school_name: 'Sample School 1-A',

      //   uid: 20,
      //   last_name: 'Doe',
      //   first_name: 'John',
      //   pen: '10000000',
      //   grade: 4,
      //   confirmation_code: 'adsfasdfadsfasdf',
      //   created_on: moment().toISOString(),
      //   has_unsubmissions: 0,
      // })
      return result;
    });
  }

  getDisplay = (by: string, row: ConfirmationCodeReportRow): string | TextDisplay => {
    if (by === 'created_on') {
      return this.auth.formatDate(row.created_on);
    }
    if (by == 'grade'){
      if(row[by] == 4) return this.lang.tra('sa_grade_4')
      else return this.lang.tra('sa_grade_7')
    }
    return row[by];
  }

  isSchoolAdmin() {
    return this.auth.isSchoolAdmin(this.accountType);
  }

  export() {
    this.bcReports.exportConfirmationCodeReport({
      test_window_id: this.testWindow.id,
      district_group_id: this.districtDetail.groupId,
      school_group_id: this.schoolDetail ? this.schoolDetail.groupId : undefined,
      pagination: this.pagination,
    })
  }
}
