<div class="fsa-schools-not-started-report">
    <bc-export-button [disabled]="pagination.isLoading" [large]="true" (export)="export()"></bc-export-button>
    <div class="dropdown-selectors">
        <div class="grade-selector dropdown-selector">
            <span class="selector-label">
                <tra slug="sa_sr_viewing"></tra>
            </span>
            <select [(ngModel)]="selectedGradeOption" (change)="onSelectedGradeChange($event)"
                [disabled]="pagination.isLoading">
                <option *ngFor="let grade of gradeOptions" [ngValue]="grade">
                    <tra [slug]="grade.caption"></tra>
                </option>
            </select>
        </div>
    </div>
    <div>
        <div *ngIf="isLoading" class="notification is-warning">
            <tra slug="loading_caption"></tra>
        </div>

        <bc-paginator [paginator]="pagination" (refresh)="onPaginationChange()"></bc-paginator>
        <div class="table-section">
            <table>
                <col>
                <col>
                <col>
                <colgroup span="2"></colgroup>
                <colgroup span="2"></colgroup>
                <colgroup span="2"></colgroup>
                <thead>
                    <tr>
                        <th *ngFor="let headingToSortBy of getHeadingToSortBy()" rowspan="2" class="no-border">
                            <div>
                                <div (click)="changeOrderBy(headingToSortBy.sortBy)"
                                    *ngIf="!headingToSortBy.sortDisabled">
                                    <span>
                                        <tra [slug]="headingToSortBy.heading"></tra>
                                    </span>
                                    <i *ngIf="isSortedBy(headingToSortBy.sortBy, 'asc')" class="fa fa-caret-up"></i>
                                    <i *ngIf="isSortedBy(headingToSortBy.sortBy, 'desc')" class="fa fa-caret-down"></i>
                                </div>
                                <div *ngIf="headingToSortBy.sortDisabled" class="sort-disabled">
                                    <span>
                                        <tra [slug]="headingToSortBy.heading"></tra>
                                    </span>
                                </div>
                                <i *ngIf="!headingToSortBy.filterDisabled" class="fa fa-filter" aria-hidden="true"
                                    (click)="toggleShowFilter(headingToSortBy.sortBy)"></i>
                            </div>
                            <div *ngIf="isFilterVisible(headingToSortBy.sortBy)">
                                <input type="text" (input)="updateFilter($event, headingToSortBy.sortBy)"
                                    [value]="getFilterValue(headingToSortBy.sortBy)">
                            </div>
                        </th>
                        <th colspan="2" scope="colgroup" *ngFor="let headingToSortBy of getComponentsHeadingToSortBy()">
                            <div>
                                <div>
                                    <span>
                                        <tra [slug]="headingToSortBy.heading"></tra>
                                    </span><br><span>
                                        <tra [slug]="headingToSortBy.subheading"></tra>
                                    </span>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">
                                        <tra slug="sa_se_status"></tra>
                                    </span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">
                                        <tra slug="da_sr_sns_submissionProgress"></tra>
                                    </span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">
                                        <tra slug="sa_se_status"></tra>
                                    </span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>

                                <div><span class="nowrap">
                                        <tra slug="da_sr_sns_submissionProgress"></tra>
                                    </span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">
                                        <tra slug="sa_se_status"></tra>
                                    </span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">
                                        <tra slug="da_sr_sns_submissionProgress"></tra>
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">
                                        <tra slug="sa_se_status"></tra>
                                    </span></div>
                            </div>
                        </th>
                        <th scope="col">
                            <div>
                                <div><span class="nowrap">
                                        <tra slug="da_sr_sns_submissionProgress"></tra>
                                    </span></div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of reportTable, index as i" [class.odd]="row._isOdd">
                        <td><span *ngIf="row._isInfo">{{row.school_name}}</span></td>
                        <td><span *ngIf="row._isInfo">{{formatSchoolCode(row.school_foreign_id)}}</span></td>
                        <td><span *ngIf="row._isInfo">{{row.school_type}}</span></td>
                        <td>
                            <tra slug="sa_grade"></tra> {{row.grade}}
                        </td>
                        <td>
                            <div [class.not-started]="getStatus(row.sr_literacy, row.enrollment) == 'da_a_not_started'">
                                <tra [slug]="getStatus(row.sr_literacy, row.enrollment)"></tra>
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="getStatus(row.sr_literacy, row.enrollment) != 'da_a_not_started'">
                                {{row.sr_literacy}}/{{row.enrollment}} ({{getDivisionPercentageDisplay(row.sr_literacy,
                                row.enrollment)}})
                            </ng-container>
                        </td>


                        <td>
                            <div [class.not-started]="getStatus(row.sr_numeracy, row.enrollment) == 'da_a_not_started'">
                                <tra [slug]="getStatus(row.sr_numeracy, row.enrollment)"></tra>
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="getStatus(row.sr_numeracy, row.enrollment) != 'da_a_not_started'">
                                {{row.sr_numeracy}}/{{row.enrollment}} ({{getDivisionPercentageDisplay(row.sr_numeracy,
                                row.enrollment)}})
                            </ng-container>
                        </td>


                        <td>
                            <div [class.not-started]="getStatus(row.cr_literacy, row.enrollment) == 'da_a_not_started'">
                                <tra [slug]="getStatus(row.cr_literacy, row.enrollment)"></tra>
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="getStatus(row.cr_literacy, row.enrollment) != 'da_a_not_started'">
                                {{row.cr_literacy}}/{{row.enrollment}} ({{getDivisionPercentageDisplay(row.cr_literacy,
                                row.enrollment)}})
                            </ng-container>
                        </td>

                        <td>
                            <div [class.not-started]="getStatus(row.cr_numeracy, row.enrollment) == 'da_a_not_started'">
                                <tra [slug]="getStatus(row.cr_numeracy, row.enrollment)"></tra>
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="getStatus(row.cr_numeracy, row.enrollment) != 'da_a_not_started'">
                                {{row.cr_numeracy}}/{{row.enrollment}} ({{getDivisionPercentageDisplay(row.cr_numeracy,
                                row.enrollment)}})
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>