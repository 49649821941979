import { Injectable } from "@angular/core";
import Typo from "typo-js";
import { Language } from "../types/bced";

export enum DictionaryLanguage {
    English = 'en',
    French = 'fr', // https://cgit.freedesktop.org/libreoffice/dictionaries/tree/fr_FR
}
export interface Alert {
    readonly word: string;
    readonly startNode: Node;
    readonly startNodeOffset: number;
    readonly endNode: Node;
    readonly endNodeOffset: number;
}

export interface Highlight {
    top: number;
    left: number;
    width: number;
    height: number;
}

export interface Offsets {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

export interface CheckOptions {
    language?: Language;
}

export interface CalculateHighlightsOptions {
    mode: 'ckeditor5';
}

export interface SpellCheckResult {
    word: string;
    top: number;
    left: number;
    width: number;
    height: number;
    // color: string;
}

@Injectable({
    providedIn: 'root'
})
export class SpellCheckService {
    private dictionary: { [key in Language]?: any } = {};
    private readonly dictionaryPath: string = 'assets/dictionaries';
    private readonly TextNodeType: number = 3;
    // following characters will be treated as separators of words (e.g. well-defined => well defined)
    private readonly IgnoreSymbols = {
        [Language.English]: new Set(['-','!','#','$','%','&','(',')','*','+',',','.','/',':',';','[',']','?','=','@','^','_','~','|','`','«','»','<','>','{','}','\"','\\']),
        [Language.French]: new Set(['-','!','#','$','%','&','(',')','*','+',',','.','/',':',';','[',']','?','=','@','^','_','~','|','`','«','»','<','>','{','}','\"','\\']),
    };

    // following characters will be replaced before spell check 
    private readonly CharacterReplacement = {
        [Language.English]:  {
            '“': '\"', // left double quotation mark (8220) -> 	quotation mark (34)
            '”': '\"', // right double quotation mark (8221) -> quotation mark (34)
            '’': '\'', // right single quotation mark (8217) -> apostrophe (39)
            '–': '-', // en dash (8211) -> hyphen-minus (45)
            '—': '-', // em dash (8212) -> hyphen-minus (45)
        },
        [Language.French]: {
            '“': '"', // left double quotation mark (8220) -> 	quotation mark (34)
            '”': '"', // right double quotation mark (8221) -> quotation mark (34)
            '’': '\'', // right single quotation mark (8217) -> apostrophe (39)
            '–': '-', // en dash (8211) -> hyphen-minus (45)
            '—': '-', // em dash (8212) -> hyphen-minus (45)
        },
    }
    private readonly BlockNodeNames = ['UL', 'OL'];
    private readonly Whitespaces = new Set([32, 160, 8194, 8195, 8200]); // UTF-16 codes

    private currentWord = '';
    private startNode = null;
    private startNodeOffset = null;
    private endNode = null;
    private endNodeOffset = null;
    private alerts: Alert[] = [];
    private highlights: Highlight[] = [];

    constructor() {
        
    }

    public init(language: Language): void {
        const dl: DictionaryLanguage = this.getDictionaryLanguage(language);
        if (this.dictionary[language] !== undefined) return;
        
        this.dictionary[language] = new Typo(dl, false, false, { 
            dictionaryPath: this.dictionaryPath, 
            asyncLoad: true,
        });
    }

    private getDictionaryLanguage(l: Language): DictionaryLanguage | null {
        switch(l) {
            case Language.English:
                return DictionaryLanguage.English;
            case Language.French:
                return DictionaryLanguage.French;
            default:
                return null;
        }
    }

    public checkWord(word: string, options?: CheckOptions): boolean {
        const { 
            language = Language.English,
        } = options || {};

        if (!this.dictionary[language]) return false;

        return this.dictionary[language].check(word);
    }

    public generateCKEditor5Highlights(node: HTMLElement, l: Language): Highlight[] {
        // CKEditor5: the element with class ".ck-content" has all CKEditor5 calculated data
        const contentNode: Element = node.querySelector('.ck-content');
        if (!contentNode) return [];
        if (!contentNode.hasChildNodes()) return [];

        // the parent container
        const ckContentRect = contentNode.getClientRects()[0];

        this.calculateHighlights(contentNode, ckContentRect, l);

        return this.highlights;
    }

    private resetAllState() {
        this.alerts = [];
        this.highlights = [];
        this.resetTempState();
    }

    private resetTempState() {
        this.currentWord = '';
        this.startNode = null;
        this.startNodeOffset = null;
        this.endNode = null;
        this.endNodeOffset = null;
    }
 
    private calculateHighlights(node: Node, containerRect: DOMRect, l: Language): void {
        this.resetAllState();
        if (!node.hasChildNodes()) return;

        for (const child of Array.from(node.childNodes)) {
            if (child.nodeName === 'P') {
                this.scanNodes(child, l);
                this.checkCurrentWord(l); // check leftover
                this.resetTempState();
            }

            if (this.BlockNodeNames.includes(child.nodeName) && child.hasChildNodes()) {
                for (const c of Array.from(child.childNodes)) {
                    this.scanNodes(c, l);
                    this.checkCurrentWord(l); // check leftover
                    this.resetTempState();
                }
            }
        }

        if (!this.alerts.length) return;

        for (const alert of this.alerts) {
            const r = document.createRange();
            r.setStart(alert.startNode, alert.startNodeOffset);
            r.setEnd(alert.endNode, alert.endNodeOffset);
            const rangeSpec = r.getBoundingClientRect(); // do not use getClientRects()[0]

            this.highlights.push({
                top: rangeSpec.top - containerRect.top + rangeSpec.height,
                left: rangeSpec.left - containerRect.left,
                width: rangeSpec.width,
                height: 2,
            });
        }
    }

    private checkCurrentWord(l: Language) {
        if (!this.currentWord.length) return;

        // console.log({ current: this.currentWord, result: this.checkWord(this.currentWord, { language: l }) });

        // skip check if the word is just digits (can't figure out how to setup this in .aff file)
        if (/\d/.test(this.currentWord) && !isNaN(parseInt(this.currentWord))) {
            return;
        }
        
        if (!this.checkWord(this.currentWord, {
            language: l,
        })) {
            this.alerts.push({
                word: this.currentWord,
                startNode: this.startNode,
                startNodeOffset: this.startNodeOffset,
                endNode: this.endNode,
                endNodeOffset: this.endNodeOffset + 1,
            });
        }
    }

    private scanNodes(node: Node, l: Language): void {
        if (node.nodeType === this.TextNodeType) {
            this.scanText(node, l);
            return;
        }

        if (node.hasChildNodes()) {
            for (const child of Array.from(node.childNodes)) {
                this.scanNodes(child, l);
            }
        }
    }

    private scanText(node: Node, l: Language): void {
        if (node.textContent.trim()) {
            this.endNode = node;
            this.endNodeOffset = 0;
        }

        for (let i = 0; i < node.textContent.length; i++) {
            const currentCharacter = this.checkReplacement(l, node.textContent[i]);

            if (this.IgnoreSymbols[l].has(currentCharacter) || this.Whitespaces.has(currentCharacter.charCodeAt(0))) {
                if (this.currentWord.trim().length > 0) {
                    this.checkCurrentWord(l);
                    this.currentWord = '';
                    this.startNode = null;
                }
            } else {
                if (!this.currentWord.trim().length) {
                    this.startNode = node;
                    this.startNodeOffset = i;
                }

                this.currentWord += currentCharacter;
                this.endNodeOffset = i;
            }
        }
    }

    private checkReplacement(l: Language, character: string): string | null {
        return this.CharacterReplacement[l][character] || character;
    }
}

