import { IMenuTabConfig } from "../../../ui-partial/menu-bar/menu-bar.component";

export interface IView<T> extends IMenuTabConfig<T> {
  link: string,
  description: string,
  hasIndicator?: boolean,
}

export enum BCFSAAssessmentSession {
  _2020_2021_FUNDAMENTAL_SKILLS_ASSESSMENT = '2020_2021_fundamental_skills_assessment'
}

export enum BCFSAProgressReportsView {
  REPORTS_LIST = 'session_reports',
  DAILY_DISTRICT_SUBMISSION = 'daily_district_submission',
  DAILY_SUBMISSION_SUMMARY = 'daily_submission_summary',
  DISTRICT_ROLL_UP = 'district_roll_up',
  ENROLMENT_EXTRACT_1300C = 'enrolment_extract_1300c',
  ENROLMENT_EXTRACT_1300D = 'enrolment_extract_1300d',
  NOT_STARTED_SR_CR = 'not_started_sr_cr',
  SCHOOL_COMPLETION = 'school_completion',
  STUDENT_LOGIN_INFORMATION = 'student_login_information',
  SCHOOL_LEVEL_DATA_REPORT = 'school_level_data_report',
  STUDENT_RESPONSE_TIME_REPORT = 'student_response_time_report',
  CONFIRMATION_CODE_REPORT = 'confirmation_codes_report',
  FSA_SAMPLE_ASSESSMENT_LOGINS = 'fsa_sample_assessment_logins',
}
export const BC_FSA_PROGRESS_REPORTS_VIEWS: Map<BCFSAProgressReportsView, IView<BCFSAProgressReportsView>> =
  new Map<BCFSAProgressReportsView, IView<BCFSAProgressReportsView>>([
    [BCFSAProgressReportsView.DAILY_DISTRICT_SUBMISSION, {
      id: BCFSAProgressReportsView.DAILY_DISTRICT_SUBMISSION,
      caption: 'sc_ddsr_title',
      link: ``,
      description: 'sc_ddsr_desc',
      disabled: false,
    }],
    [BCFSAProgressReportsView.DAILY_SUBMISSION_SUMMARY, {
      id: BCFSAProgressReportsView.DAILY_SUBMISSION_SUMMARY,
      caption: 'sc_dssr_title',
      link: ``,
      description: `sc_dssr_desc`,
      disabled: false,
    }],
    [BCFSAProgressReportsView.DISTRICT_ROLL_UP, {
      id: BCFSAProgressReportsView.DISTRICT_ROLL_UP,
      caption: 'sc_drur_title',
      link: ``,
      description: `sc_drur_desc`,
      disabled: false
    }],
    [BCFSAProgressReportsView.ENROLMENT_EXTRACT_1300C, {
      id: BCFSAProgressReportsView.ENROLMENT_EXTRACT_1300C,
      caption: 'sc_ses_1701_title',
      link: ``,
      description: `sc_ses_1701_desc`,
      disabled: false
    }],
    [BCFSAProgressReportsView.ENROLMENT_EXTRACT_1300D, {
      id: BCFSAProgressReportsView.ENROLMENT_EXTRACT_1300D,
      caption: 'sc_spss_title',
      link: ``,
      description: `sc_spss_desc`,
      disabled: false
    }],
    [BCFSAProgressReportsView.NOT_STARTED_SR_CR, {
      id: BCFSAProgressReportsView.NOT_STARTED_SR_CR,
      caption: 'da_sr_sns_title',
      link: ``,
      description: `da_sr_sns_desc`,
      disabled: false,
    }],
    [BCFSAProgressReportsView.SCHOOL_COMPLETION, {
      id: BCFSAProgressReportsView.SCHOOL_COMPLETION,
      caption: 'sc_scr_title',
      link: ``,
      description: `sa_sr_desc_full`,
      disabled: false
    }],
    [BCFSAProgressReportsView.STUDENT_LOGIN_INFORMATION, {
      id: BCFSAProgressReportsView.STUDENT_LOGIN_INFORMATION,
      caption: 'sc_slir_title',
      link: ``,
      description: 'sc_slir_desc',
      disabled: false
    }],
    [BCFSAProgressReportsView.SCHOOL_LEVEL_DATA_REPORT, {
      id: BCFSAProgressReportsView.SCHOOL_LEVEL_DATA_REPORT,
      caption: 'sc_sldr_title',
      link: ``,
      description: `sc_sldr_desc`,
      disabled: false,
    }],
    [BCFSAProgressReportsView.STUDENT_RESPONSE_TIME_REPORT, {
      id: BCFSAProgressReportsView.STUDENT_RESPONSE_TIME_REPORT,
      caption: 'sc_srtr_title',
      link: ``,
      description: `sc_srtr_desc`,
      disabled: false,
    }],
    [BCFSAProgressReportsView.CONFIRMATION_CODE_REPORT, {
      id: BCFSAProgressReportsView.CONFIRMATION_CODE_REPORT,
      caption: 'sc_ccca_title',
      link: ``,
      description: `sc_ccca_desc`,
      disabled: false,
    }],
    [BCFSAProgressReportsView.FSA_SAMPLE_ASSESSMENT_LOGINS, {
      id: BCFSAProgressReportsView.FSA_SAMPLE_ASSESSMENT_LOGINS,
      caption: 'sc_fsal_title',
      link: ``,
      description: 'sc_fsal_desc',
      disabled: false,
    }],
  ]);
