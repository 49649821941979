import { IPanelLink } from "./type";
import { BCFSAMinistryAdminView, BC_FSA_MINISTRY_ADMIN_VIEWS } from '../../../ui-ministryadmin/view-ministryadmin-dashboard/data/views' 

const items: IPanelLink[] = BC_FSA_MINISTRY_ADMIN_VIEWS.map(view => {
  // if(view.id == BCFSAMinistryAdminView.MONITORING_MARKING) {
  //   console.log('BCFSAMinistryAdminView.MONITORING_MARKING override')
  //   return {
  //     caption: view.caption,
  //     routerLink: `/en/mrkg-coord/dashboard`,
  //     iconUrl: view.imgUrl,
  //     disabled: !!view.disabled
  //   }
  // }
  return {
    id: view.id,
    caption: view.caption,
    routerLink: `/en/ministry-admin/bc-fsa/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled
  }
}) 

export default items