import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import moment, { Moment } from 'moment';
import { AuthService } from 'src/app/api/auth.service';
import { PaginatedRows, Pagination } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcReportsService } from 'src/app/bc-reports/bc-reports.service';
import { IFsaSampleAssessmentLoginReportRow } from 'src/app/bc-reports/types';
import { BcPaginatedTableComponent, IColumnHeading } from '../bc-paginated-table/bc-paginated-table.component';
import { sum } from 'lodash';

@Component({
  selector: 'fsa-sample-assessment-login-report',
  templateUrl: './fsa-sample-assessment-login-report.component.html',
  styleUrls: ['./fsa-sample-assessment-login-report.component.scss']
})
export class FsaSampleAssessmentLoginReportComponent implements OnInit, OnChanges {

  @ViewChild(BcPaginatedTableComponent) table: BcPaginatedTableComponent<IFsaSampleAssessmentLoginReportRow>;

  @Input() testWindow: TestWindow;

  start_date: string;
  end_date: string;

  pagination: Pagination;

  columnHeadings: IColumnHeading[] = [];

  tableColumnWidths: number[] = [300, 100];

  constructor(
    private auth: AuthService,
    private bcReports: BcReportsService,
  ) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.testWindow) {
      this.start_date = moment(this.testWindow.dateStart, this.auth.getTimezone()).format('YYYY-MM-DD');
      this.end_date = moment(this.testWindow.dateEnd, this.auth.getTimezone()).format('YYYY-MM-DD');
      this.onDateChange();
    }
  }

  getRows = async (pagination: Pagination): Promise<PaginatedRows<IFsaSampleAssessmentLoginReportRow>> => {
    if (this.start_date && this.end_date) {
      const [start_date_m, end_date_m] = this.getDateRange();
      const rows = await this.bcReports.getFsaSampleAssessmentLoginReport(pagination, {
        start_date: start_date_m.utc().format("YYYY-MM-DD HH:mm"),
        end_date: end_date_m.utc().format("YYYY-MM-DD HH:mm"),
        time_zone: this.auth.getTimezone()
      });

      return {
        count: rows.length,
        data: rows,
      };
    } else {
      return {
        count: 0,
        data: [],
      }
    }
  }

  getDisplay = (by: string, row: IFsaSampleAssessmentLoginReportRow): string => {
    switch (by) {
      case 'assessment': return row.assessment;
      case 'language': return row.language;
      default:
        if (row.logins[by] != null) {
          return row.logins[by].toString();
        }
        return '0';
    }
  }

  onDateChange() {
    this.columnHeadings = this.makeColumnHeadings();
    this.tableColumnWidths = this.makeColumnWidths();
    if (this.table) {
      this.table.updateTable();
    }
  }

  private getDateRange(): [Moment, Moment] {
    const range: [Moment, Moment] = [
      moment.tz(this.start_date, this.auth.getTimezone()).startOf('day'),
      moment.tz(this.end_date, this.auth.getTimezone()).endOf('day'),
    ];
    return range;
  }

  private makeColumnHeadings(): IColumnHeading[] {
    const columnHeadings: IColumnHeading[] = [
      { heading: 'Assessment', sortBy: 'assessment', filterDisabled: true, sortDisabled: true, },
      { heading: 'Language', sortBy: 'language', filterDisabled: true, sortDisabled: true, },
    ];
    const [start_date_m, end_date_m] = this.getDateRange();
    const numDays = end_date_m.diff(start_date_m, 'days') + 1;
    for (let i = 0; i < numDays; i++) {
      const heading = start_date_m.format('MMM. D');
      const sortBy = start_date_m.format('YYYY-MM-DD');
      columnHeadings.push({
        heading,
        sortBy,
        filterDisabled: true,
        sortDisabled: true,
      });
      start_date_m.add(1, 'days');
    }

    return columnHeadings;
  }

  private makeColumnWidths(): number[] {
    const columnWidths = [300, 100];
    const [start_date_m, end_date_m] = this.getDateRange();
    const numDays = end_date_m.diff(start_date_m, 'days') + 1;
    for (let i = 0; i < numDays; i++) {
      columnWidths.push(50);
    }
    columnWidths.push(200);
    return columnWidths;
  }

  export() {
    const [start_date_m, end_date_m] = this.getDateRange();
    this.bcReports.exportFsaSampleAssessmentLogins({
      test_window_id: this.testWindow.id,
      start_date: start_date_m.utc().format("YYYY-MM-DD HH:mm"),
        end_date: end_date_m.utc().format("YYYY-MM-DD HH:mm"),
      pagination: this.pagination,
    })
  }

}
