import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

enum RectState {
  idle = 'idle',
  hovering = 'hovering',
  drawing = 'drawing'
} 

@Component({
  selector: 'annotation-tools',
  templateUrl: './annotation-tools.component.html',
  styleUrls: ['./annotation-tools.component.scss']
})
export class AnnotationToolsComponent implements OnInit {

  

  @Input() enableWrite:boolean = false;
  @Input() scanID:number;
  @Input() activePage:number;
  @Input() currentScroll:number;
  @Output() updateAnnotations:EventEmitter<any> = new EventEmitter();

  @ViewChild('AnnotationContainer', { read: ElementRef }) annotationContainer:ElementRef;

  constructor() { }

  @Input() annotations:any[] = [];

  currentRectState:RectState = RectState.idle;

  ngOnInit(): void {

    
  }

  restartRectState(event) {
    event.stopPropagation();
    if(this.currentRectState == RectState.idle){
      this.currentRectState = RectState.hovering;
    }
    else {
      this.currentRectState = RectState.idle;
    }

  }

  mouseLeaveContainer(event) {
    if(this.currentRectState == RectState.drawing) {
      this.currentRectState = RectState.hovering;
      this.annotations.pop();
    }

  }

  mouseDownContainer(event) {
    if(this.currentRectState == RectState.hovering) {
      this.annotations.push({
        type:'hide',
        x: event.offsetX,
        y: event.offsetY,
        top: (event.offsetY / event.target.offsetHeight) * 100,
        left:(event.offsetX / event.target.offsetWidth) * 100,
        width: 0,
        height: 0
      });
      this.currentRectState = RectState.drawing;
    }
    
  }

  mouseMoveContainer(event) {
    if(this.currentRectState == RectState.drawing) {
      let activeAnnotation = this.activeAnnotation();
      if(!activeAnnotation) return;


      activeAnnotation.width = ((event.offsetX -  activeAnnotation.x) / event.target.offsetWidth) * 100;
      activeAnnotation.height = ((event.offsetY - activeAnnotation.y) / event.target.offsetHeight) * 100;
    }
  }

  mouseUpContainer(event) {
    if(this.currentRectState != RectState.idle) {
      this.currentRectState = RectState.hovering;
      this.updateAnnotations.emit({scanID:this.scanID, annotations:this.annotations});
    }
   
  }

  activeAnnotation() {
    if(this.annotations.length > 0) {
      return this.annotations[this.annotations.length - 1];
    }

    return null;
  }

  undoAnnotation(event) {
    event.stopPropagation();
    this.annotations.pop();
  }

  getToolbarTop() {
    if(!this.annotationContainer) return 16;

    if(this.scanID == this.activePage) {
      return this.currentScroll - this.annotationContainer.nativeElement.scrollTop + 16;
    }
    else {
      return -1000000;
    }
  }
}
