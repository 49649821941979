export enum UserGroupType {
    SCHOOL_BOARDS = 'districts',
    SCHOOLS = 'schools',
    TEACHERS = 'teachers',
    SCHOOL_CLASSES = 'classes',
    STUDENTS = 'students',
}

export enum UserGroupType {
    Registered = 'Registered',
    BoardCompletedTech = 'BoardCompletedTech',
    CompletedTech = 'CompletedTech',
    CompletedSample = 'CompletedSample',
    CompletedSampleAndTech = 'CompletedSampleAndTech',
    StartedOper = 'StartedOper',
    CompletedOper = 'CompletedOper',
    StuReportsAccessed = 'StuReportsAccessed',
}


export const userGroupTypes = [
    { 
        id: UserGroupType.SCHOOL_BOARDS, 
        caption:'Boards',
        apiPath:'registrations/boards',
     },
    { 
        id: UserGroupType.SCHOOLS, 
        caption:'Schools',
        apiPath:'registrations/schools',
     },
    { 
        id: UserGroupType.TEACHERS, 
        caption:'Teachers', 
        apiPath:'registrations/teachers',
    }, 
    { 
        id: UserGroupType.SCHOOL_CLASSES, 
        caption:'Classes',
        apiPath:'registrations/class',
     },
    { 
        id: UserGroupType.STUDENTS, 
        caption:'Students',
        apiPath:'registrations/students',
     },
]

// id is used as an api query slug
export const userGroupSummaryColumns = [
    {id:UserGroupType.Registered, caption:'Registered'},
    {id:UserGroupType.BoardCompletedTech, caption:'Completed Brd. Tech. Readiness'},
    {id:UserGroupType.CompletedTech, caption:'Completed Sch. Techn. Readiness'},
    {id:UserGroupType.CompletedSample, caption:'Completed Sample Assessment'},
    {id:UserGroupType.CompletedSampleAndTech, caption:'Onboarded (Completed Tech and Sample)'},
    {id:UserGroupType.StartedOper, caption:'(Started) Operational Assessment'},
    {id:UserGroupType.CompletedOper, caption:'(Completed) Operational Assessment'},
    {id:UserGroupType.StuReportsAccessed, caption:'Student Reports Accessed'},
]

export const userGroupSummaryTooltips = {
    [UserGroupType.SCHOOL_BOARDS]: {
        [UserGroupType.Registered]: null,
        [UserGroupType.CompletedTech]: 'Counts all boards for which at least one school has indicated that they have completed their technical readiness.',
        [UserGroupType.CompletedSample]: 'Counts all boards for which at least one class has completed a sample assessment.',
        [UserGroupType.CompletedSampleAndTech]: null,
        [UserGroupType.StartedOper]: null,
        [UserGroupType.CompletedOper]: null,
        [UserGroupType.StuReportsAccessed]: null,
    },
    [UserGroupType.SCHOOLS]: {
        [UserGroupType.Registered]: null,
        [UserGroupType.CompletedTech]: null,
        [UserGroupType.CompletedSample]: 'Counts all schools for which at least one class has completed a sample assessment.',
        [UserGroupType.CompletedSampleAndTech]: null,
        [UserGroupType.StartedOper]: null,
        [UserGroupType.CompletedOper]: null,
        [UserGroupType.StuReportsAccessed]: null,
    },
    [UserGroupType.TEACHERS]: {
        [UserGroupType.Registered]: null,
        [UserGroupType.CompletedTech]: 'Counts all teachers in which their school has indicated that they have completed their technical readiness.',
        [UserGroupType.CompletedSample]: null,
        [UserGroupType.CompletedSampleAndTech]: null,
        [UserGroupType.StartedOper]: null,
        [UserGroupType.CompletedOper]: null,
        [UserGroupType.StuReportsAccessed]: null,
    },
    [UserGroupType.SCHOOL_CLASSES]: {
        [UserGroupType.Registered]: null,
        [UserGroupType.CompletedTech]: 'Counts all classes in which their school has indicated that they have completed their technical readiness.',
        [UserGroupType.CompletedSample]: null,
        [UserGroupType.CompletedSampleAndTech]: null,
        [UserGroupType.StartedOper]: null,
        [UserGroupType.CompletedOper]: null,
        [UserGroupType.StuReportsAccessed]: null,
    },
    [UserGroupType.STUDENTS]: {
        [UserGroupType.Registered]: null,
        [UserGroupType.CompletedTech]: 'Counts all students in which their school has indicated that they have completed their technical readiness.',
        [UserGroupType.CompletedSample]: null,
        [UserGroupType.CompletedSampleAndTech]: null,
        [UserGroupType.StartedOper]: null,
        [UserGroupType.CompletedOper]: null,
        [UserGroupType.StuReportsAccessed]: null,
    }
}