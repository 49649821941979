<div *ngIf="isDisplayStyleDropdown()" class="select select-dropdown" [class.icon-invert]="isInverted()">
  <select type="dropdown" [formControl]="dropdownSelector" class="dropdown-validate"
    [class.icon-invert]="isInverted()" 
    [class.drop-down-invert]="isInverted()" 
    [class.not-option]="isInverted()"
    [class.is-incorrect]="isLocked && !mcqRenderer.isDropdownCorrect(dropdownSelector)"
    [class.is-correct]="isLocked && mcqRenderer.isDropdownCorrect(dropdownSelector)"
    [style.font-size.em]="getDropdownOptionFontSize()"
    [style.text-align]="element.isDropdownOptionCentered? 'center': ''"
    [class.is-high-contrast]="textToSpeech.isHiContrast"
    (change)="onSelectOptionChange()"
  >
      <ng-container [ngSwitch]="!!hasDefaultDropDownText()">
        <option *ngSwitchCase="true" [class.drop-down-invert]="isInverted()" [class.an-option]="isInverted()" [value]="undefined" [disabled]="true" >
          {{element.defaultDropdownText}}
        </option>
        <option *ngSwitchCase="false" [class.drop-down-invert]="isInverted()" [class.an-option]="isInverted()" [value]="undefined"></option>
      </ng-container>
      <option 
        *ngFor="let optionElement of element.options; let i = index" 
        [value]="i" 
        [id]="'Option'+i+'caption'" 
        [class.drop-down-invert]="isInverted()"
        [class.an-option]="isInverted()"
        [style.font-size.em]="getDropdownOptionFontSize()"
      >
        {{optionElement.content}}
        <div *ngIf="optionElement.voiceover && optionElement.voiceover.url">
          <render-audio 
            [url]="optionElement.voiceover.url" 
            [trigger]="mcqRenderer.getClickTrigger(optionElement)" 
            [isTriggerDisabled]="!mcqRenderer.isVoiceoverEnabled()"
          ></render-audio>
        </div>
      </option>
  </select>
</div>
<div [style.width.em]="element.maxCustomDDWidth ? element.maxCustomDDWidth : ''" 
     [style.max-width.em]="element.maxCustomDDWidth ? element.maxCustomDDWidth : ''" class="custom-dropdown" *ngIf="isDisplayStyleCustomDropdown()">
  <button style="font-size:1em;width:100%;display:flex;flex-direction: row-reverse;justify-content:space-between;align-items: center;padding: 0em 1em;" class="custom-dropdown-button" 
    (click)="isCustomDropdownOn ? closeCDD() : openCDD()">
    <div>
      <i *ngIf="isCustomDropdownOn" class="fas fa-chevron-up" name="dropdown-icon-up"></i>
      <i *ngIf="!isCustomDropdownOn" class="fas fa-chevron-down" name="dropdown-icon-down"></i>
    </div>
    <div *ngFor="let optionElement of getCurrentDropDownAnswers()">
      <mcq-option [optionElement]="optionElement" [questionState]="questionState" [alignLeft]="element.isTextLeftAligned"></mcq-option>   
    </div>
  </button>
  <div style="position:relative;">
    <div class="dropdown-option-container">
      <div class="dropdown-option" [class.droplist-closed]="!isCustomDropdownOn" *ngFor="let optionElement of element.options" [style.text-align]="element.isTextLeftAligned ? 'left' : 'center'" (click)="mcqRenderer.selectOptionManual(optionElement);closeCDD()" tabindex="0" (keydown.enter)="mcqRenderer.selectOptionManual(optionElement);closeCDD()">
        <mcq-option [optionElement]="optionElement" [questionState]="questionState" [alignLeft]="element.isTextLeftAligned"></mcq-option>   
        <div *ngIf="getVoiceURL(optionElement)">
          <render-audio 
            [url]="getVoiceURL(optionElement)" 
            [trigger]="mcqRenderer.getClickTrigger(optionElement)" 
            [isTriggerDisabled]="!mcqRenderer.isVoiceoverEnabled()"
          ></render-audio>
        </div>
      </div>
    </div>
  </div>
</div>
<div 
  *ngIf="isDisplayStyleLikert()" 
  class="likert-buttons"
  [class.is-colored]="isColoredLikert()"
>
  <div class="field has-addons">
    <p class="control" *ngFor="let optionElement of element.options; let optionIndex = index">
      <button
        class="button " 
        aria-current="option"
        [attr.aria-label]="'Option ' + (optionIndex + 1)" 
        (click)="mcqRenderer.selectOptionManual(optionElement)" 
        [class.option-1]="optionIndex==0"
        [class.option-2]="optionIndex==1"
        [class.option-3]="optionIndex==2"
        [class.option-4]="optionIndex==3"
        [class.option-5]="optionIndex==4"
        [ngSwitch]="!!mcqRenderer.isSelected(optionElement)"
      >
        <span *ngIf="optionIndex == 0" [class.black-text]="element.avoidTextColorChangeLikert && !isHighContrast()">{{optionElement.caption}}</span>
        <i *ngSwitchCase="true" class="far fa-dot-circle radio-icon"></i>
        <i *ngSwitchCase="false" class="far fa-circle radio-icon"></i>
        <span *ngIf="optionIndex == element.options.length - 1" [class.black-text]="element.avoidTextColorChangeLikert && !isHighContrast()">{{optionElement.caption}}</span>
        
        <div *ngIf="optionIndex == 0" class="tail-arrow" style="position:absolute;left: 0em;top:0em;bottom:0em;color: #ed2826;">
          <div style="position:absolute;right: -0.3em;top: -2.25em;bottom:0em;display:flex;">
              <i class="fas fa-caret-left" style=" font-size: 7em; "></i>
          </div>
        </div>

        <div *ngIf="optionIndex == element.options.length - 1"  class="tail-arrow" style="position:absolute;right:0em;top:0em;bottom:0em;color: #46ae3d;">
          <div style="position:absolute;left:-0.3em;top: -2.25em;bottom:0em;display:flex;">
              <i class="fas fa-caret-right" style=" font-size: 7em; "></i>
          </div>
      </div>


      </button>
    </p>
  </div>
</div>

<!-- [style.height.em]="this.element.mcqAreaHeight" -->
<div *ngIf="!isDisplayStyleDropdown() && !isDisplayStyleLikert() && !isDisplayStyleCustomDropdown()" >
  <div *ngIf="showSelectAllMessage()" class="small-instruction">
    <tra slug="lbl_select_all_apply"></tra>
  </div>
  
  <div 
    class="option-container"
    [class.is-vertical]="isDisplayStyleVertical()"
    [class.is-horizontal]="isDisplayStyleHorizontal()"
    [class.is-wraparound]="isDisplayStyleWrapAround()"
    [class.is-limitted-width]="element.isLimittedWidth"
    [class.is-grid]="isDisplayStyleGrid()"
    [class.is-locked]="mcqRenderer.isInputDisabled()"
    [class.is-absolute-children]="isDisplayStyleFreeform()"
    [class.has-frame]="isDisplayStyleFreeform() && element.hasFrame"
    [class.is-no-option-indicator]="element.isOptionLabelsDisabled && element.isRadioDisabled"
    [style.display]="!isDisplayStyleBubble() ? 'static' : 'relative'"
    [style.position]="isDisplayStyleFreeform() && element.staticOptionContainerPosition ? 'static' : 'relative'"
    [ngStyle]="getOptionsContainerStyle()"
  >
    
    <div 
      *ngFor="let optionElement of element.options; let index = index"
      class="option-button-container" 
      [ngStyle]="getButtonContainerStyle(optionElement)"
    >

      <button 
        tabindex="0"
        class="option "
        [class.is-high-contrast]="textToSpeech.isHiContrast"
        (click)="mcqRenderer.selectOptionManual(optionElement)" 
        [ngStyle]="getButtonOptionStyle(optionElement)"
        [class.is-active]="mcqRenderer.isSelected(optionElement)"
        [class.is-incorrect]="mcqRenderer.isSelected(optionElement) && isLocked && !mcqRenderer.isMCQCorrect(optionElement,index)"
        [class.is-correct]="mcqRenderer.isSelected(optionElement) && isLocked && mcqRenderer.isMCQCorrect(optionElement,index)"
        [class.is-missed]="!mcqRenderer.isSelected(optionElement) && isLocked && mcqRenderer.isMCQCorrect(optionElement,index)"
        [class.is-selected-not-obvious] = "element.isHideOptionActivated"
        [class.is-bg-clear]="element.isBgClear"
        [class.is-bg-clear-always]="element.isBgClearAlways"
        [class.is-polaroid-style]="element.isPolaroidStyle"
        [class.is-borderless]="element.isNoThickBorder"
        [class.is-contents-centered]="element.isContentsCentered"
        [class.is-contents-vert-pad]="!element.isContentsVertPaddDisabled"
        [class.is-custom-hover-effect]="element.isCustomHoverEffect"
      >
        <div class="option-content" >
            <div 
              [style.display]="'flex'"
              [style.justify-content]="element.isContentsCentered ? 'center' : 'flex-start'"
              [style.align-items]="element.isContentsCenteredVertically ? 'center' : 'flex-start'"
              [style.overflow]="'visible'"
            >
            
              <!--<div [ngSwitch]="optionElement.elementType" >
                <div *ngSwitchCase="'text'">
                  <div *ngIf="isAdvTextEl(optionElement)" >
                    <element-render-text [element]="optionElement"></element-render-text>
                  </div>
                  <div *ngIf="!isAdvTextEl(optionElement)" class="adv-text-container">
                    <markdown-inline [isInvisible]="optionElement.isInvisible" [input]="optionElement.content"></markdown-inline>
                  </div>
                </div>
                <div *ngSwitchCase="'audio'">
                  <element-render-audio [element]="optionElement" [questionState]="questionState"></element-render-audio>
                </div>
                <div *ngSwitchCase="'table'">
                  <element-render-table [element]="optionElement"></element-render-table>
                </div>
                <div *ngSwitchCase="'image'">
                  <render-image *ngIf="isBasicImg(optionElement)" [element]="optionElement"></render-image>
                  <element-render-image *ngIf="!isBasicImg(optionElement)" [element]="optionElement"></element-render-image>
                </div>
                <div *ngSwitchCase="'math'">
                  <element-render-math [element]="optionElement" [changeCounter]="optionElement._changeCounter"></element-render-math>
                </div>
                <div *ngSwitchCase="'frame'">
                  <element-render-frame [element]="optionElement"></element-render-frame>
                </div>
              </div>-->
              <mcq-option [optionElement]="optionElement" [questionState]="questionState" [alignLeft]="true"></mcq-option>   
              <div *ngIf="optionElement.voiceover && optionElement.voiceover.url">
                <render-audio 
                  [url]="optionElement.voiceover.url" 
                  [trigger]="mcqRenderer.getClickTrigger(optionElement)" 
                  [isTriggerDisabled]="!mcqRenderer.isVoiceoverEnabled()"
                ></render-audio>
              </div>
              <div *ngIf="!this.element.isOptionLabelsDisabled || !this.element.isRadioDisabled" 
              [class.no-bg]="this.element.isRadioBackgroundHidden && (this.element.isRadioBackgroundHiddenAlways || !mcqRenderer.isSelected(optionElement))" 
              [ngStyle]="optionIndicatorStyle()"
              class="option-indicator" >
                <div *ngIf="!this.element.isOptionLabelsDisabled" class="radio-label">
                  {{buttonLabels[index]}}
                </div>
                <div *ngIf="!this.element.isRadioDisabled" class="radio-container">
                  <div class="radio-outer" [class.is-square]="isCheckbox()"></div>
                  <ng-container *ngIf="isCheckbox()">
                    <i class="fa fa-check icon radio-inner checkmark"></i>
                  </ng-container>
                  <ng-container *ngIf="!isCheckbox()">
                    <div class="radio-inner"></div>
                  </ng-container>
                </div>
              </div>
            </div> 

            <div class="option-link" 
              (click)="stopEventProp($event)" 
              [style.display]="'flex'" 
              [style.flex-direction]="'row'" 
              [style.justify-content]="element.isContentsCentered? 'center' : 'flex-start'" 
              [style.align-items]="element.isContentsCenteredVertically ? 'center' : 'flex-start'" *ngIf="this.element.isCanLink">
              <element-render-text-link *ngIf="optionElement.link" [element]="optionElement.link" ></element-render-text-link>    
            </div> 
        </div>
        <div 
          class="hard-selection"
          [class.is-enabled]="element.isBorderColor || element.isPolaroidStyle"
          [class.is-offset]="element.isOffsetSelectionBorder"
          [style.border-color]="getBorderColor()"
        ></div>
      </button>

      <div *ngIf="isLocked && isDisplayStyleVertical() && optionElement.isCorrect" style="font-size:0.8em; padding-left: 2em; margin-bottom:1em;">
        <!-- <tra slug="lbl_correct_answer"></tra> : <b>{{buttonLabels[index]}}</b> -->
      </div>
      
    </div>
    
  </div>
  <div *ngIf="mcqRenderer.isMaxAnswerMsgShown" (click)="mcqRenderer.turnMaxMsgOff()" class="mcq-max-selected-msg">
    <markdown 
        class="markdown is-condensed" 
        [data]="mcqRenderer.getMaxMsgText()" 
        ngPreserveWhitespaces
    ></markdown>
    <div *ngIf="!hideCloseMsgInfo()" class="click-dismiss-msg" >
      <markdown 
        class="markdown is-condensed" 
        [data]="mcqRenderer.getClickToDismissMsg()" 
        ngPreserveWhitespaces
      ></markdown>
    </div>
  </div>
</div>
<div *ngIf="element.enableResetButton" [style.margin-left.em]="element.resetBtnX" [style.margin-top.em]="element.resetBtnY">
  <button style="font-size:1em;width:100%;display:flex;flex-direction: row-reverse;justify-content:space-between;align-items: center;padding: 0em 1em;cursor:pointer" 
          [style.font-size.em]="element.resetBtnWidth"
          [style.border-radius.em]="element.resetBtnBorderRadius"
          [style.border-color]="element.resetBtnBorderColour"
          [style.color]="element.resetBtnTextColour"
          [style.background-color]="element.resetBtnBackGroundColour"
          class="custom-dropdown-button"
          (click)="resetCount()">
    {{getRestBtnText()}}
  </button>
</div>
