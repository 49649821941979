import JSZip from "jszip";
import FileSaver from 'file-saver';

export async function downloadAsZip(fileName: string, blobs: {name: string, blob: Blob}[]) {
    const zip = new JSZip();

    blobs
        .filter(b => b !== null)
        .forEach((item) => {
            if (item) {
                zip.file(`School Level Data Report - ${item.name}.xlsx`, item.blob);
            }
        });

    const zipFile = await zip.generateAsync({ type: "blob" });

    return FileSaver.saveAs(zipFile, fileName);
}

export async function getBlobs(urls: {name: string, url: string}[]): Promise<({name: string, blob: Blob} | null)[]> {
    return Promise.all(urls.map(item => getFileBlob(item.name, item.url, true)));
}

export async function getFileBlob(name: string, url: string, isErrorSlienced: boolean = true): Promise<{name: string, blob: Blob} | null> {
    try {
        const r = await fetch(url);

        if (r.status === 200) {
            const blob = await r.blob();
            return {
                name,
                blob
            };
        }

        if (isErrorSlienced) {
            return null;
        } else {
            throw new Error(`Error ${r.status}: ${r.statusText}`);
        }
    } catch(e) {
        if (isErrorSlienced) {
            return null;
        } else {
            throw e;
        }
    }
}

export function sliceIntoChunks<Type>(arr: Type[], chunkSize: number): Type[][] {
    const res: Type[][] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk: Type[] = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

export function calculateRatio(numerator: number, denominator: number): number {
    try {
        if (denominator === 0) return 0;
        return numerator / denominator;
    } catch (error) {
        throw error;
    }
}

// e.g. test_windows.window_code => 202409
export function parseWindowCode(code: string): { year: number, month: number } {
    const year = +code.slice(0, 4);
    const month = +code.slice(4);

    return {
        year,
        month,
    }
}

export function downloadFile(href: string, fileName: string): void {
    const element = document.createElement("a");

    element.href = href;
    element.download = fileName;
    element.click();
    element.remove();
}