<ngx-charts-bar-vertical
        [view]="view"
        [scheme]="colorScheme"
        [results]="data"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="labelX"
        [yAxisLabel]="labelY"
        [showDataLabel]="showDataLabel"
        [tooltipDisabled]="toolTipDisabled"
        (select)="onSelect($event)">
</ngx-charts-bar-vertical>
