import {IMarkingItem} from './data';

enum ItemType {
    SYSTEM = 'SYSTEM', // comes from the system itself, authoring => test taking.
    SCAN = 'SCAN',
}

export const markerItems: IMarkingItem[] = [

];
