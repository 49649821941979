<table class="results-table" [ngStyle]="getStyle()" [class.is-hi-contrast]="isHiContrast()">
    <tr>
        <th colspan="3"><tra slug="tr_results_cr_questions"></tra></th>
    </tr>
    <tr>
        <td colspan="3">
            <tra [slug]="getResultScoreSlug()"></tra>&nbsp;<tra class="tr_results_cr_score" [slug]="getResultCRScoreSlug()"></tra>
        </td>
    </tr>
    <tr>
        <td>
            <tra slug="tr_results_total_questions"></tra>
        </td>
        <td>
            {{numCRQuestions}}
        </td>
        <td>
        </td>
    </tr>
</table>