<div class="header-layout" *ngIf="phantom"></div>
<ng-container *ngIf="!phantom">
    <div class="header-layout dont-print">
        <div class="view-header">
            <span>
                <tra [slug]="getTitle()"></tra>
            </span>
            <img [src]="imgSrc">
        </div>
        <div >
            <div *ngIf="isMinistryAdmin() && showSchoolSelector" class="school-selector">
                <label>
                    <tra slug="sa_aa_district"></tra>
                </label>
                <bc-search-dropdown 
                    [disabled]="isLoading || isDataLoading || disableDistrict" 
                    [debounce]="isInputDebounced"
                    [inputValue]="selectedDistrict" 
                    [itemDisplayFn]="getDistrictDisplay" 
                    [filterFn]="getFilterDistrictsFn()"
                    (itemSelected)="districtSelected($event)"
                >
                </bc-search-dropdown>

                <button *ngFor="let config of buttons" class="extra-button" [style]="config.style"
                    [id]="config.buttonId" (click)="extraButtonClicked(config.buttonId)" [disabled]="disableDistrict">
                    {{getExtraButtonTitle(config)}}
                </button>
            </div>
            <div class="school-selector" *ngIf="showSchoolSelector">
                <label>
                    <tra slug="sa_aa_school"></tra>
                </label>
                <bc-search-dropdown 
                    [disabled]="isLoading || isDataLoading || disableSchool" 
                    [debounce]="isInputDebounced"
                    [inputValue]="selectedSchool" 
                    [itemDisplayFn]="getSchoolDisplay" 
                    [filterFn]="getFilterSchoolsFn()"
                    (itemSelected)="schoolSelected($event)"
                >
                </bc-search-dropdown>

                <button *ngFor="let config of buttons" class="extra-button" [style]="config.style"
                    [id]="config.buttonId" (click)="extraButtonClicked(config.buttonId)" [disabled]="disableSchool">
                    {{getExtraButtonTitle(config)}}
                </button>
            </div>

            <div 
                class="assessment-session-selector" 
                [style.display]="getHideDropdownFlag() ? 'none' : ''"
            >
                <label>
                    <tra slug="sa_aa_assessment_session"></tra>
                </label>
                <select [(ngModel)]="selectedTestWindow" (change)="onSelectedTestWindowChange()" [disabled]="isLoading || isDataLoading">
                    <option *ngFor="let testWindow of testWindows" [ngValue]="testWindow">
                        {{getTestWindowDisplay(testWindow)}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <hr [style.display]="getHideDropdownFlag() ? 'none' : ''">
</ng-container>