export enum ScoringLeaderView {
  SUPERVISORS = 'supervisors',
  SCORERS = 'scorers',
  ITEMS = 'items',
  TRAINING = 'training',
  // MONIT_VALIDITY = 'monit-validity',
  // MONIT_TRAINING = 'monit-training',
}

export const SCORING_LEADER_VIEWS = [
    {
      id:ScoringLeaderView.SUPERVISORS, 
      caption: ('Supervisors'), 
      imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/online/1610324506178/online.png',
      description: ('Upload and manage supervisor accounts.'), //'',
      sideStats: [
        {caption:'{{SUPR_ACCT_INVIT}} invited'},
        {caption:'{{SUPR_ACCT}} confirmed'},
      ]
    },
    {
      id:ScoringLeaderView.SCORERS, 
      caption: ('Scorers'),
      imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/project/1610324458874/project.png',
      description: ('Upload, manage, and monitor scorers.'), 
      sideStats: [
        {caption:'{{SCOR_ACCT_INVIT}} invited'},
        {caption:'{{SCOR_ACCT}} confirmed'},
        {caption:'{{SCOR_TRAIN}} are trained'},
        {caption:'{{SCOR_SCOR}} are scoring'},
        {caption:'{{SCOR_BATCHES}} batches claimed'},
      ]
    },
    {
      id:ScoringLeaderView.ITEMS, 
      caption: ('Items and Responses'),
      imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/algorithms/1610324520257/algorithms.png',
      description: ('View, configure, and monitor assessment items and associated student responses (including the selection of validity responses).'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
      sideStats: [
        {caption:'{{ITEMS}} items'},
        {caption:'{{SCHOOLS}} schools'},
        {caption:'{{STUDENTS}} students'},
        {caption:'{{RESPONSES}} responses'},
        {caption:'{{RESPONSES_SCORED}} scored'},
        {caption:'{{RESPONSE_FLAGS}} new flags'},
      ]
    },

    {
      id:ScoringLeaderView.TRAINING, 
      caption: ('Training Materials'),
      imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/authorization/1610324576927/authorization.png',
      description: ('View and configure training materials.') // '67 students are currently enrolled. Click here to validated their information and re-assign them to different teachers.',
    },
    // {
    //   id:ScoringLeaderView.MONIT_TRAINING, 
    //   caption: ('g9_students'), //'Students',
    //   imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/students/1602128010278/students.png',
    //   description: ('txt_students') // '67 students are currently enrolled. Click here to validated their information and re-assign them to different teachers.',
    // },
]