<!-- [view]="view" -->
<ngx-charts-line-chart
        [scheme]="colorScheme"
        [legend]="legend"
        legendPosition="right"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xScaleMin] = "2015"
        [xScaleMax] = "2021"
        [xAxisLabel]="labelX"
        [yAxisLabel]="labelY"
        [timeline]="timeline"
        [results]="data"
        [yAxisTicks]="yAxisTicks"
        [trimYAxisTicks]="trimYAxisTicks"
        [trimXAxisTicks]="trimXAxisTicks"
        [yScaleMax]="100"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
>
</ngx-charts-line-chart>
