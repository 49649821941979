import { elementTypes, TextParagraphStyle, InputFormat, IContentElementLocAndDims, IContentElementDynamicImage, IContentElementMath, IContentElementImage, IContentElementText, IContentElement, IContentElementVideo, IContentElementMcq, IContentElementInput, ElementType, McqDisplay, IContentElementTable, IContentElementDnd, DndTargetType, IContentElementOrder, IContentElementMcqOption, IContentElementSbs, IContentElementTextLink, defaultImageScaleFactor, IContentElementFrameStateStyle, IContentElementAnnotatedText, IContentElementCanvas, IContentElementCanvasPage, IContentElementFrame, IContentElementSelectionTable, IContentElementCanvasDisplayElement, IContentElementSelectableText, IContentElementTextSelection, IContentElementMoveableDragDrop, IContentElementInsertion, IContentElementCustomMCQ, IContentElementSolution, IContentElementResultsPrint, IContentElementGroup, IContentElementConditionalImage, IContentElementImageSubText, IContentElementDocLink } from "../../../ui-testrunner/models";
import { indexOf } from "../../services/util";

export interface ICustomTaskSet { 
  __id?: string,
  __backup__id?: string,
  __backup__userDisplayName?: string,
  name?: string,
  order?: number,
  taskId?: string, // cross link to collection `tasks`
  project?: string,
  isReady?: boolean,
  labelCounter?:number,
  isArchived?: boolean,
  questions?:IQuestionConfig[] | string,
  creatorUid?: string,
  timeCreated?: number,
  timeLastSaved?: number,
  schemaVersion?: number,
  lastTouchedBy?: string,
  assessmentFrameworkId?:string,
  isProtected?:boolean,
}

export interface ICustomTaskTag {
  __id?:string,
  caption: string,
  customTaskSetId: string,
  localTaskId: number,
  createdBy: string,
  timestamp: number,
}

export interface ICustomTaskComment {
  __id?:string,
  caption: string,
  customTaskSetId: string,
  localTaskId: number,
  createdByName: string,
  createdByUid: string,
  timestamp: number,
}
export interface IQuestionRun {
  content: IContentElement[],
  // voiceover?: {url:string},
  entryOrder?: number[],
  isReadingSelectionPage?: boolean,
  readingSelectionCaption?: string,
  test_question_version_id?:number,
}
export interface IQuestionConfig extends IQuestionRun {
  label: string,
  isReady?: boolean,
  readSel?:string,
  caption?:string,
  points?:string,
  ishidePoints?: boolean;
  readSelections?:string[],
  defaultReadSelection?: string,
  isInfoSlide?: boolean,
  notes?: string,
  taskId?: string,
  localTaskId: number,
  entryIdCounter?: number,
  testLabel?: string,
  isReqFill?:boolean,
  reqFillEntries?:string,
  reqFillMsg?:string,
  isReadingSelectionsAlwaysShown?: boolean,
  isStartHalf?: boolean;
  isReviewQuestionExculded?: boolean;
  reviewQuestionText?: string;
  enableManualSaveButton?: boolean
  manualSaveButtonTopMargin?: number;
  disableQuestionResponseShrinkageDetection?: boolean;
}

export interface ExpectedAnswer {
  total?: number;
  formatted_response?: string;
  score?: number;
  weight?: number;
  entries?: any[];
  lang?: string;
  is_no_submission?: boolean;
}

export const generateDefaultElementText = (elementType):IContentElementText => {
  return {
    elementType,
    caption: '',
    simpleList: [],
    advancedList: [],
    paragraphStyle: TextParagraphStyle.REGULAR
  }
}

export const generateDefaultElementAnnotatedText = ():IContentElementAnnotatedText => {
  const obj = {
    ...generateDefaultElementText(ElementType.TEXT),
    text: generateDefaultElementText(ElementType.TEXT),
    annotation: ""
  }
  obj.elementType = ElementType.ANNOTATION
  return obj;
}


export const generateDefaultFrameStateStyle = ():IContentElementFrameStateStyle => {
  console.log('generateDefaultElementTable')
  return {
    backGroundImg: generateDefaultElementImage(ElementType.IMAGE),
    dropShadowX: 0,
    dropShadowY: 0,
    blurRadius: 0,
    shadowColor: "#ffffff",
    backgroundColor: "#ffffff",
    padding: 0,
  }
}

export const generateDefaultElementTable = (elementType):IContentElementTable => {
  console.log('generateDefaultElementTable')
  return {
    elementType,
    grid: [
      [{val:''}, {val:''}],
      [{val:''}, {val:''}],
      [{val:''}, {val:''}],
    ],
    isHeaderRow: false,
    isHeaderCol: false,
    isColWidthConst: false,
    isTableOfValues: true,
    colWidthConst: 4
  }
}
export const generateDefaultElementMath = (elementType):IContentElementMath => {
  return {
    elementType,
    latex: '',
    accessibilityImg: generateDefaultElementImage('image'),
    _changeCounter: 0,
    paragraphStyle: TextParagraphStyle.REGULAR
  }
}
export const generateDefaultElementImage = (elementType):IContentElementImage => {
  return {
    elementType,
    url: null,
    scale: 100,
    scaleFactor: defaultImageScaleFactor
  }
}

export const generateOldDefaultImage = (element:IContentElementDynamicImage, condition:string) => {
  const image = { 
    elementType: ElementType.IMAGE,
    url: element.url, 
    fileType: element.fileType,
    altText: element.altText,
    scale: element.scale,
    outline: element.outline
  }
  return { condition: condition, image };
}

export const generateDefaultElementVideo = (elementType):IContentElementVideo => {
  return {
    elementType,
    url: null,
    urls: [],
  }
}


export const generateDefaultElementMcq = (elementType):IContentElementMcq => {
  return {
    elementType,
    displayStyle: McqDisplay.VERTICAL,
    options: [
      {elementType:ElementType.TEXT, content: 'Option 1', isCorrect:false, optionId: 1, link: generateDefaultElementTextLink()},
      {elementType:ElementType.TEXT, content: 'Option 2', isCorrect:true,  optionId: 2, link: generateDefaultElementTextLink()},
      {elementType:ElementType.TEXT, content: 'Option 3', isCorrect:false, optionId: 3, link: generateDefaultElementTextLink()},
      {elementType:ElementType.TEXT, content: 'Option 4', isCorrect:false, optionId: 4, link: generateDefaultElementTextLink()},
    ]
  }
}

export const generateDefaultElementTextLink = ():IContentElementTextLink => {
  return { elementType: "text_link", caption: ""};
}

export const generateDefaultElementOrder = (elementType):IContentElementOrder => {
  return {
    elementType,
    displayStyle: McqDisplay.HORIZONTAL,
    isScrambled: false,
    delimeter: '',
    options: [
    ]
  }
}

export const generateDefaultElementDnd = (elementType):IContentElementDnd => {
  return {
    elementType,
    targetType: DndTargetType.TARGET,
    defaultTargetStyle: null,
    draggableCounter:0,
    targetCounter:0,
    width: 360,
    height: 200,
    backgroundElements: [], 
    draggables: [],
    targets: [],
    groups:[],
  } 
}

export const generateDefaultElementGrouping = (elementType) => {
  return {
    elementType,
    targetType: DndTargetType.TARGET,
    defaultTargetStyle: null,
    draggableCounter:0,
    targetCounter:0,
    width: 360,
    height: 200,
    draggables: [],
    targets: [],
  }
}

export const generateDefaultElementFrame = (elementType):IContentElementFrame => {
  return {
    elementType,
    content: [],
    // activatedStyles: [],
    // styleRaw:{},
  }
}

export const generateDefaultElementCanvas = (elementType):IContentElementCanvas => {
  return {
    elementType,
    pages: [],
    width: 1,
    height: 1
  }
}

export const generateDefaultElementInput = (elementType):IContentElementInput => {
  return {
    elementType,
    format: null,
    alternativeValues: [],
    ratioTerms: [],
  }
}

export const getElementChildrenText = (element:IContentElementText) => {
  if (element.advancedList){
    return element.advancedList.map(el => el);
  }
  return [];
}

export const getElementChildrenSelectText = (element:IContentElementTextSelection) => {
  const elements:IContentElement[] = [];
  for(const text of element.texts) {
    if(text.elementType) {
      elements.push(text);
    }
  }
  return elements;
}
export const getElementChildrenTable = (element:IContentElementTable) => {
  const elements:IContentElement[] = [];
  if (element.grid){
    element.grid.forEach(row => {
      row.forEach(cell => {
        if (cell.elementType){
          elements.push(<IContentElement> cell);
        }
      })
    })
  }
  return elements;
}
export const getElementChildrenSbs = (element:IContentElementSbs) => {
  return element.left.concat(element.right);
}
export const getElementChildrenMcq = (element:IContentElementMcq) => {
  const elements:IContentElement[] = []
  if (element.options){
    element.options.forEach(option => {
      if (option.elementType){ // this is not handling the simple text properly
        elements.push(option);
      }
    })
  }
  return elements;
}
export const getElementChildrenOrder = (element:IContentElementOrder) => {
  const elements:IContentElement[] = []
  if (element.options){
    element.options.forEach(option => {
      if (option.elementType){ // this is not handling the simple text properly
        elements.push(option);
      }
    })
  }
  return elements;
}

export const getElementChildrenFrame = (element:IContentElementFrame)=>{
  return element.content.map( el => el )
}

export const getElementChildrenCanvas = (element:IContentElementCanvas)=>{
  const elements:IContentElement[] = []
  element.pages.forEach((page:IContentElementCanvasPage)=>{
    page.displayList.forEach(el => {
      if (el.elementType){
        elements.push(el);
      }
    });
  })
  return elements;
}

export const getElementChildrenSelectTable = (element:IContentElementSelectionTable)=> {
  const elements:IContentElement[] = []
  element.leftCol.forEach(element => {
    if (element.content && element.content.elementType) {
      elements.push(element.content)
    }
    if (element.audio) {
      elements.push(element.audio)
    }
  });
  // console.log(elements);
  return elements
}

export const getElementChildrenImage = (element: any) => {
  const elements:IContentElement[] = [];
  //Could be IContentElementDynamicImage
  if(element.images) {
    for(const condImage of Object.values(element.images)) {
      if(condImage){
        const image = (<IContentElementConditionalImage>condImage).image;
        if(image.elementType) {
          elements.push(image);
        }
      }
    }
  }

  if(element.subtexts) {
    for(const subtext of element.subtexts) {
      if(subtext.elementType) {
        elements.push(<IContentElementImageSubText>subtext);
      }
    }
  }

  return elements;
}

export const getElementChildrenInsertion = (element: IContentElementInsertion) => {
  const elements: IContentElement[] = [];

  for(const draggable of element.draggables.concat(element.textBlocks)) {
    const el = draggable.element;
    if(el.elementType) {
      elements.push(el);
    }
  }

  return elements;
}

export const getElementChildrenMoveableDnd = (element:IContentElementMoveableDragDrop) => {
  const elements: IContentElement[] = [];
  for(const draggable of element.draggables) {
    const el = draggable.element;
    if(el.elementType) {
      elements.push(el);
    }
  }

  //Not technically a content element
  // for(const target of element.targets) {
  //   elements.push(target);
  // }

  return elements;
}

export const getElementChildrenGrouping = (element:IContentElementGroup) => {
  const elements: IContentElement[] = [];
  
  if(element.separatorText && element.separatorText.elementType) {
    elements.push(element.separatorText);
  }

  if(element.separatorImage && element.separatorImage.elementType) {
    elements.push(element.separatorImage);
  }

  if(element.separatorCanvas && element.separatorCanvas.elementType) {
    elements.push(element.separatorCanvas);
  }

  for(const draggable of element.draggables) {
    const el = draggable.element;
    if(el.elementType) {
      elements.push(el);
    }
  }  

  for(const target of element.targets) {
    const el = target.element;
    if(el && el.elementType) {
      elements.push(el);
    }
    const bgdImg = target.backgroundImg;
    if(bgdImg && bgdImg.elementType) {
      elements.push(bgdImg);
    }
  }
  return elements;
}

export const getElementChildrenResultsPrint = (element:IContentElementResultsPrint) => {
  const elements: IContentElement[] = [];
  for(const el of element.content) {
    if(el.elementType) {
      elements.push(el);
    }
  }
  return elements;
}

export const getElementChildrenSolution =(element:IContentElementSolution) => {
  const elements: IContentElement[] = [];
  for(const el of element.content) {
    if(el.elementType) {
      elements.push(el);
    }
  }
  return elements;
}

export const getElementChildrenCustomMcq = (element:IContentElementCustomMCQ) => {
  return getElementChildrenMcq(element);
}

export const getElementChildrenDnd = (element:IContentElementDnd) => {
  const elements: IContentElement[] = [];

  for(const bgdEl of element.backgroundElements) {
    const el = bgdEl.element;
    if(el.elementType) {
      elements.push(el);
    }
  }

  for(const draggable of element.draggables) {
    const el = draggable.element;
    if(el.elementType) {
      elements.push(el);
    }
  }

  //note: targets are not technically elements, so not included.

  return elements;
}

export const elementIconById:Map<string, string> = new Map();
elementTypes.forEach(elementType => {
  elementIconById.set(elementType.id, elementType.icon)
});

export const createDefaultElementWithDims = (elementType:string):IContentElementLocAndDims => {
  return {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    ...createDefaultElement(elementType)
  }
}

export const createDefaultElement = (elementType:string):IContentElement => {
  switch(elementType){
    case ElementType.TEXT:     return generateDefaultElementText(elementType);
    case ElementType.TABLE:    return generateDefaultElementTable(elementType);
    case ElementType.MATH:     return generateDefaultElementMath(elementType);
    case ElementType.IMAGE:    return generateDefaultElementImage(elementType);
    case ElementType.MCQ:      return generateDefaultElementMcq(elementType);
    case ElementType.ORDER:    return generateDefaultElementOrder(elementType);
    case ElementType.DND:      return generateDefaultElementDnd(elementType);
    case ElementType.VIDEO:    return generateDefaultElementVideo(elementType);
    case ElementType.INPUT:    return generateDefaultElementInput(elementType);
    case ElementType.FRAME:    return generateDefaultElementFrame(elementType);
    case ElementType.GROUPING: return generateDefaultElementGrouping(elementType);
    case ElementType.MATCHING: return generateDefaultElementGrouping(elementType);
    case ElementType.CANVAS: return generateDefaultElementCanvas(elementType);
    default: return { elementType }
  }
}

export const frameElement = (content:any[], element:any) => {
  if (window.confirm('Frame this element?')){
    let i = indexOf(content, element);
    const replacementElement = <IContentElementFrame> createDefaultElement(ElementType.FRAME);
    replacementElement['x'] = element.x;
    replacementElement['y'] = element.y;
    replacementElement.content.push(element)
    content[i] = replacementElement;
  }
}

export const getElementChildren = (element:IContentElement, includeSolution: boolean = false, includeResultsPrint: boolean = false) : IContentElement[] => {
  switch(element.elementType){
    case ElementType.TEXT:    return getElementChildrenText   (<IContentElementText>   element);
    case ElementType.TABLE:   return getElementChildrenTable  (<IContentElementTable>  element);
    case ElementType.MCQ:     return getElementChildrenMcq    (<IContentElementMcq>    element);
    case ElementType.ORDER:   return getElementChildrenOrder  (<IContentElementOrder>  element);
    case ElementType.SBS:     return getElementChildrenSbs    (<IContentElementSbs>    element);
    case ElementType.SELECT_TABLE: return getElementChildrenSelectTable (<IContentElementSelectionTable> element);
    case ElementType.CANVAS:  return getElementChildrenCanvas (<IContentElementCanvas> element);
    case ElementType.FRAME:   return getElementChildrenFrame  (<IContentElementFrame>  element);
    case ElementType.SELECT_TEXT: return getElementChildrenSelectText (<IContentElementTextSelection> element);
    case ElementType.INSERTION: return getElementChildrenInsertion (<IContentElementInsertion> element);
    case ElementType.IMAGE: return getElementChildrenImage (<IContentElementImage> element);
    case ElementType.MOVEABLE_DND: return getElementChildrenMoveableDnd (<IContentElementMoveableDragDrop> element);
    case ElementType.GROUPING: return getElementChildrenGrouping(<IContentElementGroup> element);
    case ElementType.CUSTOM_MCQ: return getElementChildrenCustomMcq(<IContentElementCustomMCQ> element);
    case ElementType.RESULTS_PRINT: return includeResultsPrint ? getElementChildrenResultsPrint(<IContentElementResultsPrint> element) : [];
    case ElementType.SOLUTION: return includeSolution ? getElementChildrenSolution(<IContentElementSolution> element) : [];
    case ElementType.DND: return getElementChildrenDnd (<IContentElementDnd> element);     
    default: return []
  }
}

export const checkElementIsEntry = (element:IContentElement, isAutoScoreable?:boolean, typePropName = 'elementType') : boolean => {
  if (element["isScoringDisabled"] && element[typePropName] && String(element[typePropName]).startsWith(ElementType.INPUT)) {
    return false
  }
  if (element[typePropName]==ElementType.INPUT && (
    element["format"]==InputFormat.NUMBER ||
    element["format"]==InputFormat.NUMBER_LIST ||
    element["format"]==InputFormat.RATIO ||
    element["format"]==InputFormat.ALGEBRA ||
    element["format"]==InputFormat.FRACTION)) {
    return true;
  } else if (
    element[typePropName]==ElementType.INPUT+'-'+InputFormat.NUMBER ||
    element[typePropName]==ElementType.INPUT+'-'+InputFormat.NUMBER_LIST ||
    element[typePropName]==ElementType.INPUT+'-'+InputFormat.ALGEBRA ||
    element[typePropName]==ElementType.INPUT+'-'+InputFormat.FRACTION ||
    element[typePropName]==ElementType.INPUT+'-'+InputFormat.RATIO
  ) {
    return true;
  }
  switch(element[typePropName]){
    case ElementType.CAMERA:
    case ElementType.GRAPHING:
    case ElementType.UPLOAD:
    case ElementType.MIC:
    return !isAutoScoreable;
    case ElementType.INPUT:
    return !isAutoScoreable;
    case ElementType.DND:
    case ElementType.MOVEABLE_DND:
    case ElementType.MCQ:
    case ElementType.CUSTOM_MCQ:
    case ElementType.HOTSPOT:
    case ElementType.HOTTEXT:
    case ElementType.MATCHING:
    case ElementType.SELECT_TEXT:
    case ElementType.ORDER:
    case ElementType.SELECT_TABLE:
    case ElementType.GROUPING:
      if (element["isScoringDisabled"]) {
        return false
      }
    case ElementType.VALIDATOR:
    case ElementType.CUSTOM_INTERACTION:
    case ElementType.INSERTION:
    return true;
    default: 
    return false;
  }
}



export const applyDefaultElementProps = (target:any, elementType:string) => {
  const ref = createDefaultElement(elementType);
  Object.keys(ref).forEach(key => {
    target[key] = ref[key];
  })
}
