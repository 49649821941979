import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { LangService } from 'src/app/core/lang.service';

export interface TestWindow {
  id: number
  title: {
    en: string,
    fr: string,
  }
  isGrad: boolean
  isFsa: boolean
  isActive: boolean
  isClosed: boolean
  isQa: boolean
  isBg: boolean
  isRegistrationOpen: boolean
  isIrtReportReady: boolean
  scoreEntryStartDate: string | null
  scoreEntryEndDate: string | null
  startDate: string
  endDate: string
  isSample: boolean
}

@Component({
  selector: 'test-window-picker',
  templateUrl: './test-window-picker.component.html',
  styleUrls: ['./test-window-picker.component.scss']
})
export class TestWindowPickerComponent implements OnInit {

  constructor(
    private auth:AuthService,
    private lang: LangService,
  ) { 

  }

  testWindows: TestWindow[] = [];
  selectedTestWindow: TestWindow | null = null;
  isLoading = false;
  
  @Input() labelText: string = 'Test Window';
  @Input() defaultOptionText: string = 'Select a test window to continue...';
  @Input() isSampleInclude: boolean = false;
  @Input() showTestWindowId: boolean = false;
  @Input() isGradOnly: boolean = false;
  @Input() isFsaOnly: boolean = false;

  @Output() onSelected = new EventEmitter();

  ngOnInit(): void {
    this.fetchData();
  }

  async fetchData() {
    this.isLoading = true;

    try {
      const { data: testWindows } = await this.auth.apiFind('public/bced/test-windows', {
        query: {
          withAssessments: 1,
          isSample: this.isSampleInclude ? 1 : 0,
          isFsaOnly: this.isFsaOnly ? 1 : 0,
          isGradOnly: this.isGradOnly ? 1 : 0,
        }
      });

      this.testWindows = testWindows;
    } catch(err) {
      console.log(err);
    }

    this.isLoading = false;
  }

  onSelectedTestWindowChange() {
    this.onSelected.emit(this.selectedTestWindow);
  }

  getTestWindowTitle(testWindow: any) {
    const lang = this.lang.getCurrentLanguage()?.toLowerCase?.() || 'en';

    let title = '';

    if (!this.isFsaOnly && !this.isGradOnly) {
      if (testWindow.isGrad) title = title + '[GRAD]';
      else if (testWindow.isFsa) title = title + '[FSA]';
    }

    title = title + (testWindow?.title[lang] || '');

    if (this.showTestWindowId) title = title + ` (${testWindow.id})`;

    return title;
  }
}
