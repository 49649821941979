
<div *ngIf="getImage()" style="position: relative;" [style.left.em]=" element.setRelative ? element.XPosition : 0" [style.top.em]=" element.setRelative ? element.YPosition : 0"
    [style.filter]="getFilter()"
    [style.padding.em]="getPadding()">
    <render-image *ngIf="getImage()" [parentElement]="element" [element]="getImage().image" [questionState]="questionState" [questionPubSub]="questionPubSub"
    [draggable]="draggable"></render-image>
    <!--<render-image *ngIf="getImage()" [element]="getImage().image" [scaleWidth]="this.element.canvasScaleWidth" [scaleHeight]="this.element.canvasScaleHeight"></render-image>-->
    <div 
        *ngFor="let sub of this.element.subtexts"
        class="image-sub-text"
        [class.is-transparent-text]="sub.invisible"
        [style.width.em]="sub.width - 5"
        [style.font-size.em]="sub.size"
        [style.left.em]="sub.x/sub.size" 
        [style.top.em]="sub.y/sub.size" 
        [style.font-family]="sub.fontFamily ? sub.fontFamily : '' "
        [style.line-height]="sub.lineHeight ? sub.lineHeight : 1.3 "
        [style.text-align]="sub.alignment"
        [style.color]="getSubTextColor(sub)"
        [style.transform]="getTransform(sub)"
    >
    <markdown-inline [ngStyle]="getImageSubTextStyle(sub)" [notInline]="true" [input]="sub.text" [style.text-align]="sub.alignment"></markdown-inline>    </div>
</div>

