
import { Component, OnInit, Input } from '@angular/core';
import { Moment } from 'moment';

enum StudentAssessmentStatus {
  SUBMITTED = 'Submitted',
  IN_PROGRESS = 'In Progress',
  NOT_STARTED = 'Not Started'
}

@Component({
  selector: 'student-assessment-status-card',
  templateUrl: './student-assessment-status-card.component.html',
  styleUrls: ['./student-assessment-status-card.component.scss']
})
export class StudentAssessmentStatusComponent implements OnInit {

  @Input() title: string;
  @Input() loginTime?: Moment;
  @Input() submissionTime?: Moment;

  public status: string;

  constructor(
    
  ) { }

  ngOnInit(): void {
    this.initStatus();
  }

  public displayTitle(): string {
    if (typeof this.title !== 'string') return 'N/A';

    const code = this.title.split('_')?.[1]

    return code ? code.toUpperCase() : this.title.toUpperCase();
  }

  public displayDate(m?: Moment): string {
    if (!m) return 'N/A';
    // hardcoding " PST" in the end as this in America/Vancouver - PST - time
    return m.tz("America/Vancouver").format('MMM D, YYYY') + ' at ' + m.format('h:mm a') + " PST";
  }

  private initStatus() {
    if (this.loginTime && this.submissionTime) {
      this.status = StudentAssessmentStatus.SUBMITTED;
    } else if (this.loginTime) {
      this.status = StudentAssessmentStatus.IN_PROGRESS;
    } else {
      this.status = StudentAssessmentStatus.NOT_STARTED;
    }
  }
}

