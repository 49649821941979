<div class="sa-individual-reports fit-print-to-page" *ngIf="isFsaAdmin()">
    <div class="no-school" *ngIf="!schoolDetail || schoolDetail.groupId < 0">
      <tra slug="sa_sa_select_school"></tra>
    </div>
  
    <ng-container *ngIf="schoolDetail && schoolDetail.groupId > 0">
      <bc-paginated-table *ngIf="mode == Mode.TABLE" class="dont-print" [columnHeadings]="columnHeadings"
        [pagination]="pagination" [getRows]="getRows" [getDisplay]="getDisplay" [getTag]="getTag"
        [defaultFilterCondition]="FilterCondition.LIKE" [columnWidths]="tableColumnWidths" [getAnyValue]="getAnyValue"
        [filterUpdater]="filterUpdater" [zebraConfig]="zebraConfig" (link)="onLinkClicked($event)">
      </bc-paginated-table>
  
      <div class="single-student" *ngIf="mode == Mode.SINGLE && singleModeData && !isLoading">
        
        <ng-container *ngIf="isFsaMinistryAdmin()">
          <div class="select-sheet">
            <button 
                *ngIf="isRawScoreAvailable()"
                class="sheet-button"
                [class.selected]="selectedScoreType == ScoreType.RAW_SCORE"
                (click)="selectScoreType(ScoreType.RAW_SCORE)"
            >
                Raw Score
            </button>

            <button 
                *ngIf="isScaledScoreAvailable()"
                class="sheet-button"
                [class.selected]="selectedScoreType == ScoreType.SCALED_SCORE"
                (click)="selectScoreType(ScoreType.SCALED_SCORE)"
            >
                Scaled Score
            </button>
          </div>
        </ng-container>
  
        <div class="single-student-header">
          <bc-paginator class="dont-print" [paginator]="singleModeData.singlePagination" [showCount]="true"
            [getCountDisplay]="getCountDisplay" (refresh)="onSinglePaginationChange()">
          </bc-paginator>
          <a class="dont-print" (click)="backToListOfStudents()">
            <tra slug="sa_fr_issr_backToStudent"></tra>
          </a>
        </div>
  
        <div class="dont-print">
          <ng-container *ngIf="isDownloading">
            <button disabled style="width: 7em;" class="button is-large">
              <div class="ball-pulse">
                  <div></div>
                  &nbsp;
                  <div></div>
                  &nbsp;
                  <div></div>
              </div>
            </button>
          </ng-container>

          <ng-container *ngIf="!isDownloading">
            <button (click)="printReport()" class="button is-large">
              <tra slug="sa_print_report"></tra>
            </button>
          </ng-container>
        </div>
        <student-individual-report *ngIf="!isLoading && !languageRefresh" [isScaled]="selectedScoreType == ScoreType.SCALED_SCORE" [singleModeData]="singleModeData" [reportYear]="reportYear"
          [districtCode]="districtDetail.foreignId" [schoolLang]="schoolDetail ? schoolDetail.lang : null"
          [testWindow]="testWindow"
        >
        </student-individual-report>
  
      </div>
    </ng-container>
  
  </div>