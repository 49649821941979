import { FormControl } from '@angular/forms';
import { IContentElementMcq, IContentElementCustomMCQ, IContentElementMcqOption, QuestionState, IEntryStateMcq, McqDisplay, IContentElementText, IScoredResponse, getElementWeight, ScoringTypes, IContentElement, ElementType, elementTypes, IContentElementTextLink } from '../models';
import { indexOf } from '../services/util';
import { TextToSpeechService } from '../text-to-speech.service';
import { StyleprofileService } from '../../core/styleprofile.service';
import { LangService } from '../../core/lang.service';
import { getRandomInt } from '../../marking/data/data';
import { Subject } from 'rxjs';
import { LoginGuardService } from '../../api/login-guard.service';
import { SimpleChanges } from '@angular/core';
import { DrawingLogService } from '../drawing-log.service';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { PubSubTypes } from '../element-render-frame/pubsub/types';
import { QuestionRunnerLayoutService } from '../question-runner-layout.service';
import { HyperlinkService } from '../hyperlink.service';

export class McqRenderer {
    
    currentSelections;
    isStarted = false;
    alreadyScrambled;
    clickTriggers:Map<IContentElementMcqOption, Subject<boolean>> = new Map();
    lastTrackedQuestionState
    isShowSolutionApplied:boolean;
    lastDropDownSelection: number | undefined;

    constructor(
      public element:IContentElementMcq, 
      public questionState:QuestionState, 
      public getMcqContent:Function,
      public isShowSolution,
      public isLocked:boolean, 
      public textToSpeech:TextToSpeechService,
      public profile: StyleprofileService,
      public loginGuard: LoginGuardService,
      public lang: LangService,
      private questionPubSub: QuestionPubSub,
      private hyperLinkService: HyperlinkService,
      private questionRunnerLayout?: QuestionRunnerLayoutService,
      private dropdownSelector?:FormControl,
      private bufferedLog?: DrawingLogService,
    ) {
        this.currentSelections = [];
        // this.alreadyScrambled = this.element.alreadyScrambled;
        this.supressBookmarkLink();
    }

    checkForChanges(changes?:SimpleChanges) {
      if (changes){
        if (changes.isLocked){ this.isLocked = changes.isLocked.currentValue }
        if (changes.isShowSolution) { this.isShowSolution = changes.isShowSolution.currentValue; }
        if (changes.questionState) { this.questionState = changes.questionState.currentValue; }
      }
      if (this.lastTrackedQuestionState !== this.questionState){
        this.lastTrackedQuestionState = this.questionState;
        this.questionState = this.questionState;
        if (this.questionState){
          this.handleNewState();
        }
      }
      if (this.isShowSolution && !this.isShowSolutionApplied){
        this.showSolution()
        this.isShowSolutionApplied = true;
      }
    }

    getLastTrackedQuestion() {
      return this.lastTrackedQuestionState
    }

    updateState() {
      let selectedOptionId: Set<number> = new Set()
      const entryState:IEntryStateMcq = this.ensureState();
      const weight = getElementWeight(this.element);
      let score =  this.element.isOptional ? undefined :weight ;
      let totalPartialScore = 0;
      let isCorrect = false;
      let selectionsMap = new Map();
      let isOneCorrectOptionSelected = false;
      let selections = this.currentSelections.map( selection => {
        let i = indexOf(this.element.options, selection);
        let temporaryId = i;
        selectionsMap.set(temporaryId, true);
        if (selection.isCorrect){
          isOneCorrectOptionSelected = true
        }
        const content = this.getMcqContent(selection);
        return {
          i,
          id: selection.optionId,
          elementType: selection.elementType,
          content,
          advancedList: selection.advancedList,
          paragraphStyle: selection.paragraphStyle
        }
      });
      
      let isFilled, isAnyFilled;
      isFilled = isAnyFilled = selections.length > 0;

      if (this.element.isMultiSelect && this.element.numberOfSelectionsToMarkFilled){
        isFilled = selections.length >= this.element.numberOfSelectionsToMarkFilled;
      }
      // Updated isFilled and isResponded should become true if even one selection is made.
      // https://bubo.vretta.com/vea/platform/vea-web-client/-/issues/27146, keeping original 
      // in case we need it back in the future
      // if (this.element.isMultiSelect && this.element.maxOptions && selections.length < this.element.maxOptions){
      //   isFilled = false;
      // }
      
      score = 0
      let numCorrect = 0;
      let numLimittedCorrect = 0;
      let numLimittedMax = 0;
      let numTotalMax = 0;
      let numIncorrect = 0;
      this.element.options.forEach((option, i) => {
        const temporaryId = i;
        const expected = !!option.isCorrect;
        const input = !!selectionsMap.has(temporaryId);
        const isMatch = (expected === input);
        numTotalMax ++
        if (expected){
          numLimittedMax ++
          if (input){
            numLimittedCorrect ++ 
            numCorrect ++
            totalPartialScore += option.partialWeight || 0
          }
        }
        else if (isMatch){
            numCorrect ++
            totalPartialScore += option.partialWeight || 0
        }

        if (!isMatch){
          numIncorrect ++
        }

      })
        
      // conclude the scoring


      if (this.element.isMultiSelect){
        if (this.element.isAllOrNothing) {
          if (this.currentSelections.length === parseInt(this.element.selectTargetNumRequire)) {
            isCorrect = true
            score = weight
          } else {
            isCorrect = false
            score = 0;
          }
        }

        else if (this.element.enableProportionalScoring){
          if (this.element.maxOptions){
            if (numLimittedMax){
              score = weight * (numLimittedCorrect/numLimittedMax)
            }
          }
          else{
            if (numTotalMax){
              score = weight * (numCorrect/numTotalMax)
            }
          }
        }
        else if (this.element.isEnablePartialweights){
          let fullMarks = numLimittedMax == numLimittedCorrect && selections.length == numLimittedMax
          score = fullMarks ? weight : totalPartialScore
        }
        else{ // deduction by default
          if (numIncorrect < weight && !this.element.isAllOrNothing){
            score = weight - numIncorrect
          }
        }
        isCorrect = (score == weight)
      }
      else {
        if (numLimittedCorrect > 0){
          isCorrect = true;
          score = weight;
        }
        else{
          isCorrect = false
          score = 0;
        }
      }

      // console.log({
      //   maxOptions: !!this.element.maxOptions,
      //   isMultiSelect: this.element.isMultiSelect,
      //   enableProportionalScoring: this.element.enableProportionalScoring,
      //   numIncorrect,
      //   numLimittedCorrect,
      //   numLimittedMax,
      //   numCorrect,
      //   numTotalMax,
      //   score,
      //   isCorrect
      // })

      score = Math.max(0, score)

      const isOptional = this.element.isOptional

      entryState.isCorrect = this.element.isOptional? undefined :isCorrect;
      entryState.isStarted = this.isStarted;
      entryState.isFilled = isFilled || this.element.isOptional;
      entryState.isResponded = isAnyFilled && !isOptional;
      entryState.isOptionalResponded = isAnyFilled && isOptional;
      entryState.selections = selections;
      entryState.selectionsMap = selectionsMap
      entryState.alreadyScrambled = this.alreadyScrambled,
      entryState.score = score;
      entryState.weight = weight;
      entryState.isOptional = this.element.isOptional;
      entryState.isOptionalButRecordResponse = this.element.isOptionalButRecordResponse;

      if (this.bufferedLog){
        this.bufferedLog.bufferedLog('MCQ_UPDATE', {entryId: this.element.entryId, entryState });
      }
      if(this.questionPubSub){
        this.questionPubSub.allPub({entryId: this.element.entryId, type: PubSubTypes.UPDATE_VALIDATOR, data: {}})
        if(this.element.countNumberOfMCQSelected && this.element.inputboxCounterEntryID){
          this.questionPubSub.elementPub(this.element.inputboxCounterEntryID, PubSubTypes.INPUT, {isResponded: isAnyFilled, count: this.currentSelections.length, elementType: ElementType.MCQ})
        }
        if(this.element.blockTextInputBeforeSelection && this.element.inputboxTextrEntryID){
          this.questionPubSub.elementPub(this.element.inputboxTextrEntryID, PubSubTypes.INPUT, {isResponded: this.currentSelections.length > 0, count: this.currentSelections.length, mcqEntryID: this.element.entryId, elementType: ElementType.MCQ})
        }
      }
    }

    isMCQCorrect(optionElement, index){
      const state = this.questionState[this.element.entryId]
      if(state.isCorrect){
        if(state.selectionsMap && state.selectionsMap.has(index)) return true
      }
      return false || optionElement.isCorrect
    }

    isInputDisabled(){
      const entryState = this.ensureState();
      return (this.isLocked || entryState.isPathFollowed);
    }
    
    ensureState() {
      let entryState:IEntryStateMcq
      if (this.questionState){
          const entryId = this.element.entryId;
          entryState = this.questionState[entryId]
          // console.log('ensureState', entryState)
          if (!entryState){
            entryState  = {
            type: 'mcq',
            isCorrect: false,
            isStarted: false,
            isFilled: false,
            isResponded: false,
            selections: [],
            alreadyScrambled: this.alreadyScrambled,
            score: 0,
            weight: getElementWeight(this.element),
            scoring_type: ScoringTypes.AUTO, 
            isOptional: this.element.isOptional,
            isOptionalButRecordResponse: this.element.isOptionalButRecordResponse
          }
          // console.log('overwriteState', entryState)
          this.questionState[entryId] = entryState;
        }
      }
      return entryState;
    }

    isMaxAnswerMsgShown;
    checkIfMaxAnswersReached() {
      const selections = this.currentSelections
      if (this.element.maxOptions && selections.length >= this.element.maxOptions && this.element.maxOptions > 0) {
        //if (this.loginGuard) this.loginGuard.quickPopup("You have made the maximum number of selections. Please unselect one to make a new selection.")
        this.isMaxAnswerMsgShown = true;
      } else {
        this.isMaxAnswerMsgShown = false;
      }
      return this.isMaxAnswerMsgShown;
    }

    isHandlingNewState:boolean;
    handleNewState () {
      if (this.questionState){
        const entryState:IEntryStateMcq = this.ensureState();
        this.isStarted = entryState.isStarted;
        this.isHandlingNewState = true;
        if (this.element.isOptional){
          entryState.isFilled = true;
          entryState.isCorrect = undefined;
        }
        if (this.element.displayStyle === McqDisplay.DROPDOWN){
          this.dropdownSelector.setValue(entryState.selections[0])
          if(entryState.selections[0]){
            this.lastDropDownSelection = entryState.selections[0].i
          }          
        }
        else{
          if (!entryState.selections){
            return;
          }
          entryState.selections.forEach(selectionState => {
            let i = selectionState.i;
            this.selectOption(this.element.options[i]);
          })
        }
        this.isHandlingNewState = false;
      }
    }
    
    popOut() {
      if (this.questionRunnerLayout && this.element.framePopIds) {
        this.questionRunnerLayout.clear(this.element.entryId);
        const list = this.element.framePopIds.split(',')
        list.forEach((num, index)=>{
          this.questionRunnerLayout.mapId2ZIndex(parseInt(num), 1000-index, this.element.entryId)
        })
      }
    }

    isDisplayStyleDropdown() { return this.element.displayStyle === McqDisplay.DROPDOWN; }

    initDropDownSelector(){
      const entryState = this.ensureState();
      if (this.isDisplayStyleDropdown()) {
        if (entryState.selections && entryState.selections.length > 0){
          const selection = entryState.selections[0]
          this.dropdownSelector.setValue(selection.i);
        }
        // else if (this.element.defaultDropdownText && this.element.defaultDropdownText.length>0) {
        //   this.dropdownSelector.setValue(0);
        // }
        else{
          this.dropdownSelector.setValue(undefined)
        }
      } 
      this.dropdownSelector.valueChanges.subscribe(change => this.updateDropdownState() );
      this.updateDropdownEnabled();
    }

    updateDropdownEnabled(){
      if (this.isInputDisabled()){
        this.dropdownSelector.disable()
      }
      else{
        this.dropdownSelector.enable()
      }
    }


    isDropdownCorrect(dropdownSelector){
      return this.questionState[this.element.entryId].isCorrect
    }

    updateDropdownState(){
      if (!this.isDisplayStyleDropdown()){
        return;
      }
      if(this.dropdownSelector.value == this.lastDropDownSelection) return
      let isCorrect = false;
      let isFilled = false;
      let isAnyFilled = false;
      const isOptional = this.element.isOptional;
      let i = this.dropdownSelector.value;
      this.lastDropDownSelection = i
      let option;
      if (i>=0){
        // if (this.hasDefaultDropDownText()) option = this.element.options[i-2];
        // else option = this.element.options[i]
        option = this.element.options[i]
        if (option){
          isAnyFilled = true;
          isFilled = true
          isCorrect = option.isCorrect;
        }
      }
  
      const weight = getElementWeight(this.element);
      const entryState:IEntryStateMcq = this.ensureState();
      entryState.isCorrect = isCorrect;
      entryState.isFilled = isFilled;
      entryState.isStarted = true;
      entryState.isResponded = isAnyFilled && !isOptional;
      entryState.isOptionalResponded = isAnyFilled && isOptional;
      if (isFilled){
        entryState.selections = [{
          i: +i, 
          id: option.optionId, 
          elementType: ElementType.TEXT, 
          content: option.content,
        }]
      }
      else{
        entryState.selections = [];
      }
      entryState.score =  isCorrect ? weight : 0 ;
      if(this.questionPubSub){
        this.questionPubSub.allPub({entryId: this.element.entryId, type: PubSubTypes.UPDATE_VALIDATOR, data: {}})
      }
    }

    initElementSub(questionPubSub: QuestionPubSub){
      if (questionPubSub) {  //some of the old items giving questionPubSub undefined for first time.
        const elementSub = questionPubSub.initElementSub(this.element.entryId);
        elementSub.subscribe(payload => {
          if (this.isLocked) return;
          const optionId = payload.data.option;
          this.element.options.map((option, id) => {
          option.isCorrect = option.optionId === optionId ? true : false;
          });

          switch (payload.type) {
            case McqDisplay.DROPDOWN:
              return this.updateDropdownState();
            default:
              return this.updateState();
            //Default for : BUBBLE, HORIZONTAL, VERTICAL, FREEFORM, GRID, WRAP,LIKERT
          }
        });
      }
      
    }

    getMcqSettingsTxtObj(str:string) {
      const obj = this.profile.getStyleProfile()[this.lang.c()].configuration?.mcqSettings?.defaults
      if (obj && obj.length>0 && obj[0].msgs) {
        return obj[0].msgs[str]
      }
      return undefined
    }

    getMaxMsgText() {
      let msgObj = this.getMcqSettingsTxtObj("mainText")
      if (msgObj) return String(msgObj)
      return this.lang.tra('lbl_max_sel')
    }

    getClickToDismissMsg() {
      let msgObj = this.getMcqSettingsTxtObj("closeText")
      if (msgObj) return String(msgObj)
      return this.lang.tra('btn_click_dismiss');
    }

    turnMaxMsgOff() {
      this.isMaxAnswerMsgShown = false
    }

    _selectOption (option:IContentElementMcqOption) {
        if (!this.element.isMultiSelect){
          if (this.isSelected(option)){
            if (this.element.isSelectToggle){
              this.currentSelections = [];
            }
          }
          else {
            this.currentSelections = [option];
          }
        }
        else{
          let i = indexOf(this.currentSelections, option);
          let isSelected = (i !== -1);
          if (isSelected){ 
            this.currentSelections.splice(i, 1);
            this.checkIfMaxAnswersReached();
          }
          else{
            if (this.checkIfMaxAnswersReached()) return this.currentSelections
            this.currentSelections.push(option);
          }
        }
        return this.currentSelections;
    }

    selectOption (option:IContentElementMcqOption) {
        this.currentSelections = this._selectOption(option);
        if (!this.isHandlingNewState){
          this.updateState();
          this.getClickTrigger(option).next(true);
        }
    }

    selectOptionManual(option:IContentElementMcqOption){
        if (this.isInputDisabled()){ return; }
        // if (option.frameInteractionType && option.frameInteractionId){
        //   // console.log('single frame: ', option.frameInteractionId)
        //   this.questionPubSub.elementPub(option.frameInteractionId, option.frameInteractionType, option.frameData)
        // }
        // if (option.frameInteractionType && option.frameInteractionIdList && option.frameInteractionIdList != '' && /^(?:[0-9]+)(?:,[0-9]+)*$/.test(option.frameInteractionIdList)){
        //   const targetIDList = option.frameInteractionIdList.split(',').map(Number)
        //   // console.log("Target ID list: ", targetIDList)
        //   targetIDList.forEach(targetID => {
        //     this.questionPubSub.elementPub(targetID, option.frameInteractionType, option.frameData)
        //   })
        // }

        if (option.optionTargetsAndEvents && option.optionTargetsAndEvents.length > 0 && this.validTargetList(option)){
          option.optionTargetsAndEvents.forEach(targetAndEvent => {
            console.log('targets and events: ', targetAndEvent)
            const targetIDList = targetAndEvent.frameInteractionIdList.split(',').map(Number)
            targetIDList.forEach(targetID => {
              this.questionPubSub.elementPub(targetID, targetAndEvent.frameInteractionType, option.frameData)
            })
          })
          // const targetIDList = option.frameInteractionIdList.split(',').map(Number)
          // // console.log("Target ID list: ", targetIDList)
          // targetIDList.forEach(targetID => {
          //   this.questionPubSub.elementPub(targetID, option.frameInteractionType, option.frameData)
          // })
        }

        // Some browsers such as FireFox 52.0 used by SEB does not support <a> tags within buttons, so we need
        // to fire the link request event on the mcq button click, rather than relying on the bookmark-link element
        const bookmarkLinkEl = this.optionContainsBookmarkLink(option);
        if (bookmarkLinkEl){
          this.hyperLinkService.linkRequest.next({
            readerElementId: '',
            bookmarkId: bookmarkLinkEl.bookmarkId,
            itemLabel: bookmarkLinkEl.itemLabel,
          })
        }

        this.isStarted = true;
        this.selectOption(option);  
    }

    validTargetList(option: IContentElementMcqOption){
      option.optionTargetsAndEvents.forEach(targetAndEvent =>{
        if(!/^(?:[0-9]+)(?:,[0-9]+)*$/.test(targetAndEvent.frameInteractionIdList)){
          return false
        }
      })
      return true;
    }

    isSelected (option:IContentElementMcqOption) {
        if (indexOf(this.currentSelections, option) !== -1){
            return true;
        }
        return false;
    }

    scrambleOrder() {
        //algorithm: Fisher-Yates shuffle
        let options = this.element.options;
        for(let i  = options.length - 1; i > 0; i--) {
          let j = getRandomInt(0, i);
          [options[i], options[j]] = [options[j], options[i]]; //swap them
        }
        this.alreadyScrambled = true;
      }
    
      isVoiceoverEnabled(){
        return this.textToSpeech.isActive;
      }
    
      getClickTrigger(option: IContentElementMcqOption){
        let trigger = this.clickTriggers.get(option);
        if (!trigger){
          trigger = new Subject();
          this.clickTriggers.set(option, trigger);
        }
        return trigger;
      }
    
      deselectAllOptions(){
        this.currentSelections.splice(0, this.currentSelections.length);
      }

      showSolution(){
        // console.log("in show solution")
        this.deselectAllOptions();
        this.element.options.forEach(option => {
          if (option.isCorrect){
            this._selectOption(option);
          }
        })
      }

    resetCount(){
      this.currentSelections = [];
      this.updateState();
    }

    getElementOptions(){
      // if (this.element.randomizeChoices){
      //   return this.shuffledOptionItems;
      // }
      return this.element.options;
    }

    // This function will be called on component initialization, to check
    // if the mcq is already answered, if so, and if the selected option has
    // a TOGGLE_ON frame interaction attatched to it, it will trigger the toggle
    // automatically, so that the target frame will show up without having to 
    // answer the mcq again to manually trigger the toggle.
    checkExsistingFrameInteraction(){
      setTimeout(() => {
        const entryState = this.questionState[this.element.entryId]
        if (entryState && entryState.selections){
          entryState.selections.forEach((mcqOption) => {
            const option:IContentElementMcqOption = this.element.options.filter(option => option.optionId === mcqOption.id && option.content === mcqOption.content)[0]
            if (option.optionTargetsAndEvents && option.optionTargetsAndEvents.length > 0 && this.validTargetList(option)){
              option.optionTargetsAndEvents.forEach(targetAndEvent => {
                console.log('targets and events: ', targetAndEvent)
                if (targetAndEvent.frameInteractionType === 'TOGGLE_ON'){
                  const targetIDList = targetAndEvent.frameInteractionIdList.split(',').map(Number)
                  targetIDList.forEach(targetID => {
                    this.questionPubSub.elementPub(targetID, targetAndEvent.frameInteractionType, option.frameData)
                  })
                }
              })
            }
          })
        }
      }, 100)
    }

    checkExsistingUnlockInputBox(){
      setTimeout(() => {
        const entryState = this.questionState[this.element.entryId]
        if (entryState && entryState.selections && entryState.selections.length > 0){
          if(this.element.blockTextInputBeforeSelection && this.element.inputboxTextrEntryID){
            this.questionPubSub.elementPub(this.element.inputboxTextrEntryID, PubSubTypes.INPUT, {isResponded: entryState.selections.length, count: this.currentSelections.length, elementType: ElementType.MCQ})
          }
        }
      }, 100)
    }

    /**
     * Method to set the "isSupressed" flag to the bookmark-link element, which
     * will prevent the boomarklink service from firing the event again as the event
     * is fired by the mcq element
     */
    supressBookmarkLink(){
      this.element.options.forEach(option => {
        const bookmarkEl = this.optionContainsBookmarkLink(option);
        if (bookmarkEl)
          bookmarkEl.isSupressed = true;
      })
    }

    /**
     * Helper method to return the first bookmark link element found within an mcq option
     * @param option MCQ option object
     * @returns undefined if option does not contain a bookmark link, otherwise the bookmark link element.
     */
    optionContainsBookmarkLink(option:IContentElementMcqOption){
      if (option.elementType == 'text' && option.advancedList){
        const bookmarkEl: IContentElementTextLink = option.advancedList.filter(element => element.elementType == 'bookmark_link')[0];
        if (bookmarkEl)
          return bookmarkEl;
      }

      return undefined;
    }
}
