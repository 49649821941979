import { PubSubTypes } from "src/app/ui-testrunner/element-render-frame/pubsub/types";
import { getElementWeight, IContentElementCustomInteraction, QuestionState, ScoringTypes } from "src/app/ui-testrunner/models";
import { QuestionPubSub } from "src/app/ui-testrunner/question-runner/pubsub/question-pubsub";
import { TextToSpeechService } from "../../text-to-speech.service";
import * as PIXI from 'pixi.js-legacy';
import { PIXITextService } from "../../pixi-text.service";

export enum AccessibilityKeys {
  LEFT = '37',
  UP = '38',
  RIGHT = '39',
  DOWN = '40',
  W = '87',
  A = '65',
  S = '83',
  D = '68',
  ENTER = '13',
  ESCAPE = '27'
}

// A map to record which accessibility key is down
export const keysMap = {
  '37': false, // left
  '38': false, // up
  '39': false, // right
  '40': false, // down
  '87': false, // w
  '65': false, // a
  '83': false, // s
  '68': false, // d
  '13': false, // enter
  '27': false, // escape
}

export abstract class CustomInteractionCtrl {

    abstract element: IContentElementCustomInteraction;
    questionState: QuestionState;
    questionPubSub: QuestionPubSub;
    render;
    addGraphic;
    zoom: number;
    isLocked: boolean;
    textToSpeech: TextToSpeechService;
    pixiText: PIXITextService;
    pixiDiv;
    constructor(questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, zoom, isLocked, textToSpeech: TextToSpeechService, pixiText?: PIXITextService){
      this.questionState = questionState;
      this.questionPubSub = questionPubSub;
      this.addGraphic = addGraphic;
      this.render = render;
      this.zoom = zoom;
      this.textToSpeech = textToSpeech;
      this.pixiText = pixiText;
      if (!this.zoom) {
        this.zoom = 1;
      }
      this.isLocked = isLocked
    }

    getScaledMousePos(data) {
      data.global.x/= this.zoom;
      data.global.y/= this.zoom;
      return data.global;
    }

    abstract getUpdatedState() : any;
    updateState() : void {
      this.questionState[this.element.entryId] = 
      { ... this.getUpdatedState(), 
        weight: getElementWeight(this.element),
        scoring_type: ScoringTypes.AUTO
      };
    }
    abstract handleNewState() : void;
    abstract loadAssets(): Promise<PIXI.Loader>;
    
    // Old getText() method, where new text texture is created every time 
    // a text is to be drawn on the canvas
    // getText(text:string, style, resolution:number, x, y) {
    //   const textObj = new PIXI.Text(text, style)
    //   if (resolution) {
    //     textObj.resolution = resolution
    //   }
    //   if (x) {
    //     textObj.x = x
    //   }
    //   if (y) {
    //     textObj.y = y
    //   }
    //   return textObj
    // }

    getText(text:string, style, resolution:number, x, y) {
      let textObj: PIXI.Text;
      // First check the pixi text service to see if there's already
      // a PIXI.Text instance of the exact same text in the cache. If so,
      // use the one in the cache, otherwise create new PIXI.Text
      if(this.pixiText.textTextures[`gridText_${x}_${y}_${text}_${style.fill}_${style.fontSize}`]) {
        textObj = this.pixiText.textTextures[`gridText_${x}_${y}_${text}_${style.fill}_${style.fontSize}`];
      }else{
        textObj = new PIXI.Text(text, style)
        this.pixiText.textTextures[`gridText_${x}_${y}_${text}_${style.fill}_${style.fontSize}`] = textObj;
      }
      if (resolution) {
        textObj.resolution = resolution
      }
      if (x) {
        textObj.x = x
      }
      if (y) {
        textObj.y = y
      }
      // Need to unset its parent so that its parent PIXI.Graph is reset to the
      // new Graph created 
      textObj.parent = null
      return textObj

      // const textObj = new PIXI.Text(text, style)
      // if (resolution) {
      //   textObj.resolution = resolution
      // }
      // if (x) {
      //   textObj.x = x
      // }
      // if (y) {
      //   textObj.y = y
      // }
      // // textObj.texture.textureCacheIds.push('textTexture');
      // return textObj
    }


    isHCMode(){
      return this.textToSpeech.isHiContrast;
    }

    getColor() {
      return this.isHCMode() ? 0xffffff : 0x000000;
    }

    removeAccessibilityKeyListeners() {}
  }