import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { BcAccountsService, DistrictDetail, Pagination, School, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcReportsService } from 'src/app/bc-reports/bc-reports.service';
import { CognitiveLevelScoresReport, IndividualRawScoresProficiencyLevel, IndividualRawScoresReport, StudentSelfReflectionReport } from 'src/app/bc-reports/types';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import { downloadAsZip, getBlobs, sliceIntoChunks } from 'src/app/utils';

enum Sheet {
  INDIVIDUAL_RAW_SCORES = 'sa_fr_issr_raw_scores',
  INDIVIDUAL_SCALED_SCORES = 'sa_fr_issr_scaled_scores',
  COGNITIVE_LEVEL_SCORES = 'sa_fr_issr_level_scores',
  STUDENT_SELF_REFLECTION = 'sa_fr_issr_self_reflection',
}

@Component({
  selector: 'school-level-data-report',
  templateUrl: './school-level-data-report.component.html',
  styleUrls: ['./school-level-data-report.component.scss']
})
export class SchoolLevelDataReportComponent implements OnInit {
  @Input() testWindow: TestWindow;
  @Input() districtDetail: DistrictDetail;
  @Input() schools: School[];
  @Input() schoolDetail: SchoolDetail;
  @Input() accountType: AccountType = AccountType.BC_FSA_SCHOOL_ADMIN;
  @Input() includeScaled: boolean = false;

  isScaled: boolean = false;

  pagination: Pagination;
  individualRawScoresReport: IndividualRawScoresReport;
  cognitiveLevelScoresReport: CognitiveLevelScoresReport;
  studentSelfReflectionReport: StudentSelfReflectionReport;

  Sheet = Sheet;
  sheet: Sheet = Sheet.INDIVIDUAL_RAW_SCORES;
  sheets = [Sheet.INDIVIDUAL_RAW_SCORES, Sheet.COGNITIVE_LEVEL_SCORES, Sheet.STUDENT_SELF_REFLECTION];

  isLoading: boolean = false;

  isIrtReady: boolean = false;

  refresh: boolean = false;
  currentLang: 'en' | 'fr' = 'en';
  isExportingReports: boolean = false;

  constructor(
    private bcAccounts: BcAccountsService,
    private bcReports: BcReportsService,
    private bcAssessments: BcAssessmentsService,
    private lang: LangService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private whiteLabelService: WhitelabelService,
  ) { }

  ngOnInit(): void {
    this.generatesSheets();
    this.pagination = this.getInitialPagination();

    this.route.params.subscribe(params => {
      this.handleLanguageToggle(params.lang == 'en' ? 'en' : 'fr');
    });

    if (this.testWindow != null && this.schoolDetail != null)
    { 
      this.initTestWindow();
    }
  }

  async ngOnChanges(changes: SimpleChanges) 
  {
    console.log(changes);
    if ((changes.testWindow || this.testWindow != null) &&
    (changes.schoolDetail || this.schoolDetail != null))
    {
      await this.initTestWindow();
    }

    this.handleLanguageToggle(this.currentLang);
  }

  async initTestWindow() {
    await this.bcAssessments.getIrtFlags(this.testWindow.id).then(async ({ irt_ready, is_irt_sld_disabled }) => {
      // NWT only sees raw scores for now
      if (this.whiteLabelService.isNWT()) {
        irt_ready = false;
      }

      const canAccessFourTabs = this.auth.isFsaMinistryAdmin() || this.auth.isFsaEaoAdmin();
      // if(!this.testWindow.is_qa) ready = false;
      this.isIrtReady = irt_ready;
      if(canAccessFourTabs) this.sheets = [Sheet.INDIVIDUAL_SCALED_SCORES, Sheet.INDIVIDUAL_RAW_SCORES, Sheet.COGNITIVE_LEVEL_SCORES, Sheet.STUDENT_SELF_REFLECTION]
      else this.sheets = [Sheet.INDIVIDUAL_SCALED_SCORES, Sheet.COGNITIVE_LEVEL_SCORES, Sheet.STUDENT_SELF_REFLECTION]
      
      if (irt_ready) {
        if (!canAccessFourTabs) {
          this.sheets[0] = Sheet.INDIVIDUAL_SCALED_SCORES;
          this.isScaled = true;
        }
      } else {
        if (!canAccessFourTabs) {
          this.sheets[0] = Sheet.INDIVIDUAL_RAW_SCORES;
          this.isScaled = false;
        }
      }
      if(canAccessFourTabs) this.isScaled = true;
      
      if (!canAccessFourTabs && is_irt_sld_disabled) {
        this.sheets[0] = Sheet.INDIVIDUAL_RAW_SCORES;
        this.isScaled = false;
      }

      this.sheet = this.sheets[0];

      await this.updateTable();
    });
  }

  generatesSheets() {
    if (this.auth.isFsaMinistryAdmin() || this.auth.isFsaEaoAdmin()) {
      this.sheets = [Sheet.INDIVIDUAL_SCALED_SCORES, Sheet.INDIVIDUAL_RAW_SCORES, Sheet.COGNITIVE_LEVEL_SCORES, Sheet.STUDENT_SELF_REFLECTION];
    } else {
      this.sheets = [Sheet.INDIVIDUAL_RAW_SCORES, Sheet.COGNITIVE_LEVEL_SCORES, Sheet.STUDENT_SELF_REFLECTION];
    }
  }

  handleLanguageToggle(lang: 'en' | 'fr') {
    this.refresh = true;
    this.currentLang = lang;
    this.lang.setCurrentLanguage(lang);
    setTimeout(() => this.refresh = false, 1000);
  }

  async updateTable() {
    if (this.isLoading) return;
    if (!this.pagination) return;

    this.isLoading = true;

    if (this.schoolDetail) {
      this.pagination.orderBy = 'grade';
      this.pagination.orderDirection = 'asc';

      if (this.sheet === Sheet.INDIVIDUAL_RAW_SCORES) {
        this.individualRawScoresReport = await this.bcReports.getIndividualRawScoresReport({
          school_group_id: this.schoolDetail.groupId,
          school_code: this.schoolDetail.foreignId,
          school_name: this.schoolDetail.name,
          test_window_id: this.testWindow.id,
        }, this.pagination);
        this.pagination.count = this.individualRawScoresReport.data.count;
      } else if (this.sheet === Sheet.INDIVIDUAL_SCALED_SCORES) {
        this.individualRawScoresReport = await this.bcReports.getIndividualScaledScoresReport({
          school_group_id: this.schoolDetail.groupId,
          school_code: this.schoolDetail.foreignId,
          school_name: this.schoolDetail.name,
          test_window_id: this.testWindow.id,
        }, this.pagination);
        this.pagination.count = this.individualRawScoresReport.data.count;
      }
      else if (this.sheet === Sheet.COGNITIVE_LEVEL_SCORES) {
        this.cognitiveLevelScoresReport = await this.bcReports.getCognitiveLevelScoresReport({
          school_group_id: this.schoolDetail.groupId,
          school_code: this.schoolDetail.foreignId,
          school_name: this.schoolDetail.name,
          test_window_id: this.testWindow.id,
        }, this.pagination);
        this.pagination.count = this.cognitiveLevelScoresReport.data.count;
      } else if (this.sheet === Sheet.STUDENT_SELF_REFLECTION) {
        this.studentSelfReflectionReport = await this.bcReports.getStudentSelfReflectionReport({
          school_group_id: this.schoolDetail.groupId,
          school_code: this.schoolDetail.foreignId,
          school_name: this.schoolDetail.name,
          test_window_id: this.testWindow.id,
        }, this.pagination);
        this.pagination.count = this.studentSelfReflectionReport.data.count;
      }
    }

    if (this.pagination.count === 0){
      this.pagination.count = undefined;
    }

    this.isLoading = false;
  }

  selectSheet(sheet: Sheet) {
    if (sheet === Sheet.INDIVIDUAL_SCALED_SCORES) {
      this.isScaled = true;
    } else {
      this.isScaled = false;
    }
    console.log(this.isScaled);
    this.sheet = sheet;
    this.pagination = this.getInitialPagination();
    this.updateTable();
  }

  getSheetName(sheet: Sheet) {
    if (this.isScaled && sheet == Sheet.INDIVIDUAL_SCALED_SCORES) {
      return Sheet.INDIVIDUAL_SCALED_SCORES;
    } else {
      return sheet;
    }
  }

  isNR = (score: string | number) => {
    return (''+score).toUpperCase() == 'NR';
  }

  sumScore(srScore: string | number, crScore1: string | number, crScore2: string | number, crScore3: string | number): string {
    if (this.isNR(srScore) && this.isNR(crScore1) && this.isNR(crScore2) && this.isNR(crScore3)) {
      return 'NR';
    } else {
      return [srScore, crScore1, crScore2, crScore3].filter(score => !this.isNR(score)).reduce((sum: number, score: string | number) => {
        return sum + parseInt(score.toString());
      }, 0).toString();
    }
  }

  proficiencyLevelRange = {
    'English': {
      4: {
        'literacy': [0, 17, 31],
        'numeracy': [0, 16, 34],
      },
      7: {
        'literacy': [0, 16, 34],
        'numeracy': [0, 16, 32],
      },
    },
    'French': {
      4: {
        'literacy': [0, 15, 27],
        'numeracy': [0, 16, 34],
      },
      7: {
        'literacy': [0, 16, 29],
        'numeracy': [0, 16, 32],
      },
    },
  }

  proficiencyLevels = [
    IndividualRawScoresProficiencyLevel.PLU,
    IndividualRawScoresProficiencyLevel.EMERGING,
    IndividualRawScoresProficiencyLevel.ON_TRACK,
    IndividualRawScoresProficiencyLevel.EXTENDING,
  ];

  getProficiencyLevel(language: 'English' | 'French', grade: 4 | 7, component: 'literacy' | 'numeracy', totalScore: string | number) {
    if (totalScore == 'NR') return this.proficiencyLevels[0];
    const _totalScore = parseInt(totalScore.toString());

    let languageRange = this.proficiencyLevelRange[language];
    if (!languageRange) throw new Error;
    let gradeRange = languageRange[grade];
    if (!gradeRange) throw new Error;
    let range = gradeRange[component];
    if (!range) throw new Error;

    let level = this.proficiencyLevels[this.proficiencyLevels.length - 1]; // EX
    for (let i = 0; i < range.length - 1; i++) {
      if (_totalScore >= range[i] && _totalScore < range[i + 1]) {
        level = this.proficiencyLevels[i + 1];
        break;
      }
    }
    return level;
  }

  async export() {
    if (!this.schoolDetail) return;
    await this.bcAccounts.exportSchoolLevelDataReport(this.schoolDetail.groupId, this.testWindow, this.isScaled, this.currentLang);
  }

  async getFilenameAndUrlPromise(sGroupId: number, schoolName: string) {
    const url = await this.bcAccounts.exportSchoolLevelDataReport(sGroupId, this.testWindow, this.isScaled, this.currentLang, true);

    // Excel doesn't allow ":" in filename
    return {
      name: schoolName.includes(":") ? schoolName.replace(":", "") : schoolName,
      url: url,
    }
  }

  isSchoolAdmin() {
    return this.auth.isSchoolAdmin();
  }

  async exportSchoolLevelDataReports() {
    if (this.isSchoolAdmin()) return;
    if (!this.districtDetail) return;
    if (!(this.schools?.length > 0)) return;

    this.isExportingReports = true;

    try {
      const filename = `School Level Data Reports - ${this.districtDetail.name}.zip`;
      const BATCH_SIZE = 5;
  
      const promises = this.schools
        .filter(s => s.groupId > 0)
        .map(s => this.getFilenameAndUrlPromise(s.groupId, s.name));
  
      const urls = <{name: string, url: string}[]> await Promise.all(promises);

      const batches = sliceIntoChunks<{
        name: string;
        url: string;
      }>(urls, BATCH_SIZE);

      let allBlobs:{
        name: string;
        blob: Blob;
      }[] = [];

      for (const batch of batches) {
        const blobs = await getBlobs(batch);
        allBlobs = [...allBlobs, ...blobs];
      }
  
      await downloadAsZip(filename, allBlobs);
    } catch {

    }

    this.isExportingReports = false;
  }

  private getInitialPagination(): Pagination {
    let pagination = this.bcAccounts.getInitialPagination();
    if (this.sheet === Sheet.INDIVIDUAL_RAW_SCORES) {
      pagination.size = 100;
    }
    return pagination;
  }

  getSchoolLanguage(): 'English' | 'French' {
    if (!this.schoolDetail) return 'English';
    return this.schoolDetail.lang == 'EN' ? 'English' : 'French';
  }


  isSheetDisabled(s: Sheet) {
    const canAccessScaledScores = this.auth.isFsaMinistryAdmin() || this.auth.isFsaEaoAdmin();

    // if (s === Sheet.INDIVIDUAL_SCALED_SCORES && !canAccessScaledScores) {
    //   return true;
    // }

    return false;
  }

  getSchoolCount() {
    return this.schools?.filter?.(s => s.groupId > 0)?.length || 0;
  }
}
