import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BcAccountsService, Filter, FilterCondition, PaginatedRows, Pagination } from 'src/app/bc-accounts/bc-accounts.service';
import { sum } from 'lodash';

export interface IColumnHeadingOption {
  label: string;
  value: any;
}

export interface IColumnHeading {
  heading: string;
  subheading?: string;
  sortBy: string;
  isSelect?: boolean;
  options?: IColumnHeadingOption[];
  sortDisabled?: boolean;
  filterDisabled?: boolean;
  filterOptionsDisabled?: boolean;
  isLink?: boolean;
  isDate?: boolean;
  isInfo?: boolean;
  topHeadingRow?: [string, number];

  [key: string]: any;
}

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Data {
  [key: string]: any,
}

export interface Tag {
  text: string,
  color?: string,
  textColor?: string, // default is white text
  style?: Style,
}

export enum TagLayout {
  HORIZONTAL,
  VERTICAL,
}

export interface TagConfig {
  layout: TagLayout,
}

export interface LinkClickEvent<T> {
  row: T,
  rowIndex: number,
  by?: string, // can be excluded if link gen method is pre-specified
}

export type Style = { [key: string]: string };

export interface Toggle {
  title?: string,
  tra?: string,
}

export interface TextDisplayElement {
  text: string,
  isLink?: boolean,
  linkId?: string,
  disabled?: boolean,
  style: { [key: string]: string },
}

export enum TextDisplayDirection {
  HORIZONTAL,
  VERTICAL,
}

export interface TextDisplay {
  elements: TextDisplayElement[],
  direction: TextDisplayDirection,
}

export interface ZebraConfig {
  field: string,
}

@Component({
  selector: 'bc-paginated-table',
  templateUrl: './bc-paginated-table.component.html',
  styleUrls: ['./bc-paginated-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BcPaginatedTableComponent<T extends Data> implements OnInit {

  @Input() identifier: string = null; // this is for views that have multiple paginated tables

  @Input() updateOnLoad: boolean = true;
  @Input() columnHeadings: IColumnHeading[] = [];
  @Input() pagination: Pagination = null;
  @Input() defaultFilterCondition: FilterCondition = FilterCondition.MATCH;
  @Input() filterUpdater: (by: string, value: string | number, filters: Map<string, Filter>) => void = null;
  @Input() filterCleanup: (by: string) => string[];
  @Input() getFilterInitValue: (filter: Filter) => string | number = null;
  @Input() getAnyValue: (by: string) => string = null;
  @Input() orderUpdater: (by: string, direction: OrderDirection) => { by: string, direction: OrderDirection } = null;
  @Input() getRows: (pagination: Pagination) => Promise<PaginatedRows<T>>;
  @Input() getDisplay: (by: string, row: T) => string | TextDisplay;
  @Input() getTag: (by: string, row: T) => Tag | Tag[];
  @Input() getRowStyle: (row: T, rowIndex: number) => Style = null;
  @Input() columnWidths: number[] = [];
  @Input() addCheckbox: boolean = false;
  @Input() withExport: boolean = false;
  @Input() zebraConfig: ZebraConfig = null;
  @Input() tagConfig: TagConfig = null;
  @Input() showPaginator: boolean = true;

  @Input() elementsToRender: string[] = null;
  @Input() eventFunction: (relevantRow: any[], columnNum: number) => void = null;

  @Input() extraToggle: Toggle = null;
  @Input() linkByRouters: { [by: string]: (LinkClickEvent) => string };

  @Output() update = new EventEmitter();
  @Output() updated = new EventEmitter();
  @Output() loading = new EventEmitter();
  @Output() select = new EventEmitter();
  @Output() link = new EventEmitter();
  @Output() export = new EventEmitter();
  @Output() toggle = new EventEmitter();

  visibleFilters: Set<string> = new Set();
  filters: Map<string, Filter> = new Map();
  filterThrottle: NodeJS.Timeout | null;

  rows: T[] = [];
  displays: (string | TextDisplay)[][];

  isLoading: boolean = false;

  OrderDirection = OrderDirection;

  selectAll: boolean = false;

  toggleValue: boolean = false;

  constructor(
    private bcAccounts: BcAccountsService,
  ) { }

  ngOnInit(): void {
    if (!this.pagination) {
      this.pagination = this.bcAccounts.getInitialPagination();
    }

    if (this.updateOnLoad) {
      this.updateTable();
    }
  }

  hasExtraHeadingRow(){
    return this.columnHeadings.find(h => !!h.topHeadingRow);
  }

  async updateTable() {
    this.isLoading = true;
    this.loading.emit(this.isLoading);

    this.update.emit();

    if (this.getRows) {
      let rowData = <any> await this.getRows(this.pagination);
      if (rowData.count > 0 && this.pagination.skip > rowData.count) {
        this.pagination.skip = 0; // Math.floor(this.pagination.count / this.pagination.size) * this.pagination.size
        rowData = await this.getRows(this.pagination);
      }

      if (rowData) {
        if(rowData.data) {
          this.rows = rowData.data;
        }
        else {
          this.rows = rowData;
        }
        this.pagination.count = rowData.count;

        if (this.zebraConfig) {
          this.processZebraStyling();
        }

        this.displays = this.rows.map(r => {
          return this.columnHeadings.map(h => {
            if (this.isHeadingValueComplex(h.sortBy, r)) {
              return this.getDisplay(h.sortBy, r) || '';
            }
            return this.getHeadingValueForRow(h.sortBy, r);
          })
        });
      }

      this.isLoading = false;
      this.loading.emit(this.isLoading);

      this.updated.emit();
      return;
    }

    this.rows = []
    this.pagination.count = 0;

    this.isLoading = false;
    this.loading.emit(this.isLoading);
    this.updated.emit();
  }

  triggerClickEventIfRequired(clickedValue: string | TextDisplay, relevantRow, columnNum)
  {
    // console.log(clickedValue);
    if (typeof clickedValue == "string" && this.eventFunction)
    {
      this.elementsToRender.forEach((element) => 
      {
        if (clickedValue.includes(element))
        {
          this.eventFunction(relevantRow, columnNum);
        }
      })
    }
  }

  isDirectLinkDefined(by: string) {
    return !(!this.linkByRouters || this.linkByRouters[by] === undefined)
  }

  onLinkClicked(row: T, index: number, by: string) {
    if (!by) return;

    let event: LinkClickEvent<T> = {
      row,
      rowIndex: index,
      by,
    }
    this.link.emit(event);
  }

  changeOrderBy(by: string) {
    if (this.pagination.orderBy === by) {
      this.pagination.orderDirection = this.pagination.orderDirection === OrderDirection.ASC ? OrderDirection.DESC : OrderDirection.ASC;
    } else {
      this.pagination.orderBy = by;
      this.pagination.orderDirection = OrderDirection.ASC;
    }

    if (this.orderUpdater) {
      let customOrder = this.orderUpdater(this.pagination.orderBy, this.pagination.orderDirection as OrderDirection);
      if (customOrder && customOrder.by && customOrder.direction) {
        this.pagination.orderBy = customOrder.by
        this.pagination.orderDirection = customOrder.direction;
      }

    }

    this.pagination.skip = 0;

    this.updateTable();
  }

  isSortedBy(by: string, direction: OrderDirection): boolean {
    return (this.pagination.orderBy === by || (this.pagination.orderBy+'').includes(by)) && this.pagination.orderDirection === direction;
  }

  toggleShowFilter(by: string): void {
    if (this.visibleFilters.has(by)) {
      this.visibleFilters.delete(by);
    } else {
      this.visibleFilters.add(by);
    }
  }

  isFilterVisible(by: string): boolean {
    return this.visibleFilters.has(by);
  }

  resetPage() {
    this.pagination.skip = 0;
  }

  updateFilterByValue(by: string, value: any) {
    let heading = this.columnHeadings.find(h => h.sortBy === by);
    if (!heading) return;

    let anyValue = [''];
    if (heading.isSelect && this.getAnyValue) {
      let customAnyValue = this.getAnyValue(by);
      if (customAnyValue) {
        anyValue.push(customAnyValue);
      }
    }

    if (anyValue.includes(value)) {
      this.filters.delete(by);
      if (this.filterCleanup) {
        let fieldsToDelete = this.filterCleanup(by);
        fieldsToDelete.map(field => {
          this.filters.delete(field);
        })
      }
    } else {
      let heading = this.columnHeadings.find(h => h.sortBy === by);
      if (heading) {
        this.filters.set(heading.sortBy, {
          field: heading.sortBy,
          condition: this.defaultFilterCondition,
          value: value,
        });
      }
      if (this.filterUpdater) {
        this.filterUpdater(by, value, this.filters);
      }
    }

    if (this.filterThrottle !== null) {
      clearTimeout(this.filterThrottle);
    }
    this.filterThrottle = setTimeout(() => {
      this.updatePaginationFilters();
      this.pagination.count = undefined;
      this.pagination.skip = 0;
      this.updateTable();
      this.filterThrottle = null;
    }, 500);
  }

  updateFilter(event, by: string) {
    this.updateFilterByValue(by, event.target.value);
  }

  clearFilters() {
    this.filters.clear();
    this.visibleFilters.clear();
    if (this.pagination.filters) {
      this.pagination.filters = [];
    }
  }

  getFilterValue(field: string): string {
    const filter = this.filters.get(field) as Filter;
    let value = '';
    if (filter) {
      if (Array.isArray(filter)) {
        value = filter[0].value;
      } else {
        value = String(filter.value);
      }
    }
    return value;
  }

  isHeadingValueComplex(sortBy: string, row: T): boolean {
    if (!this.getDisplay) return false;
    let display = this.getDisplay(sortBy, row);
    return this.isDisplayComplex(display);
  }

  isDisplayComplex(display: string | TextDisplay): boolean {
    return display != null && typeof display !== 'string' && typeof display != 'number';
  }

  getHeadingValueForRow(sortBy: string, row: T): string {
    if (this.getDisplay) {
      let display = this.getDisplay(sortBy, row);

      if (display == null || display == undefined) return '';

      return display.toString();
    }
    return '';
  }

  getComplexHeadingValuesForRowCol(row: number, col: number): TextDisplay {
    return this.displays[row][col] as TextDisplay;
  }

  getTagsForRow(sortBy: string, row: T): Tag[] {
    if (this.getTag) {
      let tag = this.getTag(sortBy, row);
      if (Array.isArray(tag)) {
        return tag;
      } else if (tag == null) {
        return [];
      }
      return [tag];
    }
    return null;
  }

  getTagStyleForRow(sortBy: string, row: T, index: number): Style {
    let tags = this.getTagsForRow(sortBy, row);
    if (tags && tags.length > 0) {
      if (tags[index] != null) {
        let style: Style = {
          'background-color': tags[index].color,
          'color': tags[index].textColor || 'white',
          ...tags[index].style,
        }

        if (this.tagConfig) {
          if (this.tagConfig.layout === TagLayout.VERTICAL) {
            style['display'] = 'block';
            style['width'] = 'fit-content';
            style['margin-top'] = '5px';
            style['margin-left'] = '0';
          }
        } else {
          style['display'] = 'inline-block';
          style['margin-top'] = '0';
          style['margin-left'] = '5px';
        }

        return style;
      }
    }
    return {};
  }

  getTagTextForRow(sortBy: string, row: T, index: number): string {
    let tags = this.getTagsForRow(sortBy, row);
    if (tags && tags.length > 0) {
      if (tags[index] != null) {
        return tags[index].text
      }
    }
    return '';
  }

  getColumnWidth(column: number): string {
    if (column >= this.columnWidths.length) {
      return '100px';
    }
    return `${this.columnWidths[column]}px`;
  }

  getTotalWidth(): string {
    if (this.columnWidths.length === 0) return '0px';
    return `${sum(this.columnWidths)}px`;
  }

  onPaginationChange() {
    this.updateTable();
  }

  filterInitValue(field: string): string | number {
    let filter = this.filters.get(field);
    if (filter) {
      if (this.getFilterInitValue) {
        return this.getFilterInitValue(filter);
      } else {
        return filter.value;
      }
    } else {
      return ''
    }
  }

  onSelectAllRowsChange() {
    this.rows.map(r => (r as Data)._checked = this.selectAll);
    this.emitSelectedRows();
  }

  onSelectedRowChange(row: T) {
    this.emitSelectedRows();
  }

  private emitSelectedRows() {
    let selectedRows = this.rows.filter(r => r._checked);
    this.select.emit(selectedRows);
  }

  getInputClass(by: string): string {
    return `input-${by.replace(' ', '-')}`;
  }

  forceFilter(by: string, value: string, makeVisible: boolean = false) {
    let heading = this.columnHeadings.find(h => h.sortBy === by);
    if (!heading) return;

    let inputClass = this.getInputClass(by);
    let elementCollection = document.getElementsByClassName(inputClass);
    if (elementCollection.length === 0) return;

    let element: HTMLInputElement | HTMLSelectElement;
    if (heading.isSelect) {
      element = elementCollection.item(0) as HTMLSelectElement;
    } else {
      element = elementCollection.item(0) as HTMLInputElement;
    }

    element.value = value;

    if (makeVisible) {
      this.visibleFilters.add(by);
    }
  }

  ngOnChanges(change){
    if(change.pagination){
      let carryoverFilters = change.pagination.currentValue.filters;
      carryoverFilters?.map(filter => {
        this.toggleShowFilter(filter.field);
        this.updateFilterByValue(filter.field, filter.value);
      })
    }
  }

  onExport() {
    this.export.emit();
  }

  getStyleForRow(row: T, index: number): Style {
    if (this.getRowStyle) {
      return this.getRowStyle(row, index);
    }
    return {};
  }

  onToggleChange() {
    this.toggleValue = !this.toggleValue;
    this.toggle.emit(this.toggleValue);
  }

  getCurrentRows(): T[] {
    return this.rows;
  }

  getCurrentRow(rowIndex?: number): T {
    return this.rows[rowIndex];
  }

  private processZebraStyling() {
    let isOdd: boolean;
    let isInfo: boolean;
    let currValue: any;
    let currIndex: number = 0;
    let zebraField = this.zebraConfig.field;
    return this.rows.map(row => {
      if (row[zebraField] !== currValue) {
        currValue = row[zebraField];
        currIndex = 0;
        isOdd = !isOdd;
        isInfo = true;
      } else {
        ++currIndex;
        isInfo = false;
      }
      (row as Data)._isOdd = !isOdd;
      (row as Data)._isInfo = isInfo;
      (row as Data)._isFirstRow = currIndex === 0
      return row
    })
  }

  shouldShowCell(row: T, heading: IColumnHeading): boolean {
    if (!this.zebraConfig) return true;

    if (this.zebraConfig) {
      if (row._isInfo) {
        return true;
      }

      if (heading.isInfo) {
        return false;
      }
      return true;
    }
  }

  getInputCssDisplay(by: string): string {
    if (this.isFilterVisible(by)) {
      return 'block';
    }
    return 'none';
  }

  setRawFilter(filter: Filter, makeVisible: boolean = false) {
    this.filters.set(filter.field, filter);
    this.updatePaginationFilters();

    if (makeVisible) {
      this.visibleFilters.add(filter.field);
    }
  }

  removeRawFilter(field: string, makeInvisible: boolean = false) {
    this.filters.delete(field);
    this.updatePaginationFilters();

    if (makeInvisible) {
      this.visibleFilters.delete(field);
    }
  }

  findRawFilter(field: string): Filter | null {
    const filter = this.filters.get(field);
    return filter || null;
  }

  updatePaginationFilters() {
    this.pagination.filters = [...this.filters.values()];
  }

}
