<div class="ball-pulse" [style]="'transform:scale(' + scale + ')'">
    <div [style]="'background:' + color"></div>
    &nbsp;
    <div [style]="'background:' + color"></div>
    &nbsp;
    <div [style]="'background:' + color"></div>
</div>
<ng-container *ngIf="this.isLoadingSlow && this.showLoadingMsg">
        <tra 
            slug="slow_loading_msg"
            style="text-align: center; display: flex; justify-content: center; font-size: 1.2em; font-weight: bold;">
        </tra>
</ng-container>
