<div class="test-window-picker-wrapper">
    <label style="font-size: large;">
        {{ labelText }}:
    </label>

    <select 
        style="padding: 0.5rem; font-size: medium;"
        [(ngModel)]="selectedTestWindow" 
        (change)="onSelectedTestWindowChange()" 
        [disabled]="isLoading"
    >
        <option [ngValue]="null">
            {{ defaultOptionText }}
        </option>

        <option *ngFor="let testWindow of testWindows" [ngValue]="testWindow">
            {{ getTestWindowTitle(testWindow) }}
        </option>
    </select>
</div>