<div class="login-portal">

    <div style="font-size:1.4em; font-weight:600; margin-bottom:0.2em;">
        {{fullName}}
    </div>
    <div style="font-size:1.1em; margin-bottom: 2em">
        <tra-md slug="txt_login_portal_instr"></tra-md>
    </div>

    <div class="portal-link-mosaic">
        <a 
            *ngFor="let accountType of accountTypes"
            [href]="accountType.route"
            [style.background-color]="accountType.color"
            class="portal-link"
        >
            <tra [slug]="accountType.caption"></tra>
            <div style="font-weight:400;">
                <span *ngIf="accountType.s_name">
                    {{accountType.s_name}} ({{accountType.s_foreign_id}})
                </span>
                <span *ngIf="accountType.sd_name">
                    {{accountType.sd_name}} ({{accountType.sd_foreign_id}})
                </span>
            </div>
        </a>
    </div>
</div>