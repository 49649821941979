import { CustomInteractionType, getElementWeight, ElementType, QuestionState, IEntryState, IContentElementGridScatterPlot, intializeDefault, IEntryStateGridFill, IEntryStateGridScatterPlot, mapToObject, objectToMap } from "../../models";
import { QuestionPubSub } from "../../question-runner/pubsub/question-pubsub";
import { CustomInteractionCtrl } from "./custom-interaction";
import * as PIXI from "pixi.js-legacy";
import { Direction, ScoringTypes } from "../../models";
import { SpriteLoader } from "./sprite-loader";
import { AXIS, IGraphConfig, pixiGraph, PIXI_GRAPH_DEFAULTS } from "./util/pixi-graph";
import { drawCircle } from './util/drawShapes';
import { HostListener } from "@angular/core";
import { AccessibilityKeys, keysMap } from "./custom-interaction";

export class GridScatterPlot extends CustomInteractionCtrl {
    element: IContentElementGridScatterPlot;
    container: PIXI.Container;
    grid: pixiGraph;
    pointsContainer: PIXI.Container;
    spriteLoader: SpriteLoader;
    value: Map<string, number[]>;    
    firstKeyPress: boolean;
    prev_x_index: number;
    prev_y_index: number;

    constructor(element: IContentElementGridScatterPlot, questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, zoom, isLocked, removeGraphic, textToSpeech){
        super(questionState, questionPubSub, addGraphic, render, zoom, isLocked, textToSpeech);

        this.element = element;
        this._initializeDefault();
        this._initGraph();
        this.loadAssets().then(({resources}) => this._init());

        this.prev_x_index = 0;
        this.prev_y_index = 0;
        this.firstKeyPress = true;

        document.addEventListener("keydown", this.keydown);
        document.addEventListener("keyup", this.keyup);
    }

    keydown = (event) =>{
        if(!(document.activeElement.tagName === "ELEMENT-RENDER-CUSTOM-INTERACTION"))
            return
        
        let keys_x = Array.from( this.grid.xAxisData.keys() );
        let keys_y = Array.from( this.grid.yAxisData.keys() );
       
        if(event.keyCode in keysMap){
            keysMap[event.keyCode] = true;
        } 

        if(this.firstKeyPress && event.keyCode in keysMap){
            this.firstKeyPress = false;
            this._addGraphics_keyborad_hover(this.grid.xAxisData.get(keys_x[0]), this.grid.yAxisData.get(keys_y[0]));
            return;
        }

        //right 
        if(keysMap[AccessibilityKeys.RIGHT] || keysMap[AccessibilityKeys.D])
            this._xAxisMove_keyboard(false, keys_x, keys_y)
        //left
        if(keysMap[AccessibilityKeys.LEFT] || keysMap[AccessibilityKeys.A]) 
            this._xAxisMove_keyboard(true, keys_x, keys_y) 
        //up
        if(keysMap[AccessibilityKeys.UP] || keysMap[AccessibilityKeys.W])
            this._yAxisMove_keyboard(false, keys_x, keys_y)
        //down
        if(keysMap[AccessibilityKeys.DOWN] || keysMap[AccessibilityKeys.S])
            this._yAxisMove_keyboard(true, keys_x, keys_y)
        if(keysMap[AccessibilityKeys.ENTER])
            this._placePoint_keyboard(keys_x, keys_y)
        if(keysMap[AccessibilityKeys.ESCAPE])
            this._removeGraphics_keyborad_hover();
    }

    keyup = (event) =>{
        if(event.keyCode in keysMap)    
        keysMap[event.keyCode] = false;
    }

    removeAccessibilityKeyListeners() {
        document.removeEventListener('keydown', this.keydown)
        document.removeEventListener('keyup', this.keyup)
    }

    private _init(){
        this.render();
    }

    private _initGraph(){
        const {canvasHeight} = this.element;
        let config = { ...PIXI_GRAPH_DEFAULTS, render: this.render, canvasHeight, lineColor: this.getColor(), isHiContrast: this.isHCMode() };
        for (let conf of Object.keys(config)){
            if(conf in this.element){
                config[conf] = this.element[conf];
            }
        }
        if (this.isHCMode()) {
            config["backgroundColor"]="#000000"
        }
        this.grid = new pixiGraph(<IGraphConfig>config);
        this.grid.interactive = true;
        this.grid.zIndex = 2;
        this.registerMouseEvents(this.grid);

        this.grid.accessible = true
        this.container.addChild(this.grid);
        
        this.pointsContainer = new PIXI.Container();
        this.grid.addChild(this.pointsContainer);
    }
    
    private _initializeDefault() {
        let defaults = {
            ...PIXI_GRAPH_DEFAULTS,
            isCircled: false,
        }
        
        intializeDefault(defaults, this.element);
        this.value = new Map();
        this.spriteLoader = new SpriteLoader();
        this.container = new PIXI.Container();
        this.container.zIndex = 2;
        this.addGraphic(this.container);
    }

    getUpdatedState() :Partial<IEntryStateGridScatterPlot> {
        const weight = getElementWeight(this.element);
        const {allCorrect, totalCorrect} = this._isCorrect();
        return {
            type: ElementType.CUSTOM_INTERACTION,
            subtype: CustomInteractionType.GRID_SCATTER_PLOT,
            value: mapToObject(this.value),
            isStarted: this.value !== undefined,
            isFilled: this.value.size > 0,
            isCorrect: allCorrect, 
            isResponded: this.value.size > 0,
            score: allCorrect ? weight : 0,
            weight: weight,
            scoring_type: ScoringTypes.AUTO
        };
    }

    private _isCorrect(){
        let totalCorrect = 0;
        for (const key of Object.keys(this.element.correctPoints)){
            if(this.value.has(key)){
                totalCorrect++;
            }
        }
        let allCorrect = totalCorrect === Object.keys(this.element.correctPoints).length;
        allCorrect = allCorrect && (this.value.size === Object.keys(this.element.correctPoints).length)
        return {allCorrect, totalCorrect}
    }

    handleNewState(): void {
        const qsValue: Map<string, number[]> = objectToMap(this.questionState[this.element.entryId].value);        
        if(qsValue.size) {
            this.value = qsValue;
            qsValue.forEach((value, key) => {
                if(this.pointsContainer){
                    let x = value[0];
                    let y = value[1]
                    if(!this.pointsContainer.getChildByName(this._getName(x, y))){
                        if(this.grid){
                            this._addGraphics(
                              {coord: x, displayCoord: this.grid.xAxisData.get(x).displayCoord},
                              {coord: y, displayCoord: this.grid.yAxisData.get(y).displayCoord}
                            );
                        }
                    }
                }
            });
        }
    }

    loadAssets(): Promise<PIXI.Loader> {
        this.spriteLoader.addSpritestoLoader([]);
        return this.spriteLoader.loadSprites();
    }
    
    registerMouseEvents(obj) {
        obj.on("mousedown", $event => this.activateMouseDown($event, obj));
        obj.on("mouseup", $event => this.deactivateMouseDown($event));
        obj.on("mousemove", $event => this.changeLocation($event, obj));
        obj.on("mouseupoutside", $event => this.deactivateMouseDown($event));
        obj.on("touchstart", $event => this.activateMouseDown($event, obj));
        obj.on("touchend", $event => this.deactivateMouseDown($event));
        obj.on("touchmove", $event => this.changeLocation($event, obj));
        obj.on("touchendoutside", $event => this.deactivateMouseDown($event));
    }


    activateMouseDown(event, obj: any) {
        if (this.isLocked) return
        const mousePos = event.data.getLocalPosition(this.container);
        let x,y;
        if(this.element.isSnapping){
            x = this.grid.getSnappedAxisData(AXIS.X, this.grid.xAxisData, mousePos.x);
            y = this.grid.getSnappedAxisData(AXIS.Y, this.grid.yAxisData, mousePos.y);
        }

        if(!this._validatePosition(x.displayCoord, y.displayCoord)) return;
    
        
        if(this.value.has(this._getName(x.coord, y.coord))){
            this._removeGraphics(x.coord, y.coord);
        } else {
            this._addGraphics(x, y);
        }

        this.updateState();
    }

    deactivateMouseDown($event) {
        if (this.isLocked) return
        // throw new Error("Method not implemented.");
    }
    changeLocation($event, obj: any) {
        if (this.isLocked) return
        // throw new Error("Method not implemented.");
    }

    private _addGraphics_keyborad_hover(x, y){
        let {isPointCircled, pointColor, pointColorAlpha, pointRadius, circleRadius, circleColor, circleBorder} = this.element

        let point = drawCircle(x.displayCoord, y.displayCoord, pointRadius * 1.2, {
          isFilled: true,
          color: this.invertColor(pointColor),
          alpha: pointColorAlpha
        });
        point.name = "Hover Point";
        this.pointsContainer.addChild(point);
        if (this.isHCMode()) circleColor="#ffffff"
        if(isPointCircled){
            let circle = drawCircle(x.displayCoord, y.displayCoord, circleRadius * 1.2, {
              isFilled: false,
              color: this.invertColor(circleColor),
              width: circleBorder * 0.8
            });
            circle.name = "Hover Circle";
            this.pointsContainer.addChild(circle);
        }
        this.render();
    }

    private _removeGraphics_keyborad_hover = () => {
        let point = this.pointsContainer.getChildByName("Hover Point");
        if(this.element.isPointCircled){
            let circle = this.pointsContainer.getChildByName("Hover Circle");
            this.pointsContainer.removeChild(circle);
        } 
        this.pointsContainer.removeChild(point);
        this.render();
    }

    private _addGraphics(x, y){
        let {isPointCircled, pointColor, pointColorAlpha, pointRadius, circleRadius, circleColor, circleBorder} = this.element

        let point = drawCircle(x.displayCoord, y.displayCoord, pointRadius, {
          isFilled: true,
          color: pointColor,
          alpha: pointColorAlpha
        });
        point.name = this._getName(x.coord, y.coord);
        this.pointsContainer.addChild(point);
        if (this.isHCMode()) circleColor="#ffffff"
        if(isPointCircled){
            let circle = drawCircle(x.displayCoord, y.displayCoord, circleRadius, {
              isFilled: false,
              color: circleColor,
              width: circleBorder
            });
            circle.name = this._getName(x.coord, y.coord, true);
            this.pointsContainer.addChild(circle);
        }

        this.value.set(this._getName(x.coord, y.coord),[x.coord, y.coord]);
        this.render();
    }

    private _removeGraphics = (x: number, y: number) => {
        let point = this.pointsContainer.getChildByName(this._getName(x, y));
        if(this.element.isPointCircled){
            let circle = this.pointsContainer.getChildByName(this._getName(x, y, true));
            this.pointsContainer.removeChild(circle);
        } 
        this.pointsContainer.removeChild(point);
        this.value.delete(this._getName(x, y));
        this.render();
    }

    private _validatePosition(x,y){
        let isInsideGrid = x >= this.grid.xStart && x <= this.grid.xEnd && y <= this.grid.yStart && y >= this.grid.yEnd;
        return isInsideGrid;
    }

    private _getName(x, y, circle?: boolean){
        if(circle) return `circle(${x},${y})`;
        return `point(${x},${y})`;
    }

    private _xAxisMove_keyboard(direction_left: boolean, keys_x, keys_y){
        if(direction_left){
            if (this.prev_x_index > 0)
                this.prev_x_index = this.prev_x_index - 1;
            else 
                this.prev_x_index = keys_x.length - 1;
        } else {
            if (this.prev_x_index < keys_x.length - 1)
                this.prev_x_index = this.prev_x_index + 1;
            else 
                this.prev_x_index = 0;
        }
        
        this._removeGraphics_keyborad_hover();
        this._addGraphics_keyborad_hover(this.grid.xAxisData.get(keys_x[this.prev_x_index]), this.grid.yAxisData.get(keys_y[this.prev_y_index]));
    }

    private _yAxisMove_keyboard(direction_down: boolean, keys_x, keys_y){
        if (direction_down){
            if(this.prev_y_index > 0)
                this.prev_y_index = this.prev_y_index - 1;
            else 
                this.prev_y_index = keys_y.length - 1;

        } else {
            if(this.prev_y_index < keys_y.length - 1)
                this.prev_y_index = this.prev_y_index + 1;
            else 
                this.prev_y_index = 0;
        }
        
        this._removeGraphics_keyborad_hover();
        this._addGraphics_keyborad_hover(this.grid.xAxisData.get(keys_x[this.prev_x_index]), this.grid.yAxisData.get(keys_y[this.prev_y_index]));
    }

    private _placePoint_keyboard(keys_x, keys_y){
        let obj_x = {
            displayCoord: this.grid.xAxisData.get(keys_x[this.prev_x_index]).displayCoord,
            coord: keys_x[this.prev_x_index],
        }
        let obj_y = {
            displayCoord: this.grid.yAxisData.get(keys_y[this.prev_y_index]).displayCoord,
            coord: keys_y[this.prev_y_index],
        }
        
        if(this.value.has(this._getName(obj_x.coord, obj_y.coord))){
            this._removeGraphics(obj_x.coord, obj_y.coord);
        } else {
            this._addGraphics(obj_x, obj_y);
        }
        this.updateState();
    }

    invertColor = (col) => {
        if(col){
            col = col.toLowerCase();
            const colors = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f']
            let inverseColor = '#'
            col.replace('#','').split('').forEach(i => {
              const index = colors.indexOf(i)
              inverseColor += colors.reverse()[index]
            })
            return inverseColor
          }
          return undefined;
    }
}

