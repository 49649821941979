import { IMenuTabConfig } from "../../../ui-partial/menu-bar/menu-bar.component";

export interface IView<T> extends IMenuTabConfig<T> {
    imgUrl: string,
    description: string,
    hasIndicator?: boolean,
    disabled?: boolean
}

export enum BCGRADMinistryAdminView {
    DASHBOARD = 'dashboard',
    ASSESSMENTS = 'assessments',
    ASSESSMENT_MATERIALS = 'assessment_materials',
    STUDENT_ENROLLMENT_INFO_AND_ACCOUNTS = 'accounts',
    SESSION_PROGRESS_REPORTS = 'session_reports',
    AEGROTAT_DISQUALIFICATION = 'aegrotat_disqualification',
    SCANNING = 'scanning',
    MARKING = 'marking',
    FINAL_RESULTS = 'final_results'
}

export const BC_GRAD_MINISTRY_ADMIN_VIEWS = [
    {
        id: BCGRADMinistryAdminView.ASSESSMENT_MATERIALS,
        caption: ('Assessment Materials'), //'Assesement Materials',
        imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/assessment_materials/1610134259593/assessment_materials.svg',
        description: ('Use this page to view the Password Information Sheet, Assessment Administration Guides and Assessment materials.'),
        disabled: false,
    },
    {
        id: BCGRADMinistryAdminView.STUDENT_ENROLLMENT_INFO_AND_ACCOUNTS,
        caption: ('Student Registration & School Accounts'), //'Student Enrollment Information & District/School Accounts',
        imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/student_info/1610134278044/student_info.svg',
        description: ('Use this page to review the list of registered students and administrator accounts in order to resolve registration and access issues.'),
        disabled: false
    },
    {
        id: BCGRADMinistryAdminView.SESSION_PROGRESS_REPORTS,
        caption: ('Session Reports'), //'Session Progress Report',
        imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/session_progress_reports/1610134294563/session_progress_reports.svg',
        description: ('Use this page to review session reports.'),
        disabled: false,
    },
    {
        id: BCGRADMinistryAdminView.AEGROTAT_DISQUALIFICATION,
        caption: ('Add Special Cases'), //'Add Aegrotat or Disqualification',
        imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/aegrotat/1625675265719/aegrotat.svg',
        description: ('Use this page to add aegrotat, disqualification, or special formats.'),
        disabled: false,
    },
    {
        id: BCGRADMinistryAdminView.SCANNING,
        caption: ('Printing & Uploading Response Sheets'), //'Scanning',
        imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/scanning/1610134310674/scanning.svg',
        description: ('Use this page to upload, download, and review student response sheets for the Grade 10 Numeracy Assessment.'),
        disabled: false,
    },
    {
        id: BCGRADMinistryAdminView.MARKING,
        caption: ('Marking'), //'Marking',
        imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/marking/1610134324629/marking.svg',
        description: ('Use this page to review and validate the Marking session.'),
        disabled: false
    },
    {
        id: BCGRADMinistryAdminView.FINAL_RESULTS,
        caption: ('Final Results'), //'Final Results',
        imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/final_results/1610134339358/final_results.svg',
        description: ('Use this page to review the final results of the secure assessments.'),
        disabled: false,
    }
]
