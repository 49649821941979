<div class="page-content" [ngSwitch]="!!isLoginLink()">
  <div class="form-content" *ngSwitchCase="true">
    <p>You are accessing the site using an access link, but due to an extended period of inactivity  your login needs to be renewed. In order to log back in, use the link that was most recently sent to you via email.</p>
  </div>
  <div class="form-content" *ngSwitchCase="false">
    <div>
      <h2 class="strong"> {{lang.tra(getMainHeader())}} </h2>
    </div>
    <h2 class="minor"> {{lang.tra('lbl_returning_users')}} </h2>
    <div class="form-instruction-major">
      {{ lang.tra(getMainInstruction()) }}
    </div>

    <form [formGroup]="loginCtrl.formGroup" (ngSubmit)="submitForm()">

      <div class="field">
        <label class="label">
          {{lang.tra('lbl_user_or_email')}}
        </label>
        <div *ngIf="isInputBlank(loginCtrl.formGroupEmailAddress)" class="help "> 
            {{lang.tra('error_enter_email')}}
        </div>
        <div *ngIf="isInputInvalid(loginCtrl.formGroupEmailAddress)" class="help is-danger"> 
          {{lang.tra('error_login_invalid_email')}}
        </div>
        <div class="control">
          <input 
            type="text"
            id="username"
            [formControl]="loginCtrl.formGroupEmailAddress"
            class="input" 
            [class.is-warning]="isInputBlank(loginCtrl.formGroupEmailAddress)"
            [class.is-danger] ="isInputInvalid(loginCtrl.formGroupEmailAddress)"
          >
        </div>
      </div>

      <div class="field">
        <label class="label">
          {{lang.tra('lbl_password')}}
        </label>
        <div *ngIf="isInputBlank(loginCtrl.formGroupPassword)" class="help "> 
          {{lang.tra('error_enter_password')}}
        </div>
        <div *ngIf="isInputInvalid(loginCtrl.formGroupPassword)" class="help is-danger"> 
          {{lang.tra('error_min_length_password')}}
        </div>
        <div class="control">
          <input 
            type="password"
            id="password"
            [formControl]="loginCtrl.formGroupPassword"
            class="input" 
            [class.is-warning]="isInputBlank(loginCtrl.formGroupPassword)"
            [class.is-danger] ="isInputInvalid(loginCtrl.formGroupPassword)"
          >
        </div>
      </div>

      <div class="notification is-warning" *ngIf="loginCtrl.isFormFailed">
        <div [ngSwitch]="loginCtrl.formFailReason">
          <div *ngSwitchCase="FormFail.NOT_FOUND">
            {{lang.tra('txt_alert_bad_login')}}
          </div>
          <div *ngSwitchCase="FormFail.NOT_VERIFIED">
            {{lang.tra('txt_alert_bad_login_unverified')}}
          </div>
          <div *ngSwitchCase="FormFail.UNKNOWN">
            {{lang.tra('txt_alert_bad_login')}}
          </div>
        </div>
      </div>

      <div>
        <input 
          type="submit"
          [value]="lang.tra('sign_in')"
          id="signIn"
          class="button is-large is-success is-fullwidth signin"
          [disabled]="isFormSent"
        />
      </div>
      
    </form>

    
    <div>
      <a [routerLink]="getForgotPasswordRoute()">
        {{lang.tra('lbl_forgot_password')}}
      </a>
    </div>
    <div class="or-horz"><hr> {{lang.tra('dividing_or')}} <hr></div>
    <div *ngIf="isCreateAcctActive()">
      <h2 class="minor">
        {{lang.tra('lbl_new_users')}} 
      </h2>
      <a [routerLink]="getAccountCreationRoute()" class="button is-info is-large is-fullwidth">
        {{lang.tra('btn_create_account')}}
      </a>
    </div>

    <div *ngIf="loginCtrl.loginErrorTroubleshootMsg" style="margin-top:4em;">
      <button class="button is-light is-small" (click)="loginCtrl.isLoginTroubleshooting = !loginCtrl.isLoginTroubleshooting">Troubleshoot Login</button>
      <div *ngIf="loginCtrl.isLoginTroubleshooting" style="margin-top:1em;">
        {{loginCtrl.loginErrorTroubleshootMsg}}
      </div>
    </div>

  </div>
  
</div>
