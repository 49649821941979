import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, interval } from 'rxjs';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { OverlayRef } from '@angular/cdk/overlay';
export const KEY_C = 67;
export const KEY_V = 86;
export const KEY_X = 88;

interface ILog {
    id: string,
    chars_per_sec: number,
    characters?: {
        charsAdded: string,
        charsDeleted: string,
    },
    keystroke_count: number,
    date: Date,
    flagged_keystrokes?: string[]
}

interface Inputs {
    val: string,
    isActive: boolean,
}
export enum Keystroke {
    CUT = 'CUT',
    COPY = 'COPY',
    PASTE = 'PASTE'
}

const TEMP_LOG_INTERVAL_MS = 1000;
const BUNDLE_LOG_INTERVAL_MS = 30000;
@Injectable({
  providedIn: 'root'
})
export class StudentSecurityService {
    defaultLogSlug:string = "student-asmt-activity/keystrokes";
    timerLogSub: Subscription;
    timerBundleSub: Subscription;
    tempLogs: ILog[] = [];
    logs: ILog[][] = [];
    currInputs: Map<string, Inputs> = new Map();
    lastInputs: Map<string, Inputs> = new Map();
    keystrokeCount: number = 0;
    flaggedKeystrokes: Keystroke[] = [];
    charCount: number = 0;
    textEventListener = this.textKeyDown.bind(this);
    private textChangeSubject = new BehaviorSubject<{ textEntryId: string, value: string }>(null);
    textChangeData$ = this.textChangeSubject.asObservable();

    private filterRegEx = /(<p>|<\/p>|<strong>|<\/strong>|<i>|<\/i>|<u>|<\/u>|<mark [^>]+>|<\/mark>|<ul>|<\/ul>|<li>|<\/li>|<ol>|<\/ol>|&nbsp;|\s)/
    private filterChars = ['', '&nbsp;','\t', '<p>','&nbsp;</p>', '&nbsp;&nbsp;</p>', '</p>', '<p>', '<strong>', '</strong>', '<i>', '</i>', '<u>', '</u>', '<ul>', '</ul>', '<ol>', '</ol>', '<li>', '</li>', '<mark', '</mark>']

    private activeContextMenuOverlay: OverlayRef | null = null;
    private activeContextMenuSub: Subscription | null = null;

    constructor(
        public auth: AuthService,
        private routes: RoutesService
    ) {
    }

    uid: number;
    testSessionId: number;
    testAttemptId: number;
    itemId: number
    init() {
        this.resetCounts();
        this.startLogging();
        this.addTextEventListener();
    }

    initIds(uid: number, testSessionId: number, testAttemptId: number, itemId: number) {
        this.uid = uid;
        this.testSessionId = testSessionId;
        this.testAttemptId = testAttemptId;
        this.itemId = itemId;
    }

    destroy() {
        if(this.timerBundleSub) {
            this.timerBundleSub.unsubscribe();
        }
        if(this.timerLogSub) {
            this.timerLogSub.unsubscribe();
        }
        this.removeTextEventListener();
        
        // when we destroy the service, we want to log the last remaining unlogged keystrokes
        if(this.tempLogs.length > 0) {
            this.logStudentAction(this.tempLogs);
        }
    }

    getRegEx(): RegExp {
        return this.filterRegEx;
    }

    getFilteredChars(): string[] {
        return this.filterChars;
    }

    updateKeystroke(count: number, key: Keystroke) {
        this.keystrokeCount += count;
        if(key.length > 1) {
            this.flaggedKeystrokes.push(key)
        }
    }

    updateChar(id: string, input: string) {

        this.currInputs.set(id, {val: input, isActive: true});
    }
    private escapeRegExp(input) {
        return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

      disableInput(id:string, text:string) {
        this.currInputs.set(id, {val: text, isActive: false})
      }
      activateInputId(inputId:string, text:string) {
        for (const [ id ] of this.currInputs.entries()) {
          if (id == inputId) {
            this.currInputs.set(id, {val: text, isActive: true});
            this.lastInputs.set(id, {val: text, isActive: true});
            return id;
          }
        }
        // Handles case where initializing textbox first time
          this.currInputs.set(inputId, {val: text, isActive: true});
          this.lastInputs.set(inputId, {val: text, isActive: true})
          return inputId;
      }

    startLogging() {
        if(!this.auth.userIsStudent()) {
            // console.log('NOT STUDENT')
            return;
        }
        const timerSecond$ = interval(TEMP_LOG_INTERVAL_MS); // Emit a value every 1000ms (1 second)
        // const timerThirtyInterval$ = interval(BUNDLE_LOG_INTERVAL_MS);

        this.timerLogSub = timerSecond$.subscribe(() => {
            let allKeys = Array.from(this.currInputs.keys());
            // Grab active keys only (inputs currently available on view)
            let activeCurrInputs = allKeys.filter(key => this.currInputs.get(key).isActive === true);
            activeCurrInputs.forEach((key) => {
                const currentInput = this.currInputs.get(key).val ? this.processInput(this.currInputs.get(key).val) : '';

                const lastInputData = this.lastInputs.get(key);
                const lastInput = lastInputData ? (this.lastInputs.get(key).val ? this.processInput(this.lastInputs.get(key).val) : '') : '';
                
                const chars_per_sec = currentInput.length - lastInput.length || 0;
                if (chars_per_sec === 0) {
                    return
                }
                const charsAdded = currentInput.replace(new RegExp(this.escapeRegExp(lastInput)), '');
                const charsDeleted = lastInput.replace(new RegExp(this.escapeRegExp(currentInput)), '');

                // Create the log object
                const log: ILog = {
                    id: key,
                    chars_per_sec,
                    characters: {
                        charsAdded,
                        charsDeleted,
                    },
                    keystroke_count: this.keystrokeCount,
                    flagged_keystrokes: this.flaggedKeystrokes,
                    date: new Date()
                };

                if(!(this.tempLogs.length > 0 && this.charCount == 0 
                    && this.keystrokeCount == 0 
                    && this.tempLogs[this.tempLogs.length-1].chars_per_sec == 0 
                    && this.tempLogs[this.tempLogs.length-1].keystroke_count == 0
                )) {
                    this.tempLogs.push(log);
                }
            });

            // Resets
             this.lastInputs = new Map(this.currInputs);
             this.resetCounts();
        });

        // this.timerBundleSub = timerThirtyInterval$.subscribe(() => {
        //     console.log(this.tempLogs);
        //     this.logStudentAction(this.tempLogs);
        //     this.tempLogs = [];
        // })
    }

    async logStudentAction(info: any, slug = this.defaultLogSlug) {
        console.log('logs', info);
        try {
            let result = await this.auth.apiCreate( this.routes.LOG,
                {
                  slug,
                  data: {
                      uid: this.uid,
                      test_session_id: this.testSessionId,
                      test_attempt_id: this.testAttemptId,
                      item_id: this.itemId,
                      info
                  }
                }
              )
              return result;
        }
        catch (err) {
            throw err
        }
      }

    resetCounts() {
        this.charCount = 0;
        this.keystrokeCount = 0;
        this.flaggedKeystrokes = [];
    }

    textKeyDown(e: KeyboardEvent) {
        let key = e.key;
        if((e.metaKey || e.ctrlKey)) {
            // paste events handled in pasteHandler
            if(e.keyCode == KEY_C) {
                key = Keystroke.COPY;
            } else if(e.keyCode == KEY_X) {
                key = Keystroke.CUT;
            }
        }

        this.updateKeystroke(1, key as Keystroke);
    }
    pasteHandler() {
        let key;
        key = Keystroke.PASTE;
        this.updateKeystroke(1, key);
    }

    addTextEventListener() {
        window.addEventListener("keydown", this.textEventListener);
    }

    removeTextEventListener() {
        window.removeEventListener("keydown", this.textEventListener);
    }

    processInput(input) {
        const words: string[] = input.split(this.getRegEx());
        let processedString = [];
        
        words.forEach((word: string, i: number)=>{
        if (!this.getFilteredChars().includes(word)) {
            let additional = '';
            processedString.push(word + additional);
        }
        })

        processedString.forEach((word: string, i: number) => {
        let additional = '';
        if(i < words.length - 1 && words[i+1] != ' ' && word != ' ') {
            additional = ' ';
        }
        word+=additional;
        })

        return processedString.join('');
    }

    public setActiveContextMenu(ref: OverlayRef, mouseClickSub: Subscription): void {
        if (this.activeContextMenuOverlay) {
            this.activeContextMenuOverlay.dispose();
        }

        if (this.activeContextMenuSub) {
            this.activeContextMenuSub.unsubscribe();
        }

        this.activeContextMenuOverlay = ref;
        this.activeContextMenuSub = mouseClickSub;
        ref = null;
        mouseClickSub = null;
    }
}
