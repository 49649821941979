import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { LangService } from "./lang.service";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DataGuardService implements CanDeactivate<any> {
    private active: boolean = false;
    private forceSave = new Subject();
    private leavePageResponse = new Subject();
    private customConfirmation: () => Promise<boolean> | boolean = null;
    constructor(public lang: LangService) {
        //console.log('constructing DataGuardService');
    }
    triggerForceSave() {
        this.forceSave.next(true);
    }
    forceSaveSub() {
        return this.forceSave;
    }
    canDeactivate(
        component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Promise<boolean> | boolean {
        if (this.active) {
            if (this.customConfirmation) {
                let conf = this.customConfirmation();
                let conf_promise = Promise.resolve(conf).then((res) => {
                    this.leavePageResponse.next(res);
                    return res;
                });
                return conf_promise;
            }
            let res = confirm(this.lang.tra('leave_page_unsaved_changes'));
            this.leavePageResponse.next(res);
            return res;
        }
        return true;
    }
    isActive() {
        //console.log(`retrieving guard: ${this.active}`);
        return this.active;
    }
    activate() {
        //console.log('activating guard');
        this.active = true
    }
    deactivate() {
        //console.log('deactivating guard');
        this.active = false
    }
    leavePageResponseSub() {
        return this.leavePageResponse;
    }
    setCustomConfirmation(confirmationFunc: () => Promise<boolean> | boolean) {
        this.customConfirmation = confirmationFunc;
    }
    clearCustomConfirmation() {
        this.customConfirmation = null;
    }
}
