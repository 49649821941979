import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class videoPlaybackService {
    private _currentUniversalPlaybackSpeed: number = 1;
    private _isUniversalPlaybackSpeedDisabled: boolean = false;

    constructor() { }

    public get currentUniversalPlaybackSpeed(){
        return this._currentUniversalPlaybackSpeed;
    }

    public get isUniversalPlaybackSpeedDisabled(){
        return this._isUniversalPlaybackSpeedDisabled;
    }

    public set currentUniversalPlaybackSpeed(playbackSpeed: number){
        this._currentUniversalPlaybackSpeed = playbackSpeed;
    }

    public set isUniversalPlaybackSpeedDisabled(isUniversalPlaybackSpeedDisabled: boolean){
        this._isUniversalPlaybackSpeedDisabled = isUniversalPlaybackSpeedDisabled;
    }
}

