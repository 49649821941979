import { Injectable } from "@angular/core";
import moment from "moment";
import { AuthService } from "../api/auth.service";
import { RoutesService } from "../api/routes.service";
import { Filter, FilterCondition, PaginatedRows, Pagination } from "../bc-accounts/bc-accounts.service";
import { LangService } from "../core/lang.service";
import {
    DailySubmissionSummaryReport,
    DailySubmissionSummaryReportParams,
    DistrictRollUpReport,
    DistrictRollUpReportParams,
    DistrictRollUpReportRow,
    EnrollmentExtract1300CReportParams,
    EnrollmentExtract1300CReportRow,
    EnrollmentExtract1300DReportParams,
    EnrollmentExtract1300DReportRow,
    SchoolCompletionReportParams,
    SchoolCompletionReport,
    SchoolsNotStartedReportParams,
    SchoolsNotStartedReportRow,
    IndividualRawScoresReport,
    IndividualRawScoresReportParams,
    CognitiveLevelScoresReportParams,
    CognitiveLevelScoresReport,
    StudentSelfReflectionReportParams,
    StudentSelfReflectionReport,
    ReportType,
    StudentLoginReportParams,
    StudentLoginReportRow,
    StudentResponseTimesReportParams,
    StudentResponseTimesReportRow,
    DailyDistrictSubmissionReport,
    DailyDistrictSubmissionReportParams,
    EnrollmentExtract1300DReportExportParams,
    SchoolsNotStartedReportExportParams,
    ConfirmationCodeReportRow,
    IndividualScaledScoresReportParams,
    DailyDistrictSubmissionReportExportParams,
    IGradLoginReportParams,
    IGradLoginReportRow,
    ILoginByDistrictReportParams,
    ILoginByDistrictReportRow,
    SchoolCompletionReportExportParams,
    StudentLoginReportExportParams,
    StudentResponseTimesReportExportParams,
    ConfirmationCodeReportParams,
    IScansByDistrictReportRow,
    IScansByDistrictReportParams,
    ILoginBySchoolReportParams,
    ILoginBySchoolReportRow,
    IScansBySchoolReportParams,
    IScansBySchoolReportRow,
    ILoginScanByProvinceReportParams,
    ILoginScanByProvinceReport,
    IScansByProvinceReportParams,
    IScansByProvinceReportRow,
    IGradSRScoreParams,
    IGradLTESRScoreRow,
    IGradCRScoreParams,
    IGradLTECRScoreRow,
    gradSRScores,
    IUnfilteredGradCRScoreRow,
    IFsaSampleAssessmentLoginReportParams,
    IFsaSampleAssessmentLoginReportRow,
    IDoARReportParams,
    DLLoginReportParams,
    DLLoginReportRow,
    gradAssessmentSummary,
    DOARReturnData,
    DOARTQRMap,
    LoginByDistrict,
    LoginBySchool,
    DOARLoginBreakdowns
} from "./types";

@Injectable({
    providedIn: 'root'
})
export class BcReportsService {

    constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private lang: LangService,
    ) 
    {
    }

    DLPageSelectedAssessment: gradAssessmentSummary = null;

    setDLPageSelectedAssessment(assessment: gradAssessmentSummary)
    {   
        this.DLPageSelectedAssessment = assessment;
    }

    getDLPageSelectedAssessment(): gradAssessmentSummary
    {   
        return this.DLPageSelectedAssessment;
    }

    FSASessionReportSelectedTWId: number = null;

    setFSASessionReportSelectedTW(testWindowId: number)
    {
        this.FSASessionReportSelectedTWId = testWindowId;
    }

    getFSASessionReportSelectedTW(): number
    {
        return this.FSASessionReportSelectedTWId;
    }

    /* Daily Submission Summary Report */

    getDailySumissionReport(params: DailySubmissionSummaryReportParams, pagination: Pagination): Promise<DailySubmissionSummaryReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.DAILY_SUBMISSION_SUMMARY,
                start_date: params.start_date.toISOString(),
                end_date: params.end_date.toISOString(),
                test_window_id: params.test_window_id,
                pagination,
            }
        });
    }

    async exportDailySubmissionSummaryReport(params: DailySubmissionSummaryReportParams) {
        let filename = `Daily-Submission-Summary-Report-${moment(params.start_date).format('YYYY-MM-DD')}-to-${moment(params.end_date).format('YYYY-MM-DD')}`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.DAILY_SUBMISSION_SUMMARY,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }


    /* District Roll Up Report */

    async getDistrictRollUpReport(params: DistrictRollUpReportParams, pagination: Pagination): Promise<DistrictRollUpReport> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.DISTRICT_ROLL_UP,
                grades: JSON.stringify(params.grades),
                test_window_id: params.test_window_id,
                start_date: params.start_date,
                end_date: params.end_date,
                pagination,
            }
        });
    }

    async exportDistrictRollUpReport(params: DistrictRollUpReportParams, pagination: Pagination) {
        let filename = `District-Roll-Up-Report-Grade(s)-${params.grades.join('-')}`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.DISTRICT_ROLL_UP,
                grades: JSON.stringify(params.grades),
                test_window_id: params.test_window_id,
                pagination,
            }
        }), filename);
        window.open(url, '_blank');
    }


    /* Enrollment Extract 1300C Report */

    getEnrollmentExtract1300cReport(params: EnrollmentExtract1300CReportParams, pagination: Pagination): Promise<EnrollmentExtract1300CReportRow[]> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.ENROLLMENT_EXTRACT_1300_C,
                ...params,
                pagination,
            }
        });
    }

    async exportEnrollmentExtract1300cReport(test_window_id: number) {
        let filename = `Summary-of-Enrolled-Students-from-Sept-and-Feb-1701-Report-Data`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.ENROLLMENT_EXTRACT_1300_C,
                test_window_id,
            }
        }), filename);
        window.open(url, '_blank');
    }


    /* Enrollment Extract 1300D Report */

    getEnrollmentExtract1300dReport(params: EnrollmentExtract1300DReportParams, pagination: Pagination): Promise<PaginatedRows<EnrollmentExtract1300DReportRow>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.ENROLLMENT_EXTRACT_1300_D,
                load: params.load,
                district_group_id: params.district_group_id,
                testWindowId: params.testWindowId,
                pagination,
            }
        });
    }

    async exportEnrollmentExtract1300dReport(params: EnrollmentExtract1300DReportExportParams) {
        let filename = `Summary-of-Participating-Schools-and-Students-(${params.district_name})`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.ENROLLMENT_EXTRACT_1300_D,
                district_group_id: params.district_group_id,
                testWindowId: params.testWindowId,
            }
        }), filename);
        window.open(url, '_blank');
    }


    /* School Completion Report */

    getSchoolCompletionReport(params: SchoolCompletionReportParams, pagination: Pagination): Promise<SchoolCompletionReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.SCHOOL_COMPLETION,
                district_group_id: params.district_group_id,
                test_window_id: params.test_window_id,
                school_group_id: params.school_group_id,
                no_independent: params.no_independent,
                grades: JSON.stringify(params.grades),
                pagination,
            }
        });
    }

    async exportSchoolCompletionReport(filename: string, params: SchoolCompletionReportExportParams) {
        let _filename = `${filename}-(${params.district_name})`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.SCHOOL_COMPLETION,
                lang: this.lang.c(),
                ...params,
            }
        }), _filename);
        window.open(url, '_blank');
    }

    /* Roll Up Report */
    /* School Completion Report */

    getRollUpReport(params: SchoolCompletionReportParams, pagination: Pagination): Promise<SchoolCompletionReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.SCHOOL_COMPLETION,
                district_group_id: params.district_group_id,
                test_window_id: params.test_window_id,
                school_group_id: params.school_group_id,
                no_independent: params.no_independent,
                grades: JSON.stringify(params.grades),
                pagination,
                mark_with_asterisk: 1,
            }
        });
    }

    async exportRollUpReport(filename: string, params: SchoolCompletionReportExportParams) {
        let _filename = `${filename}-(${params.district_name})`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.SCHOOL_COMPLETION,
                ...params,
                mark_with_asterisk: 1,
            }
        }), _filename);
        window.open(url, '_blank');
    }


    /* Schools Not Started Report */

    getSchoolsNotStartedReport(params: SchoolsNotStartedReportParams, pagination: Pagination): Promise<PaginatedRows<SchoolsNotStartedReportRow>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.SCHOOLS_NOT_STARTED,
                district_group_id: params.district_group_id,
                school_group_id: params.school_group_id,
                grades: JSON.stringify(params.grades),
                test_window_id: params.test_window_id,
                pagination,
            }
        });
    }

    async exportSchoolsNotStartedReport(params: SchoolsNotStartedReportExportParams) {
        let filename = `Schools-Not-Started-the-Online-FSA-and-CR-Score-Entry-(${params.district_name})`;
        if (params.school_group_id && params.school_name) {
            filename += `-(${params.school_name})`;
        }
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                lang: this.lang.c(),
                report: ReportType.SCHOOLS_NOT_STARTED,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }

    getIndividualRawScoresReport(params: IndividualRawScoresReportParams, pagination: Pagination): Promise<IndividualRawScoresReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.INDIVIDUAL_RAW_SCORES,
                ...params,
                pagination,
            }
        });
    }

    getIndividualScaledScoresReport(params: IndividualScaledScoresReportParams, pagination: Pagination): Promise<IndividualRawScoresReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.INDIVIDUAL_SCALED_SCORES,
                ...params,
                is_scaled: 1,
                pagination,
            }
        });
    }

    getCognitiveLevelScoresReport(params: CognitiveLevelScoresReportParams, pagination: Pagination): Promise<CognitiveLevelScoresReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.COGNITIVE_LEVEL_SCORES,
                ...params,
                pagination,
            }
        });
    }

    async getStudentSelfReflectionReport(params: StudentSelfReflectionReportParams, pagination: Pagination): Promise<StudentSelfReflectionReport> {
        const report: StudentSelfReflectionReport = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.STUDENT_SELF_REFLECTION,
                ...params,
                pagination,
            }
        });

        const formatResponse = (response_raw: string): string => {
            if (!response_raw) return;

            let responseFormatted: string = '';

            let response = JSON.parse(response_raw);
            let entries = Object.entries(response) as [[string, any]];

            let mcqResponse = entries.find(([key, value]) => value && value.type == 'mcq');
            if (mcqResponse) {
                if (mcqResponse[1]) {
                    if (mcqResponse[1].selections) {
                        if (mcqResponse[1].selections[0]) {
                            if (mcqResponse[1].selections[0].advancedList) {
                                let elements = mcqResponse[1].selections[0].advancedList as any[];
                                let textElement = elements.find(e => e.elementType == 'text');
                                if (textElement) {
                                    responseFormatted += `<b>(${(textElement.caption as string).split('\n').join(' ')})</b>`;
                                }
                            }
                        }
                    }
                }

            }

            let textResponse = entries.find(([key, value]) => value && value.type == 'input-longtext');
            if (textResponse) {
                let str = textResponse[1].str;
                if (str) {
                    responseFormatted += '\n' + str;
                }
            }

            return responseFormatted;
        }

        // report.data.data.map(row => {
        //     row.literacy = formatResponse(row.literacy);
        //     row.numeracy = formatResponse(row.numeracy);
        // });

        return report;
    }


    /* Student Login Report */

    getStudentLoginReport(params: StudentLoginReportParams, pagination: Pagination): Promise<PaginatedRows<StudentLoginReportRow>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.STUDENT_LOGIN,
                ...params,
                pagination,
            }
        });
    }

    async exportStudentLoginReport(params: StudentLoginReportExportParams, filename: string = `Student-Login-Report`) {
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.STUDENT_LOGIN,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }


    /* Student Response Times Report */

    getStudentResponseTimesReport(params: StudentResponseTimesReportParams, pagination: Pagination): Promise<PaginatedRows<StudentResponseTimesReportRow>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.STUDENT_RESPONSE_TIME,
                ...params,
                pagination,
            }
        });
    }

    async exportStudentResponseTimesReport(params: StudentResponseTimesReportExportParams) {
        let filename = `Student-Response-Times`;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.STUDENT_RESPONSE_TIME,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }

    private MINUTE = 60;
    private HOUR = 3600;
    private DAY = 86400;

    formatSeconds(seconds: number): string {
        if (seconds == null) return '';

        let days = Math.floor(seconds / this.DAY);
        seconds -= days * this.DAY;
        let hours = Math.floor(seconds / this.HOUR);
        seconds -= hours * this.HOUR;
        let minutes = Math.floor(seconds / this.MINUTE);
        seconds -= minutes * this.MINUTE;

        let str: string = '';
        str += `${seconds}s`
        if (minutes > 0 || hours > 0 || days > 0) {
            str = `${minutes}m ` + str;
        }
        if (hours > 0 || days > 0) {
            str = `${hours}h ` + str;
        }
        if (days > 0) {
            str = `${days}d ` + str;
        }
        return str;
    }



    /* Daily District Submission Report */

    getDailyDistrictSubmissionReport(params: DailyDistrictSubmissionReportParams, pagination: Pagination): Promise<DailyDistrictSubmissionReport> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.DAILY_DISTRICT_SUBMISSION,
                ...params,
                pagination,
            }
        });
    }

    async exportDailyDistrictSubmissionReport(params: DailyDistrictSubmissionReportExportParams) {
        let filename = `Daily-District-Submission-Report-${moment(params.start_date).format('YYYY-MM-DD')}-to-${moment(params.end_date).format('YYYY-MM-DD')}`;
        // let filename = 'Daily-District-Submission-Report';
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.DAILY_DISTRICT_SUBMISSION,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }

    async exportFsaSampleAssessmentLogins(params) {
        console.log(params);
        let filename = `FSA-Sample-Assessment-Login-Report-${moment(params.start_date).format('YYYY-MM-DD')}-to-${moment(params.end_date).format('YYYY-MM-DD')}`;

        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.FSA_SAMPLE_ASSESSMENT_LOGINS ,
                ...params,
            }
        }), filename);


        window.open(url, '_blank');
    };

    /* Confirmation Code Report */

    async getConfirmationCodeReport(params: ConfirmationCodeReportParams, pagination: Pagination): Promise<PaginatedRows<ConfirmationCodeReportRow>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.CONFIRMATION_CODEs,
                ...params,
                pagination,
            }
        });
    }

    async exportConfirmationCodeReport(params: ConfirmationCodeReportParams) {
        let filename = 'Confirmation-Codes-For-Completed-Assessments';
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: ReportType.CONFIRMATION_CODEs,
                ...params,
            }
        }), filename);
        window.open(url, '_blank');
    }

    /* Grad Login By Student Report */
    async getGradLoginReport(params: IGradLoginReportParams): Promise<PaginatedRows<IGradLoginReportRow>> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.LOGIN_BY_STUDENT,
                ...params,
            },
        });
    }

    /* Grad Login By Student Report For DL Schools Only */

    async getLoginReportForDLSchool(params: DLLoginReportParams): 
    Promise<
    {
        gradLoginReport: PaginatedRows<DLLoginReportRow['gradLoginReport']>, 
        uids: number[]
    }> 
    {
        return await this.auth.apiFind(this.routes.SCHOOL_ADMIN_DL_SCHOOLS, 
        {
            query: params
        });
    }


    /* Grad Login By District Report */
    async getGradLoginByDistrictReport(params: ILoginByDistrictReportParams): Promise<ILoginByDistrictReportRow[]> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.LOGIN_BY_DISTRICT,
                ...params,
            },
        });
    }


    /* Grad Scans By District Report */
    async getGradScansByDistrictReport(params: IScansByDistrictReportParams): Promise<IScansByDistrictReportRow[]> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.SCANS_BY_DISTRICT,
                ...params,
            },
        });
    }

    /* Grad Logins By School Report */
    async getGradLoginsBySchoolReport(params: ILoginBySchoolReportParams): Promise<PaginatedRows<ILoginBySchoolReportRow>> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.LOGIN_BY_SCHOOL,
                ...params,
            },
        });
    }


    /* Grad Scans By School Report */
    async getGradScansBySchoolReport(params: IScansBySchoolReportParams): Promise<IScansBySchoolReportRow[]> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.SCANS_BY_SCHOOL,
                ...params,
            },
        });
    }

    /* Grad Logins By Province Report */
    async getGradLoginsByProvinceReport(params: ILoginScanByProvinceReportParams): Promise<ILoginScanByProvinceReport> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.LOGIN_BY_PROVINCE,
                ...params,
            },
        });
    }

    /* Grad Scans By Province Report */
    async getGradScansByProvinceReport(params: IScansByProvinceReportParams): Promise<IScansByProvinceReportRow> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.SCANS_BY_PROVINCE,
                ...params,
            },
        });
    }

    // returns Promise<IGradLTESRScoreRow | gradSRScores[] >
    async getGradSRScores(params: IGradSRScoreParams): Promise<IGradLTESRScoreRow | any> 
    {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_GRAD_INDIVIDUAL_STUDENT_REPORTS_SR,
        {
            query: params
        });
    }
    
    /* FSA Sample Assessment Login Report */
    async getFsaSampleAssessmentLoginReport(pagination: Pagination, params: IFsaSampleAssessmentLoginReportParams): Promise<IFsaSampleAssessmentLoginReportRow[]> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: ReportType.FSA_SAMPLE_ASSESSMENT_LOGINS,
                pagination,
                ...params,
            }
        })
    }

    async getGradCRScores(params: IGradCRScoreParams): Promise<IUnfilteredGradCRScoreRow []>
    {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_GRAD_INDIVIDUAL_STUDENT_REPORTS_CR,
            {
                query: params
            });
    }

    async getDoARReport(params: IDoARReportParams): Promise<DOARReturnData> // todo: change return type to specified return data
    {
        return await this.auth.apiFind(this.routes.DISTRIBUTION_OF_ASSESSMENT_RESULTS_REPORT, 
        {
            query: params
        });
    }

    async getDOARStructures(testWindowId: number): Promise<DOARTQRMap[]>
    {
        return await this.auth.apiFind(this.routes.DISTRIBUTION_OF_ASSESSMENT_RESULTS_REPORT, 
        {
            query: 
            {
                DOARStructureReq: 1,
                testWindowId
            }
        });
    }

    async getGradISRNumLoginsPerSchoolAndDistrict(testWindowId: number, assessment: string): Promise<DOARLoginBreakdowns>
    {
        // used for the DOAR to create toggles for removing schools and districts without data
        const schoolLevel = await this.auth.apiFind(this.routes.DISTRIBUTION_OF_ASSESSMENT_RESULTS_REPORT,
        {
            query:
            {
                testWindowId,
                assessment,
                numLoginsBySchoolsReq: 1
            }
        });

        const districtLevel = await this.auth.apiFind(this.routes.DISTRIBUTION_OF_ASSESSMENT_RESULTS_REPORT,
        {
            query:
            {
                testWindowId,
                assessment,
                numLoginsByDistrictsReq: 1
            }
        });

        return {
            schoolLevel,
            districtLevel
        };
    }
}

