<div class="fsa-sample-assessment-login-report">
    <div class="filter-section">
        <label>Start Date:</label>
        <input type="date" [(ngModel)]="start_date" (change)="onDateChange()">

        <label>End Date:</label>
        <input type="date" [(ngModel)]="end_date" (change)="onDateChange()">

        <button 
            style="margin-left: 1rem;"
            class="button is-small export" 
            (click)="export()"
        >
            <span class="icon"></span>
            <span class="title">Export</span>
        </button>
    </div>

    <div>
        <bc-paginated-table 
            [pagination]="pagination" 
            [columnWidths]="tableColumnWidths" 
            [getRows]="getRows" 
            [getDisplay]="getDisplay"
            [columnHeadings]="columnHeadings"
        ></bc-paginated-table>
    </div>
</div>