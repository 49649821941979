import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { LangService } from 'src/app/core/lang.service';

@Component({
  selector: 'view-login-portal',
  templateUrl: './view-login-portal.component.html',
  styleUrls: ['./view-login-portal.component.scss']
})
export class ViewLoginPortalComponent implements OnInit {

  constructor(
    private auth:AuthService,
    private lang:LangService,
  ) { }

  fullName:string;
  accountTypes:any = [];

  ngOnInit(): void {

    const replaceProps = (str:string, params:any) => {
      Object.keys(params).forEach(paramKey => {
        str = str.split(':'+paramKey).join(params[paramKey])
      });
      return str;
    }

    this.auth.user().subscribe(async userInfo => {
      if (userInfo) {
        this.fullName = userInfo.firstName +' ' + userInfo.lastName
        const lang = this.lang.c();
        this.accountTypes = userInfo.accountTypes.map(record => {
          const {route_template, group_id} = record;
          record.route = '#'+replaceProps(route_template, {
            lang,
            group_id,
          })
          return record;
        })
      }
    })

  }

}
