declare var MediaRecorder: any;

import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MicService } from '../../microphone/mic.service';
import { AuthService } from '../../api/auth.service';
import { bindFormControls } from '../../ui-item-maker/services/data-bind';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { RoutesService } from '../../api/routes.service';
import { LangService } from '../../core/lang.service';
import { ScriptGenService } from '../../ui-item-maker/script-gen.service';
import { IContentElement, IQuestionConfig } from 'src/app/ui-testrunner/models';

export const DEFAULT_VOICEOVER_PROP = 'voiceover';

export const getVoiceChange = (el: IContentElement | IQuestionConfig, voiceoverProp: string = DEFAULT_VOICEOVER_PROP) => {
  if (el && el[voiceoverProp]){
    return el[voiceoverProp].url + el[voiceoverProp].script
  }
}

export const getElVoice =  (el: IContentElement | IQuestionConfig, voiceoverProp: string = DEFAULT_VOICEOVER_PROP) => {
  if (!el[voiceoverProp]) {
    el[voiceoverProp] = {script:'', url:'', lastModifiedOn:''}
  }
  return el[voiceoverProp]
}
@Component({
  selector: 'capture-voice',
  templateUrl: './capture-voice.component.html',
  styleUrls: ['./capture-voice.component.scss']
})
export class CaptureVoiceComponent implements OnInit, OnChanges {

  @ViewChild('recorder', { static: false }) recorder:ElementRef<HTMLElement>;
  @ViewChild('player', { static: false }) player:ElementRef<AudioNode>;

  @Input() element:any = {};
  @Input() title:string;
  @Input() isResponse:boolean;
  @Input() isSmall:boolean;
  @Input() isMagicEnabled:boolean = true;
  @Input() recordEndCallback:Function;
  @Input() scriptOverride:string;
  @Input() urlOverride:string;
  @Input() langOverride:string;
  @Input() scriptChanges:any;
  @Input() urlChanges:any;
  @Input() startNow?:boolean;
  @Input() isDisabled:boolean;
  @Input() alwaysOn?: boolean = false;
  @Input() fromItemVoiceOver?: boolean;
  @Output() notifyRecordingStart = new EventEmitter();
  @Output() triggerMagic = new EventEmitter();
  @Output() urlUpdate = new EventEmitter();
  

  constructor(
    private mic: MicService,
    private auth: AuthService,
    private routes: RoutesService,
    private lang: LangService,
    private sanitizer: DomSanitizer,
    private scriptGen: ScriptGenService,
  ) { }

  isEditing:boolean = false;
  isRecording:boolean = false;
  isUploading:boolean = false;
  shouldStop:boolean = false;
  isStopped:boolean = false;
  mediaRecorder:any;
  script = new FormControl();
  audioUrl;
  playbackSpeed = new FormControl('1');

  ngOnInit() {
    if (this.scriptOverride){
      this.element.script = this.scriptOverride;
      this.script.disable();
    }
    if (this.urlOverride){
      this.element.url = this.urlOverride
    }
    if (Array.isArray(this.element.script)){
      this.element.script = this.element.script[0]
    }
    bindFormControls(this.element, [
      {f: this.script, p:'script' },
    ]);
    this.updateFormElements();
    this.playbackSpeed.valueChanges.subscribe(()=>{
      const el:HTMLMediaElement = <any> this.player.nativeElement;
      if (el){
        el.playbackRate = +this.playbackSpeed.value;
      }
    })
  }

  updateFormElements(){
    // console.log('updateFormElements')
    this.updateScript();
    this.updateAudioUrl();
  }

  onTriggerMagic(upload:boolean = true){
    this.triggerMagic.emit();
    this.updateScript();
    
    if(upload) {
      this.runTextToVoice(this.fromItemVoiceOver);
    }
  }

  recorderEl;
  ngOnChanges(changes:SimpleChanges) {
    if (changes.element){
      this.updateFormElements();
    }
    if (changes.scriptOverride){
      this.script.setValue(changes.scriptOverride.currentValue);
    }
    if (changes.scriptChanges){
      this.updateScript();
    }
    if (changes.urlChanges){
      this.updateAudioUrl();
    }
    // if (changes.startNow) {
    //   if (this.startNow) {
    //     this.startRecording();
    //   }
    // }
    // if (this.element && this.recorder && this.recorderEl !== this.recorder.nativeElement){
    //   this.recorderEl = this.recorder.nativeElement;
    //   this.recorderEl.addEventListener('change', (e) => {
    //     const file = (<any>e.target).files[0];
    //     this.auth
    //       .uploadFile(file, file.name, 'voice', true)
    //       .then(res => {
    //         this.element.url = res.url;
    //         this.updateAudioUrl();
    //       })
    //   });
    // }
  }

  toggleEditing(){
    this.isEditing = !this.isEditing;
  }

  startRecording = () => {
    if (this.isRecording) return;
    this.isRecording = true;
    navigator
      .mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(this.startStream);
    this.notifyRecordingStart.emit();
  };

  stopRecording = () => {
    this.mediaRecorder.stop();
    this.isRecording = false;
    if(this.recordEndCallback){
      this.recordEndCallback();
    }
  };

  hasAudioUrl(){
    return !!this.element.url;
  }

  getAudioUrl(){
    return this.audioUrl;
  }

  updateScript(){
    if (this.element && this.element.script){
      this.script.setValue(this.element.script);
    }
    else{
      this.script.reset();
    }
  }
  updateAudioUrl(){
    // console.log('updateAudioUrl')
    // console.log('updateAudioUrl', this.element.url)
    if (this.element && this.element.url){
      this.audioUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.element.url);
    }
    else{
      this.audioUrl = null;
    }
    this.urlUpdate.emit({url: this.element.url});
  }

  startStream = (stream) => {
    const options = {mimeType: 'audio/webm'};
    const recordedChunks = [];
    this.mediaRecorder = new MediaRecorder(stream, options);
    this.mediaRecorder.addEventListener('dataavailable', (e) => {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    });
    this.mediaRecorder.addEventListener('stop', () => {
      this.isUploading = true;
      const blob = new Blob(recordedChunks, { 'type' : 'audio/webm' });
      const file = new File([blob], 'voice.wav');
      this.auth
        .uploadFile(file, file.name, 'authoring', true)
        .then(res => {
          this.element.fileType = 'audio/webm';
          this.element.url = res.url;
          this.isUploading = false;
          this.updateAudioUrl();

          if(this.recordEndCallback){
            this.recordEndCallback();
          }

        })
    });
    this.mediaRecorder.start();
  };

  isRunningTextToVoice:boolean;
  runTextToVoice(fromItemVoiceOver?:boolean){
    let currentLang = this.lang.c();
    if (this.langOverride){
      this.lang.setCurrentLanguage(this.langOverride);
    }
    this.isRunningTextToVoice = true;
    const script = this.script.value || this.element.script;
    if (!script){
      alert('Enter the script below to send it for text-to-voice processing.')
      return;
    }
    if (script == ''){
      this.isRunningTextToVoice = false;
      return;
    }
    return this.scriptGen.uploadNewVoice(
      script,
      this.element,
      this.lang.c(),
      fromItemVoiceOver,
    )
    .then(() => {
      console.log('Upload is complete')
      this.updateAudioUrl();
      this.isRunningTextToVoice = false;
      if (currentLang !== this.lang.c()){
        this.lang.setCurrentLanguage(currentLang);
      }
    })
  }

  removeTextToVoice(){
    if (this.element && this.element.url){
      this.element.url = null;
    }
  }
}
