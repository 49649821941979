import { Injectable } from '@angular/core';
import { KeyCode } from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomClipboardService {

  private clipboardContent:string = '';

  private pasteSub:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { 
    // listen for copy and paste events
    document.addEventListener('copy', (e: ClipboardEvent) => {
      this.copy(window.getSelection().toString());
    });
    document.addEventListener('cut', (e: ClipboardEvent) => {
      this.copy(window.getSelection().toString());
    });
    document.onkeydown = (e: KeyboardEvent) => {
      if (e.ctrlKey && (e.key == 'v')) {
        // e.preventDefault(); disabled custom clipboard
        this.pasteSub.next(true);
      }
    }
  }

  public copy(content: string): void {
    this.clipboardContent = content;
  }

  public paste(): string {
    return this.clipboardContent;
  }

  public pasteEvents(): Observable<any> {
    return this.pasteSub.asObservable();
  }
}
