<div class="bc-paginated-table">
    <bc-export-button *ngIf="withExport" [disabled]="isLoading" [large]="true" (export)="onExport()"></bc-export-button>
    <div class="paginator-and-export">
        <bc-paginator *ngIf="showPaginator" [paginator]="pagination" (refresh)="onPaginationChange()"
            [showCount]="true"></bc-paginator>
        <bc-toggle class="toggle" *ngIf="extraToggle" [tra]="extraToggle.tra" [title]="extraToggle.title"
            [disabled]="isLoading" (toggle)="onToggleChange()"></bc-toggle>
    </div>
    <div *ngIf="isLoading" class="loader-container">
        <div class="bcg-loader">
            <div></div>
        </div>
    </div>
    <div class="table-container">

        <table [style.width]="getTotalWidth()">

            <tr *ngIf="hasExtraHeadingRow()">
                <th *ngFor="let headingToSortBy of columnHeadings" [attr.colspan]="headingToSortBy.topHeadingRow && headingToSortBy.topHeadingRow[1]" class="no-border"
                [class.none-empty-top]="headingToSortBy.topHeadingRow" [class.empty-top]="!headingToSortBy.topHeadingRow">
                    <span *ngIf="headingToSortBy.topHeadingRow"><tra-md [isCondensed]="true" [slug]="headingToSortBy.topHeadingRow[0]" ></tra-md></span>
                </th>
            </tr>
            <tr class="header-row">
                <th *ngIf="addCheckbox" class="checkbox-cell">
                    <div style="padding-left: 0.5em; padding-bottom: 0.5em;">
                        <input type="checkbox" [(ngModel)]="selectAll" (change)="onSelectAllRowsChange()">
                    </div>
                </th>
                <th *ngFor="let headingToSortBy of columnHeadings" class="no-border">
                    <div>
                        <div (click)="changeOrderBy(headingToSortBy.sortBy)" *ngIf="!headingToSortBy.sortDisabled">
                            <span *ngIf="!headingToSortBy.noMd"><tra-md [isCondensed]="true" [slug]="headingToSortBy.heading"></tra-md></span>
                            <span *ngIf="headingToSortBy.noMd">{{headingToSortBy.heading}}</span>
                            <ng-container *ngIf="headingToSortBy.subheading">
                                <br>
                                <span class="subheading">{{headingToSortBy.subheading}}</span>
                            </ng-container>
                            <i *ngIf="isSortedBy(headingToSortBy.sortBy, OrderDirection.ASC)"
                                class="fa fa-caret-up"></i>
                            <i *ngIf="isSortedBy(headingToSortBy.sortBy, OrderDirection.DESC)"
                                class="fa fa-caret-down"></i>
                        </div>
                        <div *ngIf="headingToSortBy.sortDisabled" class="sort-disabled">
                            <span>{{ headingToSortBy.heading }}</span>
                            <ng-container *ngIf="headingToSortBy.subheading">
                                <br>
                                <span>{{headingToSortBy.subheading}}</span>
                            </ng-container>
                        </div>
                        <i *ngIf="!headingToSortBy.filterDisabled" class="fa fa-filter" aria-hidden="true"
                            (click)="toggleShowFilter(headingToSortBy.sortBy)"></i>
                    </div>
                    <div [style.display]="getInputCssDisplay(headingToSortBy.sortBy)">
                        <input *ngIf="!headingToSortBy.isSelect && !headingToSortBy.isDate && !headingToSortBy.filterDisabled" type="text" class="input is-small"
                            [class]="getInputClass(headingToSortBy.sortBy)"
                            (input)="updateFilter($event, headingToSortBy.sortBy)"
                            [value]="filterInitValue(headingToSortBy.sortBy)"
                        >
                        
                        <select [class]="getInputClass(headingToSortBy.sortBy)" *ngIf="headingToSortBy.isSelect"
                            (change)="updateFilter($event, headingToSortBy.sortBy)" [disabled]="headingToSortBy.filterOptionsDisabled">
                            <option *ngFor="let option of headingToSortBy.options" 
                                [selected]="filterInitValue(headingToSortBy.sortBy) == option.label">
                                {{option.label}}
                            </option>
                        </select>

                        <input 
                            *ngIf="headingToSortBy.isDate" 
                            class="input is-small"
                            type="date" 
                            [class]="getInputClass(headingToSortBy.sortBy)"
                            (change)="updateFilter($event, headingToSortBy.sortBy)"
                            [value]="filterInitValue(headingToSortBy.sortBy)"
                        >

                    </div>
                </th>
            </tr>

            <tr *ngFor="let row of rows, index as r" [style]="getStyleForRow(row, r)" [class.odd]="row._isOdd">
                <td *ngIf="addCheckbox" class="checkbox-cell">
                    <input type="checkbox" [(ngModel)]="row._checked" (change)="onSelectedRowChange(row)">
                </td>
                <td *ngFor="let headingToSortBy of columnHeadings, index as col" [style.width]="getColumnWidth(col)">
                    <ng-container *ngIf="shouldShowCell(row, headingToSortBy)">
                        <ng-container *ngIf="!isDisplayComplex(displays[r][col])"
                            [ngSwitch]="headingToSortBy.isLink === true">
                            <ng-container *ngSwitchCase="true">
                                <a *ngIf="isDirectLinkDefined(headingToSortBy.sortBy)"
                                    [routerLink]="linkByRouters[headingToSortBy.sortBy]({row: row, rowIndex: r})">{{displays[r][col]}}</a>
                                <a *ngIf="!isDirectLinkDefined(headingToSortBy.sortBy)"
                                    (click)="onLinkClicked(row, r, headingToSortBy.sortBy)">{{displays[r][col]}}</a>
                            </ng-container>
                            <span *ngSwitchCase="false" [innerHTML]="displays[r][col]" (click)="triggerClickEventIfRequired(displays[r][col], displays[r], col)"></span>
                        </ng-container>

                        <ng-container *ngIf="isDisplayComplex(displays[r][col])">
                            <ng-container
                                *ngFor="let element of getComplexHeadingValuesForRowCol(r, col).elements, index as e">
                                <div *ngIf="!element.isLink" [style]="element.style">
                                    {{element.text}}
                                </div>
                                <ng-container *ngIf="element.isLink">
                                    <ng-container *ngIf="!element.disabled">
                                        <a *ngIf="isDirectLinkDefined(element.linkId)" [style]="element.style"
                                            [href]="linkByRouters[element.linkId]({row: row, rowIndex: e})">{{element.text}}</a>
                                        <a *ngIf="!isDirectLinkDefined(element.linkId)" [style]="element.style"
                                            (click)="onLinkClicked(row, e, element.linkId)">{{element.text}}</a>
                                    </ng-container>
                                    <ng-container *ngIf="element.disabled">
                                        <a *ngIf="element.disabled" [style]="element.style"
                                            class="disabled-link">{{element.text}}</a>
                                    </ng-container>
                                </ng-container>
                                <br *ngIf="e < getComplexHeadingValuesForRowCol(r, col).elements.length - 1">
                            </ng-container>

                        </ng-container>

                        <div *ngFor="let tag of getTagsForRow(headingToSortBy.sortBy, row), index as t"
                            class="custom-tag" [ngStyle]="getTagStyleForRow(headingToSortBy.sortBy, row, t)">
                            {{getTagTextForRow(headingToSortBy.sortBy, row, t)}}
                        </div>

                    </ng-container>

                    <ng-container *ngIf="!shouldShowCell(row, headingToSortBy)">
                        <div></div>
                    </ng-container>
                </td>
            </tr>
        </table>
    </div>

</div>