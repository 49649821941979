import { Component, Input, OnInit } from '@angular/core';
import { LangService } from 'src/app/core/lang.service';
import { TextToSpeechService } from '../text-to-speech.service';

@Component({
  selector: 'results-summary-cr',
  templateUrl: './results-summary-cr.component.html',
  styleUrls: ['./results-summary-cr.component.scss']
})
export class ResultsSummaryCrComponent implements OnInit {

  @Input() totalCRScore: number;
  @Input() numCRQuestions: number;
  @Input() isExportPage: boolean = false;
  @Input() frameWorkTagsRef: Map<string, boolean>;
  
  constructor(public lang: LangService, public textToSpeech: TextToSpeechService) {}

  ngOnInit(): void {
  }

  getStyle() {
    if(this.isExportPage) {
      return {
        'width.em': 30
      }
    } else {
      return {};
    }
  }

  isHiContrast(){
    return this.textToSpeech.isHiContrast;
  }

  checkTag(tag:string){
    if (!this.frameWorkTagsRef) return false;
    return this.frameWorkTagsRef.get(tag);
  }

  getResultScoreSlug(){
    if (this.checkTag('FR_RESULTS')) return 'tr_results_score_fr_sample';
    return 'tr_results_score';
  }

  getResultCRScoreSlug(){
    if (this.checkTag('FR_RESULTS')) return 'tr_results_cr_score_fr_sample';
    return 'tr_results_cr_score';
  }

}
