import * as moment from 'moment-timezone';

const tzGuess = moment.tz.guess();
export const mtz = (dateStr?:string | Date) => moment.tz(dateStr, tzGuess);

export const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
    //using moment js
    var dateAsString = moment(cellValue).format('DD/MM/YYYY');
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
    if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) { return 0 }
    if (cellDate < filterLocalDateAtMidnight) { return -1; }
    if (cellDate > filterLocalDateAtMidnight) { return 1; }
  }