import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { BcAccountsService, District, DistrictDetail, Filter, FilterCondition, PaginatedRows, Pagination, School, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { BcAssessmentsService, IScoreProfile, IThemeProfile, ScoreEntryStudentRow, SpreadSheetScoreEntryRow, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcScoreEntryService } from 'src/app/bc-score-entry/bc-score-entry.service';
import { IScoreEntryCell, IScoreEntryChange, IScoreEntryData, IScoreEntrySchoolAggregation, IScoreEntryUploadCell, IScoreEntryUploadData, ScoreEntryComponent, ScoreEntryStatus } from 'src/app/bc-score-entry/types';
import { AccountType } from 'src/app/constants/account-types';
import { BreadcrumbsService } from 'src/app/core/breadcrumbs.service';
import { DataGuardService } from 'src/app/core/data-guard.service';
import { LangService } from 'src/app/core/lang.service';
import { SidepanelService } from 'src/app/core/sidepanel.service';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import { BCFSAScoreEntrySessionView, BC_FSA_SCORE_ENTRY_TABS } from 'src/app/ui-ministryadmin/ma-score-entry-session/ma-score-entry-session.component';
import { IView } from 'src/app/ui-ministryadmin/view-ministryadmin-dashboard/data/views';
import { BcPaginatedTableComponent, IColumnHeading, LinkClickEvent, TextDisplay, TextDisplayDirection, ZebraConfig } from '../bc-paginated-table/bc-paginated-table.component';
import { BcUploadWidgetComponent } from '../bc-upload-widget/bc-upload-widget.component';
import * as Papa from 'papaparse'; // for reading CSVs
import { ScoreEntryAggregationTableComponent } from '../score-entry-aggregation-table/score-entry-aggregation-table.component';

interface SchoolScoreEntryAggregatedRow {
  group_id: number,
  school_name: string,
  school_code: string,
  grade: number,
  view_score_entry: string,
  sldr: string,
  students_enrolled: number,
  cr_literacy: number,
  sr_literacy: number,
}


@Component({
  selector: 'score-entry-students',
  templateUrl: './score-entry-students.component.html',
  styleUrls: ['./score-entry-students.component.scss']
})
export class ScoreEntryStudentsComponent implements OnInit, OnChanges {

  @ViewChild(BcPaginatedTableComponent) table: BcPaginatedTableComponent<SchoolScoreEntryAggregatedRow>;

  @ViewChild(BcUploadWidgetComponent) uploadWidget: BcUploadWidgetComponent;

  @Input() school: SchoolDetail;
  @Input() testWindow: TestWindow;
  @Input() accountType: AccountType;
  @Input() district: DistrictDetail;
  @Input() themeProfile: IThemeProfile;
  @Input() scoreProfile: IScoreProfile;
  @Output() refresh = new EventEmitter();

  viewSldReportSlugProps: {
    SCHOOL_NAME: string,
  };


  routeSub: Subscription;
  breadcrumb = [];

  isLoading: boolean = false;
  isBulkView: boolean = true;

  iAmScoreEntrySchoolAdmin = false;
  iAmScoreEntryDistrictAdmin = false;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private lang: LangService,
    private sidePanel: SidepanelService,
    private breadcrumbsService: BreadcrumbsService,
    private auth: AuthService,
    private whitelabel: WhitelabelService,
    private loginGuard: LoginGuardService,
  ) { }

  ngOnInit(): void {
    if (this.whitelabel.isForceEnglish()) {
      this.loginGuard.forceEnglish()
    }

    this.loginGuard.activate();
    this.sidePanel.activate();
    this.sidePanel.unexpand();

    this.auth.user().subscribe(userInfo => {
      if (userInfo) {
        this.iAmScoreEntryDistrictAdmin = this.auth.isScoreEntryDistrictAdmin(userInfo.accountType);
        this.iAmScoreEntrySchoolAdmin = this.auth.isScoreEntrySchoolAdmin(userInfo.accountType);
      }
    })

    this.updateBreadcrumb();

  }

  changeBulkStatus(isBulk: boolean): void
  {
    this.isBulkView = isBulk;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.school && this.school) {
      this.viewSldReportSlugProps = {
        SCHOOL_NAME: this.school.name,
      };
    }
  }

  getBaseRoute() {
    return `/${this.lang.c()}/${this.getAdminPath()}`;
  }

  getDashboardRoute() {
    if (this.isFSA()) {
      return `/${this.lang.c()}/${this.getAdminPath()}/bc-fsa/dashboard`;
    }
  }

  getBaseViewRoute() {
    if (this.isFSA()) {
      return `${this.getBaseRoute()}/bc-fsa/score-entry`;
    }
  }

  getViewRoute(viewSlug: BCFSAScoreEntrySessionView) {
    if (viewSlug === BCFSAScoreEntrySessionView.SUMMARY) {
      return `${this.getBaseViewRoute()}/schools`;
    } else if (viewSlug === BCFSAScoreEntrySessionView.STUDENTS) {
      return `${this.getBaseViewRoute()}/bulk`;
    } else if (viewSlug === BCFSAScoreEntrySessionView.EDIT) {
      return `${this.getBaseViewRoute()}/session`;
    }
  }

  updateBreadcrumb() {
    this.breadcrumb.splice(0, this.breadcrumb.length);
    this.breadcrumb.push(this.breadcrumbsService._CURRENT(this.getAdminName(), this.getDashboardRoute()));
    this.breadcrumb.push(this.breadcrumbsService._CURRENT(this.lang.tra('sa_score_entry_bc'), this.getBaseViewRoute()));
  }

  getAdminPath() {
    if (this.isSchoolAdmin()) {
      return "school-admin";
    }
    if (this.isMinistryAdmin()) {
      return "ministry-admin";
    }
    else {
      return "dist-admin";
    }
  }

  isFSA(): boolean {
    return this.route.snapshot.data['schoolType'] === 'BC_FSA'
  }

  getAdminName() {
    if (this.isSchoolAdmin()) {
      let schoolAdmin = this.lang.tra('sa_grad_dashboard_school_admin');
      if (!this.isFSA()) schoolAdmin += this.lang.tra('sa_grad_bcgrad');
      else schoolAdmin += this.lang.tra('sa_fsa_bcfsa');
      if (this.iAmScoreEntrySchoolAdmin) {
        schoolAdmin = 'sa_dashboard_score_entry_school_admin';
      }
      return schoolAdmin;
    }
    if (this.isMinistryAdmin()) {
      return "Ministry Administration Coordinator";
    }
    else {
      if (this.iAmScoreEntryDistrictAdmin) {
        return this.lang.tra('da_dashboard_score_entry_district_admin_bc');
      }
      return this.lang.tra('da_dashboard_district_admin_bc');
    }
  }

  isSchoolAdmin() {
    return this.auth.isSchoolAdmin();
  }

  isDistrictAdmin() {
    return this.auth.isDistrictAdmin();
  }

  isMinistryAdmin() {
    return this.auth.isMinistryAdmin();
  }

  private goToSldReport(school_group_id: number) {
    this.router.navigate([this.getBaseRoute(), 'bc-fsa', 'final_results', 'school_level_data_report'], {
      queryParams: {
        school: school_group_id,
      }
    })
  }

  viewSldReportForSchool() {
    if (!this.school) return;

    this.goToSldReport(this.school.groupId);
  }


}
