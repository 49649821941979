<div class="page-content">
    <div>
    </div>

    <div *ngIf="!isLoading">
        <bc-export-button [disabled]="isLoading" [large]="true" (export)="export()"></bc-export-button>

        <div *ngIf="isLoading" class="notification is-warning">
            <tra slug="loading_caption"></tra>
        </div>

        <div class="dropdown-selectors">
            <div class="grade-selector dropdown-selector">
                <span class="selector-label">
                    <tra slug="sa_sr_viewing"></tra>
                </span>
                <select [(ngModel)]="selectedGradeOption" (change)="onSelectedGradeChange($event)" [disabled]="isLoading">
                    <option *ngFor="let grade of gradeOptions" [ngValue]="grade">
                        <tra-md [slug]="grade.caption"></tra-md>
                    </option>
                </select>
            </div>
        </div>
        <div class="district-summary" *ngIf="!isSchoolAdmin()">
            <div class="table-title">
                <tra slug="da_district_submissions"></tra>
            </div>

            <bc-paginated-table [identifier]="districtTableIdentifier" [columnHeadings]="districtColumnHeadings"
                [columnWidths]="districtTableColumnWidths" [getRows]="districtGetRows"
                [getDisplay]="districtGetDisplay">
            </bc-paginated-table>
        </div>

        <div class="table-title">
            <tra *ngIf="!isSchoolAdmin()" slug="da_submissions_by_school"></tra>
        </div>

        <bc-paginated-table [identifier]="schoolTableIdentifier" [pagination]="schoolTablePagination"
            [columnHeadings]="columnHeadings" [columnWidths]="tableColumnWidths" [getRows]="getRows"
            [getDisplay]="getDisplay" [zebraConfig]="zebraConfig" [defaultFilterCondition]="FilterCondition.LIKE"
            (updated)="schoolsUpdated($event)">
        </bc-paginated-table>

    </div>
</div>