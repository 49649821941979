import { Component, Input, OnInit, } from '@angular/core';
import moment, { Moment } from 'moment';
import { BcAccountsService, School } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { BreadcrumbsService } from 'src/app/core/breadcrumbs.service';
import { LangService } from 'src/app/core/lang.service';
import { IMenuTabConfig } from 'src/app/ui-partial/menu-bar/menu-bar.component';
import { getG4SecureMaterialList, getG7SecureMaterialList, getGradMinistrySpecificSecureMaterialList, getGeneralGradSecureMaterialList, getGeneralFsaSecureMaterialList, getG4SampleMaterialList, getG7SampleMaterialList, MaterialList } from './data';
import { ActivatedRoute, Router } from '@angular/router';
import { SECURE_LOGIN_SYS_FLAG } from 'src/app/ui-whitelabel-bc-landing/view-secure-login/model';
import { AuthService, UploadPurpose } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import { Province } from 'src/app/bc-reports/types';

export enum SUB_SLUGS {
  LANDING_TECH_GUIDES    = 'tech_guides',
  TECHNICAL_READINESS    = 'tech_readiness',
  SCORE_ENTRY            = 'score_entry',
  ADMIN_SESSION          = 'admin_session',
}

enum AssessmentMaterialsTab {
  SECURE = 'Secure',
  SAMPLE = 'Sample'
}

interface processedCardRow {
  card_title: string,             //the title of the card
  card_description: string,       //the card subtitle
  expanded: boolean,              //does the card load expanded or rolled up
  sub_slug: string,               //the view a given card belongs to
  test_window_id: number          //pulling the test_window_id so that we can display a message notifying the user that the landing tech-guides are not test window specific since they appear on the landing page.
  content: processedSectionRow[]  //card content
}
interface processedSectionRow {
  section_title: string,            //the title of the row within a card
  content: processedSubSectionRow[] //content of the row
}
interface processedSubSectionRow {
  sub_section_title: string,       //a title of a block of links within a row - this is only relevant for fsa
  sub_section_description: string, //a description paragraph of a subsection  - this is only relevant for tech readiness and landing tech guides  
  content: processedDocumentRow[]  //a set of links
}
interface processedDocumentRow {
  id?: number,                     //we use the id to patch/remove content
  document_title: string,          //the title of a given link
  document_link: string,           //the link the user can click 
  ma_shares_sa?: boolean,          //Ministry admin uses the same link as the school admin's
  sa_shares_ma?: boolean,          //School admin uses the same link as the ministry admin's
  ma_link?: string,                //Ministry admin link in case we want the ministry admin and school admin to have two different links. eg: "/#/en/ministry-admin/..." vs "/#/en/school-admin/..."
  enabled: boolean,                //is the link enabled or disabled - only relevant for school admins
  allow_sa: boolean,               //this is the switch that controls the 'enabled' field. it is seperate because we set 'enabled' a default value of true when the admin requesting is not school admin while at the same time we want the actual 'allow_sa' value so that the support admin can toggle it as needed.
  for_sa: boolean,                 //does the current link at all appear in the school admin view.
  lang: string,                    //language of the link, it can have 'en', 'fr' or null. When the value is 'en' or 'fr' only links matching the language of the current url are returned, and when the value is null we grab the same link regardless of the language of the url.
  is_removed?: boolean,            //this value is not stored in the db, this is a flag that specifically signals if we are requesting the permenant removal of a link.
  sa_is_secure_file: boolean,      //this flag signals that for School/District admins we use the secure link generation using generateS3DownloadUrl function
  ma_is_secure_file: boolean,      //this flag signals that for Ministry admins we use the secure link generation using generateS3DownloadUrl function
  sa_key?: string,                 //when sa_is_secure_file is true, we need to capture the key so that we have all the info we need to generate a secure link that expires in 5 minutes.
  sa_bucket?: string,              //when sa_is_secure_file is true, we need to capture the bucket so that we have all the info we need to generate a secure link that expires in 5 minutes.
  ma_key?: string,                 //when ma_is_secure_file is true, we need to capture the key so that we have all the info we need to generate a secure link that expires in 5 minutes.
  ma_bucket?: string,              //when ma_is_secure_file is true, we need to capture the bucket so that we have all the info we need to generate a secure link that expires in 5 minutes.           
  new_link?: boolean,              //this is also a flag that is not stored in the db. it signals that the content does not include an id and is a new record to be added to the db.
  sub_slug: string,               //this is a value that signals if the link belongs to assessment materials -value of null- or if it belongs to other views, for eg: score_entry, tech_guides, admin_session etc
  slug: string,                    //is a value of null when the links belong to assessment materials views. any other value belongs to another view in the admin system. 
  test_window_id: number,          //the test window the current link belongs to.
  sort_order: number,              //the order of links within a block of links -subSection
  sub_section_sort_order: number,  //the order of blocks -subSections- within a given row -section
  section_sort_order: number,      //the order of rows -sections- within a card
  card_sort_order: number,         //the order of cards.
}

interface unprocessedMaterialsRow { //this is the format we send to the backend when we want to add/remove/update records
  id?: number,  //all fields here are already described in the processed interfaces
  card_title: string,
  card_description: string,
  section_title: string,
  sub_section_title: string,
  sub_section_description: string,
  document_title: string,
  meta: string,
  allow_sa: boolean,
  for_sa: boolean,
  is_removed?: boolean,
  sa_is_secure_file: boolean,    
  ma_is_secure_file: boolean,     
  sa_key?: string,                
  sa_bucket?: string,             
  ma_key?: string,                
  ma_bucket?: string,           
  new_link?: boolean,
  slug: string,
  test_window_id: number
  sort_order: number,
  card_sort_order: number,
  section_sort_order: number,
  sub_section_sort_order: number,
  lang: string, 
  sub_slug: string
}

@Component({
  selector: 'bc-assessment-materials',
  templateUrl: './bc-assessment-materials.component.html',
  styleUrls: ['./bc-assessment-materials.component.scss']
})
export class BcAssessmentMaterialsComponent implements OnInit {

  @Input() breadcrumbProxy: any;

  testWindow: TestWindow;
  testWindows: TestWindow[];

  isLoading: boolean = false;

  tabs: IMenuTabConfig<AssessmentMaterialsTab>[] = [
    { id: AssessmentMaterialsTab.SECURE, caption: 'Secure Assessments Materials' }
  ];
  initialTab = AssessmentMaterialsTab.SECURE;
  selectedTab = AssessmentMaterialsTab.SECURE;
  AssessmentMaterialsTab = AssessmentMaterialsTab;

  secureMaterialLists: MaterialList[] = [];

  expandAll: boolean = false;

  asmtAvailability: Record<SECURE_LOGIN_SYS_FLAG, boolean>;
  currentAccountType: AccountType;
  currentSchool: School;
  isOffShore: boolean;

  breadcrumb;
  isFsa: boolean;
  schoolType = this.route.snapshot.data['schoolType'];
  isSupportAccess = this.route.snapshot.data['supportAccess'];
  previewMode: boolean = false;
  editMode: boolean = false;
  loading : boolean = false;
  currentLang;
  uploadName;
  uploadURL;
  cards:      processedCardRow[];
  onLoadCopy: processedCardRow[]; //this is only used to check the edited cards compared to the onload version so that we can 
              //identify relevant id's and update only records that need to be updated

  //when setting up a secure link we take the key and bucket. but on preview mode we need to make the link functional, 
  //so we take the following header + bucket + key
  secureLinkHeader = "https://s3.ca-central-1.amazonaws.com/";
  
  //when this is true and we are on preview mode, and the support admin clicks any link, they get the school admin link, when it is false they get the ministry admin link
  previewSchoolAdminLink: boolean = true; 
  constructor(
    private bcAssessments: BcAssessmentsService,
    private bcAccounts: BcAccountsService,
    private breadcrumbsService: BreadcrumbsService,
    private lang: LangService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private routes: RoutesService,
    private router: Router,
    private loginGuard: LoginGuardService,
    private whitelabel: WhitelabelService
  ) {
    if (this.isSupportAccess) {
      this.bcAccounts.setisSACurrentlyOnGrad(this.schoolType === 'BC_GRAD');
      this.loginGuard.activate([AccountType.SUPPORT]);
      this.breadcrumb = [
        this.breadcrumbsService.SUPPORT_DASHBOARD(),
        this.breadcrumbsService._CURRENT(`${this.schoolType === 'BC_GRAD' ? 'GRAD' : 'FSA'} Assessment Materials`, this.router.url),
      ]
    }
  }

  ngOnInit(): void {
    console.log("bc-assessment-materials.component.ts");
    this.auth.user().subscribe(userInfo => {
      if (userInfo) {
        this.currentAccountType = userInfo.accountType;
        this.initMaterials();
        this.updateBreadcrumb();
      }
    })
  }

  onPasswordSheetClick = async (forceLang) => {
    if(!this.passwordInfoSheet?.is_for_sa || !this.passwordInfoSheet?.is_active_for_sa) return;
    return await this.auth.apiFind(this.routes.PASSWORD_INFO_SHEET, {query: {test_window_id: this.testWindow.id, get_url: true, schoolAdmin: this.auth.isSchoolAdmin(), lang: forceLang}} );
  }
  passwordInfoSheet;
  checkStatus = async () => {
    const res = await this.auth.apiFind(this.routes.PASSWORD_INFO_SHEET, {query: {test_window_id: this.testWindow.id}} );
    this.passwordInfoSheet = res;
  }

  isMinistryAdmin(){
    return this.auth.isMinistryAdmin();
  }

  async initMaterials(){
    if(!this.testWindow) return; //we need a test window id since the materials are test window specific now
    this.loading = true;
    this.currentLang = this.lang.c();
    const cards: processedCardRow[] = await this.auth.apiFind(this.routes.BC_ADMIN_ASSESSMENT_MATERIALS, {query: {
      test_window_id: this.testWindow.id, 
      assessment: this.bcAccounts.getIsSACurrentlyOnGrad()? AssessmentType.GRAD : AssessmentType.FSA,
      for_sa: this.auth.isMinistryAdmin() || this.isSupportAccess ? 0: 1,
      support: this.isSupportAccess ? 1 : 0,
      lang: this.lang.c(),
      province: this.whitelabel.isNWT() ? Province.NWT : Province.BC
    }}); 
    this.onLoadCopy = JSON.parse(JSON.stringify(cards));
    this.cards      = JSON.parse(JSON.stringify(cards));
    this.parseTitles(this.cards);
    this.parseTitles(this.onLoadCopy);
    this.loading = false;
  }

  //this functions processes all cards and their content and wherever we use an en vs fr json object we parse them.
  parseTitles(cards: processedCardRow[]){
    for(let card of cards){
      try{
        card.card_title = JSON.parse(card.card_title);
        for(let section of card.content){
          section.section_title = JSON.parse(section.section_title);
          for(let subSection of section.content){
            if(subSection.sub_section_title) subSection.sub_section_title = JSON.parse(subSection.sub_section_title);
          }
        }
      }catch(e){}
    }
  }

  //this is the start of handling the secure link processing.
  //we make sure to override the key bucket accordingly based on the cases where one admin shares their info with the other.
  convertLinkstoMeta(content: processedDocumentRow){
    let {sa_key, sa_bucket, ma_key, ma_bucket} = content;
    if(content.ma_shares_sa){
      ma_key    = sa_key;
      ma_bucket = sa_bucket;
    }
    else if(content.sa_shares_ma){
      sa_key    = ma_key;
      sa_bucket = ma_bucket;
    }
    const meta = JSON.stringify({
      link:   content.document_link, sa_shares_ma: content.sa_shares_ma,
      sa_key: sa_key               , sa_bucket: sa_bucket              , ma_shares_sa: content.ma_shares_sa,
      ma_key: ma_key               , ma_bucket: ma_bucket              , ma_link: content.ma_link,
    });
    return meta;
  }

  //we convert the format from processed cards to unprocessed rows, similar to how they are stored in the db.
  turnCardsIntoDBRecordFormat(Cards: processedCardRow[]) {
    let originalData: unprocessedMaterialsRow[] = [];
    Cards.forEach((card: processedCardRow) => {
      card.content.forEach((section: processedSectionRow) => {
        section.content.forEach((subSection: processedSubSectionRow) => {
          subSection.content.forEach((content: processedDocumentRow) => {
            const meta = this.convertLinkstoMeta(content);
            originalData.push({
              card_title:             card.card_title,
              card_description:       card.card_description,
              section_title:          section.section_title,
              sub_section_title:      subSection.sub_section_title,
              sub_section_description:subSection.sub_section_description,
              id:                     content.id,
              document_title:         content.document_title,
              meta,
              allow_sa:               content.allow_sa,
              for_sa:                 content.for_sa,
              is_removed:             content.is_removed,
              new_link:               content.new_link,
              slug:                   content.slug,
              test_window_id:         content.test_window_id,
              sort_order:             content.sort_order,
              card_sort_order:        content.card_sort_order,
              section_sort_order:     content.section_sort_order,
              sub_section_sort_order: content.sub_section_sort_order,
              lang:                   content.lang,
              sub_slug:               content.sub_slug,
              sa_is_secure_file:      content.sa_is_secure_file,
              ma_is_secure_file:      content.ma_is_secure_file,
            });
          });
        });
      });
    });
    return originalData;
  }

  //since we parsed the en vs fr titles, when sending them back into the db we should stringify them.
  stringifyTitles(cards: processedCardRow[]){
    for(let card of cards){
      card.card_title = JSON.stringify(card.card_title);
      for(let section of card.content){
        section.section_title = JSON.stringify(section.section_title);
        for(let subSection of section.content){
          subSection.sub_section_title = JSON.stringify(subSection.sub_section_title);
        }
      }
    }
  }

  handleEdit(){
    this.editMode = !this.editMode;
  }

  async handleSave(){
    this.loading = true;
    let relevantRecords = [];
    this.stringifyTitles(this.cards);
    this.stringifyTitles(this.onLoadCopy);
    const editedCards: unprocessedMaterialsRow[] = this.turnCardsIntoDBRecordFormat(this.cards);
    const onLoadCards: unprocessedMaterialsRow[] = this.turnCardsIntoDBRecordFormat(this.onLoadCopy);
    for(let i = 0; i < editedCards.length; i++){
      let canCompare = onLoadCards[i] != null;
      if((canCompare && this.didContentChange(editedCards[i], onLoadCards[i])) || editedCards[i].new_link || editedCards[i].is_removed){
        relevantRecords.push(editedCards[i]);
      }
    }
    if(relevantRecords.length){
      await this.auth.apiPatch(this.routes.BC_ADMIN_ASSESSMENT_MATERIALS, null, {cards: relevantRecords}, {
        query: {
          province:  this.whitelabel.isNWT() ? Province.NWT : Province.BC,
        }
      }); 
    }
    this.handleCancel();
  }

  async handlePreview(){
    for(let card of this.cards){
      for(let section of card.content){
        for(let subSection of section.content){
          for(let doc of subSection.content){
            let sa_link = doc.document_link;
            let ma_link = doc.ma_link;
            if     (doc.sa_is_secure_file) sa_link = `${this.secureLinkHeader}${doc.sa_bucket}/${doc.sa_key}`;
            else if(doc.ma_is_secure_file) ma_link = `${this.secureLinkHeader}${doc.ma_bucket}/${doc.ma_key}`;
            doc.document_link = sa_link;
            doc.ma_link       = ma_link;
          }
        }
      }
    }
    this.reSortLinks   (this.cards); 
    this.previewMode = !this.previewMode;
    this.editMode    = !this.editMode;
  }

  reSortLinks(cards: processedCardRow[]){
    for(let card of cards){
      for(let section of card.content){
        for(let subSection of section.content){
          subSection.content.sort((a, b) => a.sort_order - b.sort_order);
        }
      }
    }
  }

  async handleCancel(){
    this.previewMode = false;
    this.editMode    = false;
    await this.initMaterials();
  }

  //checking if any property has been edited or not
  didContentChange(c1: unprocessedMaterialsRow, c2: unprocessedMaterialsRow){
    let keys1 = Object.keys(c1);
    if (keys1.every(key => JSON.stringify(c1[key]) == JSON.stringify(c2[key]))) return false;
    return true;
  }

  isGradMinistryAdmin() {
    return this.auth.isGradMinistryAdmin(this.currentAccountType);
  }

  isGradSchoolAdmin() {
    return this.isSchoolAdmin() && !this.isFSA();
  }

  isSchoolAdmin() {
    return this.auth.isSchoolAdmin();
  }

  isNotGrad() {
    return !this.isGradSchoolAdmin() && !this.isGradMinistryAdmin()
  }

  isGrad() {
    return this.isGradSchoolAdmin() || this.isGradMinistryAdmin() || this.schoolType === 'BC_GRAD';
  }

  getTestWindowTitle(testWindow: TestWindow): string {
    return this.bcAssessments.getTestWindowTitle(testWindow);
  }

  onSelectedTestWindowChange(testWindow: TestWindow) {
    this.testWindow = testWindow;
    if(!this.bcAccounts.getIsSACurrentlyOnGrad()) this.checkStatus();
    this.initMaterials();
  }


  getBaseRoute() {
    if (this.isGradMinistryAdmin()) return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}`;
    else return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}`;
  }

  getDashboardRoute() {
    if (this.isGradMinistryAdmin()) return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}/bc-grad/dashboard`;
    else if (this.isGradSchoolAdmin()) return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}/bc-grad/dashboard`;
    else return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}/bc-fsa/dashboard`;
  }

  getBaseViewRoute() {
    if (this.isGradMinistryAdmin()) return `${this.getBaseRoute()}/bc-grad/assessment_materials`;
    else if (this.isGradSchoolAdmin()) return `/${this.getBaseRoute()}/bc-grad/assessment_materials`;
    else return `${this.getBaseRoute()}/bc-fsa/assessment_materials`;
  }

  getViewRoute() {
    return this.getBaseViewRoute();
  }

  isFSA(): boolean {
    return this.route.snapshot.data['schoolType'] === 'BC_FSA';
  }

  updateBreadcrumb() {
    if(this.isSupportAccess) return;
    if(!this.auth.isFsaMinistryAdmin())this.breadcrumbProxy.clear();

    if (this.isGradMinistryAdmin()) {
      this.breadcrumbProxy.push(this.breadcrumbsService._CURRENT(this.lang.tra('ma_ministryTitle') + this.lang.tra('sa_grad_bcgrad'), this.getDashboardRoute()));
    } else if (this.isSchoolAdmin()) {
      let admin = this.lang.tra('sa_grad_dashboard_school_admin');
      if (!this.isFSA()) admin += this.lang.tra('sa_grad_bcgrad');
      else admin += this.lang.tra('sa_fsa_bcfsa');
      this.breadcrumbProxy.push(this.breadcrumbsService._CURRENT(admin, this.getDashboardRoute()));
    }
    this.breadcrumbProxy.push(this.breadcrumbsService._CURRENT(this.lang.tra('sa_assessment_material_bc'), this.getBaseViewRoute()));
  }

  onSelectedSchoolChange(school: School) {
    this.bcAccounts.getSchoolDetail(school.groupId).then(schoolInfo => {
      if(!schoolInfo) return;
      this.bcAccounts.getDistrictDetail(schoolInfo.district.groupId).then(districtInfo => {
        this.isOffShore = districtInfo.is_offshore
      })
    })
  }

  
  async uploadFile(event){
    const filePath = `scan_coord_replace/${+(new Date())}_${event.target.files[0].name}`;
    let upload = await this.auth.uploadFile(event.target.files[0], filePath, UploadPurpose.AUTHORING, true);
    this.uploadName = event.target.files[0].name;
    this.uploadURL  = upload.url;
  }

  addNewLink(card_i, section_i, sub_section_i){
    let order = -1;
    let doc: any = {
      card_sort_order:         0,
      section_sort_order:      0,
      sub_section_sort_order:  0,
      sa_is_secure_file:       0,
      ma_is_secure_file:       0,
      allow_sa:                true,
      enabled:                 true,
      for_sa:                  true,
      ma_shares_sa:            true,
      lang:                    null,
      sub_slug:                null,
      sub_section_description: null,
    }
    if(this.cards[card_i].content[section_i].content[sub_section_i].content.length) {
      const last_index  = this.cards[card_i].content[section_i].content[sub_section_i].content.length - 1;
      doc               = {...this.cards[card_i].content[section_i].content[sub_section_i].content[last_index]};
      order             = doc.sort_order;
    } 
    const newLink: processedDocumentRow = {
      document_link: "",
      document_title: "New Link Title",
      new_link: true,
      test_window_id: this.testWindow.id,
      slug: this.schoolType === 'BC_GRAD' ? 'grad' : 'fsa',
      sort_order: (order + 1),
      card_sort_order: doc.card_sort_order,
      section_sort_order: doc.section_sort_order,
      sub_section_sort_order: doc.sub_section_sort_order,
      ma_shares_sa: doc.ma_shares_sa,
      sub_slug: doc.sub_slug,
      enabled: doc.enabled,
      for_sa: doc.for_sa,
      lang: doc.lang,
      allow_sa: doc.allow_sa,
      sa_is_secure_file: doc.sa_is_secure_file,
      ma_is_secure_file: doc.ma_is_secure_file,
    }
    this.cards[card_i].content[section_i].content[sub_section_i].content.push(newLink);
  }

  removeLink(card_i, section_i, sub_section_i, link_i){
    this.cards[card_i].content[section_i].content[sub_section_i].content[link_i].is_removed = true;
  }

  maSharingChange(document: processedDocumentRow){
    if(document.sa_shares_ma && document.ma_shares_sa) document.sa_shares_ma = false;
  }
  
  saSharingChange(document: processedDocumentRow){
    if(document.sa_shares_ma && document.ma_shares_sa) document.ma_shares_sa = false;
  }

  enabledForSAChange(document: processedDocumentRow){
    if(document.allow_sa) document.for_sa = true;
  }

  availableForSAChange(document: processedDocumentRow){
    if(!document.for_sa) {
      document.allow_sa = false;
      document.ma_shares_sa = false;
    }
  }

  toggleExpanded(card: processedCardRow) {
    card.expanded = !card.expanded;
    if (!this.cards.find(c => c.expanded)) this.expandAll = false;
    else if (!this.cards.find(c => !c.expanded)) this.expandAll = true;
  }

  displayTitle(title){
    try{
      return this.lang.c() == 'en' ? title.en : title.fr; 
    }catch(e){
      return title;
    }
  }

  toggleExpandAll() {
    this.expandAll = !this.expandAll;
    this.cards.map(c => c.expanded = this.expandAll);
  }

  techGuidesOrTechReadiness(card: processedCardRow){
    if(card?.sub_slug == SUB_SLUGS.LANDING_TECH_GUIDES || card?.sub_slug == SUB_SLUGS.TECHNICAL_READINESS) return true;
    return false;
  }

  //all following functions are not used anymore, just keeping them here in case we need to refrence them:
  private async updateMaterialLists() {
    if ((!this.bcAccounts.getIsSACurrentlyOnGrad() && !this.testWindow) || !this.asmtAvailability) return;

    const disabledList = [];
    const hiddenList = [];

    // Hidden list for both GradSchoolAdmin and GradMinistryAdmin
    hiddenList.push('grad_session_password_ltf12');

    // Disable list for both GradSchoolAdmin and GradMinistryAdmin
    // disabledList.push('grad_session_password');
    disabledList.push('grad_session_password_ltf12');

    let secureMaterialLists;
    if (this.isGradSchoolAdmin()) {
      const selectedSchoolGroupId = this.route.snapshot.queryParamMap.get('school');
      const schools = await this.bcAccounts.getSchoolsSimpleDetails([parseInt(selectedSchoolGroupId)]);
      const selectedSchool = schools[0];

      if (selectedSchool.isOOP) {
        hiddenList.push('grad_session_password');
        hiddenList.push('grad_session_password_ltf12');
      }

      secureMaterialLists = [
        getGeneralGradSecureMaterialList(disabledList, hiddenList)
      ];
    }
    else if (this.isGradMinistryAdmin()) {
      secureMaterialLists = [
        getGeneralGradSecureMaterialList(disabledList, hiddenList),
        getGradMinistrySpecificSecureMaterialList()
      ];
    }
    else {
      secureMaterialLists = [
        getGeneralFsaSecureMaterialList(),
        this.getMaterialListWithFixedColumnAvailability(getG4SecureMaterialList),
        this.getMaterialListWithFixedColumnAvailability(getG7SecureMaterialList),
      ];

      const isAvailable = this.asmtAvailability[SECURE_LOGIN_SYS_FLAG.FSA];
      secureMaterialLists[1].columnAvailable[3] = isAvailable;
      secureMaterialLists[2].columnAvailable[3] = isAvailable;
    }
    this.secureMaterialLists = secureMaterialLists;
  }

  private isLinkDisabled(link:{isDisabled?:boolean, isDelayedAccess?:boolean, isRestricted?: boolean}, i: number){
    // if(this.isOffShore && i == 1){
    //   return true;
    // }
    if (link.isDisabled){
      return true;
    }
    if(link.isRestricted){
      if(!this.passwordInfoSheet?.is_for_sa || !this.passwordInfoSheet?.is_active_for_sa) return true;
    }
    if (link.isDelayedAccess){
      const isAsmtAvail = this.isFSA() ? this.bcAssessments.isFsaAdminAvailable() : this.bcAssessments.isGradAdminAvailable() ;
      if (!isAsmtAvail){
        return true;
      }
    }
    return false
  }

  private async selectTab(tab: AssessmentMaterialsTab) {
    this.selectedTab = tab;
    this.expandAll = true;
    this.toggleExpandAll();
    this.updateBreadcrumb();
  }

  private async tra(slug: string, index: number, forcedLang?:string, isDynamicLang?:string, link?:{isDisabled?:boolean, isDelayedAccess?:boolean, isRestricted?: boolean}) {
    let url = "";
    if(link.isRestricted){
      url = await this.onPasswordSheetClick(forcedLang);
    }else{
      if (index == 0) {
        url = this.lang.tra(slug, "en");
      } else {
        url = this.lang.tra(slug, "fr");
      }  
      if(isDynamicLang){
        url = this.lang.tra(slug);
      }
      if (forcedLang){
        url = this.lang.tra(slug, forcedLang);
      }
    }
    const newlink = document.createElement('a');
    newlink.setAttribute('target', '_blank');
    newlink.setAttribute('href', url);
    document.body.appendChild(newlink);
    newlink.click();
    newlink.remove();
  }

  private langTra(slug: string, forcedLang?:string){
    return this.lang.tra(slug, forcedLang);
  }

  private getReleaseDate(): Moment | null {
    if (!this.testWindow) return null
    return moment(this.testWindow.dateStart).subtract(2, 'weeks');
  }

  private getReleaseDateString(): string {
    const releaseDate = this.getReleaseDate();
    if (!releaseDate) return '';
    return releaseDate.format('DD/MM/YYYY');
  }

  private getMaterialListWithFixedColumnAvailability(getMaterialList: () => MaterialList): MaterialList {
    const ml = getMaterialList();
    ml.columnAvailable[0] = this.getReleaseDate().isSameOrBefore(moment(), 'date');
    return ml
  }

  private getMaterialListsByAssessment(): MaterialList[] {
    if (this.selectedTab === AssessmentMaterialsTab.SECURE) {
      // console.log(this.secureMaterialLists);
      return this.secureMaterialLists;
    }
    return null;
  }
}
