import { Injectable, OnDestroy } from '@angular/core';
import { AuthScopeSettingsService, AuthScopeSetting } from './auth-scope-settings.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class EditingDisabledService {

  private isEditingDisabled = new BehaviorSubject<boolean>(false);
  private isCurrQLocked = new BehaviorSubject<boolean>(false);
  
  constructor(private authScopeSettings: AuthScopeSettingsService) { 
  }

  setEditingDisabled(b:boolean) {
    this.isEditingDisabled.next(b);
  }

  setCurrQLocked(b:boolean){
    this.isCurrQLocked.next(b);
  }

  getEditingDisabled() {
    return this.isEditingDisabled.getValue();
  }

  getCurrQLocked() {
    return this.isCurrQLocked.getValue();
  }

  registerFormControls(config?:any){

  }

  isReadOnly(ignoreCurrQLock: boolean = false, ignoreEditingDisabled: boolean = false) {
    return this.authScopeSettings.getSetting(AuthScopeSetting.DISABLE_EDITING) || (!ignoreEditingDisabled && this.getEditingDisabled()) || (!ignoreCurrQLock && this.getCurrQLocked());
  }
}