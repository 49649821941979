<!-- <h1 style="margin-top: -0.5em;">
    Assessment Keys
    </h1> -->

    <div class="dropdown-selector">
        <label>Viewing Assessment: </label>
        <select [(ngModel)]="selectedAssessment" 
        (change)="onSelectedAssessmentChange()"
        [disabled]="isLoading">
            <option *ngFor="let assessment of assessments" [ngValue]="assessment">
                {{assessment}}
            </option>
        </select>
    </div>

    <div class="dropdown-selector"
    *ngIf="selectedAssessment !== this.assessments[0]">
        <label>Viewing Component: </label>
        <select [(ngModel)]="selectedComponent" 
        (change)="onSelectedComponentChange()"
        [disabled]="isLoading">
            <option *ngFor="let component of currentComponents" [ngValue]="component">
                {{component}}
            </option>
        </select>
    </div>

    <button
    *ngIf="selectedAssessment !== this.assessments[0] && selectedComponent && currentTQRParams.length !== 0"
    [disabled]="isLoading"
    class="button"
    id="TQRExportBtn" 
    (click)="export()"> 
    Export
    </button>

    <div *ngIf="isLoading"
    class="notification is-warning enlarge-mini"
    style="margin-top: 1em;">
        <tra slug="loading_caption"></tra>
    </div> 


    <table class="table horizontally-scrollable is-bordered" 
    *ngIf="selectedAssessment !== this.assessments[0] && selectedComponent && currentTQRParams.length !== 0"> 
        <tr>
            <th *ngFor="let tableHeading of getKeys(currentTQRParams)">
                {{tableHeading}}
            </th>
        </tr>
        <tr *ngFor="let row of currentTQRParams">
            <td *ngFor="let tableValue of getValues(row)">
                {{tableValue}}
            </td>
        </tr>
    </table> 