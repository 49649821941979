<div class="page-content">
    <bc-export-button [disabled]="isLoading" [large]="true" (export)="export()"></bc-export-button>

    <div class="dropdown-selectors">
        <div *ngIf="!isDistrictAdmin() && !isSchoolAdmin()" class="district-selector dropdown-selector">
            <bc-search-dropdown placeholder="District" [disabled]="isLoading" [inputValue]="selectedDistrict"
                [itemDisplayFn]="districtDisplayFn" [filterFn]="getFilterDistrictsFn()"
                (itemSelected)="districtSelected($event)"></bc-search-dropdown>
        </div>
        <div class="grade-selector dropdown-selector">
            <span class="selector-label">
                <tra slug="sa_sr_viewing"></tra>
            </span>
            <select [(ngModel)]="selectedGradeOption" (change)="onSelectedGradeChange($event)" [disabled]="isLoading">
                <option *ngFor="let grade of gradeOptions" [ngValue]="grade">
                    <tra-md [slug]="grade.caption"></tra-md>
                </option>
            </select>
        </div>
    </div>
    <div class="cover-with-loading">
        <div *ngIf="isLoading" class="reports-loader">
            <div></div>
        </div>
        <div class="district-summary" *ngIf="!isSchoolAdmin()">
            <div class="table-title">
                <tra slug="da_district_submissions"></tra>
            </div>
            <bc-paginated-table [identifier]="districtTableIdentifier" [columnHeadings]="districtColumnHeadings"
                [columnWidths]="districtTableColumnWidths" [getRows]="districtGetRows"
                [getDisplay]="districtGetDisplay">
            </bc-paginated-table>
        </div>

        <div class="table-title">
            <tra *ngIf="!isSchoolAdmin()" slug="da_submissions_by_school"></tra>
        </div>
        <bc-paginated-table [identifier]="schoolTableIdentifier" [pagination]="schoolTablePagination"
            [columnHeadings]="columnHeadings" [columnWidths]="tableColumnWidths" [getRows]="getRows"
            [getDisplay]="getDisplay" [getTag]="getTag" [zebraConfig]="zebraConfig" [tagConfig]="tagConfig"
            [defaultFilterCondition]="FilterCondition.LIKE" (updated)="schoolsUpdated($event)">
        </bc-paginated-table>

    </div>
</div>