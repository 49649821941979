import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LangService } from '../../core/lang.service';
import * as lodash from 'lodash';
import { ElementType,IContentElementIframe } from '../models';

export enum CALC {
  SIMPLE_CALCULATOR = "SIMPLE_CALCULATOR",
  FSA_SIMPLE_CALCULATOR = "FSA_SIMPLE_CALCULATOR"
}

enum Func {
  BACKSPACE,
  CLEAR,
  NEG,
  PERC,
  PI,
}
enum Operator {
  SQRT,
  PERC,
  DIVIDE,
  MULTIPLY,
  MINUS,
  PLUS,
  EQUAL,
}
enum ButtonType {
  FUNCTION,
  VALUE,
  OPERATOR
}
const COMPUTER_DEC_DELIM = '.';
interface ICalcButton {
  type: ButtonType;
  operator?: Operator;
  func?: Func;
  num?: string | number;
  caption?: string;
  isDoubleWidth?: boolean;
  isTripleWidth?: boolean;
  isVisible?: boolean;   //By default all are visible
}
interface ICalculatorModel {
    display: string;
    storedValue: number;
    newValue: string;
    newOperator: Operator;
    lastNewValue: string;
    lastNewOperator: Operator;
}


@Component({
  selector: 'widget-calculator',
  templateUrl: './widget-calculator.component.html',
  styleUrls: ['./widget-calculator.component.scss']
})
export class WidgetCalculatorComponent implements OnInit, OnDestroy {
  
  @Input() zoom:number;
  @Input() isZoomed:boolean;
  @Input() type:CALC | null;
  @Input() disableCalcSD:boolean = false;
  @Input() disableCalcRoot:boolean = false;
  @Input() disableCalcDecimal:boolean = false;
  @Input() disableCalcFraction:boolean = false;
  @Input() enableScientificCalc: boolean = false;
  @Input() disableCalcFactorial: boolean = false;
  @Input() disableCalcABS: boolean = false;
  @Input() disableCalcMOD: boolean = false;
  @Input() disableCalcln: boolean = false;
  @Input() disableCalcLOGY: boolean = false;
  @Input() disableCalcLOG10: boolean = false;
  @Input() disableCalcE: boolean = false;
  @Input() disableCalcEPOW: boolean = false;
  @Input() disableCalcPI: boolean = false;
  @Input() disableCalcPOW: boolean = false;
  @Input() disableCalcNTHROOT: boolean = false;
  @Input() disableCalcONEOVERX: boolean = false;
  @Input() disableCalcEXP: boolean = false;
  isFrench: boolean;
  
  isSimple:boolean = false;
  isFSA:boolean = false;

  calcFrameHeight: number;
  
  constructor(
    public lang: LangService
  ) { }


  model: ICalculatorModel = {
    display: '0',
    storedValue: 0,
    newValue: '',
    newOperator: null,
    lastNewValue: '',
    lastNewOperator: null,
  };
  iframeUrl 
  element:IContentElementIframe = {
     elementType:ElementType.IFRAME,
     url:''
  }
  DECIMAL_DELIM = this.lang.tra('decimal_delim');

  functionButtons: ICalcButton[];
  numberButtons: ICalcButton[];
  operatorButtons: ICalcButton[];

  ngOnInit() {
    this.isFrench = this.lang.c() === 'fr';
    if(this.type) this.initCalcType();
    this.initButtons();     // Init buttons after the Calc type is initialized;
    window.addEventListener('keydown', this.onKeyboardDown);
    this.getIframeURL()
  }

  ngAfterViewInit(){
    window.onmessage = (e) => {
      if (e.data.hasOwnProperty("frameHeight")) {
        this.calcFrameHeight = e.data.frameHeight + 43
        let calcDiv = document.getElementById("calculator-div")
        if(calcDiv){
          calcDiv.style.height = this.getTransformHeight();
        }
      }
    };
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.onKeyboardDown);
  }

  initButtons(){
    this.functionButtons = [
      {type: ButtonType.OPERATOR, func: Func.CLEAR, caption: this.getCaption('CLEAR'), isTripleWidth: this.isFSA ? false : true, isDoubleWidth: false, isVisible: this.displayButton()},
      {type: ButtonType.OPERATOR, func: Func.BACKSPACE, caption: this.getCaption('<DEL'), isVisible: this.displayButton('<DEL')},
      {type: ButtonType.OPERATOR, func: Func.NEG, caption: '±', isVisible: this.displayButton('±')},
      {type: ButtonType.OPERATOR, func: Func.PERC, caption: '%', isVisible: this.displayButton('%')},
      {type: ButtonType.OPERATOR, func: Func.PI, caption: 'π', isVisible: this.displayButton('π')},
    ];
    this.numberButtons = [
      {type: ButtonType.VALUE, num: 7},
      {type: ButtonType.VALUE, num: 8},
      {type: ButtonType.VALUE, num: 9},
      {type: ButtonType.VALUE, num: 4},
      {type: ButtonType.VALUE, num: 5},
      {type: ButtonType.VALUE, num: 6},
      {type: ButtonType.VALUE, num: 1},
      {type: ButtonType.VALUE, num: 2},
      {type: ButtonType.VALUE, num: 3},
      {type: ButtonType.VALUE, num: 0, isDoubleWidth: true, },
      {type: ButtonType.VALUE, num: COMPUTER_DEC_DELIM, caption: this.DECIMAL_DELIM },
    ];
    this.operatorButtons = [
      {type: ButtonType.OPERATOR, operator: Operator.SQRT, caption: '√', isVisible: this.displayButton('√')},
      {type: ButtonType.OPERATOR, operator: Operator.DIVIDE, caption: '÷', isVisible: this.displayButton()},
      {type: ButtonType.OPERATOR, operator: Operator.MULTIPLY, caption: '×', isVisible: this.displayButton()},
      {type: ButtonType.OPERATOR, operator: Operator.MINUS, caption: '-', isVisible: this.displayButton()},
      {type: ButtonType.OPERATOR, operator: Operator.PLUS, caption: '+', isVisible: this.displayButton()},
      {type: ButtonType.OPERATOR, operator: Operator.EQUAL, caption: '=', isVisible: this.displayButton()},
    ];
  }

  onKeyboardDown = (event: KeyboardEvent) => {
    // console.log('event', event);
    switch (event.key) {
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        return this.pressNumber(event.key);
      case '.':
      case ',':
        return this.pressNumber(COMPUTER_DEC_DELIM);
      case 'p':
        return this.pressFunction(Func.PI);
      case '=':
        return this.pressOperator(Operator.EQUAL);
      case 'Enter':
        return this.pressOperator(Operator.EQUAL);
      case '+':
        return this.pressOperator(Operator.PLUS);
      case '-':
        return this.pressOperator(Operator.MINUS);
      case '*':
      case 'x':
        return this.pressOperator(Operator.MULTIPLY);
      case '/':
      case 'd':
        return this.pressOperator(Operator.DIVIDE);
      case 's':
        return this.pressOperator(Operator.SQRT);
      case '%':
        return this.pressOperator(Operator.PERC);
      case 'c':
        return this.pressFunction(Func.CLEAR);
      case 'Delete':
        return this.pressFunction(Func.CLEAR);
      case 'Backspace':
        return this.applyBackspace();
    }
  }

  getScreenDisplay() {
    return this.model.newValue || this.model.display;
  }
  getFontDisplayScale() {
    const len = this.getScreenDisplay().length;
    const softCap = 8;
    const baseScale = 1;
    const maxScale = 2;
    if (len > softCap) {
      return baseScale;
    } else {
      return baseScale + (maxScale - baseScale) * (1 - len / softCap);
    }
  }

  pressFunction(func: Func) {
    switch (func) {
      case Func.PERC:
        this.processStoredOperation(true);
        this.processOperation(Operator.PERC, '' ); break;
      case Func.NEG:
        this.invertActiveSign(); break;
      case Func.PI:
        this.model.newValue = this.renderDisplayValue(Math.PI); break;
      case Func.CLEAR:
        this.clearDisplay(); break;
      case Func.BACKSPACE:
        this.applyBackspace(); break;
    }
  }

  pressNumber(num: string | number) {
    const currentValue = this.model.newValue;
    if (currentValue.length < 9) {
      if (num === COMPUTER_DEC_DELIM) {
        if (currentValue.indexOf(this.DECIMAL_DELIM) === -1) {
          this.model.newValue += this.DECIMAL_DELIM;
        }
      } else {
        this.model.newValue += num;
      }
    }
  }

  pressOperator(operator: Operator) {
    switch (operator) {
      case Operator.EQUAL:
        this.processStoredOperation(); break;
      case Operator.DIVIDE:
      case Operator.MULTIPLY:
      case Operator.MINUS:
      case Operator.PLUS:
        this.storeOperation(operator); break;
      case Operator.SQRT:
        this.processStoredOperation(true);
        this.processOperation(Operator.SQRT, '' ); break;
    }
  }

  applyBackspace() {
    if (this.model.newValue) {
      this.model.newValue = this.model.newValue.slice(0, this.model.newValue.length - 1);
    } else {
      this.model.display = this.model.display.slice(0, this.model.display.length - 1);
      this.model.storedValue = parseInt(this.model.storedValue.toString().slice(0, this.model.storedValue.toString().length - 1), 10)
    }
  }

  clearDisplay() {
    if (this.model.newValue) {
      this.model.newValue = '';
      this.model.display = '0';
      this.model.storedValue = 0;
      this.model.newOperator = null;
    } else if (this.model.newOperator) {
      this.model.newOperator = null;
      this.model.display = '0';
      this.model.storedValue = 0;
    } else {
      this.model.display = '0';
      this.model.storedValue = 0;
      this.model.lastNewOperator = null;
      this.model.lastNewValue = '';
    }
  }

  processStoredOperation(isBlockReapply: boolean= false) {
    // console.log('processStoredOperation', this.model.newOperator, this.model.lastNewOperator, this.model.newValue)
    if (this.model.newOperator) {
      this.processOperation(this.model.newOperator, this.model.newValue || this.model.display);
    } else if (this.model.lastNewOperator && !isBlockReapply) {
      this.model.newValue;
      this.processOperation(this.model.lastNewOperator, this.model.lastNewValue);
    } else if (this.model.newValue) {
      this.model.display = this.model.newValue;
      this.captureDisplayValue();
      this.model.newValue = '';
      this.model.lastNewOperator = null;
      this.model.lastNewValue = undefined;
    }
  }

  invertActiveSign() {
    if (this.model.newValue) {
      this.model.newValue = this.invertValueSign(this.model.newValue);
    } else {
      this.model.display = this.invertValueSign(this.model.display);
    }
  }

  invertValueSign(displayValue: string) {
    const newValueNum = this.parseDisplayValue(displayValue);
    return this.renderDisplayValue(-1 * newValueNum);
  }

  processOperation(op: Operator, newValue: string) {
    const baseValue = this.model.storedValue;
    if (this.model.newValue) {
      newValue = this.model.newValue;
    } else {
      newValue = this.model.display;
    }
    const newValueNum = this.parseDisplayValue(newValue);
    let result: number;
    switch (op) {
      case Operator.PERC     : result = baseValue / 100; break;
      case Operator.SQRT     : result = Math.sqrt(baseValue); break;
      case Operator.DIVIDE   : result = baseValue / newValueNum; break;
      case Operator.MULTIPLY : result = baseValue * newValueNum; break;
      case Operator.MINUS    : result = baseValue - newValueNum; break;
      case Operator.PLUS     : result = baseValue + newValueNum; break;
    }
    // console.log('processOperation', result, op, baseValue, newValueNum)
    this.model.display = this.renderDisplayValue(result);
    this.captureDisplayValue();
    this.model.newValue = '';
    this.model.newOperator = null;
    this.model.lastNewOperator = op;
    this.model.lastNewValue = newValue;
  }

  captureDisplayValue() {
    this.model.storedValue = this.parseDisplayValue(this.model.display);
  }

  parseDisplayValue(str: string) {
    const computerDecimal = str.split(this.DECIMAL_DELIM).join('.');
    return parseFloat(computerDecimal);
  }
  renderDisplayValue(num: number) {
    let numTruncated = parseFloat((num).toFixed(9));
    const displayDecimal = ('' + numTruncated).split('.').join(this.DECIMAL_DELIM);
    return displayDecimal;
  }

  storeOperation(operator: Operator) {
    if (this.model.newOperator && this.model.newValue) {
      this.processStoredOperation();
    } else if (this.model.newValue) {
      this.model.display = this.model.newValue;
      this.model.storedValue = this.parseDisplayValue(this.model.display);
      this.model.newValue = '';
    }
    this.model.newOperator = operator;
  }
  getIframeURL(){
    let url = window.location.protocol + "//" + window.location.host + "/assets/sci_calc/index.html?"
    if (this.disableCalcSD) {
      url += "disableSD=true&"
    }
    if (this.disableCalcDecimal) {
      url += "disableDecimal=true&"
    }
    if (this.disableCalcRoot) {
      url += "disableRoot=true&"
    }
    if (this.disableCalcFraction) {
      url += "disableFraction=true&"
    }
    if(this.lang.c() === 'fr'){
      url += "isFrench=true&"
    }
    if(this.enableScientificCalc){
      url += "enableScientificCalc=true&"
    }
    if (this.disableCalcFactorial) {
      url += "disableFactorial=true&"
    }
    if (this.disableCalcABS) {
      url += "disableABS=true&"
    }
    if (this.disableCalcMOD) {
      url += "disableMOD=true&"
    }
    if (this.disableCalcln) {
      url += "disableln=true&"
    }
    if (this.disableCalcLOGY) {
      url += "disableLOGY=true&"
    }
    if (this.disableCalcLOG10) {
      url += "disableLOG10=true&"
    }
    if (this.disableCalcE) {
      url += "disableE=true&"
    }
    if (this.disableCalcEPOW) {
      url += "disableEPOW=true&"
    }
    if (this.disableCalcPI) {
      url += "disablePI=true&"
    }
    if (this.disableCalcPOW) {
      url += "disablePOW=true&"
    }
    if (this.disableCalcNTHROOT) {
      url += "disableNTHROOT=true&"
    }
    if (this.disableCalcONEOVERX) {
      url += "disableONEOVERX=true&"
    }
    if (this.disableCalcEXP) {
      url += "disableEXP=true&"
    }
    if (url.charAt(url.length-1)=='&') {
      url = url.substring(0, url.length-1)
    }
    console.log(url)
    this.element.url = url;
  
  }
  getTransformWidth(){
    let min_zoom = Math.max(1, this.zoom)
    return min_zoom * 340 + 'px'
  }
  getTransformHeight(){
    let height = 520; //550
    if(this.calcFrameHeight){
      height = (this.calcFrameHeight > height) ? this.calcFrameHeight : height
    }
    let min_zoom = Math.max(1, this.zoom)
    return min_zoom * height + 'px'

  }

  initCalcType(){
    switch(this.type){
      case CALC.SIMPLE_CALCULATOR: this.isSimple = true; break;
      case CALC.FSA_SIMPLE_CALCULATOR: this.isFSA = true; break;
      default:
        break;
    }
  }

  getCaption(btn:string){
    switch (btn) {
      case "CLEAR":
        let caption = this.isFrench ? 'Effacer' : 'Clear'
        return this.isFSA ? caption : 'C'
      case "<DEL":
        return this.isFrench ? 'Suppr.': '<DEL'
      default:
        break;
    }
  }

  displayButton(btn?:string){
    switch (btn) {
      case '±':
      case '%':
      case 'π':
      case '√':
        return this.isFSA ? false : true
      case '<DEL':
        return this.isFSA ? true : false
      default:
        return true;
    }
  }
}
