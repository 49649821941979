export enum Language {
    English = 'en',
    French = 'fr',
}

export enum AssessmentProgram {
    GRAD = 'GRAD',
    FSA = 'FSA',
}

export enum Province {
    BC = 'BC',
    YUKON = 'Yukon',
    NWT = 'NWT'
}

export enum AuthGroup {
    NWT = 726500,
    BC = 726501,
}