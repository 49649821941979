export enum PubSubTypes {
    TOGGLE     = 'TOGGLE',
    TOGGLE_ON  = 'TOGGLE_ON',
    TOGGLE_OFF = 'TOGGLE_OFF',
    ROTATE = 'ROTATE',
    INC_HEIGHT = 'INC_HEIGHT',
    INC_WIDTH = 'INC_WIDTH',
    TOGGLE_CLICKABLE = 'TOGGLE_CLICKABLE',
    CLICKABLE = 'CLICKABLE',
    NOT_CLICKABLE = 'NOT_CLICKABLE',
    UPDATE_VALIDATOR = 'UPDATE_VALIDATOR',
    INPUT = 'INPUT',
    TIMER = 'TIMER',
    TRANSCRIPT = 'TRANSCRIPT'
}