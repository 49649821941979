import { IPanelLink } from "./type";
import { BC_FSA_DISTRICT_ADMIN_VIEWS, BC_FSA_FISA_VIEWS, BC_FSA_SCORE_ENTRY_DISTRICT_ADMIN_VIEWS } from '../../../ui-dist-admin/view-districtadmin-dashboard/data/views'

export const fsaDistrictAdminPanels: IPanelLink[] = BC_FSA_DISTRICT_ADMIN_VIEWS.map(view => {
  return {
    id: view.id,
    caption: view.caption,
    routerLink: `/en/dist-admin/bc-fsa/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled
  }
})

export const fsaDistrictAdminScoreEntryPanels: IPanelLink[] = BC_FSA_SCORE_ENTRY_DISTRICT_ADMIN_VIEWS.map(view => {
  return {
    id: view.id,
    caption: view.caption,
    routerLink: `/en/dist-admin/bc-fsa/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled
  }
})

export const fsaDistrictFisaAdminPanels: IPanelLink[] = BC_FSA_FISA_VIEWS.map(view => {
  return {
    id: view.id,
    caption: view.caption,
    routerLink: `/en/dist-admin/bc-fsa/${view.id}`,
    iconUrl: view.imgUrl,
    disabled: !!view.disabled
  }
});

