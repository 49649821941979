import { Injectable } from '@angular/core';
import { AuthService, IUserInfo } from '../api/auth.service';
import { BehaviorSubject } from 'rxjs';
import { RoutesService } from '../api/routes.service';
import { UserRoles } from '../api/models/roles';

type RolesList = string[];

@Injectable({
  providedIn: 'root'
})
export class MyCtrlOrgService {

  // services + init
  constructor(
    private auth: AuthService,
    private routes: RoutesService,
  ) {
    this.auth.user().subscribe(v => {
      this._userInfo = v;
      this.loadMyRoles();
    });
  }

  // vars (these should never be public)
  private _userInfo:IUserInfo;
  private _roles:RolesList;
  private info: BehaviorSubject<RolesList> = new BehaviorSubject(null);

  // public methods
  public sub() {
    return this.info;
  }

  getMyUid(){
    if (this._userInfo){
      return this._userInfo.uid
    }
  }

  hasRole(role:UserRoles){
    if (this._roles && this._roles.indexOf(role) !== -1){
      return true;
    }
    return false;
  }

  private loadMyRoles(){
    return this.auth
      .apiFind(this.routes.TEST_CTRL_ROLES)
      .then(roles => {
        this._roles = roles;
        this.info.next(this._roles);
      })
  }

  constructG9PermissionsParams(query?:any){
    return {
      query:{
        ... query,
        tc_group_id: 5403,
      }
    }
  }

  getG9Summaries(col:string, projectId:number){
    return this.auth.apiGet(
      '/public/test-ctrl/schools/summary',
      0,
      this.constructG9PermissionsParams({ col, projectId, courseType: 'EQAO_G9' })
    )
  }
  getG10Summaries(col:string, projectId:number){
    return this.auth.apiGet(
      '/public/test-ctrl/schools/summary',
      0,
      this.constructG9PermissionsParams({ col, projectId, courseType: 'EQAO_G10'})
    )
  }
  getG9Boards(){
    return this.auth.apiFind(
      '/public/test-ctrl/schools/boards',
      this.constructG9PermissionsParams({})
    )
  }
  getG9Schools(){
    return this.auth.apiFind(
      '/public/test-ctrl/schools/schools',
      this.constructG9PermissionsParams({})
    )
  }
  getG9Students(id){
    return this.auth.apiGet(
      '/public/test-ctrl/schools/students-teachers-classes',
      id,
      this.constructG9PermissionsParams({})
    )
  }


  // private utilities
}
