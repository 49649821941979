export interface IGradeOption {
    grade: string | null;
    caption: string;
}
export const GRADE_OPTIONS: IGradeOption[] = [
    { grade: '4', caption: 'sa_sr_grade4' },
    { grade: '7', caption: 'sa_sr_grade7' },
    { grade: null, caption: 'sa_all_grades' },
]

export enum StudentProgress {
    NOT_STARTED = 0,
    ONGOING = 1,
    SUBMITTED = 2
}

export enum DisplayMode {
    OVERVIEW = 'overview',
    ENROLLED = 'enrolled',
    ENROLL = 'enroll',
    EXEMPTED = 'exempted',
    EXEMPT = 'exempt',
    UNENROLLED = 'unenrolled',
    UNENROLL = 'unenroll',
}