<div class="page-body">
  <div>
    <div class="new-header">
      <div class="breadcrumb"><i class="fas fa-home"></i> / Landing</div>
      <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/BCID_H_rgb_rev/1609866956940/BCID_H_rgb_rev.png">
    </div>
    <div class="page-content is-fullpage" >
      <div *ngIf="isLoaded" class="inner">
        <div class="bigger text">
          Markers
        </div>
        <div class="text">Please log in by clicking the link that was provided to you by your leader/coordinator.</div>
        <div class="or">
          <hr><div>or</div><hr>
        </div>
        <div class="bigger text">
          Leaders/Coodinators
        </div>
        <div class="text">Please click the button below to sign in.</div>
        <button routerLink="/en/general/login/false" class="login">Leader/Coordinator Sign-in</button>
      </div>
    </div>
  </div>
</div>
