<div class="split-example ex-pixel">
  <as-split unit="pixel"  direction="horizontal" gutterSize="30" (dragEnd)="log($event)" style="height:100vh;">
    <as-split-area size="600" maxSize="800">
      <div class="simulated-question-text" style="min-width:20em">
        <div>ITEM STIMULUS</div>
        <div>ITEM STIMULUS</div>
        <div>ITEM STIMULUS</div>
      </div>
    </as-split-area>
    <as-split-area size="*" style="position:relative;">
      <div >
        <div class="blocks" cdkDropList (cdkDropListDropped)="drop(blocks, $event);">
          <div *ngFor="let block of blocks; let index = index;" class="solution-block" cdkDrag>
            <button class="button is-white is-handle" cdkDragHandle>
              <i class="fa fa-bars" aria-hidden="true"></i>
            </button>
                            <div class="solution-block-content" [ngSwitch]="block.blockType">
                                <div *ngSwitchCase="BlockTypes.TEXT" [class.hide-ckeditor-toolbar]="!block.isInFocus" #text class="ckdiv">
                                    <!-- <element-render-text-constr></element-render-text-constr> -->
                                    <ckeditor [editor]="Editor" [config]="ckEditorConfig" (ready)="onReady($event)" (focus)="block.isInFocus=true" (blur)="block.isInFocus=false"></ckeditor>
                                </div>
                                <div class=""></div>
                                <div *ngSwitchCase="BlockTypes.MATH">
                                    <capture-math></capture-math>
                                </div>
                                <div *ngSwitchCase="BlockTypes.TABLE" #table id="{{ 'tableBlock' + index }}">
                                    <table class="table-input" id="table">
                                        <tr *ngIf="block.isEditingTable">
                                            <td></td>
                                            <td *ngFor="let cell of block.rows[0].cells; let col_i = index;" class="edit-cell">
                                                <button *ngIf="!review" class="button  is-small " (click)="removeColumn(block, col_i)">
                        <i class="fa fa-trash" aria-hidden="true" aria-label="DeleteBlock"></i>
                      </button>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let row of block.rows; let row_i = index;">
                                            <td *ngIf="block.isEditingTable" class="edit-cell">
                                                <button *ngIf="!review" class="button is-small " (click)="removeRow(block, row_i)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                                            </td>
                                            <td *ngFor="let cell of row.cells">
                                                <textarea 
                                                    cdkTextareaAutosize 
                                                    [cdkTextareaAutosize]="true" 
                                                    [cdkAutosizeMinRows]="2"
                                                    [readonly]="review"
                                                ></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                    </table>
                                    <!-- <img *ngIf="review" [src]="imgCanvas"> -->
                                    <div style="display:flex; flex-direction:row; justify-content: space-between;">
                                        <div>
                                            <div *ngIf="block.isEditingTable">
                                                <button (click)="insertRow(block)" class="button is-small">Add Row</button>
                                                <button (click)="insertColumn(block)" class="button is-small">Add Column</button>
                                            </div>
                                        </div>
                                        <button *ngIf="!review" (click)="block.isEditingTable = !block.isEditingTable" class="button is-white is-small show-on-hover">
                      <i class="fas fa-cog"></i>
                    </button>
                                    </div>
                                </div>
                                <div *ngSwitchCase="BlockTypes.DIAGRAM" [ngClass]="{'drawing': review === false}" #diagram>
                                    <element-render-drawing [mode]="mode" [id]="index"></element-render-drawing>
                                </div>
                            </div>
                            <button *ngIf="!review" class="button is-white is-handle" (click)="removeElement(blocks, index)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!review" #constrRespInput class="buttons block-inserters">
                    <button *ngFor="let block of availableBlocks" [disabled]="isBlockInsertBuffered(block.id)" (click)="insertBlock(block.id)" class="button btn block-selector">
                    <div class="icon"><i  [ngClass]="block.icon"></i></div>
              <div> {{block.caption}} </div>
            </button>
                </div>
                <div *ngIf="blocks.length && !review" style="margin:0 5em;">
                    <hr>
                    <div>
                        <button class="button is-info" (click)="toggleReview()">Review</button>
                        <!-- <button class="button" (click)="showNext()">Next Question</button> -->
                    </div>
                </div>



            <div *ngIf="review" class="review">
                <div class="review__content">
                    <div style="margin:0 5em;">
                        <hr>
                        <div>
                            <button class="button is-info" (click)="toggleReview()">Cancel</button>
                            <button class="button" (click)="showNext()">Next Question</button>
                        </div>
                    </div>
                </div>
            </div>
        </as-split-area>
    </as-split>
</div>

<button *ngIf="removeStack.length > 0 && !review" class="undo_btn" (click)="undo()">Undo <i class="fas fa-undo-alt"></i></button>
<!---Instructions modal box begins here-->
<div class="instr-popup" #popup id="modal1" aria-modal="true" role=”dialog” aria-hidden="true">
    <div class="instr-popup__box">
        <div class="instr-popup__box--header">
            <div class="intsr-popup__box--header-contents" style="width: 100%;height: 100%;display: flex;align-items: center; justify-content: space-between;font-size: 1rem;">
                <h1 style="color:whitesmoke;">Editor Instructions</h1>
                <button style="border:none; background:transparent;" (click)="removeInstructions()" aria-label="closemodal" tabindex="0">
          <i class="fas fa-times cancel"  style="font-size:1.6rem; color:whitesmoke; cursor:pointer;"></i>
         </button>
            </div>
        </div>
        <div class="instr-popup__box--body">
            <p>Below is a list of the most important keystrokes supported by the text editor and its features:</p>
            <table>
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>PC</th>
                        <th>Mac</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Copy</td>
                        <td><kbd>Ctrl</kbd> + <kbd>C</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>C</kbd></td>
                    </tr>
                    <tr>
                        <td>Paste</td>
                        <td><kbd>Ctrl</kbd> + <kbd>V</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>V</kbd></td>
                    </tr>
                    <tr>
                        <td>Undo</td>
                        <td><kbd>Ctrl</kbd> + <kbd>Z</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>Z</kbd></td>
                    </tr>
                    <tr>
                        <td>Redo</td>
                        <td><kbd>Ctrl</kbd> + <kbd>Y</kbd> <br> <kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>Z</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>Y</kbd> <br> <kbd>&#x2318;</kbd> + <kbd>Shift</kbd> + <kbd>Z</kbd></td>
                    </tr>
                    <tr>
                        <td>Bold</td>
                        <td><kbd>Ctrl</kbd> + <kbd>B</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>B</kbd></td>
                    </tr>
                    <tr>
                        <td>Italic</td>
                        <td><kbd>Ctrl</kbd> + <kbd>I</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>I</kbd></td>
                    </tr>
                    <tr>
                        <td>Link</td>
                        <td><kbd>Ctrl</kbd> + <kbd>K</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>K</kbd></td>
                    </tr>
                    <tr>
                        <td>Insert a hard break (e.g. a new paragraph)</td>
                        <td colspan="2"><kbd>Enter</kbd></td>
                    </tr>
                    <tr>
                        <td>Insert a soft break (i.e. a <code>&lt;br&gt;</code>)</td>
                        <td colspan="2"><kbd>Shift</kbd> + <kbd>Enter</kbd></td>
                    </tr>
                    <tr>
                        <td>Nest the current list item (when in a list)</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <th colspan="3">When a widget is selected (for example: image, table, horizontal line, etc.)</th>
                    </tr>
                    <tr>
                        <td>Insert a new paragraph directly after a widget</td>
                        <td colspan="2"><kbd>Enter</kbd></td>
                    </tr>
                    <tr>
                        <td>Insert a new paragraph directly before a widget</td>
                        <td colspan="2"><kbd>Shift</kbd> + <kbd>Enter</kbd></td>
                    </tr>
                    <tr>
                        <td>Display the caret to allow typing directly before a widget</td>
                        <td colspan="2"><kbd>&#x2191;</kbd> / <kbd>&#x2190;</kbd></td>
                    </tr>
                    <tr>
                        <td>Display the caret to allow typing directly after a widget</td>
                        <td colspan="2"><kbd>&#x2193;</kbd> / <kbd>&#x2192;</kbd></td>
                    </tr>
                    <tr>
                        <th colspan="3">In a table cell</th>
                    </tr>
                    <tr>
                        <td>Move the selection to the next cell</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Move the selection to the previous cell</td>
                        <td colspan="2"><kbd>Shift</kbd> + <kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Insert a new table row (when in the last cell of a table)</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Navigate through the table</td>
                        <td colspan="2"><kbd>&#x2191;</kbd> / <kbd>&#x2192;</kbd> / <kbd>&#x2193;</kbd> / <kbd>&#x2190;</kbd></td>
                    </tr>
                </tbody>
            </table>
            <h2 id="user-interface-and-navigation"><a class="headerlink" href="#user-interface-and-navigation">#</a> User interface and navigation</h2>
            <p>Use the following keystrokes for more efficient navigation in the CKEditor 5 user interface:</p>
            <table>
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>PC</th>
                        <th>Mac</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Close contextual balloons and UI components like dropdowns</td>
                        <td colspan="2"><kbd>Esc</kbd></td>
                    </tr>
                    <tr>
                        <td>Move focus to the visible contextual balloon</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Move focus between fields (inputs and buttons) in contextual balloons</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Move focus to the toolbar</td>
                        <td><kbd>Alt</kbd> + <kbd>F10</kbd></td>
                        <td><kbd>Alt</kbd> + <kbd>F10</kbd> <br> (may require <kbd>Fn</kbd>)</td>
                    </tr>
                    <tr>
                        <td>Navigate through the toolbar</td>
                        <td colspan="2"><kbd>&#x2191;</kbd> / <kbd>&#x2192;</kbd> / <kbd>&#x2193;</kbd> / <kbd>&#x2190;</kbd></td>
                    </tr>
                    <tr>
                        <td>Execute the currently focused button</td>
                        <td colspan="2"><kbd>Enter</kbd></td>
                    </tr>
                </tbody>
            </table>
            <style>
                .keyboard-shortcuts th {
                    text-align: center;
                }
                
                .keyboard-shortcuts td:nth-of-type(1) {
                    text-align: right;
                }
                
                .keyboard-shortcuts td:nth-of-type(2),
                .keyboard-shortcuts td:nth-of-type(3) {
                    width: 30%;
                }
            </style>
        </div>
    </div>
</div>

<!-- End of modal -->