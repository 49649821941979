import * as _ from 'lodash';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { IContentElementDnd, ElementTypeDefs, IElementTypeDef, ElementType, IContentElementDndSub, IContentElement, IContentElementDndTarget, IContentElementDndDraggable } from '../../ui-testrunner/models';
import { createDefaultElement } from '../item-set-editor/models';
import { CdkDragRelease, CdkDragStart, CdkDragEnd, moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { bindFormControls } from '../services/data-bind';
import { FormControl } from '@angular/forms';
import { indexOf } from '../services/util';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AuthScopeSettingsService, AuthScopeSetting } from '../auth-scope-settings.service';
import { EditingDisabledService } from '../editing-disabled.service';

export const renderDndElementStyle = (element:IContentElementDndSub, hasContent:boolean=false, isCustomDim:boolean=false, defaultTargetStyle:IContentElementDndSub=null) => {
  const style:{[key:string]:string | number} = {
    'transform': `translate3d(${element.x}px, ${element.y}px, 0px)`,
  }
  if (!hasContent){ // using as a proxy for targets right now...
    const dims = ['width', 'height'];
    dims.forEach(dim => {
      let val;
      if (isCustomDim){
        val = element[dim];
      }
      if (!val && defaultTargetStyle){
        val = defaultTargetStyle[dim]
      }
      if (!val){
        val = 30;
      }
      style[dim+'.px'] = val;
    })
  }
  return style;
}
export const getNewElPosFromDrag = (elEntry:IElementPos, e:CdkDragEnd) => {
  const el = e.source.element.nativeElement;
  const transform:string = el.style.transform;
  let regex = /[\-\d.]+px/g;
  var values = [];
  transform.replace(regex, (match) => {
    values.push(match.substr(0, match.length-2));
    return match;
  })
  const offset = { x: parseFloat(values[0]), y: parseFloat(values[1]) };
  const newPos = {
    x: offset.x + elEntry.originalX, //<number>elEntry.style['left.px'],
    y: offset.y + elEntry.originalY //<number>elEntry.style['top.px'],
  }
  return newPos;
}

export interface IElementPos {
  ref: IContentElementDndSub,
  originalX:number,
  originalY:number,
  isTarget?:boolean;
  isDraggable?:boolean;
  style: {
    [key:string]: string | number
  }
}

@Component({
  selector: 'element-config-dnd',
  templateUrl: './element-config-dnd.component.html',
  styleUrls: ['./element-config-dnd.component.scss']
})
export class ElementConfigDndComponent implements OnInit {

  @ViewChild('elPosContainer', { static: true }) elPosContainer:ElementRef;
  @Input() element:IContentElementDnd;

  isShowAdvancedOptions = new FormControl(false);
  targetType = new FormControl();
  width = new FormControl();
  height = new FormControl();
  customTargetDim = new FormControl();
  scoreWeight = new FormControl();

  subElementTypes:IElementTypeDef[] = [
    ElementTypeDefs.TEXT,
    ElementTypeDefs.MATH,
    ElementTypeDefs.IMAGE,
    ElementTypeDefs.TABLE,
    // ElementTypeDefs.INPUT,
  ];

  elementsToPosition:IElementPos[] = []

  constructor(
    private authScopeSettings: AuthScopeSettingsService,
    private editingDisabled: EditingDisabledService
  ) { }

  ngOnInit() {

    if (!this.element.draggableCounter){ this.element.draggableCounter = 0; }
    if (!this.element.targetCounter){ this.element.targetCounter = 0; }
    if (!this.element.defaultTargetStyle){ this.element.defaultTargetStyle = {x:0, y:0, width:30, height:30}; }
    
    const formControls = [
      {f: this.isShowAdvancedOptions, p:'isShowAdvancedOptions' },
      {f: this.targetType, p:'targetType' },
      {f: this.width, p:'width' },
      {f: this.height, p:'height' },
      {f: this.customTargetDim, p:'customTargetDim' },
      {f: this.scoreWeight, p:'scoreWeight' },
    ];

    bindFormControls(this.element, formControls)
    this.updateDisplayEls();
  }

  insertBackgroundElement(elementType:ElementType){
    const newEl = {
      element: createDefaultElement(elementType),
      ... this.newDefaultDndElementConfig()
    }
    this.element.backgroundElements.push(newEl)
    this.recordLastTouch(newEl);
    this.updateDisplayEls();
  }

  insertDraggableElement(elementType:ElementType){
    this.element.draggableCounter = this.incrementCounter(this.element.draggableCounter, this.element.draggables);
    const newEl = {
      id: this.element.draggableCounter,
      element: createDefaultElement(elementType),
      ... this.newDefaultDndElementConfig()
    }
    this.element.draggables.push(newEl)
    this.recordLastTouch(newEl);
    this.updateDisplayEls();
  }

  insertTargetElement(){
    // console.log('insertTargetElement')
    this.element.targetCounter = this.incrementCounter(this.element.targetCounter, this.element.targets);
    const newEl:IContentElementDndTarget = {
      id: this.element.targetCounter,
      ... this.newDefaultDndElementConfig()
    }
    this.element.targets.push(newEl);
    this.recordLastTouch(newEl);
    this.updateDisplayEls();
  }

  selectedPos:IElementPos;
  isSelectedPos(elPos:IElementPos){
    return (elPos === this.selectedPos);
  }
  isSelectedPosRef(el:IContentElementDndSub){
    if (this.selectedPos){
      return (el === this.selectedPos.ref);
    }
  }
  selectPosEl(elPos:IElementPos){
    this.selectedPos = elPos;
  }
  selectPosElByRef(el:IContentElementDndSub){
    this.elementsToPosition.forEach(pos =>{
      if (pos.ref === el){
        this.selectedPos = pos;
      }
    })
    console.log('selectPosElByRef', this.selectedPos)
  }

  onElementPickup(el:IContentElementDndSub){
    this.selectPosElByRef(el);
  }

  incrementCounter(counter:number, subs:IContentElementDndSub[]){
    let isFirst = true;
    let isMatchFound = false;
    while (isFirst || isMatchFound){
      counter ++;
      isFirst = false;
      isMatchFound = false;
      subs.forEach(sub => {
        if ( (''+sub.id) === (''+counter) ){
          isMatchFound = true;
        }
      });
    }
    return counter;
  }


  newDefaultDndElementConfig () : IContentElementDndSub {
    return  {
      x: 0,
      y: 0,
    }
  }

  throttledUpdate = _.throttle(()=>{
    this.updateDisplayEls();
  }, 500)

  updateDisplayEls(){
    this.elementsToPosition = [];
    this.element.backgroundElements.forEach(element => this.addElementToList(element, this.elementsToPosition));
    this.element.targets.forEach(element => this.addElementToList(element, this.elementsToPosition, true));
    this.element.draggables.forEach(element => this.addElementToList(element, this.elementsToPosition, false, true));
    this.updateChangeCounter();
  }

  addElementToList(element:IContentElementDndSub, elementsToPosition:IElementPos[], isTarget:boolean=false, isDraggable:boolean=false){
    let hasElement:boolean = false;
    if ((<IContentElementDndDraggable> element).element){
      hasElement = true;
    }
    elementsToPosition.push({
      ref: element,
      originalX: element.x,
      originalY: element.y,
      isTarget,
      isDraggable,
      style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
    });
  }
  
  removeElement(arr:any[], t:any){
    if (window.confirm('Remove this option?')){
      let i = indexOf(arr, t);
      if (i !== -1){
        arr.splice(i, 1)
      }
    }
    this.updateDisplayEls();
  }

  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr)
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

  debug(str:string){
    console.log('debug', str);
  }

  lastTouchedElement:IContentElementDndSub;
  recordLastTouch(elementSub:IContentElementDndSub){
    this.lastTouchedElement = elementSub;
  }

  updateChangeCounter(){
    // console.log('dnd updateChangeCounter')
    if (!this.element._changeCounter){
      this.element._changeCounter = 0;
    }
    this.element._changeCounter ++;
  }


  setElPosFromDrag(elEntry:IElementPos, e:CdkDragEnd){
    // console.log('setElPosFromDrag')
    const newPos = getNewElPosFromDrag(elEntry, e);
    elEntry.ref.x = newPos.x;
    elEntry.ref.y = newPos.y;
    this.updateChangeCounter();
  }

  isScoreWeightEnabled = () => this.authScopeSettings.getSetting(AuthScopeSetting.ENABLE_SCORE_WEIGHT);

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }
}
