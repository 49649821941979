import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountType } from '../constants/account-types';
import { AuthService, IUserInfo } from './auth.service';
import { LangService } from '../core/lang.service';
import { Router } from '@angular/router';
import { SiteContexts } from './constants/site-contexts'

// const ACTIVITY_TRACKER_BUFFER = 10*60*1000; // 10 minutes
const IS_FRENCH_OVERRIDE_ENABLED = false;
const MINUTES_MS = 60 * 1000;
const AUTO_LOGOUT_WARNING_INTERVAL = 15 * MINUTES_MS
const AUTO_LOGOUT_INTERVAL = 60 * MINUTES_MS
const ACTIVITY_REFRESH_INTERVAL = 10 * MINUTES_MS
const NET_STABILITY_INTERVAL = 5 * 1000; // 5 seconds
const NET_STABILITY_STEPS = Math.floor(20 / 5);

export interface IConfirmationReq {
  caption: string;
  confirm?: () => any;
  close?: () => any;
  btnProceedCaption?: string;
  btnCancelCaption?: string;
  hideCancel?: boolean;
  caption_beneath_button?: string;
  btnCancelConfig?: any;
}

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService {

  private loginReq: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private devNetFail: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private apiNetFail: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private infoModal: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private supportReq: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private autoLogoutWarning: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private autoLogout: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private confirmationReq: BehaviorSubject<IConfirmationReq> = new BehaviorSubject(null);

  private isActivated: boolean;
  private isAuthenticated: boolean;
  private currentTokenExp: number;
  private isReauthCompleted: boolean;
  private isDevNetOnline: boolean;
  private isDevNetOnlinePersistent: boolean; // needs to be persistently bad for NET_STABILITY_STEPS before this is activated
  private hasRecentActivity: boolean;
  private isValidAccountType: boolean;
  private isMonitoringDevNet: boolean;
  private currentAccountType: AccountType;
  private devNetDiscrepCounter = 0;
  private activeContexts:Map<SiteContexts, boolean> = new Map();

  private autoLogoutWarningTimeoutId;
  private autoLogoutTimeoutId;
  private recentActivityIntervalId;

  constructor(
    private auth: AuthService,
    private lang: LangService,
    private router: Router,

  ) {
    this.loginReq.next(false);
    this.apiNetFail.next(false);
    this.infoModal.next(false)
    this.updateOnlineStatus(); // devNetFail can be checked right away
    // console.log('LoginGuardService construct');
    const trackNewActivity = () => (this.hasRecentActivity = true) && this.autoLogoutWarning.next(false);;
    document.addEventListener('keydown', trackNewActivity);
    document.addEventListener('mousedown', trackNewActivity);
    document.addEventListener('touchstart', trackNewActivity);
    this.initRecentActivityChecks();

    setInterval(this.monitorDevNet, NET_STABILITY_INTERVAL);
    this.auth.registerNoApiNetSub(this.apiNetFail);
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.auth.user().subscribe(this.updateUserInfo);
    this.auth.getReauthCompletedSub().subscribe(this.updateReauthCompleted);
  }

  autoLogoutWarningTimeout: number;
  autoLogoutTimeout: number;

  public activateSiteContext(siteContext: SiteContexts){
    this.activeContexts.set(siteContext, true);
  }
  public deactivateSiteContext(siteContext: SiteContexts){
    this.activeContexts.set(siteContext, false); // to do: how do we ensure that this gets unset in all of the right places?
  }
  public checkSiteContext(siteContext: SiteContexts){
    return this.activeContexts.get(siteContext)
  }

  private clearRecentActivityChecks(skipAutoLogout = false) {
    this.hasRecentActivity = false;
    this.autoLogoutWarning.next(false);
    if (!skipAutoLogout) {
      this.autoLogout.next(false);
    }
    clearInterval(this.recentActivityIntervalId);
    clearTimeout(this.autoLogoutWarningTimeoutId);
    clearTimeout(this.autoLogoutTimeoutId);
  }

  private recentActivityChecks = (skipTokenRefresh = false) => {
    // console.log('recentActivityChecks', this.hasRecentActivity);
    if (!this.isAuthenticated) return;

    if (this.hasRecentActivity) {
      clearTimeout(this.autoLogoutWarningTimeoutId);
      this.autoLogoutWarningTimeoutId = setTimeout(() => {
        if (!this.hasRecentActivity) {
          this.autoLogoutWarning.next(true);
        }
      }, AUTO_LOGOUT_WARNING_INTERVAL);

      clearTimeout(this.autoLogoutTimeoutId);
      this.autoLogoutTimeoutId = setTimeout(() => {
        this.autoLogoutWarning.next(false);
        if (!this.hasRecentActivity) {
          this.autoLogout.next(true);
          this.auth.logout();
        }
      }, AUTO_LOGOUT_INTERVAL);

      // reset activity tracker
      this.hasRecentActivity = false;
      if (!skipTokenRefresh) {
        this.auth.refreshToken();
      }
    }
  }

  private initRecentActivityChecks() {
    // console.log('initRecentActivityChecks');
    this.clearRecentActivityChecks();
    this.hasRecentActivity = true;
    this.recentActivityChecks(true);
    this.recentActivityIntervalId = setInterval(this.recentActivityChecks, ACTIVITY_REFRESH_INTERVAL);
  }

  private updateOnlineStatus = (e?: any) => {
    this.isDevNetOnline = navigator.onLine;
    if (this.isMonitoringDevNet) {
      this.devNetDiscrepCounter = 0;
    } else {
      if (this.isDevNetOnline !== this.isDevNetOnlinePersistent) {
        this.devNetDiscrepCounter = 0;
        this.isMonitoringDevNet = true;
      }
    }
  }

  private monitorDevNet = () => {
    if (this.isMonitoringDevNet) {
      this.devNetDiscrepCounter++;
      // console.log('this.devNetDiscrepCounter', this.devNetDiscrepCounter)
      if (this.devNetDiscrepCounter >= NET_STABILITY_STEPS) {
        this.concludeDevNet();
      }
    }
  }
  private concludeDevNet() {
    this.isMonitoringDevNet = false;
    const v = this.isDevNetOnline;
    if (this.isDevNetOnlinePersistent !== v) { // this check should not be required, but just making sure so that we have the right rate limiting on the BSubject
      this.isDevNetOnlinePersistent = v;
      this.devNetFail.next(!v);
    }

  }

  private updateReauthCompleted = (v: boolean) => {
    this.isReauthCompleted = v;
    this.updateLoginReqState();
  }

  private updateUserInfo = (userInfo) => {
    if (userInfo) {
      this.isAuthenticated = true;
      this.currentAccountType = userInfo.accountType;
      this.initRecentActivityChecks();
    } else {
      this.isAuthenticated = false;
      this.currentAccountType = null;
      this.clearRecentActivityChecks(true);
    }
    this.updateLoginReqState();
  }

  isAuthActive() {
    return this.isAuthenticated;
  }

  getLoginReq() { return this.loginReq; }
  getDevNetFail() { return this.devNetFail; }
  getApiNetFail() { return this.apiNetFail; }
  getInfoOverlay() { return this.infoModal; }
  getAutoLogoutWarning() { return this.autoLogoutWarning; }
  getAutoLogout() { return this.autoLogout; }
  getSupportReq() { return this.supportReq; }
  getConfirmationReq() { return this.confirmationReq; }
  async refreshLogin() {
    return this.auth.refreshToken();
  }

  updateLoginReqState() {
    const isLoginReq = this.isReauthCompleted && this.isActivated && !this.isAuthenticated;
    // console.log('updateLoginReqState', isLoginReq, this.isReauthCompleted, this.isActivated, this.isAuthenticated)
    if (this.loginReq.getValue() !== isLoginReq) {
      this.loginReq.next(isLoginReq);
    }
  }

  public gotoUserDashboard(userInfo: IUserInfo) {
    if (userInfo) {
      if (userInfo.lang) {
        this.lang.setCurrentLanguage(userInfo.lang);
      }
      const langCode = this.lang.getCurrentLanguage();
      const accountType = userInfo.accountType;
      // if (userInfo.accountTypes && userInfo.accountTypes.length > 1){
      //   return this.router.navigate([langCode, 'login-portal']);
      // }
      switch (accountType) {
        case AccountType.TEST_ADMIN:
          return this.router.navigate([langCode, accountType, 'mng-inst-info']);
        case AccountType.EDUCATOR:
          return this.router.navigate([langCode, accountType, 'classrooms']);
        case AccountType.STUDENT:
          return this.router.navigate([langCode, accountType, 'dashboard', 'main'], { queryParamsHandling: "preserve" });
        case AccountType.MRKG_MRKR:
          return this.router.navigate([langCode, accountType, 'dashboard'], { queryParams: { training: 'true' } });
        case AccountType.SCOR_LEAD:
        case AccountType.SCOR_SUPR:
        case AccountType.SCOR_SCOR:
        case AccountType.TEST_TAKER:
        case AccountType.MRKG_CHAIR:
        case AccountType.MRKG_LEAD:
        case AccountType.TEST_CTRL:
        case AccountType.TEST_CTRLD:
        case AccountType.TEST_CTRL_EXCH:
        case AccountType.CERT_BODY:
        case AccountType.TEST_AUTH:
        case AccountType.SUPPORT:
        case AccountType.MRKG_SUPR:
        case AccountType.MRKG_CAND:
        case AccountType.MRKG_COORD:
        case AccountType.MRKG_CTRL:
        case AccountType.MRKG_UPLD:
        case AccountType.SCHOOL_ADMIN:
        case AccountType.MINISTRY_ADMIN:
          return this.router.navigate([langCode, accountType, 'dashboard']);
        case AccountType.BC_GRAD_MINISTRY_ADMIN_SPECIAL_CASE:
          return this.router.navigate([langCode, 'ministry-admin', 'bc-grad', 'dashboard']);
        case AccountType.SCANNING_COORD:
          return this.router.navigate([langCode, accountType, 'bc-grad', 'dashboard']);
        case AccountType.BC_GRAD_MINISTRY_ADMIN:
          return this.router.navigate([langCode, 'ministry-admin', 'bc-grad', 'dashboard']);
        case AccountType.BC_GRAD_DIST_ADMIN:
          return this.router.navigate([langCode, 'dist-admin', 'bc-grad', 'dashboard']);
        case AccountType.BC_GRAD_SCHOOL_ADMIN:
          return this.router.navigate([langCode, 'school-admin', 'bc-grad', 'dashboard'])
        case AccountType.BC_FSA_SCHOOL_ADMIN:
        case AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY:
          return this.router.navigate([langCode, 'school-admin', 'bc-fsa', 'dashboard'])
        case AccountType.BC_FSA_DIST_ADMIN:
        case AccountType.BC_FSA_DIST_ADMIN_SCORE_ENTRY:
          return this.router.navigate([langCode, 'dist-admin', 'bc-fsa', 'dashboard']);
        case AccountType.BC_FSA_MINISTRY_ADMIN:
        case AccountType.BC_FSA_ADMIN_EAO:
        case AccountType.MINISTRY_ADMIN:
          return this.router.navigate([langCode, 'ministry-admin', 'bc-fsa', 'dashboard']);
        default:
          throw new Error('Unknown user type');
      }
    }
  }

  activate(accountTypes?: AccountType[]) {
    this.isActivated = true;
    if (accountTypes) {
      if (accountTypes.indexOf(this.currentAccountType) === -1) {
        this.isValidAccountType = false;
      } else {
        this.isValidAccountType = true;
      }
    } else {
      this.isValidAccountType = true;
    }
    this.updateLoginReqState();
  }

  deactivate() {
    this.isActivated = false;
    this.updateLoginReqState();
  }
  supportReqActivate() {
    this.supportReq.next(true);
  }
  supportReqDeactivate() {
    this.supportReq.next(false);
  }

  quickPopup(caption?: string, btnCap?: string) {
    if (btnCap){
        this.confirmationReqActivate({
        caption: this.lang.tra(caption),
        btnProceedCaption: btnCap,
        hideCancel: true,})
    } else {
      this.confirmationReqActivate({
        caption: this.lang.tra(caption),
        hideCancel: true,})
    }
    
  }
  disabledPopup(caption?: string) {
    this.quickPopup(caption || 'feature_disabled');
  }
  disabledPopup_G9(caption?: string, btnText?: string) {
    this.quickPopup(caption || 'feature_disabled', btnText);
  }
  confirmationReqActivate(config: IConfirmationReq) {
    if (config.confirm === undefined) {
      config.confirm = () => { };
    }
    if (config.close === undefined) {
      config.close = () => { };
    }
    this.confirmationReq.next(config);
  }
  confirmationReqDeactivate() {
    this.confirmationReq.next(null);
  }

  infoModalOn = false;
  toggleInfoOverlay() {
    this.infoModalOn = !this.infoModalOn
    this.infoModal.next(this.infoModalOn)
  }

  
  async forceEnglish() {
    if (await IS_FRENCH_OVERRIDE_ENABLED){
      if (this.lang.c() !== 'en'){
        alert('La page à laquelle vous accédez est en phase de traduction et sera bientôt disponible en français.');
        this.lang.loadInLang('en')
      }
    }
  }

  getDevNetOnlineStatus(){
    return this.isDevNetOnline;
  }

}
