import accounting from 'accounting-js';
import { countDecimals } from '../../ui-testrunner/models';

export const INPUT_NUMBER_FORMATS = {
  'None': function(val, isFrench){
    if(!isFormattable(val, isFrench)) return val;
    let unformattedNumber = unformatNumber(val, isFrench);
    return accounting.formatNumber(unformattedNumber, getFormatNumberSettings(countDecimals(unformattedNumber), isFrench));
  },
  '0': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(0, isFrench));
  },
  '0.0': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(1, isFrench));
  },
  '0.00': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(2, isFrench));
  },
  '0.000': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(3, isFrench));
  }
}

const isFormattable = (val: string, isFrench: boolean) => {
  // Regex to test if the value is ended by a decimal followed by one or more zeros, like 11.00, or any
  // decimal input that ends with 0s, such as 12.1200, 1.20.
  // we need to escape the unformat process for this case as well, otherwies accounting.unformat will
  // remove the .0 from the value, and that will actually change the user input.
  const zeroAfterDecimalRegex_EN = /\d*\.0?(?:[0-9]+0+)?$/g;
  const zeroAfterDecimalRegex_FR = /\d*\,0?(?:[0-9]+0+)?$/g;

  if(isFrench) 
    return !val.endsWith(',') && !val.match(zeroAfterDecimalRegex_FR);

  return !val.endsWith('.') && !val.match(zeroAfterDecimalRegex_EN);
}

const getFormatNumberSettings = (precision: number, isFrench: boolean) => {
  return { precision, thousand: getThousand(isFrench), decimal: getDecimal(isFrench) }
}

const getThousand = (isFrench) => {
  return isFrench ? " " : ","
}

const getDecimal = (isFrench) => {
  return isFrench ? "," : "."
}

const unformatNumber = (formattedVal, isFrench) => {
  return Number(accounting.unformat(String(formattedVal), getDecimal(isFrench)))
}