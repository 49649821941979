<div class="page-body">
    <header [breadcrumbPath]="breadcrumb" [hasSidebar]="true"></header>
    <div  class="page-content">
        <div *ngIf="noRole">
            <tra-md
                slug="no_school_admin_role">
            </tra-md>
        </div>
        <div *ngIf="!noRole" class="bc-accounts-admins">
            <bc-header-layout [tra]="'sa_admin_accounts_bc'"
                imgSrc="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/504237/authoring/admin/1627063636881/admin.png"
                [accountType]="accountType" [buttons]="extraButtons"
                [disableSchool]="isDistrictAdmin() && disableSchoolInHeaderLayout"
                [readQueryParams]="!isDistrictAdmin() || readQueryParams" (button)="extraButtonClicked($event)"
                (school)="onSelectedSchoolChange($event)" (district)="onSelectedDistrictChange($event)"
                (testWindow)="onSelectedTestWindowChange($event)">
            </bc-header-layout>

            <!-- <bc-tabs *ngIf="isDistrictAdmin()" [tabs]="tabs" (select)="onSelectedTabChange($event)"></bc-tabs> -->

            <bc-accounts-admins-school *ngIf="isSchoolAdmin()" [districtDetail]="districtDetail"
                [schoolDetail]="selectedSchool" [accountType]="accountType" [testWindow]="selectedTestWindow"
                [getVisibleSchools]="getVisibleSchools" [assessmentType]="getAssessmentType()">
            </bc-accounts-admins-school>

            <bc-accounts-admins-district *ngIf="isDistrictAdmin()" [districtDetail]="districtDetail"
                [schoolDetail]="selectedSchool" [accountType]="accountType" [testWindow]="selectedTestWindow">
            </bc-accounts-admins-district>
        </div>
    </div>
</div>