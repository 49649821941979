import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { AssessmentType, BcAssessmentsService, DuplicateStudentTestAttempts, SRDuplicateStudentTestAttempts, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { ALL_SCHOOLS, BcAccountsService, District, DistrictDetail, School, SchoolDetail } from "../../bc-accounts/bc-accounts.service";
import { CellClickedEvent, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { ngHasInputChanged } from 'src/app/ui-schooladmin/sa-accounts-students/sa-accounts-students-enrolled/util';

const TWTAR_FSA_SLUG_COMPONENT_LENGTH = 3;
@Component({
  selector: 'fsa-duplicated-attempts-report',
  templateUrl: './fsa-duplicated-attempts-report.component.html',
  styleUrls: ['./fsa-duplicated-attempts-report.component.scss']
})
export class FsaDuplicatedAttemptsReportComponent implements OnInit {

  @Input() testWindow : TestWindow;
  @Input() areTestWindowsLoading: boolean;
  @Input() currentTestWindowName: string;

  gridExport = () => this.downloadAsCsv(this.gridOptions);

  isLoading: boolean = false;
  areModalContentsLoading: boolean = false;
  showMoreInfoModal: boolean = false;
  currentStudent: SRDuplicateStudentTestAttempts = null;
  currentStudentAttempts =
  {
    CRAttempts: [],
    SRAttempts: []
  };

  districts: District[];
  selectedDistrict: District;
  schools: School[];
  selectedSchool: School;
  schoolDetail: SchoolDetail;
  districtDetail: DistrictDetail;

  gridOptions: GridOptions; 

  constructor
  (
    private bcAccounts: BcAccountsService,
    private bcAssessments: BcAssessmentsService,
    private LoginGuard: LoginGuardService
  ) 
  { 
    this.gridOptions = {
      rowData: [],
      columnDefs: 
      [
        { field: 'PEN', headerName: "PEN", width: 125, },
        { field: 'first_name', headerName: "First Name", width: 150, },
        { field: 'last_name', headerName: "Last Name", width: 150, },
        { field: 'grade', headerName: "Grade", width: 100, },
        { field: 'enrollment_school', headerName: "School of Enrollment", width: 300, },
        { field: 'enrollment_district', headerName: "District of Enrollment", width: 300, },
        { field: 'overall_are_all_cases_resolved', headerName: "Is Fully Resolved ", width: 150, },
        { field: 'SR_attempt_count', headerName: "# SR Attempts", width: 150, },
        { field: 'CR_attempt_count', headerName: "# CR Duplication", width: 150, },
        { field: 'SR_are_all_cases_resolved', headerName: "Is SR Resolved", width: 150, },
        { field: 'CR_are_all_cases_resolved', headerName: "Is CR Resolved", width: 150, },
      ],
      defaultColDef: 
      {
        suppressMenu: true,
        floatingFilter: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      enableCellTextSelection: true,
      animateRows: true,
      pagination: true,
      paginationPageSize: 10,
      onGridReady: (event: GridReadyEvent) => 
      {
        // console.log(event);
        this.gridOptions.api.showLoadingOverlay();
        this.initData();   
      },
    };
  }

  async ngOnChanges(changes) {
    // console.log("changes: ", changes);
    if (ngHasInputChanged(changes.testWindow, 'id')) 
    {
      this.isLoading = false;
      this.initData();
    }
  }

  async initData(findNewTestAttempts: boolean = true, currentAgGridFilters: any = null)
  {
    if (this.testWindow == null || this.isLoading)
    {
      return;
    }

    this.isLoading = true;
    this.gridOptions.api?.showLoadingOverlay();

    if (findNewTestAttempts)
    {
      await this.markNewDuplicateTestAttempts(); // changes `ta.is_duplicate`
    }
  
    await this.fetchData();

    if (currentAgGridFilters != null)
    {
      // Sets the filter model via the grid API
      this.gridOptions.api.setFilterModel(currentAgGridFilters);
    }
    
    this.isLoading = false;

  }

  async ngOnInit(): Promise<void>
  {
    this.bcAccounts.findDistricts(AssessmentType.FSA, true).then(async districts => 
    {
      this.districts = districts;
      this.selectedDistrict = this.districts[0];
      await this.onSelectedDistrictChange(null);
    });
  }

  async markNewDuplicateTestAttempts()
  {
    try 
    {
      await this.bcAssessments.flagDuplicateFSATestAttempts(this.testWindow.id);
    }

    catch(e)
    {
      console.error(e);
      this.LoginGuard.quickPopup("There was an error in identifying duplicate test attempts.");
    }
  }

  initGridOptions() 
  {
  }

  async onCellClicked(event: CellClickedEvent): Promise<void>
  {
    this.areModalContentsLoading = true;
    this.showMoreInfoModal = true;
    console.log(event);
    const clickedCellHeaderName = event.column.getColDef().headerName;
    this.currentStudent = event.data;
    await this.loadStudentTestAttempts();
    this.areModalContentsLoading = false;
  }

  async loadStudentTestAttempts()
  {
    try 
    {
      this.currentStudentAttempts = await this.bcAssessments.getDuplicateFSATestAttempts(this.testWindow.id, 
        this.currentStudent.uid);
      console.log(this.currentStudentAttempts);
    }

    catch(e)
    {
      console.error(e);
      this.LoginGuard.quickPopup("There was an error in getting duplicate test attempts.");
    }
    
  }

  closeShowMoreInfoModal()
  {
    // we don't want to slow the table from loading tremendously by also trying to find new duplicate cases
    // every time a user closes the duplicate attempts modal, as users will close this modal a lot.
    // It is very fair to assume there will be no new duplicate cases by the time the user opens the modal and closes it,
    // which should take only a few seconds most of the times. This is why we call initData with false here:

    // Gets filter model via the grid API, so we can maintain filters on modal close
    const model = this.gridOptions.api.getFilterModel(); 
    this.initData(false, model);
    this.showMoreInfoModal = false;
    this.currentStudent = null;
    this.currentStudentAttempts = null;
  }

  getAttemptStatusBtnText(isInvalid: 0 | 1): string
  {
    return isInvalid ? "Restore" : "Delete";
  }

  boolToStr(val: 0 | 1 | null): string
  {
    return val ? "Yes" : "No";
  }

  getNumValidTestAttempts(testAttempts: any[]): number
  {
    return testAttempts.filter(attempt => attempt.is_invalid === 0).length;
  }

  public getTwtdarSlugWithoutComponent(slug: string): string
  {
    return slug.slice(0, slug.length - TWTAR_FSA_SLUG_COMPONENT_LENGTH);
  }

  async toggleTAIsInvalid(testAttemptId: number, isSR: boolean)
  {
    try
    {
      const attempts = isSR ? this.currentStudentAttempts.SRAttempts : this.currentStudentAttempts.CRAttempts;
      await this.bcAssessments.toggleTestAttemptIsInvalid(testAttemptId, attempts, isSR);
      this.loadStudentTestAttempts();
    }

    catch(e)
    {
      console.error(e);
      console.log(e);

      if (e.code == 406)
      {
        this.LoginGuard.quickPopup(e.message);  
      }
      
      else 
      {
        this.LoginGuard.quickPopup("There was an error in trying to change test attempt # " + 
        testAttemptId + "'s status.");     
      }
    }
    
  }

  async fetchData(): Promise<void> 
  {
    // console.log(this.gridOptions, this.gridOptions.api);
    if (!this.gridOptions || !this.gridOptions.api) return;

    // console.log(this.gridOptions);
    // console.log(this.gridOptions.api);
    // console.log('getting data');
   
    try
    {
      const rows = await this.bcAssessments.getDuplicateFSATestAttemptsStudents(this.testWindow.id);
      console.log(rows);
      this.gridOptions.api.setRowData(this.formatBooleanDataInAgGrid(rows));
      this.gridOptions.api.hideOverlay();
    }

    catch(e)
    {
      console.error(e);
      this.LoginGuard.quickPopup("There was an error in getting students with duplicate test attempts.");      
    }
  }

  public formatBooleanDataInAgGrid(data: DuplicateStudentTestAttempts[]): any[]
  {
    return data.map((row) => 
    {
      
      let CR_are_all_cases_resolved = this.boolToStr(row.CR_are_all_cases_resolved);
      let SR_are_all_cases_resolved = this.boolToStr(row.SR_are_all_cases_resolved);
      let overall_are_all_cases_resolved = this.boolToStr(row.overall_are_all_cases_resolved);

      return {
        ...row,
        CR_are_all_cases_resolved,
        SR_are_all_cases_resolved,
        overall_are_all_cases_resolved,
      };
    });
  }

  public downloadAsCsv(gridOptions:any)
  {
    let params = 
    {
      processCellCallback: (params: any) => 
      {
        return params.value;
      }
    };

    gridOptions.api.exportDataAsCsv(params);    
  }

  public districtDisplayFn = (district: District) => {
    let display = '';
    if (district) {
      display = this.getDisplayDistrict(district)
    }
    return display;
  }

  public schoolDisplayFn = (school: School) => {
    let display = '';
    if (school) {
      display = this.bcAccounts.getSchoolDisplay(school);
    }
    return display;
  }

  getDisplayDistrict(district: District): string {
    return this.bcAccounts.getDistrictDisplay(district);
  }

  public getFilterDistrictsFn() {
    const self = this;
    return (value: string | District): District[] => {
      let filtered: District[] = [];
      if (self.districts) {
        let filterValue: string;
        if ((value as District).name) {
          filterValue = (value as District).name.toLowerCase()
        } else {
          filterValue = (value as string).toLowerCase();
        }
        filtered = self.districts.filter(district => district.name.toLowerCase().includes(filterValue) || self.formatDistrict(district.foreignId).includes(filterValue));
      }
      return filtered;
    }
  }

  public getFilterSchoolsFn() {
    const self = this;
    return (value: string | School): School[] => {
      let filtered: School[] = [];
      if (self.schools) {
        let filterValue: string = '';
        if (value) {
          if ((value as School).name) {
            filterValue = (value as School).name.toLowerCase()
          } else {
            filterValue = (value as string).toLowerCase();
          }
        }
        filtered = self.schools.filter(school => school.name.toLowerCase().includes(filterValue) || this.bcAccounts.formatSchoolCode(school.foreignId).includes(filterValue));
      }
      return filtered;
    }
  }

  public districtSelected = (event) => 
  {
    const district: District = event.option.value;
    this.selectedDistrict = district;
    this.onSelectedDistrictChange(event);
  }

  public schoolSelected = (event) => {
    const school: School = event.option.value;
    this.selectedSchool = school;
    this.onSelectedSchoolChange(event);
  }

  onSelectedDistrictChange(e) {
    if (this.selectedDistrict.groupId > 0) {
      return this.bcAccounts.getDistrictDetail(this.selectedDistrict.groupId).then(districtDetail => {
        this.districtDetail = districtDetail;
        return this.bcAccounts.findSchools(this.selectedDistrict.groupId, 'fsa').then(schools => {
          this.schools = schools;
          this.selectedSchool = this.schools[0];
          return this.onSelectedSchoolChange(null);
        });
      })
    } else {
      this.districtDetail = null;
      this.schools = [ALL_SCHOOLS];
      this.selectedSchool = this.schools[0];
      return this.onSelectedSchoolChange(null);
    }
  }

  async onSelectedSchoolChange(e) 
  {
    if (this.selectedSchool.groupId > 0) 
    {
      this.schoolDetail = await this.bcAccounts.getSchoolDetail(this.selectedSchool.groupId);    
    } 
    
    else 
    {
      this.schoolDetail = null;
    }

    this.filterData();
  }

  public filterData(): void
  {
    const schoolFilterVal = this.schoolDetail? this.schoolDetail.name + ' (' + this.schoolDetail.foreignId + ')': null;
    const districtFilterVal = this.districtDetail? this.districtDetail.name + ' (' + this.districtDetail.foreignId + ')': null;
    const filterModal = 
    {
      enrollment_school: 
      {
        filterType: 'text',
        type: 'contains',
        filter: schoolFilterVal
      },
      enrollment_district: 
      {
        filterType: 'text',
        type: 'contains',
        filter: districtFilterVal
      },
    }
    this.gridOptions.api?.setFilterModel(filterModal);
  }

  formatDistrict(district: number): string {
    return this.bcAccounts.formatDistrictCode(district);
  }

  formatSchool(school: number): string {
    return this.bcAccounts.formatSchoolCode(school);
  }

}
