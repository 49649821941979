import {ILeaderSelectionItem} from './data';

enum SelectionType {
    UNSELECTED = 'UNSELECTED',
    HIGH = 'HIGH',
    LOW = 'LOW',
    ZERO = 'ZERO',
}
enum MarkingCode {
    NONE = '',
    NR = 'NR',
    FOUR = '4',
    THREE = '3',
    TWO = '2',
    ONE = '1',
    ZERO = '0',
}

enum ItemType {
    SYSTEM = 'SYSTEM', // comes from the system itself, authoring => test taking.
    SCAN = 'SCAN',
}


export const s3LeaderItems: ILeaderSelectionItem[] = [
];
