import { IPanelLink } from "./type";
import { AccountType } from "../../../constants/account-types";
import {SCORING_SUPERVISOR_VIEWS} from '../../../ui-scoring-super/view-ss-dashboard/data/views'

export const scoringSupervisorPanels:IPanelLink[] = SCORING_SUPERVISOR_VIEWS.map(view => {
    return {
        caption: view.caption,
        routerLink: `/en/${AccountType.SCOR_SUPR}/${view.id}/1/1`, // to complete
        iconUrl: view.imgUrl
    }
})