<div class="page-body">
  <div>
    <header
            [breadcrumbPath]="breadcrumb"
            [hasSidebar]="true"
    ></header>
<div>
    <menu-bar
            [menuTabs]="views"
            [tabIdInit]="selectedTab"
            [emitChangeOnClickOnly]="true"
            (change)="selectView($event)"
            [ngClass]="selectedTab"
    ></menu-bar>
    <!-- <div *ngIf="displayedView" class="view-container"> -->
        <!-- <div [ngSwitch]="displayedView"> -->
            <!-- <div *ngSwitchCase="BCFSAScoreEntrySessionView.SUMMARY"> -->
                <!-- <ma-score-entry-summary></ma-score-entry-summary> -->
            <!-- </div> -->
            <!-- <div *ngSwitchCase="BCFSAScoreEntrySessionView.STUDENTS"> -->
                <!-- <ma-score-entry-students></ma-score-entry-students> -->
            <!-- </div> -->
            <!-- <div *ngSwitchCase="BCFSAScoreEntrySessionView.EDIT"> -->
                <!-- <ma-edit-score-session></ma-edit-score-session> -->
            <!-- </div> -->
            <!-- <div *ngSwitchCase="BCFSAScoreEntrySessionView.INDIVIDUAL"> -->
                <!-- <ma-score-entry-individual></ma-score-entry-individual> -->
            <!-- </div> -->
        <!-- </div> -->
    <!-- </div> -->

</div>
</div>
</div>
