<div class="fsa-self-reflection">
    <table class="table is-bordered">
        <tr>
            <th>Session</th>
            <th>Assessment</th>
            <th>Item</th>
            <th>Data</th>
            <th>Word Cloud</th>
        </tr>
        <tr *ngFor="let item of getSelfReflections()"> 
            <td>{{ getTitle(item.meta.session) }}</td>
            <td>{{ item.meta.assessment }}</td>
            <td>{{ item.meta.item }}</td>
            <td><a [href]="item.meta.file_url"><tra [slug]="item.meta.file_link_slug"></tra></a></td>
            <td>
                <span *ngIf="!item.meta.img_url"><tra slug="sa_na" ></tra></span>
                <img *ngIf="item.meta.img_url" class="word-cloud" [src]="item.meta.img_url">
            </td>
        </tr>
    </table>

</div>