import { Component, OnInit, Input } from '@angular/core';
import { IContentElement, QuestionState } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';



@Component({
  selector: 'element-render-hotspot',
  templateUrl: './element-render-hotspot.component.html',
  styleUrls: ['./element-render-hotspot.component.scss']
})
export class ElementRenderHotspotComponent implements OnInit {

  @Input() element:IContentElement;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  constructor() { }

  ngOnInit() {
  }

}
