<div
    [class.mouse-over]="isCustomMode()"
    [class.is-perm-tooltip]="element.isPermanentTooltip"
>
    <div [style.font-size.em]="element.buttonScale"
         [class.is-main-content-invisible]="!audioFileReady">
        <render-audio 
            #renderAudio
            [audioID]="'audio'+element.entryId"
            (play)="play()" 
            (onPlay)="onPlay()" 
            (end)="playEnd($event)"
            [isTriggerDisabled]="!isEnabled()"
            [hasControls]="hasControls()" 
            [startTime]="getStartTime()"
            [preventOverload]="true"
            [trigger]="trigger" 
            [isDisabled]="!isEnabled() || this.isLocked" 
            *ngIf="element" 
            (timeUpdate)="onTimeUpdate()"
            [url]="element.url"
            (canStartPlaying)="canStartPlaying()"
            [mode]="element.displayMode"
        ></render-audio>
    </div>
    <div 
        *ngIf="!element.isHidden && isCustomMode() && showTimeLeft" 
        class="on-mouse-over" 
        [class.is-align-top]="element.toolTipPosition==ToolTipPositions.TOP || !element.toolTipPosition"
        [class.is-align-bottom]="element.toolTipPosition==ToolTipPositions.BOTTOM"
        [class.is-align-right]="element.toolTipPosition==ToolTipPositions.RIGHT"
        [class.is-align-left]="element.toolTipPosition==ToolTipPositions.LEFT"
        [class.is-main-content-invisible]="!audioFileReady"
    >
        <div class="on-mouse-over-offset" [class.is-main-content-invisible]="!audioFileReady">
            <div>
                <div [style]="getRemainingPlayStyle()" *ngIf="resetter && element.numAudioPlays && element.isLimitAudioPlays && !element.isHidden">
                    <tra-md [isCondensed]="true" [slug]="getNumPlaysRemainingSlug()" [props]="{NUM_REM_PLAYS: element.numAudioPlays-getNumViews()}"></tra-md>
                </div>
                <div  [style]="getRemainingSecStyle()"> {{getSecRemainingStr()}} </div>
                <div [style]="getTranscriptTimeRemainingStyle()" *ngIf="allowTranscripts && accommodation && element.transcriptReadTime">
                  <tra style="font-size:0.8em" slug="auth_transcript_time_left"></tra>
                  <div> {{getTranscriptTimeRemaining()}} </div>
                </div>
            </div>
            <div *ngIf="allowTranscripts && accommodation" class="transcript">
              <!-- <button *ngIf="transcriptUrl && isPlaying" class="transcript-toggle" (click)="showTranscript()">
                {{transcriptOn ? 'Hide Transcript' : 'Show Transcript'}}
              </button><br> -->
              <button *ngIf="getTranscriptTimer()>0" style="pointer-events: auto;" (click)="skipTimer()">
                <tra slug="auth_skip_transcript"></tra>
              </button>
              <!-- <div class="transcript-content" [innerHtml]="transcript" *ngIf="transcriptOn && transcriptUrl"></div> -->
            
            </div>
        </div>
    </div>

    <div class="loading-spinner" *ngIf="!audioFileReady">
      <div style="text-align: center;"
           class="loading-spinner-gif"
           [class.is-offset-top]="element.toolTipPosition==ToolTipPositions.TOP || !element.toolTipPosition"
           [class.is-offset-bottom]="element.toolTipPosition==ToolTipPositions.BOTTOM"
           [class.is-offset-right]="element.toolTipPosition==ToolTipPositions.RIGHT"
           [class.is-offset-left]="element.toolTipPosition==ToolTipPositions.LEFT">
        <div class="lds-ring" [class.is-hi-contrast]="isHighContrast()">
          <div [class.is-hi-contrast]="isHighContrast()"></div>
          <div [class.is-hi-contrast]="isHighContrast()"></div>
          <div [class.is-hi-contrast]="isHighContrast()"></div>
          <div [class.is-hi-contrast]="isHighContrast()"></div>
        </div>
      </div>
    </div>
</div>

<div *ngIf="allowPlaybackSpeed && hasCheckedCanStart"
      [style.margin-top.em]="element.customPlaybakSelectionMenuPosition? element.playbackMenuTopMargin : 0"
      [style.margin-left.em]="element.customPlaybakSelectionMenuPosition? element.playbackMenuLeftMargin : 0">
    <tra-md [isCondensed]="true" slug="bc_authoring_view_playbackrate"></tra-md>
    <div class="control is-expanded">
      <div class="select">
        <select *ngIf="!isFrench()" [(ngModel)]="playBackRate" (ngModelChange)="adjustRate()">
          <option *ngFor="let rate of playBackRates; let index = index" [value]="rate">
            {{rate}}
          </option>
        </select>
        <select *ngIf="isFrench()" [(ngModel)]="playBackRate_fr" (ngModelChange)="adjustRate_fr()">
          <option *ngFor="let rate of playBackRates_fr; let index = index" [value]="rate">
            {{rate}}
          </option>
        </select>
      </div>
    </div>  
</div>


