import { Component, OnInit, Input } from '@angular/core';
import { ElementType } from '../../ui-testrunner/models';
import { EditingDisabledService } from '../editing-disabled.service';
import { ItemEditCtrl } from '../item-set-editor/controllers/item-edit';

@Component({
  selector: 'element-config',
  templateUrl: './element-config.component.html',
  styleUrls: ['./element-config.component.scss']
})
export class ElementConfigComponent implements OnInit {

  @Input() contentElement;
  @Input() itemEditCtrl?:ItemEditCtrl

  constructor(public editingDisabled: EditingDisabledService) { }

  ElementType = ElementType;

  ngOnInit() {
  }

}
