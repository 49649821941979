import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ScrollService } from '../../core/scroll.service';
import { AuthService, IUserInfo } from '../../api/auth.service';
import { LangService } from '../../core/lang.service';
import { Subscription } from 'rxjs';
import { AccountType } from '../../constants/account-types';
import { RouteViewBaseComponent } from '../../core/route-view-base/route-view-base.component';
import { LoginGuardService } from '../../api/login-guard.service';

interface ILoginFormErrors {
  isBlank?:boolean,
  isInvalid?: boolean,
}
@Component({
  selector: 'view-landing-page',
  templateUrl: './view-landing-page.component.html',
  styleUrls: ['./view-landing-page.component.scss']
})
export class ViewLandingPageComponent implements OnInit, OnDestroy {

  constructor(
    private auth: AuthService,
    private lang: LangService,
    private loginGuard: LoginGuardService, // this.loginGuard.deactivate();
    private breadcrumbsService: BreadcrumbsService,
    private router:Router,
    private route:ActivatedRoute,
    private scrollService:ScrollService,
    private langService:LangService,
  ) { }

  public breadcrumb = [];
  public isLoaded:boolean;
  private userSub:Subscription;
  private routeSub:Subscription;
  public accountType:AccountType;
  private prefilledEmailEncoded:string
  public prefilledEmail:string
  private dontLogout:string; // this is used to flag login routes that should not trigger logout (it would have been better to flag the opposite, but the SEB files are already generated)
  private isLoginAttempted:boolean;

  ngOnInit() {
    this.loginGuard.deactivate();
    this.scrollService.scrollToTop();
    this.routeSub = this.route.params.subscribe(routeParams => {
      this.accountType = <AccountType> routeParams['accountType'];
      this.dontLogout = routeParams['dontLogout'];
      const emailEncoded = routeParams['emailEncoded'];
      if (emailEncoded){
        this.prefilledEmail = decodeURIComponent(emailEncoded);
      }
      this.initRouteView()
    });
  }

  ngOnDestroy() {
    if (this.userSub){
      this.userSub.unsubscribe();
    }
  }

  onUserInfo = (userInfo:IUserInfo) => {
    console.log(userInfo);
    if (userInfo){
      // console.log(this.dontLogout, this.isLoginAttempted || this.dontLogout)
      if (this.isLoginAttempted || this.dontLogout === 'true'){
        this.loginGuard.gotoUserDashboard(userInfo)
      }
      else{
        this.auth.logout();
      }
    }
  }
  
  getCurrentAccountType(){
    switch(this.accountType){
      case AccountType.CERT_BODY: return AccountType.CERT_BODY;
      case AccountType.TEST_ADMIN: return AccountType.TEST_ADMIN;
      case AccountType.TEST_CTRL: return AccountType.TEST_CTRL;
      default:
      case AccountType.TEST_TAKER: return AccountType.TEST_TAKER;
    }
  }

  trackLoginAttempted(){
    this.isLoginAttempted = true;
  }

  initRouteView(){
    this.isLoaded = true;
    this.userSub = this.auth.user().subscribe(this.onUserInfo);
    this.breadcrumb = [
      // this.breadcrumbsService._HEAD(this.accountType),
      this.breadcrumbsService._CURRENT( this.lang.tra('title_login'), this.router.url),
    ];
  }

}
