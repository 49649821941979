import { DBT } from "src/app/api/models";
import { IQuestionConfig } from "src/app/ui-testrunner/models";
import { ItemType } from "../models";


export enum NotifStatus {
    READ = 'READ',
    UNREAD = 'UNREAD'
}

export enum CommentType {
    ASSIGNED,
    CONTRIBUTED,
    CREATED
}

export enum NotifType {
    ASSIGNED_COMMENT = 'ASSIGNED_COMMENT',
    REPLIED_COMMENT = 'REPLIED_COMMENT',
    REPLIED_ASSIGNED_COMMENT = 'REPLIED_ASSIGNED_COMMENT',
    RESOLVED_COMMENT = 'RESOLVED_COMMENT',
    POSTED_COMMENT = 'POSTED_COMMENT',
    PARAM_CHANGES = 'PARAM_CHANGES',
    EDITED_ITEM = 'EDITED_ITEM',
    EXPIRING_COPYRIGHT = 'EXPIRING_COPYRIGHT',
    CREATED_ITEM = 'CREATED_ITEM',
    ATTACHED_FILE = 'ATTACHED_FILE',
    EDITED_NOTES = 'EDITED_NOTES',
    SHARED_ASSESSMENT = 'SHARED_ASSESSMENT',
    REVOKED_ASMT_ACCESS = 'REVOKED_ASMT_ACCESS',
    SHARED_AUTH_GROUP = 'SHARED_AUTH_GROUP',
    REVOKED_AUTH_GROUP_ACCESS = 'REVOKED_AUTH_GROUP_ACCESS',
    CREATED_ASSESSMENT = 'CREATED_ASSESSMENT',
    ARCHIVED_ASSESSMENT = 'ARCHIVED_ASSESSMENT',
    CREATED_ASSET = 'CREATED_ASSET',
    EDITED_ASSET = 'EDITED_ASSET'
}

export interface INotificationRes {
    id: number,
    activates_on: DBT.DATETIME,
    is_read: boolean,
    authGroup?: {
        description: string
    },
    item?: {
        id: number,
        label: string,
        content: string,
        isArchived: boolean,
        itemType: ItemType
    },
    asmt?: {
        id: number,
        name: string,
        isArchived: boolean,
        has_access: boolean
    },
    notifType: NotifType,
    user?: {
        first_name: string,
        last_name: string,
        contact_email: string
    },
    asset?: {
        id: number,
        name: string,
        is_archived: boolean,
        has_library_access: boolean
    },
    assetGroup?: {
        id: number,
        name: string,
        is_deleted: boolean,
        has_access: boolean
    }
    entryType: EntryType,
    commentResolveType?: CommentType,
    email?: string,
    linkToFramework?: boolean
}

export interface Notification {
    notifType: NotifType,
    user?: string,
    email?: string, //For sharing actions
    commentResolveType?: CommentType
}

export enum ENoteItemType {
    QUESTION = 'question',
    ASSET = 'asset',
    ASSET_GROUP = 'assetGroup'
}

export enum EntryType {
    ITEM = 'ITEM',
    ASSET = 'ASSET',
    ASSET_GROUP = 'ASSET_GROUP',
    ASMT = 'ASMT',
    AUTH_GROUP = 'AUTH_GROUP'
}
export interface INotificationEntryItem extends INotificationEntry {
    assessment: string,
    itemLabel: string,
    itemType: ItemType,
    itemSetId: number,
    itemIsArchived: boolean,
    asmtIsArchived: boolean,
    questionContent: IQuestionConfig,
    authGroup: string,
    hasAsmtAccess: boolean
}

export interface INotificationEntryAsset extends INotificationEntry {
    assetId: number,
    assetName: string,
    assetIsArchived: boolean,
    authGroup?: string,
    hasLibraryAccess: boolean
}

export interface INotificationEntryAssetGroup extends INotificationEntry {
    assetGroupId: number,
    assetGroupName: string,
    assetGroupIsDeleted: boolean,
    hasAssetGroupAccess: boolean
}

export interface INotificationEntryAssessment extends INotificationEntry {
    assessment: string,
    itemSetId: number,
    asmtIsArchived: boolean,
    authGroup: string,
    linkToFramework: boolean,
    hasAsmtAccess: boolean
}

export interface INotificationEntryAuthGroup extends INotificationEntry {
    authGroup: string
}

export interface INotificationEntry {
  id: number,
  date: string,
  time: string,
  notification: Notification,
  status: NotifStatus,
  isPersonal? : boolean,
  entryType: EntryType
}
