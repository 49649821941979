import { Component, OnInit } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { AuthService } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { Router } from '@angular/router';
import { IAsmtSessionProgReport } from './model/types';
import { downloadStr } from '../../core/download-string';
const _ = require('lodash');
import * as moment from 'moment-timezone';

enum DataTranche {
  PROV_TOTALS,
  LOGINS_BY_SCH_DIST,
  TOTAL,
  STUDENT,
  TEACHER,
}
type KeyPart = string | number;
type Key = KeyPart[];

const numBoolToggle = <T>(num:number, ifYes:T, ifNo:T) => {
  if (num == 1){
    return ifYes;
  }
  return ifNo;
}
const numBool = (num:number) => numBoolToggle(num, true, false);


@Component({
  selector: 'view-tracking',
  templateUrl: './view-tracking.component.html',
  styleUrls: ['./view-tracking.component.scss']
})
export class ViewTrackingComponent implements OnInit {

  breadcrumb = [];
  hasAccess:boolean;
  isShowSubmissionsOnly:boolean;
  isIncludeSampleAccounts:boolean;
  isDistSchTeachers:boolean;

  DataTranche = DataTranche;
  totals:any = {};

  assessmentComponentTitleRef:Map<string,string> = new Map();
  assessmentComponentRef:Map<string,any> = new Map();
  assessmentComponents:{code:string, order:number, group:string}[] = [];
  schoolsAndDistricts:{cat:string, schoolDistId:string, isDistrict?:boolean}[] = [];
  refreshInterval;
  isShowingRecent:boolean;

  constructor(
    public lang: LangService,
    private auth: AuthService,
    private routes: RoutesService,
    private loginGuard: LoginGuardService,
    private sidePanel: SidepanelService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
  ) { 

  }

  ngOnInit(): void {
    this.loginGuard.activate([]);
    this.sidePanel.activate();
    this.sidePanel.unexpand();
    this.breadcrumb = [
      this.breadcrumbsService._CURRENT('Ministry Administration Coordinator / Session Progress', this.router.url),
    ];
    this.auth.user().subscribe(user => {
      if (user){
        this.loadData(user.uid);
        if (this.refreshInterval){
          clearInterval(this.refreshInterval)
        }
        this.refreshInterval = setInterval(() => {
          this.loadData(user.uid);
        }, 5*60*1000)
      }
    });


    /// temp
    window['Invite2Summary'] = () => {
      const targetEmail = prompt();
      this.sendInvite(targetEmail)
    }
    window['auditMcq'] = () => this.auth.apiFind('public/test-ctrl/assessment-session/audit/mcq', {query:{tc_group_id: 1}})
    window['pullQuestions'] = () => {
      this.auth
        .apiFind('public/test-ctrl/assessment-session/audit/mcq', {query:{tc_group_id: 1, isRaw:true}})
        .then(data => {
          downloadStr(JSON.stringify(data), 'data.json')
        })
    }
  }


  ngOnDestroy(){
    if (this.refreshInterval){
      clearInterval(this.refreshInterval)
    }
  }

  renderLoginOrSubmission(isCapitalized:boolean=false){
    if (this.isShowSubmissionsOnly){
      return isCapitalized ? 'Submission' : 'submission'
    }
    return isCapitalized ? 'Login' : 'login'
  }

  createProvTotalKey(
    assessmentCode:string|number, 
    studentOrTeacher:DataTranche, 
  ){ 
    return [DataTranche.PROV_TOTALS, assessmentCode, studentOrTeacher, ]
  }

  createDistSchKey(
    cat:string, 
    distSchId:KeyPart, 
    assessmentCode:KeyPart, 
  ){ 
    return [DataTranche.LOGINS_BY_SCH_DIST, cat, distSchId, assessmentCode,]
  }

  getProvTotal(assessmentCode:KeyPart, studentOrTeacher:DataTranche){
    const key = [assessmentCode, studentOrTeacher, ]
  }

  private processAssessmentComponentTitle(title:string, isResComp:boolean = false){
    let asmtCode = this.assessmentComponentTitleRef.get(title);
    if (!asmtCode){
      const {code, order, group} = JSON.parse(title);
      this.assessmentComponentRef.set(title, code);
      asmtCode = code;
      if (!this.assessmentComponentRef.has(code)){
        const asmtComponent = {code, group, order: order || 0}
        this.assessmentComponents.push(asmtComponent);
        this.assessmentComponentRef.set(asmtCode, asmtComponent);
      }
    }
    if (isResComp){
      return this.assessmentComponentRef.get(asmtCode);
    }
    return asmtCode;
  }
  
sendInvite(email:string){
  return this.auth
      .apiCreate('public/test-ctrl/assessment-session/summary', {email}, {query:{tc_group_id: 1}})
      .then(res => {
        console.log(res)
      })
}

  private summaryData:IAsmtSessionProgReport
  loadData(uid:number){
    this.auth
      .apiGet('public/test-ctrl/assessment-session/summary', 1, {query:{tc_group_id: 1}})
      .then((res:IAsmtSessionProgReport) => {
        this.summaryData = res;
        this.processSummaryData();
        this.processRecentLogins();
        this.hasAccess = true;
      } )
  }

  getSummaryValue(key:Key){
    return _.get(this.totals, key) || 0;
  }

  recentLogins:any[];
  processRecentLogins(){
    const renderTimestamp = (date:string) => {
      return moment.tz(date, moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
    }
    this.recentLogins = this.summaryData.recentLogins.map(record => {
      let {cat, schoolDistId, isDistrict} = this.parseSchoolDistrictInfo(record);
      const asmtComponent = this.processAssessmentComponentTitle(record.title, true) || {};
      return {
        test_attempt_id: record.test_attempt_id,
        browser_name: record.browser_name,
        browser_version: record.browser_version,
        operating_system: record.operating_system,
        asmtComponent,
        created_on: renderTimestamp(record.created_on),
        closed_on: record.closed_on ? renderTimestamp(record.closed_on) : '',
        schoolDistCat: cat,
        isDistrict,
        schoolDistId: schoolDistId,
        isTeacher: numBool(record.is_teacher),
        isTester: numBool(record.is_test),
        num_questions: record.num_questions
      }
    })
  }

  private parseSchoolDistrictInfo(record:any) {
    let cat, schoolDistId, isDistrict;
    if (record.school_id){
      isDistrict = false
      cat = 'Independent';
      schoolDistId = record.school_id;
    }
    else {
      isDistrict = true
      cat = 'District';
      schoolDistId = record.district_id;
    }
    return {cat, isDistrict, schoolDistId}
  }

  processSummaryData(){
    // console.log('processSummaryData')
    this.totals = {};
    const sumTranche = (key:Key, val:number) => {
      const sum = (_.get(this.totals, key) || 0) + (+val)
      _.set(this.totals, key, sum);
    }
    
    this.summaryData.provincialTotals.forEach(record => {
      const code = this.processAssessmentComponentTitle(record.title);
      const studentOrTeacher = numBoolToggle(record.is_teacher, DataTranche.TEACHER, DataTranche.STUDENT)
      // console.log('studentOrTeacher', record.is_teacher, studentOrTeacher)
      const key = this.createProvTotalKey(code, studentOrTeacher);
      const keyTotal = this.createProvTotalKey(DataTranche.TOTAL, studentOrTeacher);
      if (numBool(record.is_test) && !this.isIncludeSampleAccounts){ return; }
      if (this.isShowSubmissionsOnly){
        sumTranche(key, record.num_completed);
        sumTranche(keyTotal, record.num_completed);
      }
      else{
        sumTranche(key, record.num_total);
        sumTranche(keyTotal, record.num_total);
      }
    });

    this.schoolsAndDistricts = []
    const schDistRef = new Map();

    this.summaryData.loginsByDistSchools.forEach(record => {
      let {cat, isDistrict, schoolDistId} = this.parseSchoolDistrictInfo(record);

      if (!schoolDistId || ! schoolDistId.trim()){ return; }

      const code = this.processAssessmentComponentTitle(record.title);
      const key = this.createDistSchKey(cat, schoolDistId, code);
      if (numBool(record.is_teacher) && !this.isDistSchTeachers){ return; }
      if (!numBool(record.is_teacher) && this.isDistSchTeachers){ return; }
      if (numBool(record.is_test) && !this.isIncludeSampleAccounts){ return; }

      let val;
      if (this.isShowSubmissionsOnly){
        val = record.num_completed;
      }
      else{
        val = record.num_total;
      }
      if (!val){ return; }

      const mapKey = [cat, schoolDistId].join(';')
      if (!schDistRef.has(mapKey)){
        this.schoolsAndDistricts.push({cat, schoolDistId, isDistrict})
        schDistRef.set(mapKey, true);
      }

      sumTranche(key, val);

    });
    
    this.assessmentComponents = _.orderBy(this.assessmentComponents, 'order');
    this.schoolsAndDistricts = _.orderBy(this.schoolsAndDistricts, ['cat', 'schoolDistId']);

    this.hasAccess = true;
  }

  selectGroup(group:string){
    if (this.isActiveGroup(group)){
      this.selectedGroup = null;
    }
    else{
      this.selectedGroup = group
    }
  }
  isActiveGroup(group:string){
    return group && (this.selectedGroup === group);
  }

  selectedGroup;


  

}
