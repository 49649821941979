import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { BcAccountsService, DistrictDetail, Filter, FilterCondition, PaginatedRows, Pagination, School, SchoolDetail, SchoolLevelDataReportProficiencyLevel } from "../../bc-accounts/bc-accounts.service";
import { AssessmentType, BcAssessmentsService, StudentIrtRow, StudentIrtRow2, TestWindow } from "src/app/bc-assessments/bc-assessments.service";
import { AccountType } from "src/app/constants/account-types";
import { BcPaginatedTableComponent, Data, IColumnHeading, LinkClickEvent, Tag, ZebraConfig } from "../bc-paginated-table/bc-paginated-table.component";
import { LangService } from 'src/app/core/lang.service';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/api/auth.service";
import { IndividualRawScoresRow } from "src/app/bc-reports/types";
import { saveAs } from 'file-saver';
import { WhitelabelService } from "src/app/domain/whitelabel.service";

enum Mode {
  TABLE,
  SINGLE,
}

export enum ScoreType {
  RAW_SCORE = 'raw-score',
  SCALED_SCORE = 'scaled-score',
}

@Component({
  selector: 'individual-student-reports',
  templateUrl: './individual-student-reports.component.html',
  styleUrls: ['./individual-student-reports.component.scss']
})
export class IndividualStudentReportsComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(BcPaginatedTableComponent) table: BcPaginatedTableComponent<StudentIrtRow2>;

  @Input() testWindow: TestWindow;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;
  @Input() accountType: AccountType = AccountType.SCHOOL_ADMIN;

  isLoading: boolean = false;

  currentWindow: TestWindow;
  currentSchool: SchoolDetail;

  pagination: Pagination;
  selectedScoreType: ScoreType;
  ScoreType = ScoreType;

  columnHeadings: IColumnHeading[] = [
    { heading: this.lang.tra(this.whitelabel.getSiteText(('student_num'))), sortBy: 'pen', isLink: true, },
    { heading: this.lang.tra('sa_lastname'), sortBy: 'last_name' },
    { heading: this.lang.tra('sa_firstname'), sortBy: 'first_name' },
    {
      heading: this.lang.tra('sa_grade'), sortBy: 'grade', isSelect: true, options: [
        { label: 'All Grades', value: -1 },
        { label: 'Grade 4', value: 4 },
        { label: 'Grade 7', value: 7 },
      ]
    },
  ]
  tableColumnWidths: number[] = [116, 210, 210, 210];

  FilterCondition = FilterCondition;

  Mode = Mode;
  mode = Mode.TABLE;

  singleModeData: any = null;


  SchoolLevelDataReportProficiencyLevel = SchoolLevelDataReportProficiencyLevel;


  selectedView = 'individual-reports';

  zebraConfig: ZebraConfig = {
    field: 'pen',
  }

  routeSubscription: Subscription;
  authSubscription: Subscription;

  isIrtReady: boolean = false;
  languageRefresh: boolean = false;
  currentlang: string = 'en';

  reportYear:string|number;
  
  constructor(
    private bcAccounts: BcAccountsService,
    private bcAssessments: BcAssessmentsService,
    private lang: LangService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private whitelabel: WhitelabelService
  ) { 
  }



  ngOnInit() {
    this.initSelectedScoreType();
    this.reportYear = this.getReportYear();
    this.pagination = this.bcAccounts.getInitialPagination();

    this.routeSubscription = this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.pen) {
        this.enterSingleMode(queryParams.pen);
      }
    })

    this.authSubscription = this.auth.user().subscribe(userInfo => {
      if (this.auth.isScoreEntrySchoolAdmin(userInfo.accountType) || this.auth.isScoreEntryDistrictAdmin(userInfo.accountType)) {
        this.router.navigate([this.getBaseRoute()]);
      }
    })

    // this.bcAssessments.isIrtReadyForTestWindow(this.testWindow.id).then(irtReady => {
    //   this.isIrtReady = irtReady || false;
    //   // if (this.table) {
    //   //   this.table.updateTable();
    //   // }
    // })
  }

  ngOnDestroy() {
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
    if (this.authSubscription) this.authSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    let needUpdate = false;

    if (changes.schoolDetail || changes.testWindow) {
      this.reportYear = this.getReportYear();
      this.initSelectedScoreType();
      const previousSchoolGid = this.currentSchool?.groupId || 0;
      const previousTestWindowId = this.currentWindow?.id || 0;

      if (changes.schoolDetail) needUpdate = this.schoolDetail?.groupId !== previousSchoolGid;
      if (changes.testWindow) needUpdate = this.testWindow?.id !== previousTestWindowId;
    }

    if (needUpdate) {
      if (this.table) {
        this.table.resetPage();
        this.table.updateTable();
      }
    }
  }

  handleLanguageToggle(lang: string){
    this.languageRefresh = true;
    this.lang.setCurrentLanguage(lang);
    this.currentlang = lang;
    setTimeout(() => {this.languageRefresh = false}, 1000);
    this.updateSingleRow()
  }

  getReportYear() {
    let year = '';
    for(let char of this.testWindow.title.en) {
      if(!isNaN(+char)) year += char;
      else if(year != '') return year;
    }

    return year;
  }

  getBaseRoute() {
    let account = AccountType.SCHOOL_ADMIN;
    if (this.auth.isDistrictAdmin(this.accountType)) {
      account = AccountType.DIST_ADMIN;
    }
    return `/${this.lang.c()}/${account}/bc-fsa/final_results`;
  }

  isFr() {
    return this.lang.c() === 'fr';
  }

  isDownloading = false;
  printReport() {
    this.singleModeData.studentSchoolLang = this.currentlang.toUpperCase();
    this.isDownloading = true;
    this.auth.apiCreate('public/bcg-scan/individual-student-results-report', {
      pages:[this.singleModeData],
      district: this.districtDetail.groupId,
      reportYear: this.reportYear,
      testWindowId: this.testWindow.id,
      isIrtReady: this.testWindow.isIrtReady,
      isRawScore: this.selectedScoreType == ScoreType.RAW_SCORE
    }).then(response => {
      const byteArray = new Uint8Array(atob(response).split('').map(char => char.charCodeAt(0)));
      return new Blob([byteArray], {type: 'application/pdf'});
    })
    .then(blob => {
      saveAs(blob, 'Individual Student Results Report.pdf');
      this.isDownloading = false;
    });
  }

  getRows = async (pagination: Pagination): Promise<PaginatedRows<StudentIrtRow2>> => {
    if (!this.schoolDetail || !this.testWindow || !this.districtDetail || this.isIrtReady === null) {
      return {
        count: 0,
        data: [],
      }
    }

    try {
      const rows = <any> await this.bcAssessments.getStudentPreIrtScore__new(
        pagination,
        AssessmentType.FSA,
        this.testWindow,
        false,
        this.districtDetail.groupId,
        this.schoolDetail ? this.schoolDetail.groupId : undefined,
      );

      this.currentWindow = this.testWindow;
      this.currentSchool = this.schoolDetail;

      // set the default report language to that of the school.
      this.currentlang = this.currentSchool.lang?.toLowerCase() || 'en';
      
      return rows;
    } catch(err) {
      
    }

    // if (this.isIrtReady) {
    //   return this.bcAssessments.getStudentIrtScore__new(
    //     pagination,
    //     AssessmentType.FSA,
    //     this.testWindow,
    //     false,
    //     this.districtDetail.groupId,
    //     this.schoolDetail ? this.schoolDetail.groupId : undefined,
    //     false,
    //   );
    // } else {
    //   console.log('c NICK');
    //   return <any> await this.bcAssessments.getStudentPreIrtScore__new(
    //     pagination,
    //     AssessmentType.FSA,
    //     this.testWindow,
    //     false,
    //     this.districtDetail.groupId,
    //     this.schoolDetail ? this.schoolDetail.groupId : undefined,
    //   );
    // }

  }

  getDisplay = (by: string, row: StudentIrtRow2): string | number => {
    if (by === 'grade') {
      return `Grade ${row.grade}`
    }
    return row[by];
  }

  getTag = (by: string, row: Data): Tag => {
    return null;
  }

  getFilterInitValue = (filter: Filter): string | number => {
    return filter ? filter.value : '';
  }

  onLinkClicked(data: LinkClickEvent<StudentIrtRow2>) {
    if (data.by === 'pen') {
      this.enterSingleMode(data.row.pen);
    }
  }

  async enterSingleMode(pen: string) {
    this.isLoading = true;
    let pens: { pen: string, lang: string, grade: number, }[];

    pens = await (this.bcAssessments.getStudentPreIrtScore__new(
      {
        ...this.bcAccounts.getInitialPagination(),
        skip: 0,
        size: -1,
      },
      AssessmentType.FSA,
      this.testWindow,
      false,
      this.districtDetail.groupId,
      this.schoolDetail.groupId,
    ) as Promise<PaginatedRows<StudentIrtRow2>>).then((rows) => rows.data.map(r => ({
      pen: r.pen,
      grade: +r.grade,
      lang: r.lang,
    })));

    // if (this.isIrtReady) {
    //   pens = await this.bcAssessments.getStudentIrtScore__penOnly(
    //     this.bcAccounts.getInitialPagination(),
    //     AssessmentType.FSA,
    //     this.testWindow,
    //     this.districtDetail.groupId,
    //     this.schoolDetail ? this.schoolDetail.groupId : undefined,
    //   );
    // } else {
    //   console.log('d');
    //   pens = await (this.bcAssessments.getStudentPreIrtScore__new(
    //     {
    //       ...this.bcAccounts.getInitialPagination(),
    //       skip: 0,
    //       size: -1,
    //     },
    //     AssessmentType.FSA,
    //     this.testWindow,
    //     false,
    //     this.districtDetail.groupId,
    //     this.schoolDetail.groupId,
    //   ) as Promise<PaginatedRows<StudentIrtRow2>>).then((rows) => rows.data.map(r => ({
    //     pen: r.pen,
    //     grade: +r.grade,
    //     lang: r.lang,
    //   })));
    // }
    this.singleModeData = {
      literacyScore: null,
      numeracyScore: null,
      singlePagination: null,
      singleModePens: null,
      singleModeName: null,
      singleModeLitAssessmentCode: null,
      singleModeNumAssessmentCode: null,
      pen: null,
      grade: null,
      studentSchool: null,
      studentDistrict: null,
      studentSchoolLang: null,
      isScaled: false,
    }

    this.singleModeData.singleModePens = pens;

    let skip = this.singleModeData.singleModePens.findIndex(p => p.pen === pen);
    this.singleModeData.singlePagination = this.bcAccounts.getInitialPagination();
    this.singleModeData.singlePagination.orderBy = this.pagination.orderBy;
    this.singleModeData.singlePagination.orderDirection = this.pagination.orderDirection;
    this.singleModeData.singlePagination.size = 1;
    this.singleModeData.singlePagination.skip = skip;
    this.singleModeData.singlePagination.count = pens.length;

    this.mode = Mode.SINGLE;
    await this.updateSingleRow();
  }

  async updateSingleRow() {
    this.isLoading = true;

    if (this.singleModeData.singlePagination.skip < 0 || this.singleModeData.singlePagination.skip >= this.singleModeData.singleModePens.length) {
      this.isLoading = false;
      return;
    }

    let penRecord = this.singleModeData.singleModePens[this.singleModeData.singlePagination.skip];

    let language = penRecord.lang == 'EN' ? 'E' : 'F';

    const isScaled = this.selectedScoreType === ScoreType.SCALED_SCORE;
    (this.bcAssessments.getStudentPreIrtScore__new(
      undefined,
      AssessmentType.FSA,
      this.testWindow,
      true,
      this.districtDetail.groupId,
      this.schoolDetail.groupId,
      penRecord.pen,
      isScaled
    ) as Promise<IndividualRawScoresRow[]>).then(async rows => {
      if (rows.length > 0) {
        let row = rows[0];

        this.singleModeData.singleModeName =
        {
          first_name: row.first_name,
          last_name: row.last_name,
        };

        this.singleModeData.studentSchool = this.schoolDetail.name;
        this.singleModeData.studentDistrict = this.districtDetail.foreignId;
        this.singleModeData.studentSchoolLang = this.schoolDetail.lang;

        this.singleModeData.singleModeLitAssessmentCode = `LT${language}${penRecord.grade}`;
        this.singleModeData.singleModeNumAssessmentCode = `NM${language}${penRecord.grade}`;
        this.singleModeData.grade = penRecord.grade;
        this.singleModeData.pen = penRecord.pen;

        this.singleModeData.literacyScore = row.literacyScore;
        this.singleModeData.numeracyScore = row.numeracyScore;
        this.singleModeData.studentSchoolLang = this.currentlang.toUpperCase();
        this.singleModeData.litLevel = isScaled ? row.li_proficiency_level : await this.translateLevel(this.getProficiencyLevel('literacy', this.singleModeData.literacyScore));
        this.singleModeData.numLevel = isScaled ? row.nu_proficiency_level : await this.translateLevel(this.getProficiencyLevel('numeracy', this.singleModeData.numeracyScore));

        this.singleModeData.isScaled = isScaled;
      }

      this.isLoading = false;
    });

    // if (this.isIrtReady) {
    //   const getScore = (
    //     pagination: Pagination,
    //     byAssessment: boolean = false,
    //   ) => {
    //     return this.bcAssessments.getStudentIrtScore__new(
    //       pagination,
    //       AssessmentType.FSA,
    //       this.testWindow,
    //       false,
    //       this.districtDetail.groupId,
    //       this.schoolDetail ? this.schoolDetail.groupId : undefined,
    //     );
    //   }

    //   let namePagination = this.bcAccounts.getInitialPagination();
    //   namePagination.filters = [{
    //     field: 'pen',
    //     condition: FilterCondition.MATCH,
    //     value: penRecord.pen,
    //   }];
    //   let p1 = getScore(namePagination, false).then(({ data: studentData, count: nameCount }) => {
    //     if (nameCount > 0) {
    //       this.singleModeData.singleModeName =
    //       {
    //         first_name: studentData[0].first_name,
    //         last_name: studentData[0].last_name,
    //       };

    //       this.singleModeData.studentSchool = studentData[0].school_name || this.schoolDetail.name;
    //       this.singleModeData.studentDistrict = studentData[0].district_code || this.districtDetail.foreignId;
    //       this.singleModeData.studentSchoolLang = studentData[0].lang || this.schoolDetail.lang;
    //     }

    //     else {
    //       this.singleModeData.singleModeName =
    //       {
    //         first_name: '',
    //         last_name: '',
    //       };

    //       this.singleModeData.studentSchool = '';
    //       this.singleModeData.studentSchoolLang = '';
    //     }
    //   });

    //   this.singleModeData.singleModeLitAssessmentCode = `LT${language}${penRecord.grade}`;
    //   this.singleModeData.grade = penRecord.grade;
    //   this.singleModeData.pen = penRecord.pen;
    //   let literacyPagination = this.bcAccounts.getInitialPagination();
    //   literacyPagination.filters = [{
    //     field: 'assessment_code',
    //     condition: FilterCondition.MATCH,
    //     value: this.singleModeData.singleModeLitAssessmentCode,
    //   }, {
    //     field: 'pen',
    //     condition: FilterCondition.MATCH,
    //     value: penRecord.pen,
    //   }];
    //   let p2 = getScore(literacyPagination, true).then(({ data: litData, count: litCount }) => {
    //     if (litCount > 0) {
    //       this.singleModeData.literacyScore = litData[0].score;
    //     } else {
    //       this.singleModeData.literacyScore = null;
    //     }
    //   });

    //   this.singleModeData.singleModeNumAssessmentCode = `NM${language}${penRecord.grade}`;
    //   let numeracyPagination = this.bcAccounts.getInitialPagination();
    //   numeracyPagination.filters = [{
    //     field: 'assessment_code',
    //     condition: FilterCondition.MATCH,
    //     value: this.singleModeData.singleModeNumAssessmentCode,
    //   }, {
    //     field: 'pen',
    //     condition: FilterCondition.MATCH,
    //     value: penRecord.pen,
    //   }];
    //   let p3 = getScore(numeracyPagination, true).then(({ data: numData, count: numCount }) => {
    //     if (numCount > 0) {
    //       this.singleModeData.numeracyScore = numData[0].score;
    //     } else {
    //       this.singleModeData.numeracyScore = null;
    //     }
    //   });

    //   Promise.all([p1, p2, p3]).then(async () => {
    //     this.isLoading = false;
    //     this.singleModeData.studentSchoolLang = this.currentlang.toUpperCase();
    //     this.singleModeData.litLevel = await this.translateLevel(this.getProficiencyLevel('literacy', this.singleModeData.literacyScore));
    //     this.singleModeData.numLevel = await this.translateLevel(this.getProficiencyLevel('numeracy', this.singleModeData.numeracyScore));
        
    //     console.log(this.singleModeData);
    //   })
    // }

    // else {
    //   const isScaled = this.selectedScoreType === ScoreType.SCALED_SCORE;
    //   (this.bcAssessments.getStudentPreIrtScore__new(
    //     undefined,
    //     AssessmentType.FSA,
    //     this.testWindow,
    //     true,
    //     this.districtDetail.groupId,
    //     this.schoolDetail.groupId,
    //     penRecord.pen,
    //     isScaled
    //   ) as Promise<IndividualRawScoresRow[]>).then(async rows => {
    //     if (rows.length > 0) {
    //       let row = rows[0];

    //       this.singleModeData.singleModeName =
    //       {
    //         first_name: row.first_name,
    //         last_name: row.last_name,
    //       };

    //       this.singleModeData.studentSchool = this.schoolDetail.name;
    //       this.singleModeData.studentDistrict = this.districtDetail.foreignId;
    //       this.singleModeData.studentSchoolLang = this.schoolDetail.lang;

    //       this.singleModeData.singleModeLitAssessmentCode = `LT${language}${penRecord.grade}`;
    //       this.singleModeData.singleModeNumAssessmentCode = `NM${language}${penRecord.grade}`;
    //       this.singleModeData.grade = penRecord.grade;
    //       this.singleModeData.pen = penRecord.pen;

    //       this.singleModeData.literacyScore = row.literacyScore;
    //       this.singleModeData.numeracyScore = row.numeracyScore;
    //       this.singleModeData.studentSchoolLang = this.currentlang.toUpperCase();
    //       this.singleModeData.litLevel = isScaled ? row.li_proficiency_level : await this.translateLevel(this.getProficiencyLevel('literacy', this.singleModeData.literacyScore));
    //       this.singleModeData.numLevel = isScaled ? row.nu_proficiency_level : await this.translateLevel(this.getProficiencyLevel('numeracy', this.singleModeData.numeracyScore));

    //       this.singleModeData.isScaled = isScaled;
    //     }

    //     console.log(this.singleModeData);

    //     this.isLoading = false;
    //   });
    // }


  }

  backToListOfStudents() {
    this.singleModeData = null;
    this.mode = Mode.TABLE;
  }

  onSinglePaginationChange() {
    this.updateSingleRow();
  }

  getProficiencyLevel(type: 'literacy' | 'numeracy', score: number): string {
    if (score === undefined || score === null) {
      return SchoolLevelDataReportProficiencyLevel.PLU;
    }

    let assessmentCode = type == 'literacy' ? this.singleModeData.singleModeLitAssessmentCode : this.singleModeData.singleModeNumAssessmentCode;
    this.isIrtReady = false; // deprecated variable
    let level = this.bcAccounts.calculateLevelFromIrtScore(assessmentCode, score, this.isIrtReady);
    return level;
  }

  translateLevel(level) {
    if (level == 'EM') {
      return this.lang.tra('sa_sr_emerging');
    } else if (level == 'OT') {
      return this.lang.tra('sa_sr_ontrack');
    } else if (level == 'EX') {
      return this.lang.tra('sa_sr_extend');
    } else {
      return this.lang.tra('sa_sr_plu');
    }
  }

  getStudentGrade() {

  }

  setClasses(level, subject) {
    let myClasses = {
      active: level === subject,
      inactive: level !== subject
    }
    return myClasses;
  }

  getCountDisplay = (page: number, total: number): string => {
    return `Record ${page} of ${total}`;
  }

  export(e) {
    this.bcAssessments.exportStudentIrtScore__new(
      AssessmentType.FSA,
      this.testWindow,
      this.districtDetail.groupId,
      this.schoolDetail ? this.schoolDetail.groupId : undefined,
    )
  }

  filterUpdater = (by: string, value: string | number, filters: Map<string, Filter>) => {
    if (by === 'grade') {
      if (value === 'Grade 4') {
        filters.set('grade', {
          field: 'grade',
          condition: FilterCondition.MATCH,
          value: 4,
        });
      }
      if (value === 'Grade 7') {
        filters.set('grade', {
          field: 'grade',
          condition: FilterCondition.MATCH,
          value: 7,
        });
      }
    }
  }

  getAnyValue = (by: string): string | null => {
    if (by === 'grade') {
      return 'All Grades';
    }
    return null;
  }

  isScoreEntryAdmin(): boolean {
    return this.auth.isScoreEntrySchoolAdmin() || this.auth.isScoreEntryDistrictAdmin();
  }

  public isFsaMinistryAdmin(): boolean {
    return this.auth.isFsaMinistryAdmin(this.accountType);
  }

  private isFsaSchoolAdmin(): boolean {
    return this.auth.isFsaSchoolAdmin(this.accountType);
  }

  private isFsaDistrictAdmin(): boolean { 
    return this.auth.isFsaDistrictAdmin(this.accountType);
  }

  public isFsaAdmin() {
    return this.isFsaMinistryAdmin() || this.isFsaSchoolAdmin() || this.isFsaDistrictAdmin();
  }

  async selectScoreType(type: ScoreType) {
    this.selectedScoreType = type;

    await this.updateSingleRow()
  }

  public isIrtReadyOnCurrTestWindow(): boolean {
    return this.testWindow?.isIrtReady === true;
  }

  public isRawScoreAvailable(): boolean {
    if (this.isFsaMinistryAdmin()) return true;

    return !this.isIrtReadyOnCurrTestWindow();
  }

  public isScaledScoreAvailable(): boolean {
    if (this.isFsaMinistryAdmin()) return true;

    return this.isIrtReadyOnCurrTestWindow();
  }

  private initSelectedScoreType(): void {
    if (this.isScaledScoreAvailable()) {
      this.selectedScoreType = ScoreType.SCALED_SCORE;
    } else {
      this.selectedScoreType = ScoreType.RAW_SCORE;
    }
  }
}