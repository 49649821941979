
<ng-container *ngIf="isVisible()">
  <div style="padding:0.5em; background-color:#ccc;">
    <div style="padding:0.5em; border: 1px solid #ccc;">
      <element-render *ngFor="let contentElement of element.content"
        [contentElement]="contentElement" 
        [questionState]="questionState" 
        [isLocked]="isLocked"
        [questionPubSub]="questionPubSub"
      ></element-render>
    </div>
  </div>
  <div 
    *ngIf="getResponseEntry()" 
    style="padding:1em; line-height:1.3em"
    [ngSwitch]="isResponseUpload()"
  >
    <div *ngSwitchCase="true">
      <div [ngSwitch]="!!getResponseEntry().url">
        <img *ngSwitchCase="true" [src]="getResponseUrl()">
        <span *ngSwitchCase="false">(no upload)</span>
      </div>
    </div>
    <div *ngSwitchCase="false">
      <markdown 
        class="markdown" 
        [data]="getResponseText()" 
        [class.is-condensed]="true"
        ngPreserveWhitespaces
      ></markdown>
    </div>
  </div>
</ng-container>