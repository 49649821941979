import { Injectable } from "@angular/core";
import * as PIXI from 'pixi.js-legacy';

@Injectable({
    providedIn: 'root'
})
export class PIXITextService {
    public textTextures: PIXI.Text[];

    constructor() {
        this.textTextures = [];
    }
}
