<div class="score-entry-documents button-control grade-links-container" [class.large]="large">
    <div style="font-weight: bold;">
        <tra slug="sa_se_documents"></tra>
    </div>

    <div class="grade-links" *ngFor="let group of documentGroups">
        <ng-container *ngFor="let doc of group">
            <ng-container *ngIf="doc.enabled">
                <a [href]="doc.document_link">
                    {{doc.document_title}}
                </a>
            </ng-container>
            <ng-container *ngIf="!doc.enabled">
                <a class="disabled-link">
                    {{doc.document_title}}
                </a>
            </ng-container>
        </ng-container>
    </div>

</div>