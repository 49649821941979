import { Component, Input, OnInit, Output, EventEmitter, SimpleChange } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription, SubscriptionLike } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { ALL_SCHOOLS, BcAccountsService, District, DistrictDetail, School, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { MyBoardService } from 'src/app/ui-dist-admin/my-board.service';
import { MySchoolService } from 'src/app/ui-schooladmin/my-school.service';

export interface ButtonConfig {
  title?: string,
  tra?: string,
  style?: { [key: string]: string },
  buttonId: string,
}

@Component({
  selector: 'bc-header-layout',
  templateUrl: './bc-header-layout.component.html',
  styleUrls: ['./bc-header-layout.component.scss']
})
export class BcHeaderLayoutComponent implements OnInit {


  @Input() title: string;
  @Input() tra: string;
  @Input() imgSrc: string;
  @Input() accountType: AccountType = AccountType.BC_FSA_SCHOOL_ADMIN;
  @Input() phantom: boolean = false;
  @Input() disableSchool: boolean = false;
  @Input() disableDistrict: boolean = false;
  @Input() allowQA: boolean = false;
  @Input() is_force_show_asmt_materials: boolean = false;
  @Input() readQueryParams: boolean = true;
  @Input() initSchoolGroupId: number | string;
  @Input() isDataLoading: boolean = false;
  @Input() isDefaultTestWindowStarted: boolean = false;
  @Input() showUpcommingTestWindows: boolean = true;
  @Input() showPastTestWindows: boolean = true;
  @Input() showSchoolSelector: boolean = true;
  @Input() onlyShowLatestPastTestWindow: boolean = false;
  @Input() useMonitoringEndDates: boolean = false;

  @Input() buttons: ButtonConfig[] = [];
  @Input() showInactiveWindows: boolean = false;

  @Output() school = new EventEmitter();
  @Output() district = new EventEmitter();
  @Output() testWindow = new EventEmitter();
  @Output() button = new EventEmitter();
  @Output() getSchools = new EventEmitter();

  schools: School[] = [];
  selectedSchool: School;

  districts: any[];
  selectedDistrict: District;

  testWindows: TestWindow[];
  selectedTestWindow: TestWindow;

  isLoading: boolean = false;

  mySchoolSubscription: Subscription;
  myBoardSubscription: Subscription;

  disableAssessmentSessions = false; // deprecated
  disableSchoolSelections = false; // deprecated

  routeSubcription: Subscription;

  schoolDetail: SchoolDetail;
  districtDetail: DistrictDetail;

  isInputDebounced: boolean = true;
  isInitialized: boolean = false;

  constructor(
    private auth: AuthService,
    private bcAccounts: BcAccountsService,
    private bcAssessments: BcAssessmentsService,
    private mySchool: MySchoolService,
    private myBoard: MyBoardService,
    private lang: LangService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.auth.user().subscribe(userInfo => { 
      if(userInfo){
        this.accountType = userInfo.accountType;
        this.loadDistrictAndSchools();
      }
    })
    
    this.route.queryParams.subscribe(async queryParams => {
      let testWindows;
      if(this.bcAccounts.getIsSACurrentlyOnGrad()) testWindows = await this.bcAssessments.findAllTestWindows(AssessmentType.GRAD, false, false, true, true);
      else if (this.useMonitoringEndDates) testWindows = await this.bcAssessments.findAllTestWindows(AssessmentType.FSA, false, false, false, false, false, this.allowQAWindows(), false, true);
      else testWindows = await this.bcAssessments.findAllTestWindows(AssessmentType.FSA, false, false, false, false, false, this.allowQAWindows());
      
      if (!this.showUpcommingTestWindows) {
        testWindows = testWindows.filter(tw => tw.dateStart <= new Date());
      }

      if (!this.showPastTestWindows) {
        testWindows = testWindows.filter(tw => tw.dateEnd >= new Date());
      }

      if (this.showPastTestWindows && this.onlyShowLatestPastTestWindow) {
        const pastTestWindows = [];
        const others = [];
        let twDateFieldKey = this.useMonitoringEndDates ? 'monitoringSessionEndDate' : 'dateEnd';
          for (const tw of testWindows) {
            if (tw[twDateFieldKey] < new Date()) {
              pastTestWindows.push(tw);
            }
            else {
              others.push(tw);
            }
          }
          if (pastTestWindows.length) {
            testWindows = [...others, (pastTestWindows.sort((a, b) => b[twDateFieldKey] - a[twDateFieldKey]))[0]];
          } else {
            testWindows = others;
          }
      }

      let activeTestWindow;
      if (queryParams.testWindow) {
        activeTestWindow = testWindows.find(tw => tw.id == queryParams.testWindow);
      }
      if (!activeTestWindow || !queryParams.testWindow) {
        activeTestWindow = testWindows.find(tw => tw.is_active);
      }

      // select the latest started test window as default
      if (this.isDefaultTestWindowStarted && !this.isInitialized) {
        const filteredAndSortedTestWindows = testWindows
          .filter(tw => tw.dateStart <= new Date())
          .sort((a, b) => b.dateStart - a.dateStart)

        activeTestWindow = filteredAndSortedTestWindows[0];
      }

      this.selectedTestWindow = activeTestWindow;

      if (this.showInactiveWindows) {
        if (!this.selectedTestWindow) {
          this.selectedTestWindow = testWindows[testWindows.length - 1];
        }
      }
      
      this.testWindows = testWindows;
      
      this.onSelectedTestWindowChange();
    })

    this.isInitialized = true;
  }


  allowQAWindows(){
    return this.allowQA && !this.isMinistryAdmin();
  }


  isSchoolAdmin(): boolean {
    return this.auth.isSchoolAdmin(this.accountType);
  } 
  isDistrictAdmin(): boolean {
    return this.auth.isDistrictAdmin(this.accountType);
  }

  isGradSchoolAdmin(): boolean {
    return this.auth.isGradSchoolAdmin(this.accountType);
  }

  public getHideDropdownFlag(): boolean {
    return this.bcAccounts.getHideDropdownForGradSchoolAdminView();
  }

  ngOnChanges(change: SimpleChange) {
  }

  ngOnDestroy() {
    if (this.mySchoolSubscription) this.mySchoolSubscription.unsubscribe();
    if (this.myBoardSubscription) this.myBoardSubscription.unsubscribe();
    if (this.routeSubcription) this.routeSubcription.unsubscribe();
  }

  async loadDistrictAndSchools(): Promise<void> {
    // Todo: once api is configured, ask for this admin's accessible schools
    // temporarily assume this school admin has access to all schools in the district.
    const schools = await this.bcAccounts.findSchools(undefined, AssessmentType.FSA, false, true);
    if (this.isSchoolAdmin()) {
      this.mySchool.subSchools().subscribe(schools => {
        if (schools.length > 0) 
        {
          this.schools = this.filterSchoolsForAssessmentType(schools);
          this.schools = this.removeDuplicatesFromSchools(this.schools);
          this.sortSchoolsByNameAsc();

          const { school } = this.route.snapshot.queryParams;
          let selectedSchool = this.schools.find(s => s.groupId === parseInt(school));

          if (!selectedSchool) {
            selectedSchool = this.schools[0];
          }

          this.selectedSchool = selectedSchool;
          this.onSelectedSchoolChange(this.selectedSchool);
        }
      })
    }

    else if (this.isDistrictAdmin()) {
      if (this.myBoardSubscription) this.myBoardSubscription.unsubscribe();
      this.myBoardSubscription = this.myBoard.sub().subscribe(groupList => {
        if (!groupList || groupList.length === 0) return;

        const dist_group_id = groupList[0].group_id;

        this.bcAccounts.getDistrictDetail(dist_group_id).then(districtDetail => {
          this.districtDetail = districtDetail;
          // console.log('emiting district 6');
          this.onDistrictDetailReady();
        });

        this.bcAccounts.findSchools(dist_group_id, AssessmentType.FSA, false, true).then(schools => {
          this.schools = schools;
          if (this.getSchools) this.getSchools.emit(schools);
          this.schools = this.removeDuplicatesFromSchools(this.schools);
          this.sortSchoolsByNameAsc();

          const { school } = this.route.snapshot.queryParams;
          let selectedSchool = this.schools.find(s => s.groupId === parseInt(school));

          if (!selectedSchool) {
            selectedSchool = this.schools[0];
          }

          this.selectedSchool = selectedSchool;
          this.onSelectedSchoolChange(this.selectedSchool);
          // this.coerceSchoolFromQueryParams();
        })
      })
    }

    else {
      const districts = await this.bcAccounts.findDistricts(undefined, false);
      this.districts = districts;
      this.coerceDistrictFromQueryParams();

      if (this.selectedDistrict)
      {
        this.bcAccounts.findSchools(this.selectedDistrict.groupId, AssessmentType.FSA, false, true).then(schools => 
        {
          this.schools = schools;
          this.schools = this.removeDuplicatesFromSchools(this.schools);
          this.sortSchoolsByNameAsc();
          this.coerceSchoolFromQueryParams();

          if (this.initSchoolGroupId) {
            const selectedSchool = this.schools.find(s => s.groupId == this.initSchoolGroupId);
            this.onSelectedSchoolChange(selectedSchool);
          }
        });
      }
    }
  }

  private filterSchoolsForAssessmentType(schools: School[]): School[]
  {
    // only used for school admins
    // console.log("beforeFilter", schools);

    if (this.bcAccounts.getIsSACurrentlyOnGrad() == null)
    {
      return schools;
    }

    if (this.bcAccounts.getIsSACurrentlyOnGrad())
    {
      return schools.filter((school) => 
      {
        return school.isGrad;
      });
    }

    else 
    {
      return schools.filter((school) => 
      {
        return school.isFSA;
      })
    }  
  }

  private removeDuplicatesFromSchools(schools: School[]): School[]
  {
    const uniqueSchoolGroupIds = new Set(schools.map(school => school.groupId));
    const uniqueSchools: School[] = [];

    uniqueSchoolGroupIds.forEach((groupId) => 
    {
      uniqueSchools.push(schools.find((school) => 
      {
        return school.groupId === groupId;
      }));
    });

    return uniqueSchools;
  }

  isMinistryAdmin()
  {
    return this.auth.isMinistryAdmin();
  }

  private coerceDistrictFromQueryParams() {
    if (this.readQueryParams) {
      this.routeSubcription = this.route.queryParams.subscribe(params => 
      {
        let district = params.district;

        if (district) 
        {
          // console.log(+district, this.selectedDistrict);
          if (!this.selectedDistrict || +district != this.selectedDistrict.groupId) 
          {
            let match = this.districts.find(s => s.groupId === +district);
            if (match) {
              this.selectedDistrict = match;
            } else if (this.auth.isGradAdmin()) {
              this.selectedDistrict = null;
            } else {
              this.selectedDistrict = this.districts[0];
            }
            // console.log('emiting district 5');
            this.district.emit(this.selectedDistrict);
          }
        } 
        
        else 
        {
          if (this.auth.isGradAdmin()) 
          {
            this.selectedDistrict = null;
          }

          else 
          {
            this.selectedDistrict = this.districts[0];
          }
          
            // console.log('emiting district 1');
            this.district.emit(this.selectedDistrict);
        }
      })
    } else {
      this.selectedDistrict = this.districts[0];
      // console.log('emiting district 2');
      this.district.emit(this.selectedDistrict);
    }

  }
  
  private coerceSchoolFromQueryParams() {
    if (this.readQueryParams) {
      this.routeSubcription = this.route.queryParams.subscribe(params => {
        let school = params.school;
        if (school) {
          // console.log(+school, this.selectedSchool);
          if (!this.selectedSchool || +school != this.selectedSchool.groupId) {
            let match = this.schools.find(s => s.groupId === +school);
            if (match) {
              this.selectedSchool = match;
            } else if (this.auth.isGradSchoolAdmin()) {
              this.selectedSchool = null;
            } else {
              this.selectedSchool = this.schools[0];
            }
            // console.log('emiting school 1');
            this.school.emit(this.selectedSchool);
          }
        } else {
          const oldSelectedSchool = this.selectedSchool;
          if (this.auth.isGradSchoolAdmin()) {
            this.selectedSchool = null;
          } else {
            this.selectedSchool = this.schools[0];
          }
          
          // console.log('emiting school 2');
          this.school.emit(this.selectedSchool);
        }
      });

    } else {
      this.selectedSchool = this.schools[0];
      // console.log('emiting school 3');
      this.school.emit(this.selectedSchool);
    }

  }

  onDistrictDetailReady() 
  {
    // console.log('emiting district 3');
    this.district.emit(this.districtDetail);
  }

  onSelectedSchoolChange(school: School) 
  {
    this.updateQueryParams(school);

    // console.log(school);
    if (school.groupId > 0)
    {
      this.bcAccounts.getSchoolDetail(school.groupId).then((schoolDetail: SchoolDetail) => 
      {
        this.schoolDetail = schoolDetail;
        // console.log(schoolDetail);
        const dist_group_id = schoolDetail.district.groupId;
  
        if(!this.isMinistryAdmin() && !this.isDistrictAdmin())
        {
          this.bcAccounts.getDistrictDetail(dist_group_id).then(districtDetail => 
          {
            this.districtDetail = districtDetail;
            this.onDistrictDetailReady();
            this.school.emit(school); 
          });
        }
      })
    }

    else 
    {
      this.schoolDetail = null; 
    }

    // console.log('emiting school 4');
    if(!this.isSchoolAdmin()) this.school.emit(school); 
  }

  onSelectedDistrictChange(district: District) 
  {
    this.updateDistrictQueryParams(district);

    // console.log(district);
    // if(district) this.district.emit(district); 

    if (district.groupId > 0)
    {
      this.bcAccounts.getDistrictDetail(district.groupId).then(districtDetail => 
      {  
        this.districtDetail = districtDetail;
        this.onDistrictDetailReady();
      });
    }

    else
    {
      this.districtDetail = null;
      this.onDistrictDetailReady();
    }

    this.bcAccounts.findSchools(district.groupId, AssessmentType.FSA, false, true).then(schools => {
      this.schools = schools;
      this.schools = this.removeDuplicatesFromSchools(this.schools);
      this.schoolDetail = null;
      this.selectedSchool = this.schools[0];
      this.sortSchoolsByNameAsc();
      this.coerceSchoolFromQueryParams();
      this.onSelectedSchoolChange(this.selectedSchool);
    });
  }

  onSelectedTestWindowChange() 
  {
    // if(this.bcAccounts.getIsSACurrentlyOnGrad()) return;
    this.updateTestWindowQueryParams(this.selectedTestWindow?.id);
    this.testWindow.emit(this.selectedTestWindow);
  }

  getSchoolDisplay = (school: School): string => {
    if (!school) return '';
    return this.lang.tra(this.bcAccounts.getSchoolDisplay(school));
  }

  getDistrictDisplay = (district: District): string => {
    if (!district) return '';
    return this.lang.tra(this.bcAccounts.getDistrictDisplay(district)); 
  }

  getTestWindowDisplay(testWindow: TestWindow): string {
    return this.bcAssessments.getTestWindowTitle(testWindow);
  }

  getTitle(): string {
    if (this.tra) {
      return this.lang.tra(this.tra);
    }
    return this.title;
  }

  extraButtonClicked(id: string) {
    this.button.emit(id);
  }

  getExtraButtonTitle(config: ButtonConfig): string {
    if (config.tra) {
      return this.lang.tra(config.tra);
    }
    return config.title || '';
  }

  forceSchoolSelection(school_group_id: number) {
    let s = this.schools.find(s => s.groupId === school_group_id);
    this.onSelectedSchoolChange(s);
  }

  updateTestWindowQueryParams(tw) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: this.getQueryParamsWithTestWindow(tw),
        queryParamsHandling: 'merge',
        // skipLocationChange: true,
      });
  }

  updateDistrictQueryParams(district: District) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: this.getQueryParamsWithDistrict(district),
        queryParamsHandling: 'merge',
        // skipLocationChange: true,
      });
  }

  updateQueryParams(school: School) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: this.getQueryParamsWithSchool(school),
        queryParamsHandling: 'merge',
        // skipLocationChange: true,
      });
  }

  getQueryParamsWithDistrict(district: District): Params {
    let params: Params = {
      ...this.route.snapshot.queryParams,
    };
    if (district && district.groupId > 0) {
      params.district = district.groupId;
    } else {
      params.district = undefined;
    }
    return params;
  }

  getQueryParamsWithTestWindow(testWindow): Params {
    let params: Params = {
      ...this.route.snapshot.queryParams,
    };
    if (testWindow) {
      params.testWindow = testWindow;
    }
    return params;
  }

  getQueryParamsWithSchool(school: School): Params {
    let params: Params = {
      ...this.route.snapshot.queryParams,
    };
    if (school && school.groupId > 0) {
      params.school = school.groupId;
    } else {
      params.school = undefined;
    }
    return params;
  }

  getQueryParams(preserve: boolean = true): Params {
    let params: Params = {};
    if (preserve) {
      params = {
        ...this.route.snapshot.queryParams,
      };
    }
    if (this.selectedSchool && this.selectedSchool.groupId > 0) {
      params.school = this.selectedSchool.groupId;
    }else{
      params.school = null;
    }
    if(this.selectedDistrict && this.selectedDistrict.groupId > 0) {
      params.district = this.selectedDistrict.groupId;
    }else{
      params.district = null;
    }
    return params;
  }

  getVisibleSchools(): School[] 
  {
    return this.schools;
  }

  private sortSchoolsByNameAsc() {
    const sortFunc = (a, b) => a.name < b.name ? -1 : 1;
    let allSchoolsIndex = this.schools.findIndex(s => s.groupId == -1);
    if (allSchoolsIndex >= 0) {
      const withoutAllSchools = this.schools.slice(0, allSchoolsIndex).concat(this.schools.slice(allSchoolsIndex + 1));
      withoutAllSchools.sort(sortFunc);
      withoutAllSchools.unshift(ALL_SCHOOLS);
      this.schools = withoutAllSchools;
      this.schools = this.removeDuplicatesFromSchools(this.schools);
    } else {
      this.schools.sort(sortFunc);
    }

  }

  public getFilterSchoolsFn() {
    const self = this;
    return (value: string | School): School[] => {
      let filtered: School[] = [];
      if (self.schools) {
        let filterValue: string = '';
        if (value) {
          if ((value as School).name) {
            filterValue = (value as School).name.toLowerCase()
          } else {
            filterValue = (value as string).toLowerCase();
          }
        }
        filtered = self.schools.filter(school => school.name.toLowerCase().includes(filterValue) || this.bcAccounts.formatSchoolCode(school.foreignId).includes(filterValue));
      }
      return filtered;
    }
  }

  public getFilterDistrictsFn() {
    const self = this;
    return (value: string | District): District[] => {
      let filtered: District[] = [];
      if (self.districts) {
        let filterValue: string = '';
        if (value) {
          if ((value as District).name) {
            filterValue = (value as District).name.toLowerCase()
          } else {
            filterValue = (value as string).toLowerCase();
          }
        }
        filtered = self.districts.filter(district => district.name.toLowerCase().includes(filterValue) || this.bcAccounts.formatDistrictCode(district.foreignId).includes(filterValue));
      }
      return filtered;
    }
  }

  public districtSelected = (event) => {
    const district: District = event.option.value;
    this.onSelectedDistrictChange(district);
  }

  public schoolSelected = (event) => {
    const school: School = event.option.value;
    this.onSelectedSchoolChange(school);
  }
}
