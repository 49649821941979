import { Injectable } from '@angular/core';

export class VoiceSound { 
  constructor( private el:HTMLMediaElement, private audioBuffer:AudioBufferService){
    el.addEventListener('play', this.addToBuffer);
    el.addEventListener('pause', this.removeFromBuffer)
    el.addEventListener('ended', this.removeFromBuffer);
  }
  destroy(){
    if (this.el){
      this.el.pause()
    }
    this.el.removeEventListener('play', this.addToBuffer);
    this.el.removeEventListener('pause', this.removeFromBuffer);
    this.el.removeEventListener('ended', this.removeFromBuffer);
    this.removeFromBuffer()
  }
  getElement = () =>  this.el;
  addToBuffer = () => { this.audioBuffer.add(this); }
  removeFromBuffer = () => { this.audioBuffer.remove(this); }
  play = () => { 
    if (this.audioBuffer.activeSound){
      this.audioBuffer.activeSound.stop();
    }
    this.el.play(); 
    // console.log('activeSound', this.audioBuffer.activeSound) 
  }
  stop = () => {
    try {
      this.el.pause();
      this.el.currentTime = 0; // start it from beginning next time
    }
    catch(e){
      console.warn('Cannot stop audio', e);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AudioBufferService {

  constructor() { }
  activeSound:VoiceSound;

  add(sound:VoiceSound){
    if (this.activeSound && (sound !== this.activeSound)){
      this.activeSound.stop();  
    }
    this.activeSound = sound;
  }

  remove(sound:VoiceSound){
    if (sound === this.activeSound){
      this.activeSound = null;
    }
  }

}
