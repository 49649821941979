import { Injectable } from '@angular/core';
import { AuthService, getFrontendDomain } from './auth.service';

// local short hand used for defining valid api calls routes
const GET = 'get';
const POST = 'post';
const FIND = 'find';
const PATCH = 'patch';
const REMOVE = 'remove';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  constructor(
    private auth: AuthService,
  ) { }

  currentHost() {
    return getFrontendDomain() + '#';
    // return 'https://mathproficiencytest.ca/#'
  }

  ANALYTICS_EVENT                                      = 'public/analytics/event';
  ANALYTICS_SESSION                                    = 'public/analytics/session';
  ANON_SAMPLE_TEST_DESIGN_FORM                         = 'public/anon/sample-test-design-form';
  ASSESSMENT_KEY                                       = 'public/assessment-key';
  AUTH_CONFIRM_EMAIL                                   = 'public/auth/confirm-email';
  AUTH_RESET_PASSWORD                                  = 'public/auth/reset-password';
  AUTH_RESET_PASSWORD_REQUEST                          = 'public/auth/reset-password-request';
  AUTH_TEST_ADMIN                                      = 'public/auth/test-admin';
  AUTH_TEST_CERT                                       = 'public/auth/test-cert';
  AUTH_TEST_CTRL                                       = 'public/auth/test-ctrl';
  AUTH_TEST_TAKER                                      = 'public/auth/test-taker';
  BC_ADMIN_ASSESSMENT_MATERIALS                        = 'public/bc-admin-coordinator/test-window-materials';
  BC_ADMIN_CR_MARKS                                    = 'public/bc-admin-coordinator/constructed-response-final-scores';
  BC_ADMIN_FINAL_REPORTS                               = 'public/bc-admin-coordinator/final-reports';
  BC_ADMIN_ITEM_DATA_REPORT                            = 'public/bc-admin-coordinator/item-data-report';
  BC_ADMIN_ORAL_RESP_TRACKER                           = 'public/bc-admin-coordinator/oral-resp-tracker';
  BC_ADMIN_PROV_RESP_TRACKER                           = 'public/bc-admin-coordinator/fsa-prov-resp-tracker';
  BC_ADMIN_SCHOOL_REPORT                               = 'public/bc-admin-coordinator/login-summary';
  BC_ADMIN_SCHOOLS_MULTI                               = 'public/bc-admin-coordinator/schools-multi';
  BC_ADMIN_STUDENT_LOGIN_REPORT                        = 'public/bc-admin-coordinator/login-list';
  BC_ADMIN_TEST_WINDOW_FILE                            = 'public/test-window-file';
  BC_FSA_SCORE_ENTRY                                   = 'public/bc-fsa/score-entry';
  BC_FSA_SCORE_ENTRY_STATUS                            = 'public/bc-fsa/score-entry/status';
  BC_FSA_STUDENTS_DELETION                             = 'public/bc-fsa/students/deletion';
  BC_FSA_TWTAR                                         = 'public/bc-fsa/test-window-td-alloc-rules';
  BC_MINISTRY_ADMIN_PDF_PREVIEWS_FSA                   = "public/bc-admin-coordinator/pdf-previews";
  BC_STUDENT_ATTEMPTS                                  = 'public/test-ctrl/schools/student-attempts'
  BC_TWTAR                                             = 'public/support/test-window-td-alloc-rules';
  BCED_AUTO_CLOSE                                      = 'public/bced/test-attempts/auto-close';
  BCED_AUTO_INVALIDATION                               = 'public/auto-invalidation';
  BCED_DATA_VALIDATION                                 = 'public/bced/data-validation';
  BCED_FSA_STUDENTS                                    = 'public/bced/fsa/students';
  BCED_GRAD_SCAN_UPLOADS                               = 'public/bced/grad/scan-uploads';
  BCED_RESPONSE_IMPUTATION                             = 'public/response-imputation';
  BCED_STUDENT                                         = 'public/students';
  BCED_STUDENT_REGISTRATION                            = 'public/registrations';
  BCED_STUDENTS                                        = 'public/bced/students';
  BCED_SUPPORT_USER_ROLES_V2                           = 'public/v2/support/user-roles';
  BCED_SUPPORT_USERS                                   = 'public/support/users';
  BCED_PROFICIENCY_LEVEL                               = 'public/proficiency-level-reports';
  BCED_SUPPORT_DATA_VALIDATION                         = 'public/support/data-validation-items';
  DIST_ADMIN_BCGRAD_STUDENTS                           = 'public/dist-admin/bcgrad-students/students';
  DIST_ADMIN_CHECKLIST                                 = 'public/dist-admin/tech-readi/checklist';
  DIST_ADMIN_CONTACT                                   = 'public/dist-admin/contact';
  DIST_ADMIN_DIST                                      = 'public/dist-admin/district';
  DIST_ADMIN_LOCK_DOWN_INFO                            = 'public/dist-admin/tech-readi/lock-down-info';
  DIST_ADMIN_PEN_LOOKUP                                = 'public/dist-admin/pen-lookup';
  DIST_ADMIN_ROLES                                     = 'public/dist-admin/roles';
  DIST_ADMIN_SCORE_ENTRY                               = 'public/dist-admin/score-entry';
  DIST_ADMIN_SCORE_ENTRY_NOT_YET_SCORED                = 'public/dist-admin/score-entry/not-yet-scored';
  DIST_ADMIN_SESSIONS                                  = 'public/dist-admin/sessions';
  DIST_ADMIN_SUMMARY                                   = 'public/dist-admin/summary';
  DISTRIBUTION_OF_ASSESSMENT_RESULTS_REPORT            = 'public/bc-admin-coordinator/DoAR-Report';
  EDUCATOR_SCHOOL                                      = 'public/educator/school';
  EDUCATOR_SESSION                                     = 'public/educator/session';
  EDUCATOR_SESSION_SUB                                 = 'public/educator/session-sub';
  EDUCATOR_SOFT_LOCK                                   = 'public/educator/soft-lock';
  EDUCATOR_STUDENTS                                    = 'public/educator/students';
  FIELD_TESTER_SESSION                                 = 'public/field-tester/session'
  FIELD_TESTER_SESSION_QUESTION                        = 'public/field-tester/session-question';
  FSA_MINISTRY_ADMIN_DUPLICATE_ATTEMPTS_ANALYSIS       = 'public/bced/session-reports/duplicated-attempts-report';
  LANDING_APPLICANT_REGISTRATION                       = 'public/landing/applicant-registration';
  LANDING_BCED_SECURE_LOGIN                            = 'public/landing/bced-secure-login';
  LOG                                                  = 'public/log';
  MINISTRY_ADMIN_ACCOUNTS                              = 'public/bc-admin-coordinator/accounts';
  MINISTRY_ADMIN_ACTIVITY_LOGS                         = 'public/bc-admin-coordinator/activity-logs';
  MINISTRY_ADMIN_ALLOC_RULES                           = 'public/bc-admin-coordinator/alloc-rules';
  MINISTRY_ADMIN_ASSESSMENT_COMPONENTS                 = 'public/bc-admin-coordinator/assessment-component';
  MINISTRY_ADMIN_ASSESSMENT_KEYS                       = 'public/bc-admin-coordinator/assessment-key';
  MINISTRY_ADMIN_BCG_STUDENTS_REGISTER                 = 'public/bc-admin-coordinator/bcgrad-students/register';
  MINISTRY_ADMIN_BCGRAD_SR_RESULT                      = 'public/bc-admin-coordinator/sr-results';
  MINISTRY_ADMIN_BCGRAD_STUDENTS                       = 'public/bc-admin-coordinator/bcgrad-students/students';
  MINISTRY_ADMIN_BCGRAD_STUDENTS_ASSESSMENTS           = 'public/bc-admin-coordinator/bcgrad-students/assessment';
  MINISTRY_ADMIN_BCGRAD_STUDENTS_REQUESTS              = 'public/bc-admin-coordinator/bcgrad-students/requests';
  MINISTRY_ADMIN_BCGRAD_STUDENTS_TEST_SESSIONS         = 'public/bc-admin-coordinator/bcgrad-students/test-sessions';
  MINISTRY_ADMIN_CONFIRMATION_CODES                    = 'public/bc-admin-coordinator/confirmation-codes';
  MINISTRY_ADMIN_CUT_SCORES                            = 'public/bc-admin-coordinator/cut-scores';
  MINISTRY_ADMIN_DISTRICTS                             = 'public/bc-admin-coordinator/districts';
  MINISTRY_ADMIN_EDFMC2                                = 'public/bc-admin-coordinator/edfmc-2';
  MINISTRY_ADMIN_EXEMPTION                             = 'public/bc-admin-coordinator/exemption';
  MINISTRY_ADMIN_FSA_ANALYSIS_REPORT                   = 'public/bced/session-reports/analysis-code-report';
  MINISTRY_ADMIN_GRAD_INDIVIDUAL_STUDENT_REPORTS_CR    = 'public/cron/constructed-response-final-scores';
  MINISTRY_ADMIN_GRAD_INDIVIDUAL_STUDENT_REPORTS_SR    = 'public/bc-admin-coordinator/grad-individual-student-report';
  MINISTRY_ADMIN_IRT_READY                             = 'public/bc-admin-coordinator/irt-ready';
  MINISTRY_ADMIN_IRT_SCORE                             = 'public/bc-admin-coordinator/irt-score';
  MINISTRY_ADMIN_PRE_IRT_SCORE                         = 'public/bc-admin-coordinator/pre-irt-score';
  MINISTRY_ADMIN_PRINT_ORDERS                          = 'public/bc-admin-coordinator/print-orders';
  MINISTRY_ADMIN_PRINT_PACKAGES                        = 'public/bc-admin-coordinator/print-packages';
  FSA_PRINT_PACKAGES                                   = 'public/fsa/print-packages';
  MINISTRY_ADMIN_REGISTRATION                          = 'public/bc-admin-coordinator/registration';
  MINISTRY_ADMIN_REPORTS                               = 'public/bc-admin-coordinator/reports';
  MINISTRY_ADMIN_INDIVIDUAL_STUDENT_REPORTS            = 'public/ministry-admin/grad/individual-student-reports';
  MINISTRY_ADMIN_REVIEW_REQUEST                        = 'public/ministry-admin/grad/review-requests';
  MINISTRY_ADMIN_SCHOOL_PARTICIPATION                  = 'public/bc-admin-coordinator/school-participation';
  MINISTRY_ADMIN_SCHOOLS                               = 'public/bc-admin-coordinator/schools';
  MINISTRY_ADMIN_SCORE_ENTRY_LOGS                      = 'public/bc-admin-coordinator/score-entry-logs';
  MINISTRY_ADMIN_SESSION_PASSWORDS                     = 'public/bc-admin-coordinator/session-passwords';
  MINISTRY_ADMIN_SIMULATIONS                           = 'public/bc-admin-coordinator/simulation';
  MINISTRY_ADMIN_SPECIAL_MATERIAL_REQUESTS             = 'public/bc-admin-coordinator/special-material-requests';
  MINISTRY_ADMIN_STUDENT_ISR                           = 'public/bc-admin-coordinator/individual-student-report';
  MINISTRY_ADMIN_SUBMISSION_PROGRESS                   = 'public/bc-admin-coordinator/submission-progress';
  MINISTRY_ADMIN_TEST_ATTEMPT_METAS                    = 'public/bc-admin-coordinator/test-attempt-metas';
  MINISTRY_ADMIN_TEST_DESIGNS_NUM_QUESTIONS            = 'public/bc-admin-coordinator/test-designs/num-questions';
  MINISTRY_ADMIN_TEST_WINDOW                           = 'public/bc-admin-coordinator/test-window';
  MINISTRY_ADMIN_UNSUBMISSIONS                         = 'public/bc-admin-coordinator/unsubmissions';
  MINISTRY_TQR_EXTRACT                                 = 'public/bc-admin-coordinator/tqr-extract';
  MRKG_CAND_CERT_TEST                                  = 'public/mrkg-cand/cert-test';                           // get or submit required cert test
  MRKG_CAND_PROFILE                                    = 'public/mrkg-cand/profile';                             // get user or set profile
  MRKG_COORD_APPLICANT                                 = 'public/mrkg-coord/applicant-info';
  MRKG_CTRL_MARKING_WINDOW                             = 'public/mrkg-ctrl/marking-window';                      // get all or 1 marking windows or create new one
  MRKG_CTRL_MARKING_WINDOW_ACCOUNTS_CERT_MRKRS         = 'public/mrkg-ctrl/marking-window/accounts/cert-mrkrs';  // update or get all markers who are certified for the window
  MRKG_CTRL_MARKING_WINDOW_ACCOUNTS_MRKRS              = 'public/mrkg-ctrl/marking-window/accounts/mrkrs';       // update or get marking window markers
  MRKG_CTRL_MARKING_WINDOW_ACCOUNTS_SUPRS              = 'public/mrkg-ctrl/marking-window/accounts/suprs';       // update or get marking window supervisors
  MRKG_CTRL_MARKING_WINDOW_CERT_TESTS                  = 'public/mrkg-ctrl/marking-window/cert-tests';           // update or get all cert tests for the marking window
  MRKG_CTRL_MARKING_WINDOW_DATE_FROM                   = 'public/mrkg-ctrl/marking-window/date-from';            // update marking window start date
  MRKG_CTRL_MARKING_WINDOW_DATE_TO                     = 'public/mrkg-ctrl/marking-window/date-to';              // update marking window end date
  MRKG_CTRL_MARKING_WINDOW_NAME                        = 'public/mrkg-ctrl/marking-window/name';                 // update marking window name
  MRKG_CTRL_MARKING_WINDOW_STATUS                      = 'public/mrkg-ctrl/marking-window/status';               // get or some marking windows
  MRKG_CTRL_MARKING_WINDOW_UNIQ_ITEMS                  = 'public/mrkg-ctrl/marking-window/unique-items';         // get 1/all unique items OR update 1 (q-specific-marking)
  MRKG_CTRL_MRKRS                                      = 'public/mrkg-ctrl/mrkrs';                               // get all markers
  MRKG_CTRL_PROFILE                                    = 'public/mrkg-ctrl/profile';                             // get user profile
  MRKG_CTRL_SUPRS                                      = 'public/mrkg-ctrl/suprs';                               // get all supervisors
  MRKG_LEAD_ITEM_TEMPLATE                              = 'public/mrkg-lead/item-template';
  MRKG_LEAD_STAGE                                      = 'public/mrkg-lead/stage';
  MRKG_MRKR_CERT_TEST                                  = 'public/mrkg-mrkr/cert-test';                           // get or submit required cert test
  MRKG_MRKR_PROFILE                                    = 'public/mrkg-mrkr/profile';                             // get user profile
  MRKG_MRKR_Q_SCORING                                  = 'public/mrkg-mrkr/question-marking';                    // get or update 1 question
  MRKG_MRKR_Q_SCORING_BATCH                            = 'public/mrkg-mrkr/question-marking-batch';              // get or update multiple questions
  MRKG_MRKR_QUESTIONS                                  = 'public/mrkg-mrkr/assigned-qs';                         // get assigned questions
  MRKG_SUPR_ACCOUNTS_MRKRS                             = 'public/mrkg-supr/accounts/mrkrs';                      // get all their markers
  MRKG_SUPR_MARKING_LOG                                = 'public/mrkg-supr/marking-log';                         // get the marking log
  MRKG_SUPR_MARKING_RESPONSE                           = 'public/mrkg-ctrl/marking-response';                    // for marking_responses table
  MRKG_SUPR_MODE                                       = 'public/mrkg-supr/mode';                                // get or update assignment mode
  MRKG_SUPR_MRKR_RE_CERT                               = 'public/mrkg-supr/mrkr/re-cert';                        // require re-certification for 1 marker
  MRKG_SUPR_MRKR_STATUS                                = 'public/mrkg-supr/mrkr/status';                         // update status (pause, resume) for 1 marker
  MRKG_SUPR_PERF                                       = 'public/mrkg-supr/mrkr-perf';                           // get 1 marker's performance
  MRKG_SUPR_PRIORITY                                   = 'public/mrkg-supr/priority';                            // get or update item priority
  MRKG_SUPR_PROFILE                                    = 'public/mrkg-supr/profile'                              // get user profile
  MRKG_SUPR_Q_SCORING                                  = 'public/mrkg-supr/question-marking';                    // get or update 1 question
  MRKG_SUPR_Q_SCORING_BATCH                            = 'public/mrkg-supr/question-marking-batch';              // get or update multiple questions
  MRKG_SUPR_SCHEDULE                                   = 'public/mrkg-supr/schedule';                            // get or update scheduling
  MRKG_UPLD_PROFILE                                    = 'public/mrkg-upld/profile';                             // get user profile
  MRKG_UPLD_RESP                                       = 'public/mrkg-upld/response';                            // upload new response, update existing one, or get all/1
  MRKG_UPLD_RESP_DOC                                   = 'public/mrkg-upld/response/doc';                        // update response document
  MRKG_UPLD_RESP_TEST_FORM                             = 'public/mrkg-upld/response/test-form';                  // update response test form
  MRKG_UPLD_RESP_TEST_TAKER                            = 'public/mrkg-upld/response/test-taker';                 // update response test taker id
  MRKG_UPLD_RESP_TEST_WINDOW                           = 'public/mrkg-upld/response/test-window';                // update response test window
  PASSWORD_INFO_SHEET                                  = 'public/bc-admin-coordinator/password-info-sheet';
  PUBLIC_LANDING_MATERIALS                             = 'public/landing-pages-materials';
  SCHOOL_ADMIN_BCG_STUDENTS                            = 'public/school-admin/bcgrad-students/students';
  SCHOOL_ADMIN_BCG_STUDENTS_ENROLLMENT                 = 'public/school-admin/bcgrad-students/enrollment';
  SCHOOL_ADMIN_BCG_STUDENTS_LOOKUP                     = 'public/school-admin/bcgrad-students/lookup';
  SCHOOL_ADMIN_BCG_STUDENTS_LOOKUP2                    = 'public/school-admin/bcgrad-students/lookup-2';
  SCHOOL_ADMIN_BCG_STUDENTS_REGISTER                   = 'public/school-admin/bcgrad-students/register';
  SCHOOL_ADMIN_BCG_STUDENTS_REGISTRATION               = 'public/school-admin/bcgrad-students/registration';
  SCHOOL_ADMIN_BCG_STUDENTS_REQUESTS                   = 'public/school-admin/bcgrad-students/requests';
  SCHOOL_ADMIN_CHECKLIST                               = 'public/school-admin/tech-readi/checklist';
  SCHOOL_ADMIN_CLASSES                                 = 'public/school-admin/classes';
  SCHOOL_ADMIN_DL_REGISTRATION                         = 'public/school-admin/dl-registration';
  SCHOOL_ADMIN_DL_SCHOOLS                              = 'public/school-admin/dl-schools';
  SCHOOL_ADMIN_STUDENT_ASSESSMENT_CENTRE_REPORT        = 'public/school-admin/grad/student-assessment-centre-report';
  SCHOOL_ADMIN_GRAD_REGISTRATION                       = 'public/school-admin/grad-registration';
  SCHOOL_ADMIN_INVITE                                  = 'public/school-admin/invite';
  SCHOOL_ADMIN_LOCK_DOWN_INFO                          = 'public/school-admin/tech-readi/lock-down-info';
  SCHOOL_ADMIN_OEN_SCHOOL                              = 'public/school-admin/oen-school';
  SCHOOL_ADMIN_QUESTIONNAIRE                           = 'api/v1/school-admin/questionnaires';
  SCHOOL_ADMIN_ROLES                                   = 'public/school-admin/roles';
  SCHOOL_ADMIN_SCHOOL                                  = 'public/school-admin/school';
  SCHOOL_ADMIN_SCORE_ENTRY                             = 'public/school-admin/score-entry/all';
  SCHOOL_ADMIN_SCORE_ENTRY_CHANGES                     = 'public/school-admin/score-entry/changes';
  SCHOOL_ADMIN_SCORE_ENTRY_COMPLETE                    = 'public/school-admin/score-entry/complete';
  SCHOOL_ADMIN_SCORE_ENTRY_FILL_NR                     = 'public/school-admin/score-entry/fill-nr';
  SCHOOL_ADMIN_SCORE_ENTRY_INCOMPLETE                  = 'public/school-admin/score-entry/incomplete';
  SCHOOL_ADMIN_SCORE_ENTRY_UPLOAD                      = 'public/school-admin/score-entry/upload';
  SCHOOL_ADMIN_SCORE_PROFILE                           = 'public/school-admin/score-profile';
  SCHOOL_ADMIN_SESSION                                 = 'public/school-admin/session';
  SCHOOL_ADMIN_STUDENT                                 = 'public/school-admin/student';
  SCHOOL_ADMIN_STUDENT_ASSIGN                          = 'public/school-admin/student/assign';
  SCHOOL_ADMIN_TEACHERS                                = 'public/school-admin/teachers';
  SCHOOL_ADMIN_TEACHERS_INVITE                         = 'public/school-admin/teachers/invite';
  SCHOOL_ADMIN_TEST_WINDOW                             = 'public/school-admin/test-window';
  SCHOOL_ADMIN_TEST_WINDOW_ADMIN_SESSION_OPEN          = 'public/school-admin/test-window/admin-session-open';
  SIMULATE_EXTRACT_RESPONSE                            = 'public/student/extract-item-response';
  TEST_AUTH_ITEM_WINDOW_USES                           = 'public/test-auth/item-window-uses';
  TEST_AUTH_ITEM_STATS                                 = 'public/test-auth/item-stats';
  STUDENT_ASMT_FILTER                                  = 'public/student/asmt-filter';
  STUDENT_CHECKLIST                                    = 'public/student/checklist';
  STUDENT_CLASS_ID                                     = 'public/student/class-id';
  STUDENT_LOCKDOWN                                     = 'public/student/lock-down';
  AUTH_LOCK_DOWN_BROWSER                               = 'public/auth/lock-down-browser';
  STUDENT_RENEW_SCHL_CLASS                             = 'public/student/renew-schl-class';
  STUDENT_SESSION                                      = 'public/student/session';
  STUDENT_SESSION_QUESTION                             = 'public/student/session-question';
  STUDENT_SESSION_QUESTION_TIMESPENT                   = '/public/student/session-question-timespent';
  STUDENT_STAGE_SUBMISSION                             = 'public/student/stage-submission';
  STUDENT_TEST_WINDOW                                  = 'public/student/test-window';
  SUPPORT_INVITATION_CODE                              = 'public/support/u-invitation-code';
  SUPPORT_OVERRIDE_START_SESSION                       = 'public/support/test-session/date';
  SUPPORT_RESET_PROGRESS                               = 'public/support/reset-progress';
  SUPPORT_SCHEDULED_TASKS                              = 'public/support/scheduled-tasks';
  SUPPORT_SYS_FLAGS                                    = 'public/support/sys-flags';
  SUPPORT_TEST_FORM_PRINT                              = 'public/support/test-form-print';
  SUPPORT_TT_BOOKING                                   = 'public/support/tt-booking';
  SUPPORT_TT_LANG_REQ                                  = 'public/support/tt-lang-req';
  SUPPORT_TT_NOTIFICATIONS                             = 'public/support/tt-notifications';
  SUPPORT_TT_REPORT_NOTIFICATIONS                      = 'public/support/tt-report-notifications';
  SUPPORT_TT_UPCOMING_SESSIONS                         = 'public/support/tt-upcoming-sessions';
  SUPPORT_VALIDATE_OCT_MEMBER                          = 'public/support/validate-oct-member';
  TEST_ADMIN_ACCOMM_PENDING_REQUESTS                   = 'public/test-admin/accomm/pending-requests';
  TEST_ADMIN_ACCOMM_REQUESTS_RESPONSES                 = 'public/test-admin/accomm/responses';
  TEST_ADMIN_ACCOMM_TRANSFER_REQ                       = 'public/test-admin/accomm/transfer-req';
  TEST_ADMIN_ACCOUNTS_ACCESS                           = 'public/test-admin/accounts/access';
  TEST_ADMIN_ACCOUNTS_ASSIGNED_COORD                   = 'public/test-admin/accounts/assigned-coord';
  TEST_ADMIN_ACCOUNTS_ASSIGNED_SESSIONS                = 'public/test-admin/accounts/assigned-sessions';
  TEST_ADMIN_INSTITUTION                               = 'public/test-admin/institution';
  TEST_ADMIN_INVIGILATION_                             = 'public/test-admin/invigilation/';
  TEST_ADMIN_INVIGILATION_ABSENCE_REPORT               = 'public/test-admin/invigilation/absence-report';
  TEST_ADMIN_INVIGILATION_CLOSE                        = 'public/test-admin/invigilation/close';
  TEST_ADMIN_INVIGILATION_GROUP_ISSUE                  = 'public/test-admin/invigilation/group-issue';
  TEST_ADMIN_INVIGILATION_GROUP_TIME_EXTEND            = 'public/test-admin/invigilation/group-time-extend';
  TEST_ADMIN_INVIGILATION_IDENTITY_NO                  = 'public/test-admin/invigilation/identity-no';
  TEST_ADMIN_INVIGILATION_IDENTITY_VERIF               = 'public/test-admin/invigilation/identity-verif';
  TEST_ADMIN_INVIGILATION_INDIV_ISSUE                  = 'public/test-admin/invigilation/indiv-issue';
  TEST_ADMIN_INVIGILATION_INDIV_TIME_EXTEND            = 'public/test-admin/invigilation/indiv-time-extend';
  TEST_ADMIN_INVIGILATION_ONBEHALF_QUESTION_RESPONSE   = 'public/test-admin/invigilation/onbehalf/question-response';
  TEST_ADMIN_INVIGILATION_ONBEHALF_TEST_ATTEMPT        = 'public/test-admin/invigilation/onbehalf/test-attempt';
  TEST_ADMIN_INVIGILATION_PAUSE                        = 'public/test-admin/invigilation/pause';
  TEST_ADMIN_INVIGILATION_REPORT_APPLICANT_ISSUE       = 'public/test-admin/invigilation/report-applicant-issue';
  TEST_ADMIN_INVIGILATION_SEND                         = 'public/test-admin/invigilation/send';
  TEST_ADMIN_INVIGILATION_TEMP_ACCESS                  = 'public/test-admin/invigilation/temp-access';
  TEST_ADMIN_SEB_DOWNLOAD_LINK                         = 'public/test-admin/seb-download-link';
  TEST_ADMIN_STUDENTS_BOOKING                          = 'public/test-admin/students/booking';
  TEST_ADMIN_STUDENTS_SCHOOL                           = 'public/test-admin/students/school';
  TEST_ADMIN_STUDENTS_WALK_IN                          = 'public/test-admin/students/walk-in';
  TEST_ADMIN_TEST_SESSION_ASSIGNED_INVIGILATOR         = 'public/test-admin/test-session/assigned-invigilator';
  TEST_ADMIN_TEST_SESSION_BOOKING_TRANSFER_REQ         = 'public/test-admin/test-session/booking-transfer-req';
  TEST_ADMIN_TEST_SESSION_BOOKINGS                     = 'public/test-admin/test-session/bookings';
  TEST_ADMIN_TEST_SESSION_BOOKINGS_INDIC               = 'public/test-admin/test-session/bookings-indic';
  TEST_ADMIN_TEST_SESSION_CANCELLATION                 = 'public/test-admin/test-session/cancellation';
  TEST_ADMIN_TEST_SESSION_CAPACITY                     = 'public/test-admin/test-session/capacity';
  TEST_ADMIN_TEST_SESSION_INVITATION_CODE              = 'public/test-admin/test-session/invitation-code';
  TEST_ADMIN_TEST_SESSION_RESTRICTIONS                 = 'public/test-admin/test-session/restrictions';
  TEST_ADMIN_TEST_SESSION_SETUP_ACCOMM_PING            = 'public/test-admin/test-session-setup/accomm-ping';
  TEST_ADMIN_TEST_SESSION_SETUP_ACTIVE                 = 'public/test-admin/test-session-setup/active';
  TEST_ADMIN_TEST_SESSION_SETUP_CHECKLIST              = 'public/test-admin/test-session-setup/checklist';
  TEST_ADMIN_TEST_SESSION_SETUP_LOCATIONS              = 'public/test-admin/test-session-setup/locations';
  TEST_ADMIN_TEST_SESSION_SETUP_SESSIONS               = 'public/test-admin/test-session-setup/sessions';
  TEST_ADMIN_TEST_SESSION_SETUP_TECH_READY             = 'public/test-admin/test-session-setup/tech-ready';
  TEST_ADMIN_TEST_SESSION_SETUP_TEST_WINDOWS           = 'public/test-admin/test-session-setup/test-windows';
  TEST_ADMIN_TEST_SESSION_SETUP_TIME_SLOT_RESTRICTION  = 'public/test-admin/test-session-setup/time-slot-restriction';
  TEST_ADMIN_TEST_SESSION_SETUP_TIME_SLOTS             = 'public/test-admin/test-session-setup/time-slots';
  TEST_ADMIN_TEST_SESSION_SETUP_TIMEZONE               = 'public/test-admin/test-session-setup/timezone';
  TEST_ADMIN_TEST_SESSION_STATUS_UPDATE                = 'public/test-admin/test-session/status-update';
  TEST_ADMIN_TEST_SESSION_WAIT_LISTINGS_INDIC          = 'public/test-admin/test-session/wait-listings-indic';
  TEST_ADMIN_TEST_SESSION_WAITLISTER_TRANSFER_REQ      = 'public/test-admin/test-session/waitlister-transfer-req';
  TEST_ADMIN_TEST_SESSION_WAITLISTERS                  = 'public/test-admin/test-session/waitlisters';
  TEST_ADMIN_TEST_SESSIONS_ALL                         = 'public/test-admin/test-sessions/all';
  TEST_ADMIN_TEST_SESSIONS_MY_SINGLE                   = 'public/test-admin/test-sessions/my-single';
  TEST_ADMIN_TEST_SESSIONS_MY_UPCOMING                 = 'public/test-admin/test-sessions/my-upcoming';
  TEST_ADMIN_TEST_SESSIONS_SINGLE                      = 'public/test-admin/test-sessions/single';
  TEST_ADMIN_TEST_SESSIONS_SUMMARY                     = 'public/test-admin/test-sessions/summary';
  TEST_ADMIN_VALIDATE_SEB                              = 'public/test-admin/validate-seb';
  TEST_AUTH_ACCOUNTS_ACCESS                            = 'public/test-auth/accounts/access';
  TEST_AUTH_ACCOUNTS_RESEND_INVITE                     = 'public/test-auth/accounts/resend-invite';
  TEST_AUTH_ASSET                                      = 'public/test-auth/assets';
  TEST_AUTH_ASSET_GROUPS                               = 'public/test-auth/asset-groups';
  TEST_AUTH_ASSET_LIBRARIES                            = 'public/test-auth/asset-libraries';
  TEST_AUTH_ASSET_LIBRARY_FIELDS                       = 'public/test-auth/asset-library-fields';
  TEST_AUTH_ASSET_VERSIONS                             = 'public/test-auth/asset-versions';
  TEST_AUTH_CONTENT_SEARCH                             = 'public/test-auth/content-search';
  TEST_AUTH_FRAMEWORKS                                 = 'public/test-auth/frameworks';
  TEST_AUTH_FRAMEWORKS_AUDIT                           = 'public/test-auth/frameworks-audit'
  TEST_AUTH_GROUP                                      = 'public/test-auth/group';
  TEST_AUTH_GROUP_MEMBERS                              = 'public/test-auth/group-members';
  TEST_AUTH_GROUP_ROLES                                = 'public/test-auth/group-roles';
  TEST_AUTH_GROUPS                                     = 'public/test-auth/groups';
  TEST_AUTH_ITEM_IMPRESSION                            = 'public/test-auth/item-impressions';
  TEST_AUTH_ITEM_SET                                   = 'public/test-auth/item-set';
  TEST_AUTH_ITEM_TAG_LINK                              = 'public/test-auth/item-tag-link';
  TEST_AUTH_ITEM_TAGS                                  = 'public/test-auth/item-tags';
  TEST_AUTH_MANAGE_AUTHOR                              = 'public/test-auth/manage-author';
  TEST_AUTH_NOTE_FILES                                 = 'public/test-auth/note-files';
  TEST_AUTH_NOTES                                      = 'public/test-auth/notes';
  TEST_AUTH_NOTES_AUDIT                                = 'public/test-auth/notes/audit';
  TEST_AUTH_NOTIFICATIONS                              = 'public/test-auth/notifications';
  TEST_AUTH_PUB_PWD_PROTECTED                          = 'public/test-auth/public-pwd-protected';
  TEST_AUTH_QUESTION_REVISIONS                         = 'public/test-auth/question-revisions';
  TEST_AUTH_QUESTION_SET_LISTS                         = 'public/test-auth/question-set-lists';
  TEST_AUTH_QUESTIONS                                  = 'public/test-auth/questions';
  TEST_AUTH_SHOW_COMMENTS                              = 'public/test-auth/show-comments';
  TEST_AUTH_SINGLE_GROUP                               = 'public/test-auth/single-group';
  TEST_AUTH_STYLE_PROFILES                             = 'public/test-auth/style-profiles';
  TEST_AUTH_TEST_DESIGNS                               = 'public/test-auth/test-designs';
  TEST_AUTH_TEST_DESIGNS_IS_PUBLIC                     = 'public/test-auth/test-designs/is-public';
  TEST_AUTH_TEXT_VOICE                                 = 'public/test-auth/text-voice';
  TEST_CERT_DATA_FILE                                  = 'public/test-cert/data-file';
  TEST_CERT_ROLES                                      = 'public/test-cert/roles';
  TEST_CTRL_ACCOUNTS_INSTITUTIONS                      = 'public/test-ctrl/accounts/institutions';
  TEST_CTRL_ACCOUNTS_TEST_ADMIN_REVOKE                 = 'public/test-ctrl/accounts/test-admin-revoke';
  TEST_CTRL_ACCOUNTS_TEST_ADMINS                       = 'public/test-ctrl/accounts/test-admins';
  TEST_CTRL_ACCOUNTS_TEST_CERT_REVOKE                  = 'public/test-ctrl/accounts/test-cert-revoke';
  TEST_CTRL_ACCOUNTS_TEST_CERTS                        = 'public/test-ctrl/accounts/test-certs';
  TEST_CTRL_ACCOUNTS_TEST_CTRL_REVOKE                  = 'public/test-ctrl/accounts/test-ctrl-revoke';
  TEST_CTRL_ACCOUNTS_TEST_CTRLS                        = 'public/test-ctrl/accounts/test-ctrls';
  TEST_CTRL_BOARDS                                     = 'public/test-ctrl/schools/boards';
  TEST_CTRL_CLASSES                                    = 'public/test-ctrl/schools/classes';
  TEST_CTRL_DATA_FILE                                  = 'public/test-ctrl/data-file';
  TEST_CTRL_LANG_REQ                                   = 'public/test-ctrl/lang-req';
  TEST_CTRL_ROLES                                      = 'public/test-ctrl/roles';
  TEST_CTRL_SCHOOLS                                    = 'public/test-ctrl/schools/school';
  TEST_CTRL_STUDENTS                                   = 'public/test-ctrl/schools/students';
  TEST_CTRL_SUMMARY                                    = 'public/test-ctrl/summary';
  TEST_CTRL_TEACHERS                                   = 'public/test-ctrl/schools/teachers';
  TEST_CTRL_TEST_WINDOW_CONFIG                         = 'public/test-ctrl/test-window/config';
  TEST_CTRL_TEST_WINDOW_PUBLISH                        = 'public/test-ctrl/test-window/publish';
  TEST_CTRL_TEST_WINDOW_RELEASE_NOTES                  = 'public/test-ctrl/test-window/release-notes';
  TEST_CTRL_TEST_WINDOW_RETRIEVALS                     = 'public/test-ctrl/test-window/retrievals';
  TEST_CTRL_TEST_WINDOW_SUMMARY                        = 'public/test-ctrl/test-window/summary';
  TEST_CTRL_TEST_WINDOW_TEST_DESIGN                    = 'public/test-ctrl/test-window/test-design';
  TEST_CTRL_TEST_WINDOW_TEST_SESSIONS                  = 'public/test-ctrl/test-window/test-sessions';
  TEST_CTRL_VALIDATION_DATES                           = 'public/test-ctrl/validation/dates';
  TEST_CTRL_VALIDATION_ISSUE                           = 'public/test-ctrl/validation/issue';
  TEST_CTRL_VALIDATION_ISSUE_RESOLUTION                = 'public/test-ctrl/validation/issue-resolution';
  TEST_CTRL_VALIDATION_READY                           = 'public/test-ctrl/validation/ready';
  TEST_CTRL_VALIDATION_TEST_ATTEMPTS                   = 'public/test-ctrl/validation/test-attempts';
  TEST_CTRL_VALIDATION_TEST_SESSIONS                   = 'public/test-ctrl/validation/test-sessions';
  TEST_DESIGN_QUESTION_VERSIONS                        = 'public/test-auth/test-design-question-versions';
  TEST_QUESTION_REGISTER_ASSESSMENT_PARAMS             = 'public/test-question-register/assessment-params';
  TEST_QUESTION_REGISTER_PARAM_MAP                     = 'public/test-question-register/tqr-map';
  TEST_TAKER_ACCOMMODATIONS_PENDING_REQ                = 'public/test-taker/accommodations/pending-req';
  TEST_TAKER_DATA_DOWNLOAD                             = 'public/test-taker/data/download';
  TEST_TAKER_INVIGILATION_QUESTION_CONTENT             = 'public/test-taker/invigilation/question-content';
  TEST_TAKER_INVIGILATION_QUESTION_RESPONSE            = 'public/test-taker/invigilation/question-response';
  TEST_TAKER_INVIGILATION_REPORT_ISSUE                 = 'public/test-taker/invigilation/report-issue';
  TEST_TAKER_INVIGILATION_SCREEN_SESSION               = 'public/test-taker/invigilation/screen-session';
  TEST_TAKER_INVIGILATION_SECTION_SUBMIT               = 'public/test-taker/invigilation/section-submit';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT                 = 'public/test-taker/invigilation/test-attempt';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT_ATTEST          = 'public/test-taker/invigilation/test-attempt-attest';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT_LANG            = 'public/test-taker/invigilation/test-attempt-lang';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT_TIME            = 'public/test-taker/invigilation/test-attempt-time';
  TEST_TAKER_INVIGILATION_TEST_FORM                    = 'public/test-taker/invigilation/test-form';
  TEST_TAKER_REPORT_RESULTS                            = 'public/test-taker/report/results';
  TEST_TAKER_TEST_SESSIONS_ALL                         = 'public/test-taker/test-sessions/all';
  TEST_TAKER_TEST_SESSIONS_BOOKING                     = 'public/test-taker/test-sessions/booking';
  TEST_TAKER_TEST_SESSIONS_INVITATION                  = 'public/test-taker/test-sessions/invitation';
  TEST_TAKER_TEST_SESSIONS_PAST_ATTEMPTS               = 'public/test-taker/test-sessions/past-attempts';
  TEST_TAKER_TEST_SESSIONS_WAITLIST                    = 'public/test-taker/test-sessions/waitlist';
  TRANSLATION                                          = 'public/translation';
  SUPPORT_FORM_ASSIGNMENTS                             = 'public/support/form-assignments';
}
