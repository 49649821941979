import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { BcAccountsService, DistrictDetail, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { MaFinalResultsViews } from 'src/app/ui-ministryadmin/ma-final-results/views';
import { SaFinalResultsViews } from 'src/app/ui-schooladmin/sa-final-results/views';
import { BCFSASchoolAdminView } from 'src/app/ui-schooladmin/view-schooladmin-dashboard/data/views';

@Component({
  selector: 'fsa-self-reflection',
  templateUrl: './fsa-self-reflection.component.html',
  styleUrls: ['./fsa-self-reflection.component.scss']
})
export class FsaSelfReflectionComponent implements OnInit {

  @Input() testWindow: TestWindow;

  constructor(
    private bcAssessments: BcAssessmentsService,
    private lang: LangService
  ) { }

  data:any;

  ngOnInit(): void {
    this.init();
  }

  async init() {
    await this.getData();
  }

  getTitle(defaultTitle){
    if(typeof(this.testWindow.month_title) == 'object'){
      return this.testWindow.month_title[this.lang.c()];
    }
    return defaultTitle;
  }

  async getData() {
    const response = await this.bcAssessments.getFinalReports(this.testWindow.id);
    this.data = response;
    console.log(response);
  }
  
  public getSelfReflections() {
    return this.data.filter(d => d.slug === 'self-reflection' && d.meta);
  }

}
