<div class="left-panel"  [class.is-collapsed]="!isSidePanelExpanded()" *ngIf="isLanguageInitialized && !isInMarking()">
  <div class="panel-top">
    <div>
      <div class="user-profile dropdown"  [class.is-active]="isAccountDropupActive()">
        <div class="dropdown-trigger " (click)="toggleAccountDropdown()">
          <div class="avatar-holder">
            <!-- <img *ngIf="getUserPhotoUrl()" src="{{getUserPhotoUrl()}}"> -->
          </div>
          <a aria-haspopup="true" aria-controls="dropdown-menu2">
            <div class="main"> {{displayName}} </div>
            <div class="account-subtitle"> {{displayEmail}} </div>
          </a>
          <span class="icon is-small">
            <i *ngIf="!isAccountDropupActive()" class="fa fa-angle-right" aria-hidden="true"></i>
            <i *ngIf="isAccountDropupActive()" class="fa fa-angle-down" aria-hidden="true"></i>
          </span>
        </div>
      </div>
      <div class="account-mgmt-well" [class.is-collapsed]="!isAccountDropupActive()">
        <div class="dropdown-content">
          <!-- <a routerLink="/student/classroom/7soSeoUIFA0xCtsf7yJ1/headsup/XBsomgG1zXVuq4SE1bNF" class="dropdown-item has-icon" > -->
            <a routerLink="/teacher/avatar" class="dropdown-item has-icon" *ngIf="false">
              <i class="fa fa-profile" aria-hidden="true"></i> Account Settings
            </a>
            <hr class="dropdown-divider" *ngIf="false">
            <a href="#" class="dropdown-item" *ngIf="false">
              Subscriptions
            </a>
            <hr class="dropdown-divider" *ngIf="false">
            <a (click)="logout()" class="dropdown-item has-icon">
              <i class="fa fa-sign-out-alt" aria-hidden="true"></i>&nbsp;<tra slug="lbl_logout"></tra>
            </a>
          </div>
        </div>
      </div>
      
      <div class="panel-links">
        <a 
        *ngFor="let panelLink of sidePanelOptions" 
        class="panel-link" 
        [class.is-active]="isRouteActive(panelLink)" 
        [class.is-hidden]="isOptionHidden(panelLink)"
        [class.is-disabled]="panelLink.disabled"
        (click)="navItemClicked($event, panelLink)"
        >
        <img class="panel-icon" src="{{panelLink.iconUrl}}">
        <span class="panel-link-caption">
          <tra [slug]="panelLink.caption"></tra>
        </span>
      </a>
    </div>
    
  </div>
  <div class="panel-bottom">
    <div class="logo-container">
      <!-- <a href="https://www.eqao.com/" target="_blank"> -->
        <!-- <a routerLink="/en/login-router-st"> -->
          <!-- <div>
            <img src="{{lang.tra(whiteLabel.scurrentLogo)}}" style="max-height:4em">
          </div> -->
        <!-- </a> -->
      </div>
      <div *ngIf="false">
        <a href="" target="_blank" class="button is-small has-icon is-fullwidth is-white">
          <span class="icon">
            <i class="fa fa-life-ring" aria-hidden="true"></i>
          </span>
          <span>F.A.Q.</span>
        </a>
      </div>
      
      <div class="expander-collapser">
        <button class="button is-light" (click)="expandSidePanel()" *ngIf="!isSidePanelExpanded()">
          <i class="fa fa-caret-right" aria-hidden="true"></i>
        </button>
        <button class="button is-light" (click)="collapseSidePanel()" *ngIf="isSidePanelExpanded()">
          <i class="fa fa-caret-left" aria-hidden="true"></i>
        </button>
      </div>
      
    </div>
    
  </div>