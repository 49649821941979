import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';


export interface ILinkRequest {
  readerId?: string;
  readerElementId?: string;
  itemLabel?: string;
  bookmarkId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HyperlinkService {
  //First variable communicates from link to test runner
  public linkRequest:BehaviorSubject<ILinkRequest> = new BehaviorSubject<ILinkRequest>({});
  //Second variable communicates from test-runner to canvas
  public linkRequestSecond:BehaviorSubject<ILinkRequest> = new BehaviorSubject<ILinkRequest>({});
  
  public requestForLinkUpdate:BehaviorSubject<ILinkRequest> = new BehaviorSubject<ILinkRequest>({});
  public canvasBookmarkChanged:BehaviorSubject<ILinkRequest> = new BehaviorSubject<ILinkRequest>({});

  constructor() { }
}
