<div class="bc-upload-widget">
    <div class="dropzone">
        <tra [slug]="prompt"></tra>
        <input name="file" type="file" #importInput (change)="onImportFileChange($event.target.files)"
            [disabled]="disabled" (click)="$event.target.value=null">
        <div>
            <ng-container *ngIf="fileToUpload">
                <tra [slug]="fileDescription"></tra>&nbsp;
                <tra slug="bc_upload_widget_to_upload"></tra>: <b>{{fileToUpload.name}}</b>
            </ng-container>
            <ng-container *ngIf="!fileToUpload">
                {{dragAndDropText}}
            </ng-container>
        </div>
        <div>or</div>
        <label for="importInput">
            <ng-container *ngIf="fileToUpload">
                <tra slug="bc_upload_widget_choose_another_file"></tra>
            </ng-container>
            <ng-container *ngIf="!fileToUpload">
                <tra slug="bc_upload_widget_browse_for_file"></tra>
            </ng-container>
        </label>
    </div>
    <div *ngIf="error" class="error-message">{{error}}</div>
</div>