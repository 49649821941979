import { Component, OnInit, Input } from '@angular/core';
import { LangService } from '../../core/lang.service';

interface guidesReadinessSection {
  sub_section_title: string,
  sub_section_description: string,
  content: guidesReadinessLink[]
}

interface guidesReadinessLink {
  enabled: boolean,
  document_link: string,
  document_title: string
}

@Component({
  selector: 'tech-guides',
  templateUrl: './tech-guides.component.html',
  styleUrls: ['./tech-guides.component.scss']
})

export class TechGuidesComponent implements OnInit {

  @Input () endPointRes;
  techGuidesLinks;

  constructor(
    public lang: LangService,
  ) { }

  ngOnInit() {
    this.techGuidesLinks = this.endPointRes.reduce((acc: any, curr: any) => {
      let subSection: guidesReadinessSection = acc.find((subs: any) => subs.sub_section_title === curr.sub_section_title);
      if (!subSection) {
        subSection = { sub_section_title: curr.sub_section_title, sub_section_description: curr.sub_section_description, content: [] };
        acc.push(subSection);
      }
      subSection.content.push({
        ...curr
      });
      return acc;
    }, []);
  }

  displayTitle(title){
    try{
      title = JSON.parse(title);
      return this.lang.c() == 'en' ? title.en : title.fr; 
    }catch(e){
      return title;
    }
  }
}
