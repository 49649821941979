import { Injectable } from '@angular/core';
import { AuthService, getFrontendDomain } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { Id } from '@feathersjs/feathers';
import { LoginGuardService } from '../api/login-guard.service';
import { LangService } from '../core/lang.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

/**
 * Lockdown Browser Service
 *  - Lockdown Browser functionality, such as Respondus, SEB
 *  - Respondus Browser Link Generation and Validation
 *  - Rendering lockdown-related text and pop-ups
 */

export interface ISessionSecurityInfo {
  schl_class_group_id: Id;
  enforce_ldb: boolean;
  is_secure: boolean;
}

export interface ILDBLoginConfiguration {
  authToken?: string,
  passwordSeed?: number
}

export const LDB_LINK_TEMPLATE = {
  'LDB_WINDOWS': 'ldb_download_windows',
  'LDB_MAC': 'ldb_download_mac',
  'LDB_IPAD': 'https://apps.apple.com/ca/app/lockdown-browser/id659101775',
  'LDB_CHROMEBOOK': 'https://chromewebstore.google.com/detail/vretta-lockdown-browser/pjheepoiodhffgmpobggaacgkacoflbj',
}

@Injectable({
  providedIn: 'root'
})
export class LockdownService {

  public isInitialized: boolean = false;
  public sessionSecurityInfo: ISessionSecurityInfo;
  public isUsingSecureBrowser: boolean = false;
  public ldbLaunchURL: SafeResourceUrl;
  private launchTimeout: NodeJS.Timeout;
  public launchLinkTriggered = false;


  constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private loginGuard: LoginGuardService,
    private lang: LangService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {}
  ngOnDestroy(){}

  public async getSessionSecurityInfo(schl_class_group_id?: number): Promise<boolean> {

    const rldbarv = this.auth.getCookie('rldbarv')
    const kioskPassword = this.auth.getKioskPassword()

    const sessionSecurityInfo: ISessionSecurityInfo = await this.auth.apiFind(this.routes.STUDENT_LOCKDOWN, {
      query: {
        schl_class_group_id: schl_class_group_id,
        rldbarv,
        kioskPassword
      }
    });
    if (sessionSecurityInfo) {
      this.sessionSecurityInfo = sessionSecurityInfo;
      this.isInitialized = true;
      return true;
    }
    else {
      this.isInitialized = false;
      return false;
    }
  }

  public async getLDBEncrptedLaunchLink(LDBLoginConfiguration: ILDBLoginConfiguration){
    const res = await this.auth.apiCreate(this.routes.AUTH_LOCK_DOWN_BROWSER, {
        clientDomain: getFrontendDomain(),
        lang: this.lang.c(),
        cbLDBex: +this.isChromeBook,
        LDBLoginConfiguration,
        queryParams: this.route.snapshot.queryParams,
      }).catch(error =>{
        switch(error.message){
          default:
            this.loginGuard.quickPopup(error.message)
            break
        }
      });

    this.ldbLaunchURL = this.sanitizer.bypassSecurityTrustResourceUrl(res)
  }

  public get renderInstructionsText() {
    for(let platform in LDB_LINK_TEMPLATE) {
      LDB_LINK_TEMPLATE[platform] = this.lang.tra(LDB_LINK_TEMPLATE[platform])
    }

    const renderedCaption = this.lang.tra('txt_ldb_install_instructions', undefined, LDB_LINK_TEMPLATE)
    return renderedCaption
  }

   /** Show installation instructions pop-up */
   public installInstructionsPopup() {
    this.loginGuard.quickPopup(this.renderInstructionsText)
  }

  /** 
   * Launch pop-up in case the LDB was not started 
   * - has a delay of 5 seconds
   */
  public launchDelayedPopup() {
    if(this.launchTimeout) {
      clearTimeout(this.launchTimeout);
    }

    this.launchTimeout = setTimeout(() => {
      const popupText = this.lang.tra('txt_ldb_launch_popup', undefined, {EXAM_SUPERVISOR_TEMPLATE: this.lang.tra('lbl_invigilator')});
      this.loginGuard.quickPopup(popupText);
    }, 5000);
  }

  /** Check if the app is running on Chrome LDB Extension */
  public get isChromeBook() {
    return document.cookie.includes("cbLDBex=1");
  }

  /**
   * This function should and only be use for Chromebook to start the extension  
   * Will render the iFrame on a page, triggering an auto-launch on the link 
   */
  public oniFrameLaunchLockdownBrowser(event: Event): void {
    // Prevent the default anchor link behavior
    event.preventDefault();

    try {
      this.launchLinkTriggered = true;
      setTimeout(() => {
        this.launchLinkTriggered = false;
      }, 1000);
      console.log('LDB_LAUNCHED', this.ldbLaunchURL);
    } catch (error) {
      alert(`Error launching LockDown Browser: ${error}`);
    }
  }

  public exitBrowser() {
    window.location.href = `${window.location.origin}/?rldbxb=1`;
  }

  /** Show an error message and exit the LDB */
  public ldbExitPrompt(caption = "ldb_error_exit_caption") {
    // this happens directly after another popup, so we need to delay it
    setTimeout(() => {
      this.loginGuard.confirmationReqActivate({
        caption,
        confirm: this.exitBrowser,
        btnCancelConfig: {
          hide: true
        }
      })
    }, 500);
  }

  public isSecure() {
    if(!this.isInitialized) {
      return false;
    }
    return this.sessionSecurityInfo.is_secure;
  }

  public isEnforcingLDB() {
    return this.sessionSecurityInfo.enforce_ldb;
  }
}